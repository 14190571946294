import React, { useEffect } from "react";
import SideChatItem from "../../components/sidechat/sideChatItem/SideChatItem";
import SearchUsersChat from "../../components/sidechat/searchUsersChat/searchUsersChat";
import { useTranslation } from "react-i18next";
import { Settings } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetUserChatRoomsQuery } from "../../_redux/features/chatRoom/services";
import { useGetProfileQuery } from "../../_redux/auth/services";
import { ChatRoomInterface } from "../../_redux/features/chatRoom/chatRoomInterface";
import { Image } from "@nextui-org/react";
import SideChatSkeletons from "./sideChatItem/SideChatSkeletons";
import { AppRoutes } from "../../utils/appRoutes";
import NewAsideMessageModal from "../../pages/private/chat/NewAsideMessageModal";

export default function SideChat() {
  let [searchParams] = useSearchParams();
  const { i18n, t } = useTranslation();
  let navigate = useNavigate();
  const { id } = useParams();
  const { data: profile } = useGetProfileQuery();
  const { data: chatRoomsData, isLoading: isChatRoomsLoading } =
    useGetUserChatRoomsQuery(
      {
        id: profile?.id,
        filters: { itemsPerPage: 20, ...Object.fromEntries(searchParams) },
      },
      { skip: !profile }
    );
  const { list: chatRooms } = chatRoomsData || {};

  useEffect(() => {
    if (chatRooms?.length > 0 && !id) {
      navigate(
        AppRoutes.PRIVATE_CONVERSATION?.replace(
          ":locale",
          `${i18n.language}`
        ).replace(":id", chatRooms[0]?.id)
      );
    }
  }, [chatRooms]);

  return (
    <div
      className={`relative h-full ${
        id ? "max-md:hidden" : ""
      } max-md:w-full w-2/5 bg-white`}
    >
      <div className="absolute z-10 top-0 left-0 right-0 w-full flex flex-row justify-start items-start bg-white">
        <div className="w-full flex flex-col justify-between items-center gap-3">
          <div className="w-full flex flex-row justify-between items-center">
            <div className="text-3xl font-xing-boldd">{t("sidebar.chat")}</div>
            <div>
              <Settings className="cursor-pointer" width={30} height={30} />
              <NewAsideMessageModal />
            </div>
          </div>
          <SearchUsersChat />
        </div>
      </div>

      <div className="relative h-full w-full pt-[100px]">
        <div className="h-full w-full flex flex-col justify-start items-start overflow-y-scroll overflow-x-hidden p-1 gap-1">
          {isChatRoomsLoading ? (
            <SideChatSkeletons />
          ) : (
            <>
              {chatRooms?.length > 0 ? (
                chatRooms?.map((chatRoom: ChatRoomInterface) => (
                  <SideChatItem key={chatRoom?.id} chatRoom={chatRoom} />
                ))
              ) : (
                <div>
                  <Image
                    src="/svg/undraw_tree_swing_re_pqee.svg"
                    alt="facebook icon"
                    width="100%"
                  />
                  <p className="font-bold text-center my-4">
                    {t("chat.label", { count: 0 })}
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
