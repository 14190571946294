import { Button } from "@nextui-org/react";
import React from "react";
import { CandidateInterface } from "../../../_redux/features/events/interfaces";
import { mediaRoutes } from "../../../utils/mediaRoutes";
import { AppRoutes } from "../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import CustomUserAvatar from "../../customAvatar/CustomUserAvatar";
import { useTranslation } from "react-i18next";

interface CandidateInterfaceProps {
  you?: string | undefined;
  candidate: CandidateInterface;
}

export default function Candidate({
  you = undefined,
  candidate,
}: CandidateInterfaceProps) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const seeProfile = () =>
    navigate(
      AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
        ":locale",
        `${i18n.language}`
      ).replace(":id", candidate?.id)
    );

  return (
    <div className="select-none min-w-[200px] max-w-[200px] flex flex-col justify-around items-center gap-1">
      <CustomUserAvatar
        className="h-[60px] w-[60px] cursor-pointer"
        src={`${mediaRoutes.USER_PROFILE}${candidate?.imageUrl}`}
        onClick={() => seeProfile()}
      />
      <p
        className="text-black font-xing-bold text-sm first-letter:capitalize cursor-pointer"
        onClick={() => seeProfile()}
      >
        {candidate?.fullname + " " + you}
      </p>
      <p className="text-gray-400 font-normal text-sm">{candidate?.job}</p>
      {!you && (
        <Button radius="full" className="bg-black text-white">
          {t("add.label")}
        </Button>
      )}
    </div>
  );
}
