import React from "react";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {useTranslation} from "react-i18next";
import SkillAddModal from "../modals/Profile/Skill/SkillAddModal";
import {Add} from "@mui/icons-material";

export default function NoSkillsFound() {

    const {t} = useTranslation()

    return (
        <div className="flex flex-row justify-between items-start gap-3">
            <div className={`h-[32px] w-[45px] flex flex-row justify-center items-center bg-[#fdd039] rounded-full p-1`}>

                <PriorityHighIcon style={{ height: "12px", width:"12px" }} />
            </div>
            <div className="flex flex-col justify-between gap-3">
                <div>
                    <p className="text-md text-gray-600">
                        {t("profile.skills.what.skills.have.you.acquired.label")}
                    </p>
                </div>
                <div className="flex flex-row justify-start gap-3">
                    <SkillAddModal
                        isIconOnly={false}
                        variant="bordered"
                        color="primary"
                        radius="full"
                        className="font-xing-bold"
                        text={t("profile.skills.add.label")}
                        icon={<Add height={10} width={10} />}
                    />
                </div>
            </div>

        </div>
    )
}