import React from "react";
import { SvgIllustrationInterface } from "./AttentesProIllustration";
export default function InvitationIllustration({
  primary = "#ffb836",
}: SvgIllustrationInterface) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="Layer 1"
      width="72"
      height="72"
      viewBox="0 0 876 554"
    >
      <path
        d="M516,429H179a17.01909,17.01909,0,0,1-17-17V223a17.01909,17.01909,0,0,1,17-17H516a17.01917,17.01917,0,0,1,17,17V412A17.01917,17.01917,0,0,1,516,429ZM179,208a15.017,15.017,0,0,0-15,15V412a15.017,15.017,0,0,0,15,15H516a15.017,15.017,0,0,0,15-15V223a15.017,15.017,0,0,0-15-15Z"
        transform="translate(-162 -173)"
        fill="#e6e6e6"
      />
      <path
        d="M430.60039,281.313h-65a4.5,4.5,0,1,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
        transform="translate(-162 -173)"
        fill="#e6e6e6"
      />
      <path
        d="M493.96026,309.813H364.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H493.96026c2.01025,0,3.64013-2.02,3.64013-4.5S495.97051,309.813,493.96026,309.813Z"
        transform="translate(-162 -173)"
        fill="#e6e6e6"
      />
      <path
        d="M493.96026,339H364.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H493.96026c2.01025,0,3.64013-2.02,3.64013-4.5S495.97051,339,493.96026,339Z"
        transform="translate(-162 -173)"
        fill="#e6e6e6"
      />
      <path
        d="M316,387.5H204A10.512,10.512,0,0,1,193.5,377V252A10.512,10.512,0,0,1,204,241.5H316A10.512,10.512,0,0,1,326.5,252V377A10.512,10.512,0,0,1,316,387.5Z"
        transform="translate(-162 -173)"
        fill="#e6e6e6"
      />
      <circle cx="362" cy="36" r="36" fill="#e6e6e6" />
      <path
        d="M994,429H657a17.01909,17.01909,0,0,1-17-17V223a17.01909,17.01909,0,0,1,17-17H994a17.01917,17.01917,0,0,1,17,17V412A17.01917,17.01917,0,0,1,994,429ZM657,208a15.017,15.017,0,0,0-15,15V412a15.017,15.017,0,0,0,15,15H994a15.017,15.017,0,0,0,15-15V223a15.017,15.017,0,0,0-15-15Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M908.60039,281.313h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M971.96026,309.813H842.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H971.96026c2.01025,0,3.64013-2.02,3.64013-4.5S973.97051,309.813,971.96026,309.813Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M971.96026,339H842.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H971.96026c2.01025,0,3.64013-2.02,3.64013-4.5S973.97051,339,971.96026,339Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M794,387.5H682A10.512,10.512,0,0,1,671.5,377V252A10.512,10.512,0,0,1,682,241.5H794A10.512,10.512,0,0,1,804.5,252V377A10.512,10.512,0,0,1,794,387.5Z"
        transform="translate(-162 -173)"
        fill={primary}
      />
      <circle cx="840" cy="36" r="36" fill={primary} />
      <path
        d="M999.97633,223.57325a4.525,4.525,0,0,1-2.72269-.9044l-.04869-.03656-10.25352-7.85045a4.55541,4.55541,0,1,1,5.5412-7.232l6.64144,5.09257,15.6948-20.4683a4.55524,4.55524,0,0,1,6.38686-.84309l-.09759.13249.10012-.13056a4.56053,4.56053,0,0,1,.8431,6.3869l-18.46083,24.07562A4.55725,4.55725,0,0,1,999.97633,223.57325Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <path
        d="M516,727H179a17.01909,17.01909,0,0,1-17-17V521a17.01909,17.01909,0,0,1,17-17H516a17.01917,17.01917,0,0,1,17,17V710A17.01917,17.01917,0,0,1,516,727ZM179,506a15.017,15.017,0,0,0-15,15V710a15.017,15.017,0,0,0,15,15H516a15.017,15.017,0,0,0,15-15V521a15.017,15.017,0,0,0-15-15Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M430.60039,579.313h-65a4.5,4.5,0,1,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M493.96026,607.813H364.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H493.96026c2.01025,0,3.64013-2.02,3.64013-4.5S495.97051,607.813,493.96026,607.813Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M493.96026,637H364.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H493.96026c2.01025,0,3.64013-2.02,3.64013-4.5S495.97051,637,493.96026,637Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M316,685.5H204A10.512,10.512,0,0,1,193.5,675V550A10.512,10.512,0,0,1,204,539.5H316A10.512,10.512,0,0,1,326.5,550V675A10.512,10.512,0,0,1,316,685.5Z"
        transform="translate(-162 -173)"
        fill={primary}
      />
      <circle cx="362" cy="334" r="36" fill={primary} />
      <path
        d="M521.97633,521.57325a4.525,4.525,0,0,1-2.72269-.9044l-.04869-.03656-10.25352-7.85045a4.55541,4.55541,0,1,1,5.5412-7.232l6.64144,5.09257,15.6948-20.4683a4.55524,4.55524,0,0,1,6.38686-.84309l-.09759.13249.10012-.13056a4.56053,4.56053,0,0,1,.8431,6.3869l-18.46083,24.07562A4.55725,4.55725,0,0,1,521.97633,521.57325Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <path
        d="M994,727H657a17.01909,17.01909,0,0,1-17-17V521a17.01909,17.01909,0,0,1,17-17H994a17.01917,17.01917,0,0,1,17,17V710A17.01917,17.01917,0,0,1,994,727ZM657,506a15.017,15.017,0,0,0-15,15V710a15.017,15.017,0,0,0,15,15H994a15.017,15.017,0,0,0,15-15V521a15.017,15.017,0,0,0-15-15Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M908.60039,579.313h-65a4.5,4.5,0,0,0,0,9h65a4.5,4.5,0,0,0,0-9Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M971.96026,607.813H842.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H971.96026c2.01025,0,3.64013-2.02,3.64013-4.5S973.97051,607.813,971.96026,607.813Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M971.96026,637H842.74053c-2.01025,0-3.64014,2.02-3.64014,4.5s1.62989,4.5,3.64014,4.5H971.96026c2.01025,0,3.64013-2.02,3.64013-4.5S973.97051,637,971.96026,637Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <path
        d="M794,685.5H682A10.512,10.512,0,0,1,671.5,675V550A10.512,10.512,0,0,1,682,539.5H794A10.512,10.512,0,0,1,804.5,550V675A10.512,10.512,0,0,1,794,685.5Z"
        transform="translate(-162 -173)"
        fill={primary}
      />
      <circle cx="840" cy="334" r="36" fill={primary} />
      <path
        d="M999.97633,521.57325a4.525,4.525,0,0,1-2.72269-.9044l-.04869-.03656-10.25352-7.85045a4.55541,4.55541,0,1,1,5.5412-7.232l6.64144,5.09257,15.6948-20.4683a4.55524,4.55524,0,0,1,6.38686-.84309l-.09759.13249.10012-.13056a4.56053,4.56053,0,0,1,.8431,6.3869l-18.46083,24.07562A4.55725,4.55725,0,0,1,999.97633,521.57325Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <path
        d="M741.703,362.663c-2.52223-.0704-5.66128-.15867-8.07809-1.92421a6.20435,6.20435,0,0,1-2.44122-4.6325,4.17317,4.17317,0,0,1,1.41914-3.42716,5.38456,5.38456,0,0,1,5.09437-.73341l-2.05906-15.0477,1.51151-.20709,2.42073,17.69042-1.26214-.5792c-1.46346-.67121-3.47223-1.0124-4.72058.04209a2.68021,2.68021,0,0,0-.87923,2.208,4.689,4.689,0,0,0,1.81638,3.45435c1.88082,1.37444,4.38294,1.55174,7.221,1.63145Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <rect
        x="557.94609"
        y="165.19571"
        width="8.21703"
        height="1.52567"
        fill="#fff"
      />
      <rect
        x="583.88241"
        y="165.19571"
        width="8.21703"
        height="1.52567"
        fill="#fff"
      />
      <path
        d="M705.67028,317.1281c-6.617-.75455-11.74169-7.09452-12.30456-13.52729-.66963-7.65283,3.78715-14.92126,9.90151-19.21569,6.39421-4.491,14.36079-6.36642,22.0688-6.77927a60.65248,60.65248,0,0,1,41.45854,13.66509c5.77574,4.797,11.1699,10.90764,13.21432,18.28848,1.7755,6.41.33069,13.67159-4.82471,18.16093a12.18718,12.18718,0,0,1-9.3523,2.87948c-4.00021-.41536-7.69106-2.5187-10.88593-4.84162-6.21468-4.51858-11.31663-10.81338-18.88811-13.17464-5.55168-1.73135-12.75608-.20737-16.14872,4.81309a9.13816,9.13816,0,0,0-1.459,3.8284,1.185,1.185,0,0,0,.79919,1.40756,1.15136,1.15136,0,0,0,1.40757-.79918c.89066-6.32342,8.66589-8.683,14.122-7.2389,7.95524,2.10552,13.29551,9.20442,19.82247,13.71462a24.08826,24.08826,0,0,0,11.54351,4.60751,14.33428,14.33428,0,0,0,10.0719-3.09412c5.576-4.403,7.7084-11.976,6.47541-18.79425-1.42782-7.89562-6.7421-14.66264-12.573-19.90172a62.89317,62.89317,0,0,0-42.981-15.87789c-8.06588.18527-16.48184,1.85037-23.43056,6.11351-6.74021,4.13523-11.94982,11.05346-12.63072,19.09519-.59932,7.07828,3.19831,14.48179,9.71729,17.58279a15.2175,15.2175,0,0,0,4.87614,1.37642c1.46078.16658,1.44888-2.12328,0-2.2885Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <path
        d="M741.703,656.663c-2.52223-.0704-5.66128-.15867-8.07809-1.92421a6.20435,6.20435,0,0,1-2.44122-4.6325,4.17317,4.17317,0,0,1,1.41914-3.42716,5.38456,5.38456,0,0,1,5.09437-.73341l-2.05906-15.0477,1.51151-.20709,2.42073,17.69042-1.26214-.5792c-1.46346-.67121-3.47223-1.0124-4.72058.04209a2.68021,2.68021,0,0,0-.87923,2.208,4.689,4.689,0,0,0,1.81638,3.45435c1.88082,1.37444,4.38294,1.55174,7.221,1.63145Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <rect
        x="557.94609"
        y="459.19571"
        width="8.21703"
        height="1.52567"
        fill="#fff"
      />
      <rect
        x="583.88241"
        y="459.19571"
        width="8.21703"
        height="1.52567"
        fill="#fff"
      />
      <path
        d="M705.67028,611.1281c-6.617-.75455-11.74169-7.09452-12.30456-13.52729-.66963-7.65283,3.78715-14.92126,9.90151-19.21569,6.39421-4.491,14.36079-6.36642,22.0688-6.77927a60.65248,60.65248,0,0,1,41.45854,13.66509c5.77574,4.797,11.1699,10.90764,13.21432,18.28848,1.7755,6.40995.33069,13.67159-4.82471,18.16093a12.18718,12.18718,0,0,1-9.3523,2.87948c-4.00021-.41536-7.69106-2.5187-10.88593-4.84162-6.21468-4.51858-11.31663-10.81338-18.88811-13.17464-5.55168-1.73135-12.75608-.20737-16.14872,4.81309a9.13816,9.13816,0,0,0-1.459,3.8284,1.185,1.185,0,0,0,.79919,1.40756,1.15136,1.15136,0,0,0,1.40757-.79918c.89066-6.32342,8.66589-8.683,14.122-7.2389,7.95524,2.10552,13.29551,9.20442,19.82247,13.71462a24.08826,24.08826,0,0,0,11.54351,4.60751,14.33428,14.33428,0,0,0,10.0719-3.09412c5.576-4.403,7.7084-11.976,6.47541-18.79425-1.42782-7.89562-6.7421-14.66264-12.573-19.90172a62.89317,62.89317,0,0,0-42.981-15.87789c-8.06588.18527-16.48184,1.85037-23.43056,6.11351-6.74021,4.13523-11.94982,11.05346-12.63072,19.09519-.59932,7.07828,3.19831,14.48179,9.71729,17.58279a15.2175,15.2175,0,0,0,4.87614,1.37642c1.46078.16658,1.44888-2.12328,0-2.2885Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <path
        d="M258.703,656.663c-2.52223-.0704-5.66128-.15867-8.07809-1.92421a6.20435,6.20435,0,0,1-2.44122-4.6325,4.17317,4.17317,0,0,1,1.41914-3.42716,5.38456,5.38456,0,0,1,5.09437-.73341l-2.05906-15.0477,1.51151-.20709,2.42073,17.69042-1.26214-.5792c-1.46346-.67121-3.47223-1.0124-4.72058.04209a2.68021,2.68021,0,0,0-.87923,2.208,4.689,4.689,0,0,0,1.81638,3.45435c1.88082,1.37444,4.38294,1.55174,7.221,1.63145Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <rect
        x="74.94609"
        y="459.19571"
        width="8.21703"
        height="1.52567"
        fill="#fff"
      />
      <rect
        x="100.88241"
        y="459.19571"
        width="8.21703"
        height="1.52567"
        fill="#fff"
      />
      <path
        d="M222.67028,611.1281c-6.617-.75455-11.74169-7.09452-12.30456-13.52729-.66963-7.65283,3.78715-14.92126,9.90151-19.21569,6.39421-4.491,14.36079-6.36642,22.0688-6.77927a60.65248,60.65248,0,0,1,41.45854,13.66509c5.77574,4.797,11.1699,10.90764,13.21432,18.28848,1.7755,6.40995.33069,13.67159-4.82471,18.16093a12.18718,12.18718,0,0,1-9.3523,2.87948c-4.00021-.41536-7.69106-2.5187-10.88593-4.84162-6.21468-4.51858-11.31663-10.81338-18.88811-13.17464-5.55168-1.73135-12.75608-.20737-16.14872,4.81309a9.13816,9.13816,0,0,0-1.459,3.8284,1.185,1.185,0,0,0,.79919,1.40756,1.15136,1.15136,0,0,0,1.40757-.79918c.89066-6.32342,8.66589-8.683,14.122-7.2389,7.95524,2.10552,13.29551,9.20442,19.82247,13.71462a24.08826,24.08826,0,0,0,11.54351,4.60751,14.33428,14.33428,0,0,0,10.0719-3.09412c5.576-4.403,7.7084-11.976,6.47541-18.79425-1.42782-7.89562-6.7421-14.66264-12.573-19.90172a62.89317,62.89317,0,0,0-42.981-15.87789c-8.06588.18527-16.48184,1.85037-23.43056,6.11351-6.74021,4.13523-11.94982,11.05346-12.63072,19.09519-.59932,7.07828,3.19831,14.48179,9.71729,17.58279a15.2175,15.2175,0,0,0,4.87614,1.37642c1.46078.16658,1.44888-2.12328,0-2.2885Z"
        transform="translate(-162 -173)"
        fill="#fff"
      />
      <path
        d="M258.703,364.663c-2.52223-.0704-5.66128-.15867-8.07809-1.92421a6.20435,6.20435,0,0,1-2.44122-4.6325,4.17317,4.17317,0,0,1,1.41914-3.42716,5.38456,5.38456,0,0,1,5.09437-.73341l-2.05906-15.0477,1.51151-.20709,2.42073,17.69042-1.26214-.5792c-1.46346-.67121-3.47223-1.0124-4.72058.04209a2.68021,2.68021,0,0,0-.87923,2.208,4.689,4.689,0,0,0,1.81638,3.45435c1.88082,1.37444,4.38294,1.55174,7.221,1.63145Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
      <rect
        x="74.94609"
        y="167.19571"
        width="8.21703"
        height="1.52567"
        fill="#3f3d56"
      />
      <rect
        x="100.88241"
        y="167.19571"
        width="8.21703"
        height="1.52567"
        fill="#3f3d56"
      />
      <path
        d="M222.67028,319.1281c-6.617-.75455-11.74169-7.09452-12.30456-13.52729-.66963-7.65283,3.78715-14.92126,9.90151-19.21569,6.39421-4.491,14.36079-6.36642,22.0688-6.77927a60.65248,60.65248,0,0,1,41.45854,13.66509c5.77574,4.797,11.1699,10.90764,13.21432,18.28848,1.7755,6.41.33069,13.67159-4.82471,18.16093a12.18718,12.18718,0,0,1-9.3523,2.87948c-4.00021-.41536-7.69106-2.5187-10.88593-4.84162-6.21468-4.51858-11.31663-10.81338-18.88811-13.17464-5.55168-1.73135-12.75608-.20737-16.14872,4.81309a9.13816,9.13816,0,0,0-1.459,3.8284,1.185,1.185,0,0,0,.79919,1.40756,1.15136,1.15136,0,0,0,1.40757-.79918c.89066-6.32342,8.66589-8.683,14.122-7.2389,7.95524,2.10552,13.29551,9.20442,19.82247,13.71462a24.08826,24.08826,0,0,0,11.54351,4.60751,14.33428,14.33428,0,0,0,10.0719-3.09412c5.576-4.403,7.7084-11.976,6.47541-18.79425-1.42782-7.89562-6.7421-14.66264-12.573-19.90172a62.89317,62.89317,0,0,0-42.981-15.87789c-8.06588.18527-16.48184,1.85037-23.43056,6.11351-6.74021,4.13523-11.94982,11.05346-12.63072,19.09519-.59932,7.07828,3.19831,14.48179,9.71729,17.58279a15.2175,15.2175,0,0,0,4.87614,1.37642c1.46078.16658,1.44888-2.12328,0-2.2885Z"
        transform="translate(-162 -173)"
        fill="#3f3d56"
      />
    </svg>
  );
}
