import React from "react";

export function BlogArticleDetailsLoading() {
    return (
        <div
            className="animate-pulse w-full flex flex-col justify-between items-start gap-3 p-3"
        >
            <div className="bg-gray-100 h-[190px] min-h-[190px] w-full" />
            <div className="flex flex-col justify-between items-start gap-3 w-full">
                <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/2" />
                <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                <div
                    className="shrink-0 bg-gray-100 h-[20px] min-h-[20px]"
                />
            </div>
            <div className="w-full flex flex-row justify-end">
                <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/5" />
            </div>
        </div>
    )
}
export default function BlogListLoading() {
    return (
        <>
            <div
                className="animate-pulse w-full flex flex-col justify-between items-start gap-3 p-3"
            >
                <div className="bg-gray-100 h-[190px] min-h-[190px] w-full" />
                <div className="flex flex-col justify-between items-start gap-3 w-full">
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/2" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div
                        className="shrink-0 bg-gray-100 h-[20px] min-h-[20px]"
                    />
                </div>
                <div className="w-full flex flex-row justify-end">
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/5" />
                </div>
            </div>
            <div
                className="animate-pulse w-1/2 flex flex-col justify-between items-start gap-3 p-3"
            >
                <div className="bg-gray-100 h-[190px] min-h-[190px] w-full" />
                <div className="flex flex-col justify-between items-start gap-3 w-full">
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/2" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div
                        className="shrink-0 bg-gray-100 h-[20px] min-h-[20px]"
                    />
                </div>
                <div className="w-full flex flex-row justify-end">
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/4" />
                </div>
            </div>
            <div
                className="animate-pulse w-1/2 flex flex-col justify-between items-start gap-3 p-3"
            >
                <div className="bg-gray-100 h-[190px] min-h-[190px] w-full" />
                <div className="flex flex-col justify-between items-start gap-3 w-full">
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/2" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-full" />
                    <div
                        className="shrink-0 bg-gray-100 h-[20px] min-h-[20px]"
                    />
                </div>
                <div className="w-full flex flex-row justify-end">
                    <div className="bg-gray-100 h-[20px] min-h-[20px] w-1/4" />
                </div>
            </div>
        </>
    )
}