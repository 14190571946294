import { Container, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { object, string } from "yup";
import i18n from "i18next";
import { SendResetPasswordInterface } from "../../../_redux/settings/api";
import { useSendResetPasswordEmailMutation } from "../../../_redux/settings/services";
import { Formik } from "formik";
import React from "react";
import SendResetPasswordEmailForm from "../../../components/_form/settings/SendResetPasswordEmailForm";
import { useTranslation } from "react-i18next";
import SEO from "../../../SEO";
import { loginPage } from "../../../utils/seoProps";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";
import { toast } from "react-toastify";

export default function SendEmail() {
  const [sendResetPasswordEmailAction, triggers] =
    useSendResetPasswordEmailMutation();
  const { t } = useTranslation();

  let initialValues = {
    email: undefined,
  };

  const SendResetPasswordEmailSchema = object().shape({
    email: string()
      .email()
      .required(i18n.t("validation.field.required.unspecific")),
  });

  const handelSendResetPasswordEmail = async (
    values: SendResetPasswordInterface
  ) => {
    sendResetPasswordEmailAction(values)
      .unwrap()
      .then((response) => {
        toast.success(t("reset.password.send.email"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  return (
    <Container
      className="flex h-full flex-row justify-center items-center gap-3 mt-16"
      maxWidth="xs"
    >
      <SEO {...loginPage} />

      <h1 className="text-xl font-xing-bold">{t("forgot.password.label")}</h1>
      <div className="flex flex-row text-justify justify-start items-center">
        <Typography>{t("insert.email.label")}</Typography>
      </div>
      <Formik
        initialValues={initialValues as never}
        onSubmit={handelSendResetPasswordEmail}
        validationSchema={SendResetPasswordEmailSchema}
      >
        <SendResetPasswordEmailForm triggers={triggers} />
      </Formik>
      <div className="flex flex-col justify-center items-center gap-3">
        <p className="font-xing-bold">{t("problem.e-mail.label")}</p>
        <div className="flex flex-col justify-center items-center text-sm">
          <p>{t("other.problems.label")}</p>
          <NavLink to="/send-email" className="hover:underline text-cyan-600">
            {t("contact.us.label")}
          </NavLink>
        </div>
      </div>
    </Container>
  );
}
