import { Button, Image } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { mediaRoutes } from "../../../utils/mediaRoutes";
import { EventItemProps } from "../../../_redux/features/events/interfaces";
import { setSingleEvent } from "../../../_redux/features/events/eventsSlice";
import { useAppDispatch } from "../../../_redux/store";
import moment from "moment";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";

export default function EventItem({ event, onClick }: EventItemProps) {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function redirectTo() {
    dispatch(setSingleEvent({ event: event }));
    return navigate(`/events/${event.id}`);
  }

  return (
    <div
      onClick={redirectTo}
      key={event["@id"]}
      className="relative w-full h-[420px] min-h-[420px] max-h-[420px] bg-white overflow-hidden rounded-3xl cursor-pointer"
    >
      <img
        src={`${mediaRoutes.EVENT_TEMPLATE}${event?.template?.heroAreaImage}`}
        onError={(e) => {
          e.target.src = "/images/prototype-event-background.jpg";
        }}
        alt={event?.name}
        className="absolute min-w-full min-h-[317px] overflow-hidden hover:none"
      />
      <div className="absolute h-full w-full backdrop-blur-[30px] backdrop-saturate-[180%] bg-transparent bg-opacity-60"></div>
      <div className="relative h-full w-full p-3 overflow-hidden flex flex-col justify-between">
        <div className="flex flex-row justify-between items-center font-xing-bold">
          <div className="bg-[#FFB836] rounded-xl px-3 py-2">
            {t("events.label", { count: 1 })}
          </div>
          <div
            onClick={onClick}
            className="bg-white rounded-full h-[40px] w-[40px] flex flex-col justify-center items-center"
          >
            <Image
              radius={"none"}
              src="/svg/prototype-icon-events.svg"
              height={20}
              width={20}
              alt="calendar"
            />
          </div>
        </div>
        <div className="h-[300px] w-full bg-white p-4 rounded-2xl overflow-hidden flex flex-col justify-between items-center">
          <div className="w-full flex flex-col justify-evenly items-start text-sm">
            <div className="font-xing-bold">{event?.name}</div>
            {/*<div className="text-gray-200">{t("created.by.label")} {event?.createdBy?.fullname} </div>*/}
          </div>
          <div className="w-full flex flex-row justify-between items-center text-sm">
            <div className="w-full flex flex-col justify-evenly items-start">
              <div className="font-xing-bold">
                {" "}
                {t("", { date: event?.startDate })}
              </div>
              <div className="font-xing-bold">
                {moment(event?.startDate).format("ll")}
              </div>
              <div className="text-gray-200 first-letter:capitalize">
                {event?.location}
              </div>
            </div>
            <div className="shrink-0 py-3 flex relative ">
              <Image
                src="/svg/prototype-icon-pin.svg"
                className="absolute min-h-[18px] min-w-[18px] start-[30px] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                alt="Adress map pin"
              />
              <Image
                src="/images/prototype-image-map.png"
                height={60}
                width={60}
                className="relative z-[1] min-h-[60px] min-w-[60px] max-h-[60px] max-w-[60px] rounded-full object-cover"
                alt="Adress map pin"
              />
            </div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <p
              dangerouslySetInnerHTML={{
                __html: event?.template?.description || "",
              }}
              className="text-justify text-ellipsis overflow-hidden h-[70px] max-h-[70px]"
            />
          </div>
          <div className="w-full flex flex-row justify-end items-center">
            {event?.isParticipant ? (
              <p className="font-light text-gray-400 text-sm">
                {t("events.participated.already")}
              </p>
            ) : (
              <Button
                onClick={() =>
                  navigate(
                    AppRoutes.PRIVATE_EVENT_DETAILS.replace(
                      ":locale",
                      `${i18n.language}`
                    ).replace(":id", event?.id)
                  )
                }
                key={event["@id"]}
                className="bg-[#61F1A4] text-black font-xing-bold rounded-full"
                variant="flat"
              >
                {t("events.participate")}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
