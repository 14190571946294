import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectLanguagesLevel(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const languagesLevel = [
    {
      label: t("languages.level.school.label"),
      value: "school",
    },
    {
      label: t("languages.level.professional.label"),
      value: "professional",
    },
    {
      label: t("languages.level.current.label"),
      value: "current",
    },
    {
      label: t("languages.level.bilingual.label"),
      value: "bilingual",
    },
  ];

  return (
    <Select
      variant="standard"
      fullWidth
      label={t("level.label")}
      placeholder={t("level.label")}
      {...props}
    >
      {languagesLevel?.map((level) => (
        <MenuItem
          key={level.value}
          value={level.value}
          style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
          className="w-full"
        >
          {level.label}
        </MenuItem>
      ))}
    </Select>
  );
}
