import { Form, useFormikContext } from "formik";
import { Button } from "@nextui-org/react";
import Input from "../_field/Input";
import SelectStudyLevel from "../_field/Specified/SelectStudyLevel";
import SelectCertificateDomain from "../_field/Specified/SelectCertificateDomain";
import SelectCertificateType from "../_field/Specified/SelectCertificateType";
import Textarea from "../_field/Textarea";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import CustomSpinner from "../../spinner/CustomSpinner";

interface CertificateAddFormInterface {
  triggers: MutationResultSelectorResult<any>;
  onCancel: () => void;
}

export default function CertificateAddForm({
  triggers,
  onCancel = () => {},
}: CertificateAddFormInterface) {
  const { t } = useTranslation();
  const {
    setErrors: setErrorsHook,
    isSubmitting,
    initialValues,
  } = useFormikContext();

  useEffect(() => {
    return () => triggers.reset();
  }, []);

  useEffect(() => {
    if (triggers?.isSuccess) {
      toast.success(
        t("add.message.specific", { value: t("certificate.label") }),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );

      triggers.reset();

      if (typeof onCancel === "function") {
        onCancel();
      }
    }
  }, [triggers?.isSuccess]);

  useEffect(() => {
    if (triggers?.isError) {
      let errors = {};
      // @ts-ignore
      triggers?.error?.data?.violations?.forEach(
        (violation: { propertyPath: any; message: any }) => {
          if (violation?.propertyPath in initialValues) {
            errors[violation?.propertyPath] = violation?.message;
          } else {
            toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        }
      );

      if (errors) {
        setErrorsHook(errors);
      }
    }
  }, [triggers?.isError, triggers?.error]);

  return (
    <Form className="w-full flex flex-col gap-4">
      <Input
        fullWidth
        label={t("certificate.title.label")}
        placeholder={t("certificate.title.placeholder")}
        name="title"
        disabled={isSubmitting}
      />
      <Input
        fullWidth
        name="school"
        label={t("certificate.school.label")}
        placeholder={t("certificate.school.placeholder")}
        required
        disabled={isSubmitting}
      />
      <SelectStudyLevel
        variant="standard"
        name="studyLevel"
        required
        disabled={isSubmitting}
      />
      <SelectCertificateDomain
        variant="standard"
        name="domain"
        required
        disabled={isSubmitting}
      />
      <SelectCertificateType
        variant="standard"
        name="type"
        required
        disabled={isSubmitting}
      />
      <Input
        type="month"
        fullWidth
        name="startYear"
        variant="standard"
        label={t("certificate.startYear.label")}
        placeholder={t("certificate.startYear.placeholder")}
        required
        disabled={isSubmitting}
      />
      <Input
        fullWidth
        label={t("certificate.endDate.label")}
        placeholder={t("certificate.endDate.placeholder")}
        name="endDate"
        type="month"
        required
        disabled={isSubmitting}
        variant="standard"
      />
      <Textarea
        fullWidth
        label={t("certificate.description.label")}
        placeholder={t("certificate.description.placeholder")}
        name="description"
        required
        disabled={isSubmitting}
      />
      <div className="flex flex-row justify-center items-center gap-6">
        {typeof onCancel === "function" && (
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
            startContent={<XMarkIcon height={24} width={24} />}
          >
            {t("close.label")}
          </Button>
        )}
        <Button
          type="submit"
          spinner={<CustomSpinner />}
          isLoading={triggers?.isLoading || isSubmitting}
          disabled={isSubmitting}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          color="warning"
          startContent={
            triggers?.isLoading || isSubmitting ? null : (
              <PlusCircleIcon height={24} width={24} />
            )
          }
        >
          {t("add.label")}
        </Button>
      </div>
    </Form>
  );
}
