import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { useGetCertificateDomainsQuery } from "../../../../_redux/features/certificate/domain/services";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectCertificateDomain(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCertificateDomainsQuery({
    pagination: false,
  });
  const { list: certificateDomains } = data || {};

  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("certificate.domain.label")}
      placeholder={t("certificate.domain.placeholder")}
      {...props}
    >
      {certificateDomains?.map((certificateDomain) => (
        <MenuItem
          style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
          className="w-full"
          key={certificateDomain["@id"]}
          value={certificateDomain["@id"]}
        >
          {certificateDomain?.name}
        </MenuItem>
      ))}
    </Select>
  );
}
