import React from "react";
import CustomUserAvatar from "../../customAvatar/CustomUserAvatar";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "@mui/material";
import SendMessageButton from "../Buttons/SendMessageButton";
import CustomContainer from "../../containers/CustomContainer";

export default function NetworkUserItem({user, isLoading = false}: { user: any, isLoading?: boolean }) {

    const navigate = useNavigate()
    const viewProfile = () => navigate(`/profile/${user?.id}`)

    return (
        <CustomContainer
            className="h-[320px] min-h-[320px] max-h-[320px] bg-white flex flex-col justify-between items-center gap-3"
        >
            {
                isLoading
                    ?
                    <Skeleton animation="wave" variant="rounded" width={122} height={122}/>
                    :
                    <CustomUserAvatar
                        className="h-[122px] w-[122px] cursor-pointer"
                        src={user?.imageUrl}
                        objectfit="cover"
                        onClick={viewProfile}
                    />
            }
            {
                isLoading
                    ?
                    <Skeleton animation="wave" width={"85%"}/>
                    :
                    <>
                        {
                            user?.isRecruiting &&
                            <div className="w-full flex flex-row justify-start items-center">
                                <div>
                                    <p className="text-[#8938f3] text-sm bg-[#f2e8ff] rounded-full px-2">
                                        Recrute
                                    </p>
                                </div>
                            </div>
                        }
                    </>
            }

            {isLoading ?
                <Skeleton animation="wave" width={"80%"}/>
                :
                <div className="w-full text-center">
                    <p className="text-md font-xing-bold cursor-pointer"
                       onClick={viewProfile}
                    >
                        {user?.fullname}
                    </p>
                </div>
            }

            {isLoading ?
                <Skeleton animation="wave" width={"70%"}/>
                :
                <div className="w-full text-center">
                    <p className="text-sm font-xing">
                        {user?.company?.job}
                    </p>
                </div>
            }
            {
                isLoading ?
                    <Skeleton animation="wave" width={"90%"}/>
                    :
                    <>
                        {
                            user?.description &&
                            <div>
                                <p className="font-xing text-sm text-gray-700">
                                    <BoltOutlinedIcon className={"text-fuchsia-300"}/>
                                    {user?.description}
                                </p>
                            </div>
                        }
                    </>
            }
            <div className="w-full flex flex-row justify-center items-center">
                {
                    isLoading ?
                        <Skeleton animation="wave" variant="rounded" width={80} height={30}/>
                        :
                        <SendMessageButton user={user}/>}
            </div>
        </CustomContainer>
    )
}
