import {jobListApiRequest} from "./api";
import {JobSearchInterface, JobsState} from "./jobsSlice";
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";

export const fetchJobs = createAsyncThunk<
    JobsState,
    JobSearchInterface
>("jobs/fetchJobs", async (filters: JobSearchInterface = {_page: 1}) => await jobListApiRequest(filters))


export async function getJobItem(id: number) {
    return axios.get(`/api/jobs/${id}`)
}
