import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Container } from "@mui/material";
import { AppRoutes } from "../../../utils/appRoutes";
import EmptyNetworkIllustration from "./EmptyNetworkIllustration";
import { useNavigate } from "react-router-dom";

export default function EmptyNetwork({
  primary = "#FFB836",
  placeholder = "network.label",
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="w-full min-h-[360px] bg-white rounded-xl col-start-[1] col-end-[-1]">
      <Container>
        <div className="flex flex-col justify-around items-center gap-3">
          <EmptyNetworkIllustration primary={primary} />
          <p className="text-lg font-xing-bold text-center">
            {t(placeholder, { count: 0 })}
          </p>
          <Button
            color="warning"
            variant="contained"
            onClick={() =>
              navigate(
                AppRoutes.PRIVATE_NETWORK.replace(
                  ":locale",
                  `${i18n.language}`
                ) + "#expandYourNetwork"
              )
            }
          >
            {t("network.find.on.label")}
          </Button>
        </div>
      </Container>
    </div>
  );
}
