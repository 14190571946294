import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import { useGetParamsExperiencesQuery } from "../../../../_redux/features/experiences/services";
import { ParamsExperienceInterface } from "../../../../_redux/features/experiences/types";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  id: "id" | "@id";

  onChange?: (e: any) => void;
}

export default function SelectExperienceLevel(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const { data, isLoading } = useGetParamsExperiencesQuery();
  const { list } = data || {};

  return (
    <Select
      variant="standard"
      fullWidth
      label={t("experience.title.label")}
      placeholder={t("experience.title.label")}
      isLoading={isLoading}
      {...props}
    >
      {list?.map((item) => (
        <MenuItem key={item?.id} value={item[props.id]}>
          {item?.label}
        </MenuItem>
      ))}
    </Select>
  );
}
