import React, { ChangeEvent, useEffect } from "react";
import { useAppDispatch } from "../../../../_redux/store";
import TitledContainer from "../../../../components/containers/TitledContainer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectJobsSearch,
  setSearch,
} from "../../../../_redux/features/jobs/jobsSlice";
import {
  removeEmptyProperty,
  useGetJobsQuery,
} from "../../../../_redux/features/jobs/services";
import { Container } from "@mui/material";
import JobSearchItem from "../jobSearchItem";
import { JobInterface } from "../../../../components/jobComponent/jobComponentItem/jobComponentItem";
import { NoResultFound } from "../../../../components/noResultFound/NoResultFound";
import { JobsSearchLoading } from "../../jobs/loading";
import JobsSearchForm from "./JobsSearchForm";
import BackButton from "../../../../components/backButton/BackButton";
import useCurrentDimension, {
  getCurrentDimension,
  xl,
} from "../../../../_hooks/useCurrentDimension";
import AlertHandlerButton from "./AlertHandlerButton";
import { Paginator } from "../../../../components/paginator/Paginator";
import SortDropdown from "../../../../components/sortDropdown/SortDropdown";
import SEO from "../../../../SEO";
import { jobSearchPublic } from "../../../../utils/seoProps";

export interface Values {
  indexOf(value: any): any;
  keyword: string;
  location?: string;
  cardinals: Array<string> | undefined;
  function: Array<number> | undefined;
  sectorGroup: Array<number> | undefined;
  contractTypes: Array<number> | undefined;
}

export default function JobsSearch() {
  const [screenSize, setScreenSize] = useCurrentDimension();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const search = useSelector(selectJobsSearch);
  const { data, isLoading, isFetching } = useGetJobsQuery(
    {
      ...search,
      _page: search?._page,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { list: jobs, pagination, totalItems } = data || {};

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const initialValues = {
    cardinals: [],
    ...search,
  };

  const handleSearch = async (values: Values) => {
    const params = removeEmptyProperty({ ...search, ...values, _page: 1 });
    const paramsWithoutSpace = {
      ...params,
      ...(params?.keyword ? { keyword: params?.keyword.trim() } : {}),
      ...(params?.location ? { location: params?.location.trim() } : {}),
    };
    dispatch(setSearch({ search: paramsWithoutSpace }));
  };

  function scrollHandler(e: ChangeEvent<unknown>, _page: number) {
    if (pagination?.last?._page >= _page) {
      const params = removeEmptyProperty({ ...search, _page: _page });
      dispatch(setSearch({ search: params }));
    }
  }

  const getButtons = () => {
    return [<AlertHandlerButton />, <SortDropdown />];
  };

  return (
    <Container maxWidth="xl">
      <SEO {...jobSearchPublic} />
      <div className="m-auto w-full min-h-screen flex flex-col justify-start gap-5">
        <div className="w-full bg-white sticky z-20 max-md:top-[56px] top-[64px] min-h-[60px] flex flex-col justify-center">
          <div className="flex flex-row justify-between items-center">
            <BackButton />
          </div>
        </div>
        <JobsSearchForm
          initialValues={initialValues}
          handleSubmit={handleSearch}
        />
        <TitledContainer
          isFullWidth={true}
          title={t("job.found.for.you", { count: totalItems })}
          className="flex flex-col justify-between gap-1"
          buttons={getButtons()}
        >
          {isLoading ? (
            <JobsSearchLoading />
          ) : totalItems <= 0 ? (
            <NoResultFound
              message={t("job.found.for.you", { count: totalItems })}
              height="330"
            />
          ) : (
            <div className="grid grid-cols-1 gap-4">
              {isLoading || isFetching ? (
                <JobsSearchLoading />
              ) : (
                jobs?.map((job: JobInterface) => (
                  <JobSearchItem
                    key={job["@id"]}
                    job={job}
                    isIconOnly={screenSize.width < xl}
                  />
                ))
              )}
              <Paginator
                count={pagination?.last?._page ?? 1}
                disabled={isFetching || isLoading}
                onChange={scrollHandler}
                page={search?._page ?? 1}
              />
            </div>
          )}
        </TitledContainer>
      </div>
    </Container>
  );
}
