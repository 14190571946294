import TitledContainer from "../../../components/containers/TitledContainer";
import {Container} from "@mui/material";

export default function Subscribers () {
    return (
        <Container maxWidth="md">
            <TitledContainer
                title="Subscribers"
                className="h-screen flex flex-col"
            >
                <p>Subscribers</p>
            </TitledContainer>
        </Container>
    )
}