import React from "react";
import HobbiesIllustration from "../illustration/HobbiesIllustration";
import HobbiesEditModal from "../modals/Profile/HobbiesEditModal";
import {Edit} from "@mui/icons-material";
import {useGetMyCvQuery, useGetProfileQuery} from "../../_redux/auth/services";
import {useTranslation} from "react-i18next";

export default function NoHobbiesFound() {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const {data: cv} = useGetMyCvQuery(profile?.id, {skip: !profile})

    return (
        <div className="w-full flex flex-row justify-evenly items-center gap-6 p-3">
            <HobbiesIllustration primary="#d9d9d9" secondary="#ffb836" />
            <div className="flex flex-col justify-between items-start gap-3">
                <h1 className="font-xing-bold">
                    {t("profile.hobbies.guitar.picture.the.cats.label")}
                </h1>
                <p>
                    {t("profile.hobbies.what.are.your.passions.outside.of.your.professional.life.label")}
                </p>
                <HobbiesEditModal
                    hobby={cv?.hobby || ""}
                    isIconOnly={false}
                    variant="bordered"
                    color="primary"
                    radius="full"
                    className="font-xing-bold"
                    text={t("profile.hobbies.add.label")}
                    icon={<Edit height={10} width={10} />}
                />
            </div>
        </div>
    )
}