import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetProfileQuery } from "../../../../_redux/auth/services";
import { useGetJobAlertsQuery } from "../../../../_redux/features/jobs/services";
import { Container } from "@mui/material";
import ApplicationsIllustration from "../../../../components/illustration/ApplicationsIllustration";
import { JobsSearchLoading, SimilarJobsLoading } from "../../jobs/loading";
import JobAlertItem from "./ui/JobAlertItem";
import { Paginator } from "../../../../components/paginator/Paginator";
import AddJobAlertButton from "./ui/AddAlertDialog";

export default function SearchAlert() {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useGetJobAlertsQuery(
    {
      id: profile?.id,
      filters: { _page: page },
    },
    { skip: !profile }
  );
  const { list: jobAlerts, pagination } = data || {};

  return (
    <div className="w-full">
      {jobAlerts?.length <= 0 ? (
        <div className="w-full min-h-[360px] bg-white rounded-xl ">
          <Container>
            <div className="w-full flex flex-col justify-center items-center gap-3">
              <ApplicationsIllustration primary="#ffb836" />
              <p className="text-lg font-xing-bold text-center">
                {t("job.applications.your.search.alerts.no.result.label")}
              </p>
              <div>
                <AddJobAlertButton alert={alert} isIconOnly />
              </div>
            </div>
          </Container>
        </div>
      ) : isLoading || isFetching ? (
        <JobsSearchLoading />
      ) : (
        <div className="grid grid-cols-1 gap-4">
          <AddJobAlertButton alert={alert} isIconOnly />
          {isLoading || isFetching ? (
            <JobsSearchLoading />
          ) : (
            jobAlerts
              ?.map((alert: any) => (
                <JobAlertItem
                  key={alert["@id"]}
                  alert={alert}
                  isIconOnly={false}
                />
              ))
              ?.sort((a, b) => a.id - b.id)
          )}
          <Paginator
            count={pagination?.last?._page ?? 1}
            disabled={isFetching || isLoading}
            onChange={(e, page) => setPage(page)}
            page={page ?? 1}
          />
        </div>
      )}
    </div>
  );
}
