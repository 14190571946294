import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { regionInterface } from "../../../../_redux/features/region/types";
import { useGetRegionsQuery } from "../../../../_redux/features/region/services";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  countryId: number | null;
  type?: string;
  disabled?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectRegion(props: SelectPropsInterface): JSX.Element {
  const { t } = useTranslation();
  const { data, isLoading } = useGetRegionsQuery(
    {
      pagination: false,
      country: props?.countryId,
    },
    { skip: !props?.countryId }
  );
  const { list } = data || {};

  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("regions.label", { count: 1 })}
      placeholder={t("regions.label", { count: 1 })}
      {...props}
    >
      {list
        ?.map((region: regionInterface) => (
          <MenuItem key={region["@id"]} value={region["@id"]}>
            {region?.id} - {region?.name}
          </MenuItem>
        ))
        .sort((a, b) => (a?.id > b?.id ? -1 : 1))}
    </Select>
  );
}
