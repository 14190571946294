import React from "react";
import {Form, Formik} from "formik";
import {date, object, string} from "yup";
import {Button} from "@nextui-org/react";
import {useSelector} from "react-redux";
import Input from "../../_form/_field/Input";
import {useTranslation} from "react-i18next";
import {fetchEvents} from "../../../_redux/features/events/actions";
import {useAppDispatch} from "../../../_redux/store";
import {
    selectEventsSearch,
    selectEventsState, setSearch,
    STATUS_LOADING
} from "../../../_redux/features/events/eventsSlice";
import {SearchIcon} from "@nextui-org/shared-icons";
import i18n from "i18next";
import {useGetEventsQuery} from "../../../_redux/features/events/services";
import CustomLoader from "../../customLoader/CustomLoader";
import {removeEmptyProperty} from "../../../_redux/features/jobs/services";

interface Values {
    name: string;
    startDate: string;
    endDate: string;
}

export function EventSearchForm() {

    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const search = useSelector(selectEventsSearch)
    const eventState = useSelector(selectEventsState)

    const eventSearch = useSelector(selectEventsSearch)
    const {isLoading, isFetching} = useGetEventsQuery({...eventSearch, _page: 1})

    let initialValues = {
        name: eventSearch?.name ?? '',
        startDate: "startDate[after]" in eventSearch ? eventSearch["startDate[after]"] : "",
        endDate:  "endDate[before]" in eventSearch ? eventSearch["endDate[before]"] : "",
    }

    const schema = object().shape({
        name: string(),
        startDate: date(),
        endDate: date().when('$startDate', ([other], schema) =>
                other ? schema.min(other, i18n.t("validation.field.min.unspecific", {min: other})) : schema,
            ),
    });

    const handelSubmit = async (values: Values) => {
        const params = removeEmptyProperty({...search, ...{ name: values?.name, "startDate[after]": values.startDate , "endDate[before]": values.endDate } })
        dispatch(setSearch({search: params}))
        dispatch(fetchEvents(params))
    }

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handelSubmit}
        >
            {({values, isSubmitting}) =>
                <Form>
                    <div className="flex flex-wrap justify-between items-center gap-4">

                        <div className="flex-2 w-full">
                            <Input
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className="h-[45px] max-h-[45px] min-h-[45px]"
                                name="name"
                                label={t("keywords.label", {count: 1})}
                            />
                        </div>
                        <div className="flex-1 w-full">
                            <Input
                                type="date"
                                className="flex-1 w-full h-[45px]"
                                fullWidth={false}
                                label={t("startDate.label")}
                                name="startDate"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                placeholder={t("startDate.label")}
                            />
                        </div>
                        <div className="flex-1 w-full">
                            <Input
                                className="w-full h-[45px]"
                                type="date"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth={false}
                                label={t("endDate.label")}
                                name="endDate"
                                placeholder={t("endDate.label")}
                            />
                        </div>
                        <div className="h-[45px] w-[45px] max-h-[45px] max-w-[45px] min-h-[45px] min-w-[45px]">
                            <Button
                                className="h-[45px] w-[45px] max-h-[45px] max-w-[45px] min-h-[45px] min-w-[45px] bg-[#FFBF4A]"
                                type="submit"
                                disabled={isSubmitting || ((!search?.name && !values?.name) && (!search['startDate[after]'] && !values?.startDate) && (!search['endDate[before]'] && !values?.endDate)) || STATUS_LOADING === eventState}
                                startContent={
                                    (isSubmitting || isLoading || isFetching) ? <CustomLoader /> : <SearchIcon height={35} width={35} />
                                }
                            />
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    )
}