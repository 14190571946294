import React, {useEffect, useState} from "react";
import {SearchIcon} from "@nextui-org/shared-icons";
import {Input as NextInput} from "@nextui-org/input";
import {style} from "../../jobsSearch/filters/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {useTranslation} from "react-i18next";
import { useLocation } from "react-router-dom";

export default function MyContactSearchForm({onChange, isLoading = false}: {onChange: (object) => void, isLoading?: boolean}) {

    const {state} = useLocation();
    const { filters } = state || {filters: {_page: 1}};
    const {t} = useTranslation();
    const [onChangeTimer, setOnChangeTimer] = useState<NodeJS.Timeout | undefined>()

    useEffect(() => {
        return () => {
            clearTimeout(onChangeTimer)
        }
    }, []);

    const onChangeHandler = (value: { keyword: string, _page: number }) => {
        clearTimeout(onChangeTimer)
        const timer = setTimeout(() => {
            onChange(value)
        }, 500)
        setOnChangeTimer(timer);
    }

    return (
            <div
                className="flex flex-row justify-between items-center py-1"
            >
                <div className="flex-1">
                            <div className="w-full flex flex-col justify-start gap-9">
                                <div className="w-full flex flex-row max-md:flex-col justify-center items-center gap-6">
                                    <div className="w-full grow ">
                                        <NextInput
                                            onClear={() => onChange({_page: 1})}
                                            radius="full"
                                            startContent={<SearchIcon height={24} width={24}/>}
                                            endContent={isLoading ? <CircularProgress color={"inherit"} size={17} className={"mx-1"}/>: null}
                                            classNames={style}
                                            variant="bordered"
                                            fullWidth={true}
                                            name="keyword"
                                            defaultValue={filters?.keyword}
                                            onChange={(e) => onChangeHandler({keyword: e.target.value, _page: 1})}
                                            placeholder={t("network.contacts.yours.search")}
                                        />
                                    </div>
                                </div>
                            </div>
                </div>

            </div>
    )
}