import React, { useState } from "react";
import { setSingleJob } from "../../../_redux/features/jobs/jobsSlice";
import { mediaRoutes } from "../../../utils/mediaRoutes";
import { useAppDispatch } from "../../../_redux/store";
import { StudyLevelInterface } from "../../../_redux/features/studyLevels/types";
import { contractTypeInterface } from "../../../_redux/features/contractType/types";
import { salaryInterface } from "../../../_redux/features/salary/types";
import { Divider } from "@mui/joy";
import { NavLink } from "react-router-dom";
import moment from "moment";
import SelectedJobButton from "../../selectedJobButton/SelectedJobButton";
import CustomCompanyAvatar from "../../customAvatar/CustomCompanyAvatar";
import { CheckCircleTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectToken } from "../../../_redux/auth/authSlice";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useGetParamsExperiencesQuery } from "../../../_redux/features/experiences/services";

export interface JobComponentItemPropsInterface {
  job: JobInterface;
  iconOnly?: boolean;
}

export interface JobInterface {
  "@id": string;
  id: number;
  title: string;
  logoUrl: string | null;
  companyName: string;
  externalLink: string | null | undefined;
  publishedDate: string;
  refreshedDate: string;
  missionDescription: string;
  profileDescription: string;
  contractTypes: [contractTypeInterface];
  studyLevel: StudyLevelInterface;
  region: regionInterface;
  salary: salaryInterface;
  logo: string;
  applied?: boolean;
  selected?: boolean;
  hideCompany?: boolean;
  nbMonthExperience?: number;
  publishedJobs?: number;
  nbPosition?: number;
  city: {
    name: string;
  };
}

export interface regionInterface {
  "@id": string;
  id: number;
  name: string;
}

export interface jobXViewInterface {
  "@id": string;
  "@type": string;
  id: number;
  job: JobInterface;
}

const JobComponentItem = ({ job }: JobComponentItemPropsInterface) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);

  const { data } = useGetParamsExperiencesQuery({
    pagination: false,
  });
  const { list: experiences } = data || {};

  moment().locale(i18n.language);

  return (
    <div className="relative flex flex-col justify-between justify-self-center self-stretch min-h-72 w-full md:w-fit border-2 border-solid border-gray-200 hover:border-gray-300 rounded-2xl p-3 gap-1 transition-all duration-150">
      <NavLink
        className="grow flex flex-col justify-start gap-3 cursor-pointer"
        onClick={() => {
          dispatch(setSingleJob({ job }));
        }}
        to={`/jobs/${job?.id}`}
      >
        <div className="w-full flex flex-row gap-2 items-center">
          <div>
            <CustomCompanyAvatar
              src={
                job?.logoUrl
                  ? `${process.env.REACT_APP_MEDIA_BASE_URL}${job?.logoUrl}`
                  : `${mediaRoutes.COMPANY_LOGO}${job?.logo}`
              }
              className="max-h-[48px] max-w-[48px] object-contain"
              alt={job?.companyName ? job?.companyName : "picture company"}
            />
          </div>
          <div>
            <p className="text-md text-black">{job.companyName}</p>
          </div>
        </div>

        <div className="flex flex-wrap justify-start items-center gap-6">
          <p className="text-md text-black font-xing-bold truncate text-wrap">
            {job?.title}
          </p>
        </div>

        <div className="flex flex-row gap-1 items-center py-2">
          <LocationOnIcon fontSize="medium" />
          <p className="first-letter:uppercase text-black text-sm">
            {job?.region?.name}
          </p>
        </div>
        <div className="flex flex-row justify-between first-letter:capitalize">
          <div className="flex flex-wrap justify-end gap-2 items-center">
            <div className="flex flex-wrap justify-start items-center gap-3">
              <div className="flex flex-col max-lg:flex-row justify-between items-start max-lg:items-center gap-1 font-xing">
                <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                  <ApartmentOutlinedIcon fontSize="small" />
                  <p className="first-letter:uppercase text-black text-xs">
                    {job?.workplace?.name}
                  </p>
                </div>
              </div>

              <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                <WorkspacePremiumOutlinedIcon fontSize="small" />
                <p className="first-letter:uppercase text-black text-xs">
                  {job?.studyLevel?.name}
                </p>
              </div>
              {job?.contractTypes?.map((contract) => (
                <div
                  className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2"
                  key={contract?.id + contract?.name}
                >
                  <ArticleOutlinedIcon fontSize="small" />
                  <p className="first-letter:uppercase text-black text-xs">
                    {contract?.name}
                  </p>
                </div>
              ))}
              <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                <VerifiedIcon fontSize="small" />
                <p className="first-letter:uppercase text-black text-xs">
                  {
                    experiences?.find(
                      (exp) => exp?.id == job?.nbMonthExperience
                    )?.label
                  }
                </p>
              </div>
              <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                <GroupsOutlinedIcon fontSize="small" />
                <p className="first-letter:uppercase text-black text-xs">
                  {t("position.label", { count: job?.nbPosition ?? 1 })}
                </p>
              </div>
            </div>

            {job?.applied && (
              <div className="flex flex-row justify-evenly items-center gap-1 text-[#8938f3] text-xs bg-[#f2e8ff] rounded-full py-1 px-2 first-letter:uppercase">
                <CheckCircleTwoTone fontSize={"small"} />
                <p className="text-xs first-letter:uppercase">
                  {t("job.applied.label")}
                </p>
              </div>
            )}
          </div>
        </div>
      </NavLink>

      <div className="flex flex-col max-lg:flex-row justify-between items-start max-lg:items-center gap-1">
        <div>
          <p className="text-xs text-gray-300 first-letter:uppercase">
            {moment().locale(i18n.language).to(job?.refreshedDate)}
          </p>
        </div>
        {isAuth && (
          <>
            <div className="w-full h-[1px] max-lg:hidden">
              <Divider />
            </div>
            <div className="flex flex-row justify-start items-center gap-6">
              <SelectedJobButton job={job} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default JobComponentItem;
