import React, {useState, useEffect} from 'react';
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";

export default function Countdown ({startDate, endDate, backgroundColor = "#ffb836", alignContainers = "row"} : { startDate: string, endDate: string, backgroundColor?: string, alignContainers?: string }) {

    const { t } = useTranslation()
    const now = DateTime.local();

    const style = `flex flex-${alignContainers} justify-center items-center gap-1 bg-[${backgroundColor}] rounded-xl p-2`

    const start = DateTime.fromISO(startDate);
    const end = DateTime.fromISO(endDate);
    const calculateTimeRemaining = () => {

        const difference = start.diff(now, ["years", "months", "days", "hours"]);

        if (difference.days > 0) {
            const {months, days, hours, minutes, seconds } = difference.shiftTo('months', 'days', 'hours', 'minutes', 'seconds');
            return { months, days, hours, minutes, seconds };
        } else {
            return { months: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    const [isEventOngoing, setIsEventOngoing] = useState(false);
    const [isEventExpired, setIsEventExpired] = useState(false);

    useEffect(() => {

        const ongoing = start <= now && now <= end;
        setIsEventOngoing(ongoing);

        const expired = end < now;
        setIsEventExpired(expired);

        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(intervalId);

    }, [start, endDate]);


    return (
        <div className="grow w-full flex flex-row justify-center items-center font-xing-bold gap-3">
                {isEventOngoing &&
                    <div className={`${style} p-2`}>
                        <p>{ t('events.state.onGoing.label') }</p>
                    </div>
                }
                {isEventExpired &&
                    <div className={`${style} p-2`}>
                        <p>{ t('events.state.expired.label') }</p>
                    </div>
                }
                {!isEventOngoing && !isEventExpired && (
                    <>
                        { timeRemaining.months > 0 &&
                            <div className={style}>
                                <h1 className="text-lg font-xing-bold">
                                    { timeRemaining.months }
                                </h1>
                                <p className="text-sm">
                                    { t('events.months.label') }
                                </p>
                            </div> }
                        <div className={style}>
                            <h1 className="text-lg font-xing-bold">
                                { timeRemaining.days }
                            </h1>
                            <p className="text-sm">
                                { t('events.days.label') }
                            </p>
                        </div>
                        <div className={style}>
                            <h1 className="text-lg font-xing-bold">
                                { timeRemaining.hours }
                            </h1>
                            <p className="text-sm">
                                { t('events.hours.label') }
                            </p>
                        </div>
                        <div className={style + " max-md:hidden"}>
                            <h1 className="text-lg font-xing-bold">
                                { timeRemaining.minutes }
                            </h1>
                            <p className="text-sm">
                                { t('events.minutes.label') }
                            </p>
                        </div>
                        <div className={style + " max-sm:hidden"}>
                            <h1 className="text-lg font-xing-bold">
                                { parseInt(`${timeRemaining.seconds}`) }
                            </h1>
                            <p className="text-sm">
                                { t('events.seconds.label') }
                            </p>
                        </div>
                    </>
                )}

        </div>
    );
};