import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { useGetCountriesQuery } from "../../../../_redux/features/country/services";
import { countryInterface } from "../../../../_redux/features/country/types";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectCountry(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCountriesQuery({ pagination: false });
  const { list } = data || {};

  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("countries.label", { count: 1 })}
      placeholder={t("countries.placeholder", { count: 1 })}
      {...props}
    >
      {list
        ?.map((country: countryInterface) => (
          <MenuItem
            className="first-letter:uppercase"
            key={country["@id"]}
            value={country["@id"]}
          >
            {country?.code} - {country?.name}
          </MenuItem>
        ))
        .sort((a, b) => (a > b ? -1 : 1))}
    </Select>
  );
}
