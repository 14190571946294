import { Form, useFormikContext } from "formik";
import { Button } from "@nextui-org/react";
import Input from "../_field/Input";
import Textarea from "../_field/Textarea";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { ExperienceInterface } from "../../../pages/private/profile/ui/myProfile/Experience";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import SelectCompanyType from "../_field/Specified/SelectCompanyType";
import SelectFunctionType from "../_field/Specified/SelectFunctionType";
import SelectActivitySectorGroup from "../_field/Specified/SelectActivitySectorGroup";
import CustomCheckbox from "../_field/Checkbox";
import CustomSpinner from "../../spinner/CustomSpinner";

interface ExperienceEditFormInterface {
  experience: ExperienceInterface;
  onCancel?: () => void | undefined;
  triggers: MutationResultSelectorResult<any>;
}
export default function ExperienceEditForm({
  triggers,
  onCancel = () => undefined,
}: ExperienceEditFormInterface) {
  const { t } = useTranslation();
  const {
    setErrors: setErrorsHook,
    setSubmitting,
    initialValues,
    values,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();

  const { reset, isLoading, isError, error, isSuccess } = triggers;

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        t("update.message.specific", { value: t("experience.title.label") }),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );

      setErrorsHook({});
      reset();

      if (typeof onCancel === "function") {
        onCancel();
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    setSubmitting(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (isError) {
      let errors = {};
      // @ts-ignore
      error?.data?.violations?.forEach(
        (violation: { propertyPath: any; message: any }) => {
          if (violation?.propertyPath in initialValues) {
            errors[violation?.propertyPath] = violation?.message;
          } else {
            toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        }
      );

      if (errors) {
        setErrorsHook(errors);
      }
    }
  }, [isError, error]);

  return (
    <Form className="w-full flex flex-col gap-4">
      <Input
        fullWidth
        label={t("company.name.label")}
        placeholder={t("company.name.placeholder")}
        name="companyName"
        disabled={isSubmitting}
      />
      <SelectCompanyType name={"companyType"} />
      <SelectActivitySectorGroup id="@id" name={"sectorGroup"} />
      <Input
        fullWidth
        name="jobTitle"
        label={t("job.title.label")}
        placeholder={t("job.title.placeholder")}
        required
        disabled={isSubmitting}
      />
      <SelectFunctionType id="@id" name={"function"} />
      <Input
        type="date"
        fullWidth
        name="startDate"
        label={t("startDate.label")}
        placeholder={t("startDate.placeholder")}
        required
        disabled={isSubmitting}
      />
      <CustomCheckbox
        fullWidth
        name="current"
        label={t("current.label")}
        defaultChecked={initialValues?.current}
        disabled={isSubmitting}
        onChange={(e: any) => {
          setFieldValue("current", e.target.checked);
          if (e.target.checked) {
            setFieldValue("endDate", format(new Date(), "yyyy-MM-dd"));
          } else {
            setFieldValue("endDate", format(new Date("now"), "yyyy-MM-dd"));
          }
        }}
      />
      <Input
        fullWidth
        label="endDate"
        placeholder="endDate"
        name="endDate"
        type="date"
        required
        disabled={isSubmitting || !!values?.current}
      />
      <Textarea
        fullWidth
        label={t("job.details.label")}
        placeholder={t("job.details.placeholder")}
        name="comment"
        isRequired
        disabled={isSubmitting}
      />
      <CustomCheckbox
        fullWidth
        name="manager"
        label={t("led.team.me.label")}
        defaultChecked={initialValues?.manager}
        disabled={isSubmitting}
        onChange={(e: any) => {
          setFieldValue("manager", e.target.checked);
        }}
      />
      <div className="flex flex-row justify-center items-center gap-6">
        {typeof onCancel === "function" && (
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
            startContent={<XMarkIcon height={24} width={24} />}
          >
            {t("close.label")}
          </Button>
        )}
        <Button
          type="submit"
          spinner={<CustomSpinner />}
          disabled={isSubmitting || isLoading}
          isLoading={isSubmitting || isLoading}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          color="warning"
          startContent={
            isSubmitting || isLoading ? null : (
              <PencilIcon height={24} width={24} />
            )
          }
        >
          {t("update.label")}
        </Button>
      </div>
    </Form>
  );
}
