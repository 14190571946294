import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ExpandLess} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import ProfileVisibilityIllustration from "../../../components/illustration/ProfileVisibilityIllustration";
import {Button} from "@nextui-org/react";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import {useGetVisitsStatisticsQuery} from "../../../_redux/features/network/services";
import VisitorsLineChart from "./VisitorsLineChart";

export default function Statistics({lastDays = 90}) {

    const {t} = useTranslation()
    const [openStatistics, setIsOpenStatistics] = useState(false)

    const {data: profile} = useGetProfileQuery();

    const {data: stats} = useGetVisitsStatisticsQuery({
        id: profile?.id,
        filters: {last_days: lastDays}
    }, {skip: !profile})

    return (
        <>
            <div
                className={`transition-all duration-150 ${openStatistics ? "hidden h-0" : ""} flex flex-col justify-start items-center gap-6`}>

                <div className="w-full flex flex-col justify-start items-start gap-3">
                    <div className="text-xl font-xing-bold">
                        {t("profile.performance.analysis.label")}
                    </div>
                    <p>
                        <b className="text-lg">{stats?.visitors}</b>
                        {t("visits.profil.for.days.label", {days: lastDays})}
                    </p>
                </div>

                <VisitorsLineChart lastDays={lastDays}/>

                <div className="w-full flex flex-col justify-start items-start gap-3">
                    <div className="text-xl font-xing-bold">
                        Comment vous avez été trouvé(e) :
                    </div>
                    <p>
                        Les principaux mots-clés utilisés et plus encore
                    </p>
                </div>

                <div className="w-full flex flex-row max-lg:flex-col justify-between items-center gap-9">
                    <div
                        className="flex-1 flex flex-col justify-center gap-6 items-center px-6 py-9 rounded-xl bg-gray-100">
                        <p className="text-center">
                            Malheureusement, cette rubrique est vide. Enrichissez votre profil pour gagner en
                            visibilité.
                        </p>
                        <NavLink className="font-xing-bold" to="/profile">
                            Compléter les infos
                        </NavLink>

                    </div>
                    <div className="flex-1 flex flex-row max-lg:flex-col items-center gap-3">
                        <div>
                            <ProfileVisibilityIllustration primary="#ffb836" secondary="#ffb836"/>
                        </div>
                        <div className="flex flex-col justify-center items-center gap-3 p-3">
                            <p className="font-xing-bold text-center">
                                Envie de gagner en visibilité ? Découvrez nos conseils.
                            </p>
                            <Button className="font-xing-bold" variant="bordered" radius="full" color="primary">
                                {t("visibility.boost.yours")}
                            </Button>

                        </div>
                    </div>

                </div>

            </div>

            <div
                onClick={() => setIsOpenStatistics(!openStatistics)}
                className="w-full cursor-pointer flex flex-row justify-center items-center px-1 font-xing-bold"
            >
                <p className="text-lg">
                    {t("statistics.label")}
                </p>
                <div className={`transition-all duration-100 ${openStatistics ? "" : "rotate-180"}`}>
                    <ExpandLess height={18} width={18}/>
                </div>
            </div>

        </>
    )
}
