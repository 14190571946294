import {Radio} from "@mui/material";
import React from "react";

export default function BlackRadio({...props}) {
    return (
        <Radio
            sx={{
                color: "#000",
                fontWeight: 600,
                '&.Mui-checked': {
                    color: "#000",
                },
            }}
            {...props}
        />
    )
}