import React from "react";

type icon = {
    src: string,
    alt: string,
    height: number,
    width: number,
    onClick?: React.MouseEventHandler<HTMLImageElement> | undefined
    className?: string | undefined
}
const Icon = ({ src, alt, height, width, onClick, className = "" }: icon ) => {

    return (
        <img
            src={src}
            alt={`icon-${alt}`}
            height={height}
            width={width}
            className={`${className} cursor-pointer`}
            onClick={onClick}
        />
    )
}

export default Icon