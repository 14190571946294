import { createSlice } from "@reduxjs/toolkit";

export interface CurrentIndexState {
  value: number;
}

const initialState: CurrentIndexState = {
  value: 0,
};

export const currentIndexSlice = createSlice({
  name: "menuCurrentIndex",
  initialState,
  reducers: {
    change: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { change } = currentIndexSlice.actions;

export default currentIndexSlice.reducer;
