import TitledContainer from "../../../components/containers/TitledContainer";
import {Container} from "@mui/material";

export default function MyPosts() {
    return (
        <Container maxWidth="md">
            <TitledContainer
                title="My posts"
                className="h-screen flex flex-col"
            >
                <p>My posts</p>
            </TitledContainer>
        </Container>
    )

}