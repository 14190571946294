import {
  combineReducers,
  configureStore,
  Middleware,
  MiddlewareAPI,
  ThunkDispatch,
  isRejected,
} from "@reduxjs/toolkit";
import indexReducer from "./features/menu/current-index-slice";
import mobileMenuReducer from "./features/menu/is-open-slice";
import searchModalReducer from "./features/modals/searchModalSlice";
import jobsReducer from "./features/jobs/jobsSlice";
import eventsReducer from "./features/events/eventsSlice";
import blogReducer from "./features/blog/blogSlice";
import similarOffersApplyReducer from "./similars/similarsSlice";
import authReducer, { logOut } from "./auth/authSlice";
import thunkMiddleware from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import { useDispatch } from "react-redux";
import { jobApi } from "./features/jobs/services";
import { blogApi } from "./features/blog/services";
import { eventApi } from "./features/events/services";
import { setupListeners } from "@reduxjs/toolkit/query";
import { profileApi } from "./auth/services";
import { studyLevelApi } from "./features/studyLevels/services";
import { certificateDomainApi } from "./features/certificate/domain/services";
import { certificateTypeApi } from "./features/certificate/type/services";
import { skillApi } from "./features/skills/services";
import { languageApi } from "./features/languages/services";
import { companyTypeApi } from "./features/company/companyType/services";
import { functionTypeApi } from "./features/functionType/services";
import { activitySectorGroupApi } from "./features/activitySectorGroup/services";
import { careerLevelApi } from "./features/careerLevel/services";
import { salaryApi } from "./features/salary/services";
import { settingsApi } from "./settings/services";
import { countryApi } from "./features/country/services";
import { regionApi } from "./features/region/services";
import { cityApi } from "./features/city/services";
import { jobAlertFrequencyApi } from "./features/jobAlertFrequency/services";
import { paramsExperiencesApi } from "./features/experiences/services";
import { networkApi } from "./features/network/services";
import { contractTypeApi } from "./features/contractType/services";
import { workplaceApi } from "./features/workplace/services";
import { chatRoomApi } from "./features/chatRoom/services";
import storage from "redux-persist/lib/storage";

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejected(action)) {
      if (action.payload?.status === 401) {
        console.error("error auth");
        store.dispatch(logOut());
      }
    }
    return next(action);
  };

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  blacklist: [
    jobApi.reducerPath,
    eventApi.reducerPath,
    profileApi.reducerPath,
    studyLevelApi.reducerPath,
    certificateDomainApi.reducerPath,
    certificateTypeApi.reducerPath,
    skillApi.reducerPath,
    languageApi.reducerPath,
    companyTypeApi.reducerPath,
    functionTypeApi.reducerPath,
    activitySectorGroupApi.reducerPath,
    careerLevelApi.reducerPath,
    salaryApi.reducerPath,
    settingsApi.reducerPath,
    countryApi.reducerPath,
    regionApi.reducerPath,
    cityApi.reducerPath,
    jobAlertFrequencyApi.reducerPath,
    paramsExperiencesApi.reducerPath,
    networkApi.reducerPath,
    contractTypeApi.reducerPath,
    workplaceApi.reducerPath,
    chatRoomApi.reducerPath,
    blogApi.reducerPath,
  ],
  whitelist: [
    "auth",
    "menuCurrentIndex",
    "menuIsOpen",
    "searchModal",
    "jobs",
    "events",
    "similarOffersApply",
  ],
};
export const middlewareArray = [
  thunkMiddleware,
  jobApi.middleware,
  blogApi.middleware,
  eventApi.middleware,
  profileApi.middleware,
  studyLevelApi.middleware,
  certificateDomainApi.middleware,
  certificateTypeApi.middleware,
  skillApi.middleware,
  languageApi.middleware,
  companyTypeApi.middleware,
  functionTypeApi.middleware,
  activitySectorGroupApi.middleware,
  careerLevelApi.middleware,
  salaryApi.middleware,
  settingsApi.middleware,
  countryApi.middleware,
  regionApi.middleware,
  cityApi.middleware,
  jobAlertFrequencyApi.middleware,
  paramsExperiencesApi.middleware,
  networkApi.middleware,
  contractTypeApi.middleware,
  workplaceApi.middleware,
  chatRoomApi.middleware,
  rtkQueryErrorLogger,
];
export const reducers = combineReducers({
  auth: authReducer,
  menuCurrentIndex: indexReducer,
  menuIsOpen: mobileMenuReducer,
  searchModal: searchModalReducer,
  jobs: jobsReducer,
  events: eventsReducer,
  blog: blogReducer,
  similarOffersApply: similarOffersApplyReducer,
  [jobApi.reducerPath]: jobApi.reducer,
  [blogApi.reducerPath]: blogApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [studyLevelApi.reducerPath]: studyLevelApi.reducer,
  [certificateDomainApi.reducerPath]: certificateDomainApi.reducer,
  [certificateTypeApi.reducerPath]: certificateTypeApi.reducer,
  [skillApi.reducerPath]: skillApi.reducer,
  [languageApi.reducerPath]: languageApi.reducer,
  [companyTypeApi.reducerPath]: companyTypeApi.reducer,
  [functionTypeApi.reducerPath]: functionTypeApi.reducer,
  [jobAlertFrequencyApi.reducerPath]: jobAlertFrequencyApi.reducer,
  [activitySectorGroupApi.reducerPath]: activitySectorGroupApi.reducer,
  [careerLevelApi.reducerPath]: careerLevelApi.reducer,
  [salaryApi.reducerPath]: salaryApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [regionApi.reducerPath]: regionApi.reducer,
  [cityApi.reducerPath]: cityApi.reducer,
  [paramsExperiencesApi.reducerPath]: paramsExperiencesApi.reducer,
  [networkApi.reducerPath]: networkApi.reducer,
  [chatRoomApi.reducerPath]: chatRoomApi.reducer,
  [contractTypeApi.reducerPath]: contractTypeApi.reducer,
  [workplaceApi.reducerPath]: workplaceApi.reducer,
});

export const persistedReducer = persistReducer(persistConfig, reducers);

let preloadedState = {};
if (typeof window !== "undefined") {
  preloadedState = window.__staticRouterHydrationData?.loaderData?.["0-0"];
  delete window?.__staticRouterHydrationData;
}

export const store = configureStore({
  reducer: persistedReducer,
  preloadedState: preloadedState ? preloadedState : {},
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewareArray),
  devTools: process.env.NODE_ENV !== "production",
});

export const useAppDispatch = () => useDispatch<ThunkDispatch<any, any, any>>();
setupListeners(store.dispatch);

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
