import React from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "@mui/material";
import {ModalClose, ModalDialog, ModalOverflow} from "@mui/joy";
import SendEmail from "../../../pages/public/sendEmail/SendEmail";

interface UseDisclosureProps {
    isOpen?: boolean;
    onClose?(): void;
}

export default function ResetPasswordModal({isOpen, onClose} : UseDisclosureProps) {

    const {t} = useTranslation()

    return (
        <Modal
            className="min-h-full"
            open={isOpen}
            onClose={onClose}
            title={t("update.specific", {value: t("professionalExperience.label")})}
        >
            <ModalOverflow>
                <ModalDialog variant="plain" layout="fullscreen">
                    <ModalClose onClick={onClose} />
                    <SendEmail />
                </ModalDialog>
            </ModalOverflow>
        </Modal>
    );
}
