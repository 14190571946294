import React from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {Badge, BadgeProps} from "@mui/material";
import {styled} from "@mui/material";
import {SearchIcon} from "@nextui-org/shared-icons";

export default function SettingsIconButton() {

    const {t, i18n} = useTranslation()

    const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
        '& .MuiBadge-badge': {
            border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }));

    return (
        <NavLink to="/jobs">
            <div className="flex flex-col items-center justify-center">
                <SearchIcon height={24} width={24} />
                <p className="text-sm max-md:hidden">{ t("search.label") }</p>
            </div>
        </NavLink>
    )
}