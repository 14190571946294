import { date, object, string } from "yup";
import i18n from "i18next";

export const certificateSchema = () =>
  object().shape({
    title: string().required(i18n.t("validation.field.required.unspecific")),
    description: string().required(
      i18n.t("validation.field.required.unspecific")
    ),
    startYear: date().required(i18n.t("validation.field.required.unspecific")),
    endDate: date()
      .required(i18n.t("validation.field.required.unspecific"))
      .when("$startYear", ([other], schema) =>
        other
          ? schema.min(
              other,
              i18n.t("validation.field.min.unspecific", { min: other })
            )
          : schema
      ),
    school: string().required(i18n.t("validation.field.required.unspecific")),
  });
