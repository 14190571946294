import {Button} from "@nextui-org/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSendInvitationMutation} from "../../../_redux/features/network/services";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import {CandidateInterface} from "../../../_redux/features/events/interfaces";

export default function SendInvitationButton({user}: {user: CandidateInterface}) {

    const {t} = useTranslation()
    const [sendAction, sendTriggers] = useSendInvitationMutation()
    const {data: profile, isLoading: isProfileLoading} = useGetProfileQuery()

    return (
        <Button
            radius="full"
            variant="bordered"
            color="primary"
            disabled={isProfileLoading || sendTriggers?.isLoading || sendTriggers?.isFetching}
            isLoading={sendTriggers?.isLoading || sendTriggers?.isFetching}
            className="font-xing-bold text-sm"
            onClick={() => sendAction({
                invited: user["@id"],
                invitedBy: profile["@id"]
            })}
        >
            {t("add.label")}
        </Button>
    )
}