import React from "react";
import MyExperience from "./MyExperience";
import ExperienceAddModal from "../../../../../components/modals/Profile/Experience/ExperienceAddModal";
import { useTranslation } from "react-i18next";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../../_redux/auth/services";
import Loading from "../../../../../Loading";
import TitledContainer from "../../../../../components/containers/TitledContainer";
import NoExperienceFound from "../../../../../components/noResultFound/NoExperienceFound";
import { Add } from "@mui/icons-material";

export default function MyExperiences() {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const {
    data: cv,
    isLoading: isCvLoading,
    isFetching,
  } = useGetMyCvQuery(profile?.id, { skip: !profile });

  if (isCvLoading) {
    return <Loading />;
  }

  return (
    <>
      <TitledContainer
        title={t("professionalExperience.label")}
        buttons={[
          <ExperienceAddModal
            key={1}
            isIconOnly={true}
            color="default"
            variant="flat"
            radius="full"
            size="sm"
            className="p-0"
            icon={<Add height={10} width={10} />}
          />,
        ]}
        isLoading={isCvLoading || isFetching}
      >
        <div className="flex flex-col justify-between items-center gap-3">
          {
            // @ts-ignore
            cv?.experiences?.length > 0 ? (
              cv?.experiences?.map((experience) => (
                <MyExperience
                  key={experience["@id"]}
                  experience={experience}
                  isLoading={isCvLoading || isFetching}
                />
              ))
            ) : (
              <NoExperienceFound />
            )
          }
        </div>
      </TitledContainer>
    </>
  );
}
