import React from "react";
import { Button } from "@nextui-org/react";
import { Formik } from "formik";
import { format } from "date-fns";
import { ExperienceInterface } from "../../../../pages/private/profile/ui/myProfile/Experience";
import { useTranslation } from "react-i18next";
import { experienceSchema } from "../../../_form/_schema/experienceSchema";
import { useUpdateCvExperienceMutation } from "../../../../_redux/auth/services";
import { PencilIcon } from "@heroicons/react/24/outline";
import ExperienceEditForm from "../../../_form/Experience/ExperienceEditForm";

import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";
import { toast } from "react-toastify";

type ExperienceEditModalProps = {
  experience: ExperienceInterface;
};

export default function ExperienceEditModal({
  experience,
}: ExperienceEditModalProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [updateExperienceAction, triggers] = useUpdateCvExperienceMutation();

  let initialValues = {
    companyName: experience.companyName,
    jobTitle: experience.jobTitle,
    startDate: format(new Date(experience.startDate), "yyyy-MM-dd"),
    endDate: format(new Date(experience.endDate), "yyyy-MM-dd"),
    current: experience.current,
    comment: experience.comment,
    companyType: experience.companyType?.["@id"],
    function: experience.function?.["@id"],
    manager: experience?.manager,
    sectorGroup: experience?.sectorGroup?.["@id"],
  };

  const handelSubmit = async (values: ExperienceInterface) => {
    updateExperienceAction({ id: experience?.id, ...values })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", { value: t("experience.title.label") }),
          { position: toast.POSITION.BOTTOM_LEFT }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };
  const shema = experienceSchema();
  return (
    <>
      <Button
        isIconOnly
        aria-label={t("update.label")}
        className="p-0"
        size="sm"
        onClick={handleOpen}
        title={t("update.label")}
        variant="light"
      >
        <PencilIcon height={18} width={18} />
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("update.specific", {
          value: t("professionalExperience.label"),
        })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("update.specific", {
                    value: t("professionalExperience.label"),
                  })}
                </h1>
                <Formik
                  initialValues={initialValues as never}
                  onSubmit={handelSubmit}
                  validationSchema={shema}
                >
                  <ExperienceEditForm
                    triggers={triggers}
                    onCancel={handleClose}
                    experience={experience}
                  />
                </Formik>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
