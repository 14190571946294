import React, { ReactNode } from "react";
import { Button } from "@nextui-org/react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  useAddCvCompetenceMutation,
  useGetProfileQuery,
} from "../../../../_redux/auth/services";
import { skillSchema } from "../../../_form/_schema/skillSchema";
import { CvXSkillInterface } from "../../../../_redux/features/skills/cvXSkillInterface";
import SkillAddForm from "../../../_form/Skill/SkillAddForm";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

interface SkillsAddModalInterface {
  icon?: ReactNode;
  text?: string;
}
export default function SkillAddModal({
  text,
  icon,
  ...props
}: SkillsAddModalInterface) {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const [addCompetenceAction, addCompetenceTrigger] =
    useAddCvCompetenceMutation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let initialValues = {
    cv: `/api/candidates/${profile?.id}/cv`,
    level: "",
    rank: 0,
    competence: {
      name: "",
    },
  };

  const handelSubmit = async (values: CvXSkillInterface) => {
    addCompetenceAction(values)
      .unwrap()
      .then((response) => {
        toast.success(
          t("add.message.specific", {
            value: t("skills.label", { count: 1 }),
          }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        handleClose();
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  return (
    <>
      <Button
        {...props}
        aria-label={t("add.label")}
        title={t("add.specific", { value: t("skills.label", { count: 1 }) })}
        onClick={handleOpen}
      >
        {icon && icon}

        {text && <p className="font-xing-bold">{text}</p>}
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("add.specific", { value: t("skills.label", { count: 1 }) })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("add.specific", {
                    value: t("skills.label", { count: 1 }),
                  })}
                </h1>
                <Formik
                  initialValues={initialValues as never}
                  onSubmit={handelSubmit}
                  validationSchema={skillSchema}
                >
                  <SkillAddForm
                    onCancel={handleClose}
                    triggers={addCompetenceTrigger}
                  />
                </Formik>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
