import React from "react";
import { useTranslation } from "react-i18next";
import TitledContainer from "../../../../components/containers/TitledContainer";
import { useGetProfileQuery } from "../../../../_redux/auth/services";
import {
  useGetMyInvitationsQuery,
  useGetMyFriendRequestsQuery,
} from "../../../../_redux/features/network/services";
import { Container } from "@mui/material";
import DevelopYourNetwork from "../DevelopYourNetwork";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../utils/appRoutes";
import { Button } from "@nextui-org/react";

export default function Invitations() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: profile } = useGetProfileQuery();
  const { data: myInvitations } = useGetMyInvitationsQuery(
    { id: profile?.id },
    { skip: !profile }
  );
  const { totalItems: myInvitationsTotal } = myInvitations || {};
  const { pathname } = useLocation();

  const { data: friendRequestsData } = useGetMyFriendRequestsQuery(
    { id: profile?.id },
    { skip: !profile }
  );
  const { totalItems: friendRequestsDataTotal } = friendRequestsData || {};

  const tabs = [
    {
      title: `${t("invitations.received.label")} ${
        myInvitationsTotal > 0 ? `(${myInvitationsTotal})` : ""
      }`,
      path: AppRoutes.PRIVATE_NETWORK_REQUESTS,
    },
    {
      title: `${t("invitations.sent.label")} ${
        friendRequestsDataTotal > 0 ? `(${friendRequestsDataTotal})` : ""
      }`,
      path: AppRoutes.PRIVATE_NETWORK_REQUESTS_SENT,
    },
  ];

  function redirection(redirect: string) {
    if (redirect !== pathname) {
      return navigate(redirect);
    }
  }

  return (
    <>
      <Container maxWidth="md">
        <div className="w-full flex flex-col justify-start items-center gap-6">
          <TitledContainer
            title={t("invitations.label")}
            className="relative w-full flex flex-col justify-between gap-9"
          >
            <div className="flex flex-wrap justify-start items-center gap-3">
              {tabs?.map((tab) => (
                <Button
                  key={tab?.path}
                  onClick={() =>
                    redirection(
                      tab?.path.replace(":locale", `${i18n.language}`)
                    )
                  }
                  color={
                    pathname ===
                    tab?.path.replace(":locale", `${i18n.language}`)
                      ? "primary"
                      : "default"
                  }
                  radius="full"
                  variant="solid"
                  className="first-letter:capitalize font-xing-bold text-md"
                >
                  {tab?.title}
                </Button>
              ))}
            </div>
            <Outlet />
          </TitledContainer>
        </div>
      </Container>
      <DevelopYourNetwork />
    </>
  );
}
