import {createSlice} from '@reduxjs/toolkit';

export interface SearchModalState {
    value: boolean
}

const initialState: SearchModalState = {
    value: false
}

export const searchModalSlice = createSlice({
    name: 'searchModal',
    initialState,
    reducers: {
        toggle: (state, action) => { state.value = action.payload },
    }
})

export const { toggle } = searchModalSlice.actions;

export default searchModalSlice.reducer;