import {RootState} from "../../store";
import {createSlice} from '@reduxjs/toolkit';

interface SearchInterface {
    _page?: number,
    keyword?: string,
    category?: string,
}

export interface BlogState {
    search: SearchInterface
}

const initialState: BlogState = {
    search: {
        keyword: undefined,
        category: undefined,
        _page: 1
    }
}

export const blog = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        setSearch: (state, action) => {
            const {search} = action.payload
            return { ...state, search };

        },
        setPagination: (state, action) => {
            const { pagination } = action.payload;
            return { ...state, pagination };
        }
    }
})

export const search = (state: RootState) => state.blog.search;

export default blog.reducer;