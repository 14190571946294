import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  CvInterface,
  ProfileInterface,
} from "../../pages/private/profile/MyProfile";
import { ExperienceInterface } from "../../pages/private/profile/ui/myProfile/Experience";
import { RootState } from "../store";
import storage from "redux-persist/lib/storage";
import { CvXSkillInterface } from "../features/skills/cvXSkillInterface";
import { CvXLanguageInterface } from "../features/languages/cvXLanguageInterface";

export interface AuthState {
  user: ProfileInterface | undefined;
  cv: CvInterface | undefined;
  token: string | undefined;
  skill: CvXSkillInterface | undefined;
  language: CvXLanguageInterface | undefined;
  mercure: {
    hubUrl: undefined | string;
    token: undefined | string;
  };
}

const initialState: AuthState = {
  cv: undefined,
  skill: undefined,
  user: undefined,
  token: undefined,
  language: undefined,
  mercure: {
    hubUrl: undefined,
    token: undefined,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logIn: (state, action) => {
      const { token, mercureAuthorization } = action.payload;

      typeof window !== "undefined" && localStorage.setItem("token", token);
      typeof window !== "undefined" &&
        localStorage.setItem("mercureAuthorization", mercureAuthorization);

      axios.defaults.headers.common["Authorization"] = "bearer " + token;

      return {
        ...state,
        token: token,
        user: undefined,
        mercure: { ...state.mercure, token: mercureAuthorization },
      };
    },
    logOut: () => {
      typeof window !== "undefined" && localStorage.clear();
      storage.removeItem("persist:root");
      return {
        cv: undefined,
        user: undefined,
        token: undefined,
        stayLogged: undefined,
      };
    },
    setMercure: (state, action) => {
      const { hubUrl } = action.payload;

      typeof window !== "undefined" &&
        localStorage.setItem("mercure", { ...state.mercure, hubUrl });

      // axios.defaults.headers.common['Authorization'] = "bearer " + token;

      return { ...state, mercure: { ...state.mercure, hubUrl } };
    },
    setProfile: (state, action) => {
      const { profile } = action.payload;

      return { ...state, user: profile };
    },
    setCv: (state, action) => {
      const { cv } = action.payload;

      return { ...state, cv };
    },
    setSingleSkill: (state, action) => {
      const { skill } = action.payload;

      return { ...state, skill };
    },
    setSingleLanguage: (state, action) => {
      const { language } = action.payload;

      return { ...state, language };
    },
    setExperience: (state, action) => {
      const { experience }: { experience: ExperienceInterface } =
        action.payload;
      state?.cv?.experiences.push(experience);
      return state;
    },
    updateExperience: (state, action) => {
      const { experience }: { experience: ExperienceInterface } =
        action.payload;
      state?.cv?.experiences?.map((item) => {
        return item["@id"] === experience["@id"] ? experience : item;
      });

      return state;
    },
  },
});

export const selectProfile = (state: RootState) => state.auth.user;
export const selectCv = (state: RootState) => state.auth.cv;
export const selectToken = (state: RootState) => state.auth.token;
export const selectSingleSkill = (state: RootState) => state.auth.skill;

export const selectSingleLanguage = (state: RootState) => state.auth.language;
export const selectMercureHubUrl = (state: RootState) =>
  state.auth?.mercure?.hubUrl;
export const selectMercureAuthorization = (state: RootState) =>
  state.auth?.mercure?.token;

export const {
  logIn,
  logOut,
  setProfile,
  setCv,
  setExperience,
  updateExperience,
  setSingleSkill,
  setSingleLanguage,
  setStayLoggedIn,
  setMercure,
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type

export default authSlice.reducer;
