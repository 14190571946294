import React, { useEffect, useState } from "react";
import {
  useGetCvPdfMutation,
  useGetCvScoreQuery,
  useGetProfileQuery,
} from "../../../../../_redux/auth/services";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../Loading";
import { Button } from "@nextui-org/react";
import { toast } from "react-toastify";
import ColoredContainer from "../../../../../components/containers/ColoredContainer";
import { Download } from "@mui/icons-material";
import CustomSpinner from "../../../../../components/spinner/CustomSpinner";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toastMessageError } from "../../../../../_helpers/toastErrorMessage";

export default function CvExport() {
  const { data: profile, isLoading } = useGetProfileQuery();
  const [getCvPdfAction, getCvPdfTrigger] = useGetCvPdfMutation();
  const { isError, error, isLoading: isGetCvLoading, reset } = getCvPdfTrigger;
  const { t } = useTranslation();
  const [popupOpen, setPopupOpen] = useState(false);
  const { data: score } = useGetCvScoreQuery(profile?.id, {
    skip: !profile?.id,
  });

  if (isLoading) {
    return <Loading />;
  }
  const handelDownload = () => {
    score?.progression >= process.env.REACT_APP_CV_PROGRESSION && profile?.id
      ? getCvPdfAction(profile?.id)
          .unwrap()
          .catch((error) => {
            toastMessageError({ error });
          })
      : setPopupOpen(true);
  };
  return (
    <>
      <ColoredContainer>
        <div className="flex flex-col justify-center items-center my-3 rounded-xl p-3 px-6 py-3 gap-3">
          <div className="text-xl font-xing-bold text-black">
            {t("cv.export.yours.label")}
          </div>
          <div className="w-full flex flex-row justify-center">
            {t("cv.generate.yours.placeholder")}
          </div>

          <Button
            color="primary"
            variant="solid"
            radius="full"
            className="text-medium font-xing-bold rounded-full py-3 px-4"
            disabled={isGetCvLoading}
            isLoading={isGetCvLoading}
            spinner={<CustomSpinner />}
            onClick={handelDownload}
          >
            {!isGetCvLoading && <Download />}
            {t("cv.generate.yours.label")}
          </Button>
        </div>
      </ColoredContainer>
      <Modal open={popupOpen} onClose={() => setPopupOpen(false)}>
        <ModalOverflow>
          <ModalDialog variant="outlined">
            <ModalClose onClick={() => setPopupOpen(false)} />
            <Container maxWidth="lg">
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="lg"
              >
                <p className="text-2xl font-xing first-letter:uppercase">
                  {t("public.home.profile.condtion.download.label", {
                    progression: score?.progression,
                    limit: process.env.REACT_APP_CV_PROGRESSION,
                  })}
                </p>
                <div className="flex flex-wrap-reverse flex-row w-full gap-6 justify-center">
                  <Button
                    onClick={() => setPopupOpen(false)}
                    aria-label={t("job.applied.label")}
                    className="text-black font-xing-bold py-6 px-4 text-lg font-normal flex flex-row justify-around first-letter:uppercase"
                    variant="light"
                    color="primary"
                  >
                    {t("ignore.label")}
                  </Button>

                  <Button
                    aria-label={t("complete.profile")}
                    className="text-black font-xing-bold py-6 px-4 text-lg font-normal flex flex-row justify-around first-letter:uppercase"
                    color="warning"
                    onClick={() => setPopupOpen(false)}
                  >
                    {t("public.home.complete.profile.label")}
                  </Button>
                </div>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
