import { Form, useFormikContext } from "formik";
import { Button } from "@nextui-org/react";
import Input from "../_field/Input";
import Textarea from "../_field/Textarea";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import SelectCompanyType from "../_field/Specified/SelectCompanyType";
import SelectFunctionType from "../_field/Specified/SelectFunctionType";
import SelectActivitySectorGroup from "../_field/Specified/SelectActivitySectorGroup";
import CustomCheckbox from "../_field/Checkbox";
import CustomSpinner from "../../spinner/CustomSpinner";

interface ExperienceAddFormInterface {
  onCancel?: () => void | undefined;
  triggers: MutationResultSelectorResult<any>;
}

export default function ExperienceAddForm({
  onCancel = () => {},
  triggers,
}: ExperienceAddFormInterface) {
  const { t } = useTranslation();
  const {
    setErrors: setErrorsHook,
    setSubmitting,
    initialValues,
    isSubmitting,
    errors,
    values,
    setFieldValue,
  } = useFormikContext();
  const { reset, isSuccess, isLoading, error, isError } = triggers;

  // useEffect(() => {
  //   return () => reset();
  // }, []);

  // useEffect(() => {
  //   setSubmitting(isLoading);
  // }, [isLoading]);

  // useEffect(() => {
  //   if (isError) {
  //     let errors = {};
  //     // @ts-ignore
  //     error?.data?.violations?.forEach(
  //       (violation: { propertyPath: any; message: any }) => {
  //         if (violation?.propertyPath in initialValues) {
  //           errors[violation?.propertyPath] = violation?.message;
  //         } else {
  //           toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
  //             position: toast.POSITION.BOTTOM_LEFT,
  //           });
  //         }
  //       }
  //     );

  //     if (errors) {
  //       setErrorsHook(errors);
  //     }
  //   }
  // }, [isError, error]);

  return (
    <Form className="w-full flex flex-col gap-4">
      <Input
        fullWidth
        label={t("company.name.label")}
        placeholder={t("company.name.placeholder")}
        name="companyName"
        disabled={isSubmitting}
      />
      <SelectCompanyType name={"companyType"} />
      <SelectActivitySectorGroup id="@id" name={"sectorGroup"} />
      <Input
        fullWidth
        name="jobTitle"
        label={t("job.title.label")}
        placeholder={t("job.title.placeholder")}
        disabled={isSubmitting}
      />
      <SelectFunctionType id="@id" name={"function"} />
      <Input
        type="date"
        fullWidth
        name="startDate"
        label={t("startDate.label")}
        placeholder={t("startDate.placeholder")}
        disabled={isSubmitting}
      />
      <CustomCheckbox
        fullWidth
        name="current"
        label={t("current.label")}
        defaultChecked={initialValues?.subAnem}
        disabled={isSubmitting}
        onChange={(e: any) => {
          setFieldValue("current", e.target.checked);
          if (e.target.checked) {
            setFieldValue("endDate", format(new Date(), "yyyy-MM-dd"));
          } else {
            setFieldValue("endDate", "");
          }
        }}
      />
      {!values.current && (
        <Input
          fullWidth
          label={t("endDate.label")}
          placeholder={t("endDate.label")}
          name="endDate"
          type="date"
          disabled={isSubmitting || !!values?.current}
        />
      )}
      <Textarea
        fullWidth
        label={t("job.details.label")}
        placeholder={t("job.details.placeholder")}
        name="comment"
        disabled={isSubmitting}
      />
      <CustomCheckbox
        name="manager"
        fullWidth
        label={t("led.team.me.label")}
        disabled={isSubmitting}
      />
      <div className="flex flex-row justify-center items-center gap-6">
        {typeof onCancel === "function" && (
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
            startContent={<XMarkIcon height={24} width={24} />}
          >
            {t("close.label")}
          </Button>
        )}
        <Button
          type="submit"
          spinner={<CustomSpinner />}
          disabled={isSubmitting || isLoading}
          isLoading={isSubmitting || isLoading}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          color="warning"
          startContent={
            isSubmitting || isLoading ? null : (
              <PlusIcon height={24} width={24} />
            )
          }
        >
          {t("add.label")}
        </Button>
      </div>
    </Form>
  );
}
