import React from "react";

export default function JobsLoading() {
    return (
        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 gap-4">
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
        </div>
    )
}

export function TopViewedJobsLoading() {
    return (
        <div className="w-full flex flex-row overflow-x-scroll gap-4">
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
            <JobsItemLoading/>
        </div>
    )
}

export function SimilarJobsLoading() {
    return (
        <div className="w-full grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1 gap-1">
            <SimilarJobItemLoading/>
            <SimilarJobItemLoading/>
            <SimilarJobItemLoading/>
            <SimilarJobItemLoading/>
            <SimilarJobItemLoading/>
            <SimilarJobItemLoading/>
        </div>
    )
}

export function SimilarJobItemLoading() {
    return (
        <div className="animate-pulse  select-none min-w-[276px] min-h-[184px] p-3 transition-all duration-150">
            <div className="w-full h-full flex flex-col justify-start items-start gap-2">
                <div className="h-[48px] w-[48px] bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-2/3 bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-1/2 bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
            </div>
        </div>
    )
}

export function JobsItemLoading() {
    return (
        <div className="cursor-pointer min-w-[276px] min-h-[306px] border-2 border-solid border-gray-200 rounded-2xl p-3">
            <div className="animate-pulse w-full h-full flex flex-col justify-between gap-2 transition-all duration-150">
                <div className="h-[48px] w-[48px] bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
            </div>
        </div>
    )
}


export function JobsSearchLoading() {
    return (
        <>
            <JobsSearchItemLoading />
            <JobsSearchItemLoading />
        </>
    )
}

export function JobsSearchItemLoading() {
    return (
        <div className="animate-pulse select-none w-full min-h-[200px] h-[200px] flex flex-row justify-start items-center p-2 transition-all duration-150">
                <div className="flex flex-col justify-center items-center w-[120px]">
                    <div className="h-[92px] w-[92px] bg-gray-200 rounded-md" />
                </div>
                <div className="h-full grow flex flex-col justify-center items-start gap-3">
                    <div className="h-[15px] w-1/2 bg-gray-200 rounded-sm" />
                    <div className="h-[15px] w-1/3 bg-gray-200 rounded-sm" />
                    <div className="h-[15px] w-1/6 bg-gray-200 rounded-sm" />
                    <div className="h-[15px] w-full bg-gray-200 rounded-sm" />
                </div>
        </div>
    )
}