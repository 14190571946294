import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { JobAlertInterface, JobAlertItemPropsInterface } from "./JobAlertItem";
import {
  useGetJobAlertQuery,
  useAddJobAlertMutation,
} from "../../../../../_redux/features/jobs/services";
import { TransitionProps } from "@mui/material/transitions";
import { DialogContent, Slide } from "@mui/material";
import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { BorderColor, Close } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Form, Formik } from "formik";
import Input from "../../../../../components/_form/_field/Input";
import SelectRegion from "../../../../../components/_form/_field/Specified/SelectCardinals";
import SelectLanguages from "../../../../../components/_form/_field/Specified/SelectLanguages";
import SelectFunctionType from "../../../../../components/_form/_field/Specified/SelectFunctionType";
import SelectActivitySectorGroup from "../../../../../components/_form/_field/Specified/SelectActivitySectorGroup";
import SelectJobAlertFrequency from "../../../../../components/_form/_field/Specified/SelectJobAlertFrequency";
import SelectExperienceLevel from "../../../../../components/_form/_field/Specified/SelectExperienceLevel";
import { object, string } from "yup";
import Loading from "../../../../../Loading";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useGetProfileQuery } from "../../../../../_redux/auth/services";
import { useGetJobAlertFrequenciesQuery } from "../../../../../_redux/features/jobAlertFrequency/services";
import { JobAlertFrequencyInterface } from "../../../../../_redux/features/jobAlertFrequency/types";
import {
  selectJobsSearch,
  setSearch,
} from "../../../../../_redux/features/jobs/jobsSlice";
import { useAppDispatch } from "../../../../../_redux/store";
import { useSelector } from "react-redux";
import useCurrentDimension, {
  lg,
} from "../../../../../_hooks/useCurrentDimension";
import { toastMessageError } from "../../../../../_helpers/toastErrorMessage";
import { useLocation } from "react-router-dom";

export interface SimpleDialogProps {
  alert: JobAlertInterface;
  open: boolean;
  onClose: (value: string) => void;
}

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide timeout={4000} direction="up" ref={ref} {...props} />;
});

interface Values {
  indexOf(value: any): any;
  keyword: string;
  location?: string;
  cardinals: Array<string> | undefined;
  function: Array<number> | undefined;
  sectorGroup: Array<number> | undefined;
  contractTypes: Array<number> | undefined;
}

function AddJobAlertDialog(props: SimpleDialogProps) {
  const dispatch = useAppDispatch();
  const search = useSelector(selectJobsSearch);
  const { data: user } = useGetProfileQuery();
  const {
    data: jobAlertFrequenciesData,
    isLoading: jobAlertFrequenciesLoading,
  } = useGetJobAlertFrequenciesQuery({ pagination: false });
  const { onClose, open, alert } = props;
  const { data: singleAlert, isLoading } = useGetJobAlertQuery(alert?.id, {
    skip: !alert?.id,
  });
  const [addJobAlertAction, addJobAlertTriggers] = useAddJobAlertMutation();
  const { t, i18n } = useTranslation();
  const {
    isSuccess,
    isLoading: addJobAlertIsLoading,
    reset,
    isFetching,
  } = addJobAlertTriggers;
  const { pathname } = useLocation();
  const applicationPage =
    pathname === `/${i18n.language}/applications/search-alert`;
  const handleClose = () => {
    onClose(true);
  };

  let initialValues = {
    ...(applicationPage
      ? {
          name: "",
          frequency: "",
          keyword: "",
          sector: [],
          function: [],
          region: [],
          experience: [],
          language: "",
        }
      : {
          name: search?.name ?? "",
          frequency: search?.frequency ?? "",
          keyword: search?.keyword ?? "",
          language: search?.language ?? "",
          function: search?.function
            ? search?.function?.map((fn) => `${fn}`)
            : [],
          experience: search?.nbMonthExperience
            ? search?.nbMonthExperience
            : [],
          region: search?.cardinals
            ? search?.cardinals?.map((reg) => `${reg}`)
            : [],
          sector: search?.sectorGroup
            ? search?.sectorGroup?.map((sec) => `${sec}`)
            : [],
        }),
  };
  const handelSubmit = async (values: Values) => {
    addJobAlertAction({
      ...values,
      candidate: `/api/candidates/${user?.id}`,
    })
      .unwrap()
      .then((response: { data: JobAlertInterface }) => {
        dispatch(
          setSearch({
            search: {
              ...(applicationPage
                ? {
                    keyword: "",
                    location: "",
                    name: "",
                    frequency: "",
                    alertId: "",
                    cardinals: [],
                    sectorGroup: [],
                    function: [],
                    careerLevel: [],
                    nbMonthExperience: [],
                    contractTypes: [],
                    workplace: [],
                  }
                : {
                    ...search,
                    ...values,
                    alertId: response?.id,
                    nbMonthExperience: values.experience,
                    sectorGroup: values.sector,
                    cardinals: values.region,
                  }),
            },
          })
        );
        toast.success(
          t("add.message.specific", { value: t("search.alert.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  const schema = object().shape({
    name: string().required(i18n.t("validation.field.required.unspecific")),
    frequency: string().required(
      i18n.t("validation.field.required.unspecific")
    ),
  });

  return (
    <Dialog
      fullWidth
      lang={i18n.language}
      open={open}
      TransitionComponent={Transition}
      keepMounted={addJobAlertIsLoading || isFetching}
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {t("add.specific", { value: t("search.alert.label") })}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handelSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="w-full flex flex-col justify-between items-center gap-4">
                  <div className="w-full">
                    <Input
                      name="name"
                      placeholder={t("name.label", { count: 1 })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectJobAlertFrequency
                      name={"frequency"}
                      placeholder={t("frequency.label")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      name="keyword"
                      placeholder={t("keyword.label")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="w-full">
                    <SelectFunctionType
                      id="id"
                      name={"function"}
                      multiple
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectExperienceLevel
                      id="id"
                      name="experience"
                      multiple
                      placeholder={t("professionalExperience.label", {
                        count: 1,
                      })}
                      //   label={t("professionalExperience.label", { count: 1 })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectRegion name={"region"} countryId={3} multiple />
                  </div>
                  <div className="w-full">
                    <SelectActivitySectorGroup
                      name={"sector"}
                      id="id"
                      multiple
                      label={t("activity.sector.group.placeholder")}
                      placeholder={t("activity.sector.group.placeholder")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="w-full flex-row-reverse flex gap-1 ">
                    <Button
                      type="submit"
                      startContent={
                        addJobAlertIsLoading || isFetching || isSubmitting ? (
                          <CircularProgress
                            color="warning"
                            size={17}
                            className={"mx-1"}
                          />
                        ) : (
                          <BorderColor />
                        )
                      }
                      variant="flat"
                      color="warning"
                      disabled={
                        addJobAlertIsLoading || isFetching || isSubmitting
                      }
                    >
                      {t("add.label")}
                    </Button>
                    <Button
                      variant="flat"
                      color="primary"
                      onClick={handleClose}
                      disabled={
                        addJobAlertIsLoading || isFetching || isSubmitting
                      }
                      startContent={<Close />}
                    >
                      {t("close.label")}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default function AddJobAlertButton({
  alert,
}: JobAlertItemPropsInterface) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [screenSize] = useCurrentDimension();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="w-full flex flex-row justify-between items-center">
      <div />
      <Button
        isIconOnly={screenSize.width < lg}
        onClick={handleClickOpen}
        radius="full"
        variant="bordered"
        color="primary"
      >
        <NotificationsNoneOutlinedIcon />
        <p className="max-lg:hidden font-xing-bold text-sm text-black first-letter:capitalize">
          {t("add.specific", { value: t("search.alert.label") })}
        </p>
      </Button>
      <AddJobAlertDialog open={open} onClose={handleClose} alert={alert} />
    </div>
  );
}
