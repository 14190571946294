import React, { useState } from "react";
import ArticleComponent from "../../../components/articleComponent/ArticleComponent";
import Icon from "../../../components/icon/Icon";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";

export default function Home() {
  const { t, i18n } = useTranslation();
  const [posts] = useState([
    {
      user: {
        id: 1,
        name: "Smile",
        description: "Entreprise de recrutement",
        thumbnail: "/images/smile-logo.png",
      },
      article: {
        id: 1,
        title: "CV moderne : veuillez omettre ces 10 détails",
        body: "Les normes antérieures du CV sont désormais considérées comme obsolètes, voire illégales. Vous devez donc absolument éviter ces informations.",
        picture: "/images/prototype-image-article-1.png",
        source: "www.emploipartner.com",
        path: "./home",
      },
      comments: [
        {
          id: 1,
          user: "@Vous",
          content: "Je suis interessé",
          likes: 15,
          liked: true,
        },
        {
          id: 2,
          user: "@Diane Chiang",
          content: "Interessé par votre offre",
          likes: 15,
          liked: false,
        },
      ],
    },
    {
      user: {
        id: 2,
        name: "Smile",
        description: "Entreprise de recrutement",
        thumbnail: "/images/smile-logo.png",
      },
      article: {
        id: 1,
        title: "CV moderne : veuillez omettre ces 10 détails",
        body: "Les normes antérieures du CV sont désormais considérées comme obsolètes, voire illégales. Vous devez donc absolument éviter ces informations.",
        picture: "/images/prototype-image-article-1.png",
        source: "www.emploipartner.com",
        path: "./home",
      },
      comments: [
        {
          id: 1,
          user: "@Vous",
          content: "Je suis interessé",
          likes: 15,
          liked: true,
        },
        {
          id: 2,
          user: "@Diane Chiang",
          content: "Interessé par votre offre",
          likes: 15,
          liked: false,
        },
      ],
    },
    {
      user: {
        id: 3,
        name: "Smile",
        description: "Entreprise de recrutement",
        thumbnail: "/images/smile-logo.png",
      },
      article: {
        id: 1,
        title: "CV moderne : veuillez omettre ces 10 détails",
        body: "Les normes antérieures du CV sont désormais considérées comme obsolètes, voire illégales. Vous devez donc absolument éviter ces informations.",
        picture: "/images/prototype-image-article-1.png",
        source: "www.emploipartner.com",
        path: "./home",
      },
      comments: [
        {
          id: 1,
          user: "@Vous",
          content: "Je suis interessé",
          likes: 15,
          liked: true,
        },
        {
          id: 2,
          user: "@Diane Chiang",
          content: "Interessé par votre offre",
          likes: 15,
          liked: false,
        },
      ],
    },
  ]);

  if (AppRoutes.PRIVATE_JOBS[0]) {
    return (
      typeof window !== "undefined" && (
        <Navigate
          to={AppRoutes.PRIVATE_JOBS[0].replace(":locale", `${i18n.language}`)}
        />
      )
    );
  }

  return (
    <Container maxWidth="md">
      <div className="flex flex-col justify-start items-center gap-10">
        <div className="w-full mx-auto rounded-3xl px-6 py-3 resize-none bg-gray-50">
          <div className="flex-row justify-between relative">
            <div className="flex flex-col justify-between">
              <textarea
                className="rounded-3xl p-6 resize-none min-w-full focus:outline-none placeholder-gray-300 bg-gray-50"
                placeholder={t("home.whatsUp")}
              ></textarea>
              <div className="flex flex-row justify-start gap-3">
                <Icon
                  width={16}
                  height={16}
                  src="/svg/prototype-icon-image.svg"
                  alt="add image"
                />
                <Icon
                  width={16}
                  height={16}
                  src="/svg/prototype-icon-file.svg"
                  alt="add image"
                />
                <Icon
                  width={16}
                  height={16}
                  src="/svg/prototype-icon-smiley.svg"
                  alt="add image"
                />
              </div>
            </div>
            <Icon
              className="absolute end-1 top-1/2 translate-y-[-50%] h-auto w-auto"
              width={30}
              height={30}
              src="/svg/prototype-icon-speaker.svg"
              alt="speaker"
            />
          </div>
        </div>

        {posts?.map((post: any, i: number) => (
          <ArticleComponent key={i} post={post} />
        ))}
      </div>
    </Container>
  );
}
