import React, {useEffect, useState} from "react";
import {Button, Image} from "@nextui-org/react";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import Loading from "../../../Loading";
import {useGetPolicyQuery, useGetProfileQuery, useUpdatePolicyMutation} from "../../../_redux/auth/services";
import Drawer from "@mui/material/Drawer";
import {Checkbox, Container, FormControlLabel} from "@mui/material";
import LanguageDropdown from "../../navbar/languageDropdown/LanguageDropdown";

export default function AcceptPolicyModal() {

    const {t, i18n} = useTranslation()
    const {data: profile, isLoading, isFetching} = useGetProfileQuery()
    const {data: policy, isLoading: isPolicyLoading, isFetching: isPolicyFetching} = useGetPolicyQuery({locale: i18n.language || "fr"})
    const [policyApplyAction, {
        isLoading: isApplying,
        isError,
        error: applyError,
    }] = useUpdatePolicyMutation()
    const [accepted, setAccepted] = useState(false);

    function acceptPolicyApply() {
        if (accepted) {
            policyApplyAction(profile?.id)
        }
    }
    useEffect(() => {

        if (isError) {
            // @ts-ignore
            applyError?.data?.violations?.forEach((violation: { propertyPath: any; message: any; }) => {
                toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            });

            // @ts-ignore
            if ("hydra:description" in applyError?.data) {
                // @ts-ignore
                toast.error(applyError?.data["hydra:description"], {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        }

    }, [isError, applyError]);
    useEffect(() => {
        if (profile && profile?.id && !isLoading && !isFetching && !isPolicyLoading && !isPolicyFetching && !profile["accept_policy"]) {
            policyApplyAction(profile?.id)
        }
    }, [profile]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAccepted(event.target.checked);
    };

    const cssAppliedContent = (PolicyContent: any) => `
        <div>
            <style>
                .use-terms {
                    direction: ${i18n.dir() || "ltr"};
                    padding: 0;
                    margin: 0;
                    font-family: "XING Sans", Poppins, serif;
                    padding-inline: 10px;
                }
        
                h2, h3 {
                    display: inline-block;
                }

        
                .orange-text {
                    color: orange;
                }
                
                .condition {
                    font-size: 24px;
                }
        
                .conditions {
                    padding: 0;
                }
        
                .conditions > li {
                    text-align: justify;
                    display: inline-block
                }
        
                ol {
                    counter-reset: item;
                }
        
                ol > li {
                    display: block;
                    counter-increment: item;
                }
        
                .conditions > li:before {
                    font-size: 1.8em;
                }
        
                ol > li:before {
                    content: counters(item, ".") " ";
                    font-weight: bold;
                    font-size: 1.2em;
                }
        
                .condition > h2, h1, .sub-condition > h2, h3 {
                    margin-top: 20px;
                    font-weight: bold;
                }
        
                .condition > p {
                    text-justify: inter-word;
                    text-align: justify;
                }
        
                .condition > p, p, .condition > ul > li {
                    font-size: 18px;
                }
        
                .condition > ol > li {
                    margin-bottom: 5px;
                }
        
                .sub-condition {
                    font-size: 18px;
                }
                                
                .sub-condition ul li{
                    font-size: 18px;
                    list-style-type: circle;
                    margin-left: 25px;
                }
        
                .cls-1 {
                    fill: orange;
                }
        
                .cls-2 {
                    fill: #bababa;
                }
        
                .cls-3 {
                    fill: none;
                }
                    
            </style>
            ${PolicyContent}
        </div>
    `;

    if (!profile) {
        return <Loading/>
    }

    return (
        <Drawer
            anchor="bottom"
            open={false}
        >
            <Container maxWidth="lg">
                {isLoading || isFetching || isPolicyLoading || isPolicyFetching ?
                    <Loading fullscreen/>
                    :
                    <>
                        <div className="overflow-y-scroll scrollbar-hide">
                            <div className="p-3 flex flex-col justify-start items-center gap-6">
                                <Image
                                    className="w-full h-[192px]"
                                    src="/svg/icon-ep.svg"
                                />
                                <LanguageDropdown/>
                                <div dangerouslySetInnerHTML={{__html: cssAppliedContent(policy) || ""}}/>
                                <div>
                                    <FormControlLabel
                                        disabled={isLoading || isFetching || isPolicyLoading || isPolicyFetching}
                                        label={t("term.of.use.question")}
                                        checked={accepted}
                                        required
                                        onChange={handleChange}
                                        control={<Checkbox size="small" color="primary"/>}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                }

                <div className="shrink-0 h-[100px] flex flex-row justify-center items-center">
                    <Button
                        radius="md"
                        onClick={acceptPolicyApply}
                        disabled={!accepted || isApplying || isLoading}
                        isLoading={isApplying || isLoading}
                        color="warning"
                        variant="shadow"
                        className="p-6 bg-[#FFC965] text-md text-black first-letter:capitalize font-semibold"
                    >
                        {t("i.agree")}
                    </Button>
                </div>
            </Container>
        </Drawer>
    )
}
