import React, { useEffect, useState } from "react";
import { SearchIcon } from "@nextui-org/shared-icons";
import { Input as NextInput } from "@nextui-org/input";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { Button } from "@nextui-org/react";
import { Chip, ChipDelete } from "@mui/joy";
import { style } from "../filters/constants";
import CustomSpinner from "../../../../components/spinner/CustomSpinner";
import UTurnLeftIcon from "@mui/icons-material/UTurnLeft";
import { Field } from "formik";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useGetCareerLevelsQuery } from "../../../../_redux/features/careerLevel/services";
import { useGetFunctionTypesQuery } from "../../../../_redux/features/functionType/services";
import { useGetSectorGroupsQuery } from "../../../../_redux/features/activitySectorGroup/services";
import { useGetParamsExperiencesQuery } from "../../../../_redux/features/experiences/services";
import { useGetContractTypesQuery } from "../../../../_redux/features/contractType/services";
import { useGetWorkplacesQuery } from "../../../../_redux/features/workplace/services";
import { selectJobsSearch } from "../../../../_redux/features/jobs/jobsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../../utils/appRoutes";
import { selectToken } from "../../../../_redux/auth/authSlice";
import { useGetTopRecruitersQuery } from "../../../../_redux/features/jobs/services";

const JobsFormInputs = () => {
  const {
    values,
    submitForm,
    setFieldValue,
    isSubmitting,
    setValues,
    handleChange,
  } = useFormikContext();
  const { data: careerLevelsData } = useGetCareerLevelsQuery({
    pagination: false,
  });
  const { list: careerLevels } = careerLevelsData || {};

  const { data: functionTypesData } = useGetFunctionTypesQuery({
    pagination: false,
  });
  const { list: functionTypes } = functionTypesData || {};

  const { data: sectorGroupsData } = useGetSectorGroupsQuery({
    pagination: false,
  });
  const { list: sectorGroups } = sectorGroupsData || {};

  const { data: experiencesData } = useGetParamsExperiencesQuery({
    pagination: false,
  });
  const { list: experiences } = experiencesData || {};

  const { data: contactsData } = useGetContractTypesQuery({
    pagination: false,
  });
  const { list: contracts } = contactsData || {};

  const { data: workplacesData } = useGetWorkplacesQuery({ pagination: false });
  const { list: workplaces } = workplacesData || {};
  const search = useSelector(selectJobsSearch);
  const { data, isLoading } = useGetTopRecruitersQuery({ _page: 1 });
  const { list: topCompanies } = data || {};
  const [changed, setChanged] = useState(false);
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  const { t, i18n } = useTranslation();
  const { companyId } = useParams();
  const navigate = useNavigate();
  const isFilter =
    search?.keyword ||
    search?.acceptDisabledPeople ||
    search?.isPartialTime ||
    search?.withHigherCareerLevel ||
    search?.withRemote ||
    search?.withHigherSalary ||
    search?.withPreferredActivitySector ||
    search?.withSocialAdvantage ||
    search?.withPerformanceBonus ||
    search?.withTraining ||
    search?.withCompanyVehicle ||
    search?.areFreelance ||
    search?.onMyCity ||
    search?.location ||
    search?.cardinals?.length > 0 ||
    search?.sectorGroup?.length > 0 ||
    search?.function?.length > 0 ||
    search?.careerLevel?.length > 0 ||
    search?.nbMonthExperience?.length > 0 ||
    search?.contractTypes?.length > 0 ||
    search?.workplace?.length > 0;

  useEffect(() => {
    if (companyId) {
      navigate(
        isAuth
          ? AppRoutes.PRIVATE_JOBS_SEARCH[0].replace(
              ":locale",
              `${i18n.language}`
            )
          : AppRoutes.JOBS[0].replace(":locale", `${i18n.language}`),
        { replace: true }
      );
    }
  }, [companyId, i18n.language, isAuth, navigate]);
  return (
    <>
      <div className="w-full flex flex-row max-md:flex-col justify-center items-center gap-6">
        <div className="w-full grow ">
          <Field
            radius="full"
            startContent={<SearchIcon height={24} width={24} />}
            classNames={style}
            variant="bordered"
            fullWidth={true}
            as={NextInput}
            name="keyword"
            placeholder={t("keywords.label", { count: 1 })}
          />
        </div>
        <div className="w-full grow">
          <Field
            radius="full"
            startContent={<PlaceOutlinedIcon height={24} width={24} />}
            classNames={style}
            variant="bordered"
            fullWidth={true}
            as={NextInput}
            name="location"
            placeholder={t("locations.label")}
          />
        </div>
        <div className="w-full max-md:max-w-96 md:max-w-52 flex-1">
          <Button
            type="submit"
            fullWidth={true}
            className={`h-[42px] font-xing-bold text-lg ${
              changed ? "animate-bounce" : ""
            }`}
            radius="full"
            variant="solid"
            color="warning"
          >
            {isSubmitting ? <CustomSpinner /> : t("search.label")}
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-start gap-3">
        {search?.keyword && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("keywords.label")} :
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("keyword", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
                {search?.keyword}
              </Chip>
            </p>
          </div>
        )}
        {search?.company && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("footer.company.label")} :
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("company", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
                {
                  topCompanies?.find(
                    (c) => c.company?.id === parseInt(search?.company)
                  )?.companyName
                }
              </Chip>
            </p>
          </div>
        )}
        {search?.location && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("locations.label")} :
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("location", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
                {search?.location}
              </Chip>
            </p>
          </div>
        )}

        {search?.acceptDisabledPeople && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("acceptDisabledPeople", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("inclusion.jobs.for.all.without.exception")}
              </Chip>
            </p>
          </div>
        )}

        {search?.isPartialTime && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("isPartialTime", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("flexible.hours.work.at.your.own.pace")}
              </Chip>
            </p>
          </div>
        )}


        {search?.withHigherCareerLevel && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withHigherCareerLevel", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("growth.develop")}
              </Chip>
            </p>
          </div>
        )}



        {search?.withRemote && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withRemote", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("remote.work.work.where.you.want")}
              </Chip>
            </p>
          </div>
        )}


        {search?.withHigherSalary && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withHigherSalary", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("competitive.salary.value.your.talent.with.a.competitive.salary")}
              </Chip>
            </p>
          </div>
        )}

        {search?.withPreferredActivitySector && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withPreferredActivitySector", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("industry.work")}
              </Chip>
            </p>
          </div>
        )}

        {search?.withSocialAdvantage && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withSocialAdvantage", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("social.benefits.a.job.that.takes.care.of.you")}
              </Chip>
            </p>
          </div>
        )}

        {search?.withPerformanceBonus && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withPerformanceBonus", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("performance.bonuses.perform.well.earn.more")}
              </Chip>
            </p>
          </div>
        )}

        {search?.withTraining && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withTraining", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("training.grow.while.working")}
              </Chip>
            </p>
          </div>
        )}


        {search?.withCompanyVehicle && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("withCompanyVehicle", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("car.drive.a.company.car")}
              </Chip>
            </p>
          </div>
        )}


        {search?.areFreelance && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("areFreelance", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("freelance.work.freelance")}
              </Chip>
            </p>
          </div>
        )}


        {search?.onMyCity && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              <Chip
                endDecorator={
                  <ChipDelete
                    onDelete={() => {
                      setFieldValue("onMyCity", "");
                      submitForm();
                    }}
                  />
                }
                variant="outlined"
                className="ms-1"
              >
              {t("local.jobs.work.close.to.home")}
              </Chip>
            </p>
          </div>
        )}

        {search?.cardinals?.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("regions.label")} :
              {search?.cardinals?.map((cardinal, index) => (
                <Chip
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        setFieldValue(
                          "cardinals",
                          [...values.cardinals].filter((e) => e !== cardinal)
                        );
                        submitForm();
                      }}
                    />
                  }
                  key={index}
                  variant="outlined"
                  className="ms-1"
                >
                  {cardinal}
                </Chip>
              ))}
            </p>
          </div>
        )}
        {search?.sectorGroup?.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("activity.sector.label")} :
              {search?.sectorGroup?.map((sectorId, index) => (
                <Chip
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        setFieldValue(
                          "sectorGroup",
                          [...values.sectorGroup].filter((e) => e !== sectorId)
                        );
                        submitForm();
                      }}
                    />
                  }
                  key={index}
                  variant="outlined"
                  className="ms-1"
                >
                  {
                    sectorGroups?.find((sg) => sg.id === parseInt(sectorId))
                      ?.name
                  }
                </Chip>
              ))}
            </p>
          </div>
        )}

        {search?.function?.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("function.label")} :
              {search?.function?.map((func, index) => (
                <Chip
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        setFieldValue(
                          "function",
                          [...values.function].filter((e) => e !== func)
                        );
                        submitForm();
                      }}
                    />
                  }
                  key={index}
                  variant="outlined"
                  className="ms-1"
                >
                  {functionTypes?.find((fn) => fn.id === parseInt(func))?.name}
                </Chip>
              ))}
            </p>
          </div>
        )}

        {search?.careerLevel?.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("profile.careerLevel.label")} :
              {search?.careerLevel?.map((level, index) => (
                <Chip
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        setFieldValue(
                          "careerLevel",
                          [...values.careerLevel].filter((e) => e !== level)
                        );
                        submitForm();
                      }}
                    />
                  }
                  key={index}
                  variant="outlined"
                  className="ms-1"
                >
                  {careerLevels?.find((l) => l.id === parseInt(level))?.name}
                </Chip>
              ))}
            </p>
          </div>
        )}

        {search?.nbMonthExperience?.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("experience.title.label")} :
              {search?.nbMonthExperience?.map((experience, index) => (
                <Chip
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        setFieldValue(
                          "nbMonthExperience",
                          [...values.nbMonthExperience].filter(
                            (e) => e !== experience
                          )
                        );
                        submitForm();
                      }}
                    />
                  }
                  key={index}
                  variant="outlined"
                  className="ms-1"
                >
                  {
                    experiences?.find((e) => e.id === parseInt(experience))
                      ?.label
                  }
                </Chip>
              ))}
            </p>
          </div>
        )}

        {search?.contractTypes?.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("contract.type.label")} :
              {search?.contractTypes?.map((contractType, index) => (
                <Chip
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        setFieldValue(
                          "contractTypes",
                          [...values.contractTypes].filter(
                            (e) => e !== contractType
                          )
                        );
                        submitForm();
                      }}
                    />
                  }
                  key={index}
                  variant="outlined"
                  className="ms-1"
                >
                  {
                    contracts?.find((c) => c.id === parseInt(contractType))
                      ?.name
                  }
                </Chip>
              ))}
            </p>
          </div>
        )}

        {search?.workplace?.length > 0 && (
          <div className="flex flex-row justify-between items-center">
            <p className="font-xing p-1">
              {t("workplace.label")} :
              {search?.workplace?.map((workplace, index) => (
                <Chip
                  endDecorator={
                    <ChipDelete
                      onDelete={() => {
                        setFieldValue(
                          "workplace",
                          [...values.workplace].filter((e) => e !== workplace)
                        );
                        submitForm();
                      }}
                    />
                  }
                  key={index}
                  variant="outlined"
                  className="ms-1"
                >
                  {workplaces?.find((w) => w.id === parseInt(workplace))?.name}
                </Chip>
              ))}
            </p>
          </div>
        )}

        {isFilter && (
          <div className="flex flex-row justify-between items-center">
            <Chip
              startDecorator={<UTurnLeftIcon className="rotate-90" />}
              className="ms-1 px-6 py-2"
              onClick={() => {
                setValues({
                  keyword: "",
                  location: "",
                  name: "",
                  frequency: "",
                  alertId: "",
                  cardinals: [],
                  sectorGroup: [],
                  function: [],
                  careerLevel: [],
                  nbMonthExperience: [],
                  contractTypes: [],
                  workplace: [],
                  acceptDisabledPeople: undefined,
                  isPartialTime: undefined,
                  withHigherCareerLevel: undefined,
                  withRemote: undefined,
                  withHigherSalary: undefined,
                  withPreferredActivitySector: undefined,
                  withSocialAdvantage: undefined,
                  withPerformanceBonus: undefined,
                  withTraining: undefined,
                  withCompanyVehicle: undefined,
                  areFreelance: undefined,
                  onMyCity: undefined
                });
                submitForm();
              }}
            >
              <p className="first-letter:capitalize font-xing-bold">
                {t("delete.filtre")}
              </p>
            </Chip>
          </div>
        )}
      </div>
    </>
  );
};

export default JobsFormInputs;
