import React from "react";
import {
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
} from "@mui/material";
import TitledContainer from "../../../components/containers/TitledContainer";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
  useUpdateCvMutation,
} from "../../../_redux/auth/services";
import { useTranslation } from "react-i18next";
import Loading from "../../../Loading";
import SelectionCriteriaEditForm from "../../../components/_form/Profile/SelectionCriteriaEditForm";
import { cvTitleSchema } from "../../../components/modals/Profile/SelectionCriteriaEditModal";
import { careerLevelInterface } from "../../../_redux/features/careerLevel/types";
import { Formik } from "formik";
import BlackRadio from "../../../components/blackEdition/BlackRadio";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";
import SEO from "../../../SEO";
import { professionalExpectationsTitle } from "../../../utils/seoProps";

interface Values {
  title: string;
  mobility: string;
  targetJob: string;
  disponibility: boolean;
  disponibilityDuration: number;
  careerLevel: string | careerLevelInterface;
  country: string | null;
  region: string | null;
  city: string | null;
}

export default function ProExpectations() {
  const { t } = useTranslation();
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const { data: cv, isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });
  const [updateCvAction, trigger] = useUpdateCvMutation();
  let initialValues = {
    title: cv?.title || null,
    mobility: cv?.mobility || null,
    targetJob: cv?.targetJob || null,
    disponibility: cv?.disponibility || null,
    disponibilityDuration: cv?.disponibilityDuration || 0,
    careerLevel: cv?.careerLevel?.["@id"] || null,
    salary: cv?.salary?.["@id"] || null,
    country: cv?.country?.["@id"] || null,
    region: cv?.region?.["@id"] || null,
    city: cv?.city?.["@id"] || null,
    function: cv?.function?.["@id"] || null,
  };
  const expectations = Object.values(initialValues).slice(3, 35);
  const options = [
    { title: "Je suis en recherche active d'emploi.", value: 1 },
    {
      title:
        "Je ne suis pas en recherche d'emploi mais ouvert(e) à toute proposition.",
      value: 2,
    },
    { title: "Je ne souhaite pas recevoir d'offres.", value: 3 },
  ];

  const handelSubmit = async (values: Values) => {
    updateCvAction({ id: profile?.id, ...values })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", {
            value: t("position.current.label"),
          })
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  if (isCvLoading || isProfileLoading) {
    return <Loading />;
  }
  const shema = cvTitleSchema();

  return (
    <Container maxWidth="md">
      <SEO {...professionalExpectationsTitle} />
      <TitledContainer
        title={t("sidebar.professional.expectations")}
        subtitle={t("find.jobs.pro.expectations.label")}
        className="flex flex-col"
      >
        <div className="w-full flex lg:flex-row max-lg:flex-col-reverse justify-between items-start gap-6">
          <div className="lg:w-4/6 max-lg:w-full flex flex-col justify-between items-start gap-6">
            <div className="w-full flex flex-col justify-between items-start gap-3">
              <div className="text-2xl font-xing-bold first-letter:capitalize">
                {t("expectations.title")}
              </div>

              <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2 justify-between items-center gap-3">
                <div className="w-full">
                  {(!isCvLoading || !isProfileLoading) && (
                    <span className="font-xing-bold rounded-2xl text-xs select-none ">
                      {initialValues &&
                        expectations.filter((i) => i !== null)?.length}
                      /{initialValues && expectations?.length} &ensp;{" "}
                      {t("find.jobs.added.job.expectations.label")}
                    </span>
                  )}
                </div>

                <div className="w-full">
                  <div className="relative w-full rounded-full bg-gray-200 font-xing-bold text-xs py-1 px-2 select-none ">
                    <div
                      style={{
                        width:
                          (expectations.filter((i) => i !== null)?.length /
                            expectations?.length) *
                            100 +
                          "%",
                      }}
                      className={`left-0 top-0 absolute z-10 bg-success h-full rounded-full`}
                    />
                  </div>
                </div>
              </div>
            </div>

            <Formik
              initialValues={initialValues as never}
              validateOnChange={true}
              onSubmit={handelSubmit}
              validationSchema={shema}
            >
              <SelectionCriteriaEditForm trigger={trigger} />
            </Formik>
          </div>
        </div>
      </TitledContainer>
    </Container>
  );
}
