import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import { Field, useField } from "formik";
import React from "react";
import { OverridableStringUnion } from "@mui/types";
import { CheckboxPropsColorOverrides } from "@mui/material/Checkbox/Checkbox";

interface CustomCheckboxInterface {
  color?: OverridableStringUnion<
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "default",
    CheckboxPropsColorOverrides
  >;
  defaultChecked: boolean;
  disabled: boolean;
}

export default function CustomCheckbox({
  color = "warning",
  defaultChecked,
  disabled,
  ...props
}: CustomCheckboxInterface): JSX.Element {
  const [field, meta] = useField(props);

  return (
    <div className="w-full">
      <Field
        {...props}
        {...field}
        error={Boolean(meta.error)}
        as={FormControlLabel}
        control={
          <Checkbox
            value={props.value}
            defaultChecked={defaultChecked}
            color={color}
            disabled={disabled}
            onChange={props.onChange}
          />
        }
      />
      {meta.error && (
        <FormHelperText error={Boolean(meta.error)}>
          {meta.error}
        </FormHelperText>
      )}
    </div>
  );
}
