import React, { ReactNode } from "react";
import { Button } from "@nextui-org/react";
import Loading from "../../../Loading";
import { useTranslation } from "react-i18next";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../_redux/auth/services";
import ProfileInformationEditForm from "../../_form/Profile/ProfileInformationEditForm";
import moment from "moment";
import { ProfileInterface } from "../../../pages/private/profile/MyProfile";
import { profileInformationSchema } from "../../_form/_schema/profileInformationSchema";
import { Formik } from "formik";

import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

interface ProfileInformationEditModalInterface {
  text?: string;
  icon?: ReactNode;
}

export default function ProfileInformationEditModal({
  text,
  icon,
  ...props
}: ProfileInformationEditModalInterface) {
  const { data: profile, isLoading: isUserLoading } = useGetProfileQuery();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateProfileAction, triggers] = useUpdateProfileMutation();

  let initialValues = {
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    phone: profile?.phone,
    birthday: moment(profile?.birthday).format("yyyy-MM-DD"),
    address: profile?.address,
    civility: profile?.civility,
    mobile: profile?.mobile,
    fax: profile?.fax,
    zipcode: profile?.zipcode,
    birthplace: profile?.birthplace,
    email: profile?.email,
    martialStatus: profile?.martialStatus,
    subAnem: profile?.subAnem,
  };

  const handelSubmit = async (values: ProfileInterface) => {
    updateProfileAction({ id: profile?.id, ...values })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", {
            value: t("personalInformation.label"),
          }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        handleClose();
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  if (isUserLoading) {
    return <Loading />;
  }

  const shema = profileInformationSchema();

  return (
    <>
      <Button
        {...props}
        color="primary"
        variant="light"
        radius="full"
        aria-label={t("update.label")}
        onClick={handleOpen}
        title={t("update.label")}
        className={`w-fit mx-2 max-w-4/5 font-xing-bold p-2 flex flex-row items-center gap-2`}
      >
        {icon && icon}

        {text &&
            <p  className="!max-w-[80%] font-xing-bold text-wrap text-sm">
              {text}
            </p>
        }
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("add.specific", { value: t("professionalExperience.label") })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("update.specific", {
                    value: t("personalInformation.label"),
                  })}
                </h1>
                <Formik
                  initialValues={initialValues as never}
                  onSubmit={handelSubmit}
                  validationSchema={shema}
                >
                  <ProfileInformationEditForm
                    triggers={triggers}
                    onCancel={handleClose}
                  />
                </Formik>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
