import React from "react";
import TitledContainer from "../../../components/containers/TitledContainer";
import {useTranslation} from "react-i18next";
import Performances from "./ui/Performances";
import Previous from "./ui/Previous";
import PerformanceAnalysis from "./ui/PerformanceAnalysis";
import {Container} from "@mui/material";

export default function SkillsPerformance() {

    const {t} = useTranslation()

    return (
        <Container maxWidth="xs">
            <TitledContainer
                title={t("profile.performance.analysis.label")}
                className="flex flex-col justify-start items-start gap-6"
            >
                <Previous />

                <PerformanceAnalysis />

                <Performances />

            </TitledContainer>
        </Container>
    )

}