import React from "react";
import {useSelector} from "react-redux";
import {Button, Image} from "@nextui-org/react";
import {useTranslation} from "react-i18next";
import {mediaRoutes} from "../../../utils/mediaRoutes";
import Countdown from "../../countdown/Countdown";
import {selectEventsSearch} from "../../../_redux/features/events/eventsSlice";
import {useGetEventsQuery} from "../../../_redux/features/events/services";
import {EventInterface} from "../../../_redux/features/events/interfaces";
import {DateTime} from "luxon";
import {NextEventItemLoading} from "../../../pages/private/events/loading";
import {useNavigate} from "react-router-dom";
import moment from "moment/moment";

export default function NextEventItem() {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const eventSearch = useSelector(selectEventsSearch)
    const {data, isLoading, isFetching} = useGetEventsQuery({...eventSearch, _page: 1})
    const {list: events} = data || {}

    const nextEvent = findClosestDateObject()


    function findClosestDateObject() {
        // Get the current date using Luxon
        const currentDate = DateTime.now();

        // Initialize variables to store the minimum difference and the corresponding event
        let minDifference = Infinity;
        let closestDateObject: EventInterface | null = null;

        // Iterate through the array of objects
        if(events) {
            for (const event of events) {
                // Assuming the date is stored in a property named 'date'
                const eventDate = DateTime.fromISO(event.endDate);

                // Calculate the difference between the current date and the event's date
                const difference = Math.abs(currentDate.diff(eventDate).as('milliseconds'));

                // Update the closest date if the current event's date is closer
                if (difference < minDifference) {
                    minDifference = difference;
                    closestDateObject = event;
                }
            }
        }

        return closestDateObject;
    }

    if(isLoading || isFetching) {
        return <NextEventItemLoading />
    }

    function redirectTo() {
        return navigate(`/events/${nextEvent?.id}`)
    }

    return (
        <div
            onClick={redirectTo}
            key={nextEvent?.name}
            className={`relative w-full h-[320px] min-w-full min-h-[320px] bg-white overflow-hidden rounded-3xl cursor-pointer`}
        >
            <img
                src={`${mediaRoutes.EVENT_TEMPLATE}${nextEvent?.template?.heroAreaImage}`}
                alt={nextEvent?.name}
                onError={(e) => {
                    e.target.src = "/images/prototype-event-background.jpg"
                }}
                className="absolute w-full h-full bg-white overflow-hidden hover:none"
            />
            <div
                className="absolute h-full w-full backdrop-blur-[30px] backdrop-saturate-[180%] bg-transparent bg-opacity-60"></div>
            <div className="relative h-full w-full p-3 overflow-hidden flex flex-col justify-between">
                <div className="flex flex-row justify-between items-center font-xing-bold">
                    <div className="bg-[#FFB836] rounded-xl px-3 py-2">{t("events.label", {count: 1})}</div>
                    <div className="bg-white rounded-full h-[40px] w-[40px] flex flex-col justify-center items-center">
                        <Image
                            radius={"none"}
                            src="/svg/prototype-icon-events.svg"
                            height={20}
                            width={20}
                            alt="calendar"
                        />
                    </div>
                </div>
                <div className="relative w-full flex flex-col justify-evenly items-center first-letter:capitalize text-lg mix-blend-color-burn">
                    <div className="font-xing-bold">{nextEvent?.name}</div>
                    <div>Créer par {nextEvent?.createdBy["@type"]!} </div>
                </div>

                <Countdown backgroundColor="#ffb836" alignContainers="row" startDate={nextEvent?.startDate as string} endDate={nextEvent?.endDate as string} />

                <div className="relative h-[145px] w-full bg-white p-4 rounded-3xl overflow-hidden flex flex-col justify-between items-center">

                    <div className="w-full flex flex-row justify-between items-center text-sm">

                        <div className="w-full flex flex-col justify-evenly items-start">
                            <div className="font-xing-bold"> {moment(nextEvent?.startDate).format('ll')}</div>
                            <div className="text-gray-200 first-letter:capitalize">{nextEvent?.location}</div>
                        </div>

                        <div className="shrink-0 py-3 flex relative ">
                            <Image
                                src="/svg/prototype-icon-pin.svg"
                                className="absolute min-h-[18px] min-w-[18px] start-[30px] top-[50%] translate-x-[-50%] translate-y-[-50%]"
                                alt="Adress map pin"
                            />
                            <Image
                                src="/images/prototype-image-map.png"
                                height={60}
                                width={60}
                                className="relative z-[1] min-h-[60px] min-w-[60px] max-h-[60px] max-w-[60px] rounded-full object-cover"
                                alt="Adress map pin"
                            />
                        </div>

                    </div>

                    <div className="flex flex-col justify-between items-center">
                        <p className="text-justify">
                            {nextEvent?.template?.description}
                        </p>
                        {
                            (nextEvent?.isParticipant) ?
                                <Button className="bg-[#61F1A4] text-black font-xing-bold rounded-full"
                                        variant="flat">{t('event.participate')}</Button>
                                :
                                <p className="font-light text-gray-400 text-sm">{t('event.participate.already')}</p>
                        }
                    </div>

                </div>

            </div>

        </div>
    )
}