import React from "react";
import {useDispatch} from "react-redux";
import {setSingleLanguage} from "../../../../../_redux/auth/authSlice";
import LanguageDeleteModal from "../../../../../components/modals/Profile/Langue/LanguageDeleteModal";
import {CvXLanguageInterface} from "../../../../../_redux/features/languages/cvXLanguageInterface";
import {useTranslation} from "react-i18next";

export default function LanguageItem({language}: { language: CvXLanguageInterface }) {

    const dispatch = useDispatch()
    const {t} = useTranslation()

    function widthGetter(level: string) {

        switch (level) {
            case "school":
                return "w-1/4"
            case "professional":
                return "w-1/2"
            case "current":
                return "w-3/4"
            case "bilingual":
                return "w-full"
            default:
                return "w-0"
        }

    }

    return (
            <div className="transition ease-in-out delay-150 cursor-pointer group flex flex-col gap-3" onClick={() => dispatch(setSingleLanguage({language}))}>
                <div className="flex flex-row justify-start items-center font-xing-bold first-letter:capitalize">
                    <h1 className="transition-width duration-100 p-1 first-letter:uppercase">
                        { language?.languageName }
                    </h1>
                    <div className="hidden group-hover:block p-0">
                        <LanguageDeleteModal language={language} />
                    </div>
                </div>
                <div className="w-full h-[32px] relative bg-[#dbe2e6] rounded-full">
                    <div className={`first-letter:capitalize transition-width duration-100 h-full ${widthGetter(language?.level)} bg-[#616d7d] rounded-full text-white py-1 px-2`}>
                        { t(`languages.level.${language?.level}.label`) }
                    </div>
                </div>
            </div>
    );
}