import axios from "axios";

export const CHANGE_PASSWORD_URL = "/api/password";
export const SEND_RESET_PASSWORD_EMAIL_URL = "/api/password/reset_request";
export const RESET_PASSWORD_URL = "/api/password/reset";
export interface ChangePasswordInterface {
    oldPassword: string,
    plainPassword: string,
    confirmationPassword: string
}

export interface SendResetPasswordInterface {
    email: string
}

export interface ResetPasswordInterface {
    plainPassword: string,
    token: string,
}

export async function ChangePassword(passwords: ChangePasswordInterface) {
    return axios.put(CHANGE_PASSWORD_URL, passwords, {headers: {"Authorization": ""}});
}

export async function SendResetPasswordEmail(email: SendResetPasswordInterface) {
    return axios.put(SEND_RESET_PASSWORD_EMAIL_URL, email, {headers: {"Authorization": ""}});
}

export async function ResetPassword(password: ResetPasswordInterface) {
    return axios.put(RESET_PASSWORD_URL, password, {headers: {"Authorization": ""}});
}
