import React, {useState} from "react";
import {useGetProfileQuery} from "../../../../_redux/auth/services";
import {useGetMyFriendRequestsQuery} from "../../../../_redux/features/network/services";
import {InvitationInterface} from "./InvitationItem";
import {Paginator} from "../../../../components/paginator/Paginator";
import NoInvitationSent from "./NoInvitationSent";
import FriendRequestItem from "./FriendRequestItem";

export default function SentInvitations() {

    const [page, setPage] = useState<number>(1);
    const {data: profile} = useGetProfileQuery()
    const {data, isFetching, isLoading} = useGetMyFriendRequestsQuery({id: profile?.id, filters: {_page: page}}, {skip: !profile})
    const {list: invitations, pagination} = data || {}

    return (
        <>

            {
                isFetching ?
                    <FriendRequestItem isLoading invitation={null}/>
                    :
                    <>
                        {
                            invitations?.length
                                ?
                                invitations?.map((invitation: InvitationInterface) =>
                                    <FriendRequestItem
                                        key={invitation.id}
                                        invitation={invitation}
                                    />
                                )
                                :
                                <NoInvitationSent/>

                        }
                        <Paginator
                            count={pagination?.last?._page ?? 1}
                            disabled={isFetching || isLoading}
                            onChange={(e, page) => setPage(page)}
                            page={page ?? 1}
                        />
                    </>
            }
        </>
    )
}