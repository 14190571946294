import React from "react";
import {useTranslation} from "react-i18next";
import VisitesIllustration from "../../../components/illustration/VisitesIllustration";
import ReturningVisitorsIllustration from "../../../components/illustration/ReturningVisitorsIllustration";
import RecruitersIllustration from "../../../components/illustration/RecruitersIllustration";
import {useGetVisitsStatisticsQuery} from "../../../_redux/features/network/services";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import VisitItem from "./VisitItem";

export default function Visits({lastDays = 90}) {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const {data, isFetching} = useGetVisitsStatisticsQuery({
        id: profile?.id,
        filters: {last_days: lastDays}
    }, {skip: !profile})
    const {recruiters, returningVisits, visits} = data || {}

    return (
            <div className="w-full flex flex-row max-lg:flex-col justify-between items-center gap-3 ">

                <VisitItem label={t("visits.label")} value={visits}
                           isLoading={isFetching}
                    icon={<VisitesIllustration primary="#ffb836" secondary="#ffb836"/>}
                />

                <VisitItem label={t("visits.returning.label")} value={returningVisits}
                           isLoading={isFetching}
                           icon={<ReturningVisitorsIllustration primary="#ffb836" secondary="#ffb836"/>}
                />

                <VisitItem label={t("recruiters.label")} value={recruiters}
                           isLoading={isFetching}
                           icon={<RecruitersIllustration primary="#ffb836" secondary="#ffb836"/>}
                />

            </div>
    )
}
