import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const RedirectRegister = () => {
  const { locale } = useParams();
  useEffect(() => {
    if (locale)
      window.location.href = `${process.env.REACT_APP_V3_BASE_URL}/${locale}/register/recruiter`;
  }, [locale]);
  return null;
};

export default RedirectRegister;
