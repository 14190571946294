import React, { ReactNode } from "react";
import CustomUserAvatar from "../../../components/customAvatar/CustomUserAvatar";
import { Button } from "@nextui-org/react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

export interface NotificationItemInterface {
  id: number | string;
  createdAt: Date;
  description: string;
  type: string;
  label: string;
  icon?: ReactNode;
  thumbnail: string;
  color: string;
  redirect: string;
  viewed: boolean;
}

interface NotificationsInterface {
  notification: NotificationItemInterface;
}

export default function NotificationItem({
  notification,
}: NotificationsInterface) {
  const { i18n } = useTranslation();

  return (
    <div className="w-full flex flex-row justify-start items-start gap-3">
      <div className="flex flex-col justify-start items-center">
        <CustomUserAvatar
          src={notification?.thumbnail}
          className="h-[48px] w-[48px]"
        />
      </div>
      <div className="flex flex-col justify-between items-start gap-2">
        <p className="text-sm text-[#5e737d] first-letter:capitalize">
          {moment().locale(`${i18n.language}-DZ`).to(notification?.createdAt)}
        </p>
        <p className="text-black first-letter:capitalize">
          {notification?.description}
        </p>
        <Button
          style={{ padding: "1px 10px" }}
          radius="full"
          color={notification?.viewed ? "secondary" : "warning"}
          variant="solid"
          className="text-sm text-black first-letter:capitalize font-xing-bold"
        >
          <NavLink
            className="p-0 flex flex-row justify-between items-center gap-2"
            to={notification.redirect}
          >
            {notification?.icon ? notification?.icon : null}
            {notification?.label}
          </NavLink>
        </Button>
      </div>
    </div>
  );
}
