import {createApi} from '@reduxjs/toolkit/query/react'
import {apiPlatformResponseTransform, globalFetchBaseQuery, invalidatesList, providesList} from "../../fetchBaseQuery";
import {filterObjToString} from "../../../_helpers/urlHelpers";
import {NetworkInterface} from "./networkInterface";
import {InvitationInterface} from "../../../pages/private/network/Invitations/InvitationItem";


// Define a service using a base URL and expected endpoints
export const networkApi = createApi({
    reducerPath: 'networksApi',
    tagTypes: ['networks', 'strangers', 'network', "invitations", "viewers", "friendRequests"],
    baseQuery: globalFetchBaseQuery,
    endpoints: (builder) => ({
        getUserNetwork: builder.query<any, object>({
            query: ({id, filters = {_page: 1}}: {id: number, filters: {_page: number}}) => {
                return {
                    url: `api/users/${id}/friends`,
                    params: filters
                }
            },
            providesTags: ['networks', 'strangers'],
            transformResponse: apiPlatformResponseTransform
        }),
        getUserStrangers: builder.query<any, object>({
            query: ({id, filters = {_page: 1}}: {id: number, filters: {_page: number}}) => {
                return {
                    url: `api/users/${id}/strangers`,
                    params: filters
                }
            },
            providesTags: ['networks', 'strangers'],
            transformResponse: apiPlatformResponseTransform
        }),
        getClosestAnniversaries: builder.query<any, object>({
            query: ({id, filters = {_page: 1}}: {id: number, filters: {_page: number}}) => {
                return {
                    url: `api/users/${id}/friends_birthdays`,
                    params: filters
                }
            },
            providesTags: ['networks', 'strangers'],
            transformResponse: apiPlatformResponseTransform
        }),
        getUserCommonFriendsWith: builder.query<any, object>({
            query: ({id, filters = {_page: 1}}: {id: number, filters: {_page: number}}) => {
                return `api/users/common_friends_with/${id}?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'networks'),
            transformResponse: apiPlatformResponseTransform
        }),
        getCandidates: builder.query<any, object>({
            query: (filters: object = {_page: 1}) => {
                return `api/candidates?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'candidates'),
            transformResponse: apiPlatformResponseTransform
        }),
        getSingleCandidate: builder.query<NetworkInterface, number|undefined>({
            query: (id: number|undefined) => {
                return `api/candidates/${id}`
            },
            providesTags: (result) => providesList(result, 'candidates'),
        }),
        getProfileViewers: builder.query<any, object>({
            query: ({id, filters = {_page: 1}}: {id: number, filters: object}) => {
                return `api/users/${id}/user_viewers?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'viewers'),
            transformResponse: apiPlatformResponseTransform
        }),
        getVisitsStatistics: builder.query<any, object>({
            query: ({id, filters} : {id: number|undefined, filters: object}) => {
                return `api/users/${id}/visits_statistics?${filterObjToString(filters)}`
            },
            keepUnusedDataFor: 30,
        }),
        getVisitsEachDay: builder.query<any, object>({
            query: ({id, filters} : {id: number|undefined, filters: object}) => {
                return `api/users/${id}/visits_each_day?${filterObjToString(filters)}`
            },
            keepUnusedDataFor: 30,
        }),
        getMyInvitations: builder.query<any, number|undefined>({
            query: ({id, filters = {_page: 1}}:{id: number | undefined, filters: {_page: number}}) => {
                return `api/users/${id}/invitations?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'invitations'),
            keepUnusedDataFor: 0,
            transformResponse: apiPlatformResponseTransform
        }),
        getMyFriendRequests: builder.query<any, number|undefined>({
            query: ({id, filters = {_page: 1}}:{id: number | undefined, filters: {_page: number}}) => {
                return `api/users/${id}/friend_requests?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'friendRequests'),
            keepUnusedDataFor: 0,
            transformResponse: apiPlatformResponseTransform
        }),
        acceptInvitation: builder.mutation<InvitationInterface, number>({
            query: (id) => {
                return {
                    url: `/api/user_invitations/${id}/accept`,
                    method: 'PATCH',
                    body: {},
                }
            },
            invalidatesTags: (result, error, arg) => invalidatesList(["invitations", "networks", "candidates"], arg.id),
        }),
        refuseInvitation: builder.mutation<InvitationInterface, number>({
            query: (id) => {
                return {
                    url: `/api/user_invitations/${id}/refuse`,
                    method: 'PATCH',
                    body: {},
                }
            },
            invalidatesTags: (result, error, arg) => invalidatesList(["invitations", "networks", "candidates"], arg.id),
        }),
        cancelInvitation: builder.mutation<InvitationInterface, number>({
            query: (id) => {
                return {
                    url: `/api/user_invitations/${id}/cancel`,
                    method: 'PATCH',
                    body: {},
                }
            },
            invalidatesTags: (result, error, arg) => invalidatesList(["invitations", "networks", "candidates"], arg.id),
        }),
        sendInvitation: builder.mutation<InvitationInterface, Pick<InvitationInterface, 'invited' & 'invitedBy'>>({
            query: (data) => {
                return {
                    url: `/api/user_invitations`,
                    method: 'POST',
                    body: data,
                }
            },
            invalidatesTags: (result, error, arg) => invalidatesList(["invitations", "networks", "candidates"], arg.id),
        }),
        removeFriendship: builder.mutation<any>({
            query: ({id, friendId}: {id: number, friendId: number}) => {
                return {
                    url: `/api/users/${id}/friends/${friendId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result, error, arg) => {
                return invalidatesList(["invitations", "networks", "candidates"], arg?.friendId)
            },
        }),
    }),

})


export const {
    useGetCandidatesQuery,
    useGetSingleCandidateQuery,
    useGetProfileViewersQuery,
    useGetVisitsStatisticsQuery,
    useGetVisitsEachDayQuery,
    useGetMyInvitationsQuery,
    useGetMyFriendRequestsQuery,
    useAcceptInvitationMutation,
    useRefuseInvitationMutation,
    useCancelInvitationMutation,
    useRemoveFriendshipMutation,
    useSendInvitationMutation,
    useGetUserNetworkQuery,
    useGetClosestAnniversariesQuery,
    useGetUserStrangersQuery,
    useGetUserCommonFriendsWithQuery,
} = networkApi