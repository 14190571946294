import {styled} from "@mui/material";
import {alpha, Menu, MenuProps} from "@mui/material";
import React from "react";

export const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        getContentAnchorEl={null}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export const style = {
    input: [
        "text-lg",
        "placeholder:font-xing placeholder:text-lg"
    ],
    innerWrapper: [
        "hover:bg-transparent",
        "bg-transparent",
        "focus:bg-transparent focus-within:bg-transparent h-[42px] text-lg"
    ],
    inputWrapper: [
        "h-[42px] shadow-none",
        "bg-[#f4f4f5] focus-visible:bg-red-600 focus-within:bg-white",
        "!cursor-text",
        "border-0  border-white",
        "active:border-2 ",
        "focus-within:border-2 focus-within:border-black focus-within:border-black",
        "empty:border-2 empty:border-black empty:border-black",
        "enabled:border-solid enabled:border-2 enabled:border-black",
        "valid:border-solid valid:border-2 valid:border-black",
    ]
}