import {createTheme} from "@mui/material";

export const theme = createTheme({
    breakpoints: {
        keys: ["xs", "sm", "md", "lg", "xl"],
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        common: {
            black: "#000",
            white: "#fff"
        },
        primary: {
            main: "#000000",
            light: "#000000",
            dark: "#000000"
        },
        info: {
            main: "#0698a0",
            light: "#83ccd0",
            dark: "#026a73"
        },
        success: {
            main: "#a9e55c",
            light: "#cbeea3",
            dark: "#90cA23"
        },
        error: {
            main: "#da0000",
            light: "#ef5350",
            dark: "#c62828"
        },
        warning: {
            main: "#ed6c02",
            light: "#ff9800",
            dark: "#e65100"
        },
        grey: {
            "50": "#fefefe",
            "100": '#fcfcfc',
            "200": "#fAfAfA",
            "300": "#f7f7f8",
            "400": "#f6f6f7",
            "500": "#f4f4f5",
            "600": "#f3f3f4",
            "700": "#f1f1f2",
            "800": "#efeff0",
            "900": "#ececee",
            "A100": "#ffffff",
            "A200": "#ffffff",
            "A400": "#ffffff",
            "A700": "#ffffff"
        }
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: "XING Sans",
        fontSize: 16,
        button: {
            fontFamily: "XING Sans bold",
            fontSize: 16
        },
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700
    }
})