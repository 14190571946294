import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { AppRoutes } from "../utils/appRoutes";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectToken } from "../_redux/auth/authSlice";

export function Brand() {
  const { i18n } = useTranslation();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);

  return (
    <NavLink
      to={AppRoutes[isAuth ? "PRIVATE_HOME" : "HOME"].replace(
        ":locale",
        i18n.language
      )}
    >
      <img
        height={40}
        width={214}
        className="max-w-[214px] max-h-[40px] object-contain max-md:hidden"
        src="/logo.jpg"
        alt="menu"
      />
    </NavLink>
  );
}
