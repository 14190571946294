import React, {useState} from "react";
import {useGetProfileQuery} from "../../../../_redux/auth/services";
import {useGetSelectedJobsQuery} from "../../../../_redux/features/jobs/services";
import {JobInterface} from "../../job/ui/jobComponentItem";
import JobComponentItem from "../../jobsSearch/jobSearchItem";
import {Container} from "@mui/material";
import ApplicationsIllustration from "../../../../components/illustration/NoResultIllustration";
import {useTranslation} from "react-i18next";
import {JobsSearchLoading, SimilarJobsLoading} from "../../jobs/loading";
import {Paginator} from "../../../../components/paginator/Paginator";

export default function SelectedOffers() {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const [page, setPage] = useState<number>(1);
    const {data, isLoading, isFetching} = useGetSelectedJobsQuery({id: profile?.id, filters: {_page: page}}, {skip: !profile})
    const {list: selectedJobs, pagination, totalItems} = data || {}

    return (
        <div className="w-full flex flex-col justify-between items-center gap-3">
            {isLoading ?
                <SimilarJobsLoading/>
                :
                totalItems <= 0 ?
                    <div className="w-full min-h-[360px] bg-white rounded-xl ">
                        <Container>
                            <div className="flex flex-col justify-around items-center gap-3">
                                <ApplicationsIllustration primary="#ffb836"/>
                                <p className="text-lg font-xing-bold text-center">
                                    {t("job.applications.selected.offers.no.result.label")}
                                </p>
                                <p className="text-md font-xing text-center">
                                    {t("job.applications.selected.offers.no.result.label")}
                                    {t("job.applications.selected.offers.browse.our.recommendations.and.select.the.job.label")}
                                </p>
                            </div>
                        </Container>
                    </div>
                    :
                    <>
                        {
                            isLoading || isLoading
                                ?
                                <JobsSearchLoading/>
                                :
                                <>
                                    {
                                        selectedJobs?.length <= 0
                                            ?
                                            t("job.label", {count: selectedJobs?.length})
                                            :
                                            selectedJobs?.map((job: JobInterface) =>
                                                <JobComponentItem
                                                    key={job?.id}
                                                    job={job}
                                                    isIconOnly
                                                />
                                            )
                                    }
                                    <Paginator
                                        count={pagination?.last?._page ?? 1}
                                        disabled={isFetching || isLoading}
                                        onChange={(e, page) => setPage(page)}
                                        page={page ?? 1}
                                    />
                                </>

                        }
                    </>
            }
        </div>
    )
}