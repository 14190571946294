import React from "react";
import Select from "../Select";
import {useTranslation} from "react-i18next";
import {MenuItem} from "@mui/material";

interface SelectPropsInterface {
    name: string;
    type?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    ch?: boolean;
    onChange?: (e: any) => void;
}

export default function SelectMobility(props: SelectPropsInterface): JSX.Element {

    const {t} = useTranslation()

    return (
        <Select
            variant="standard"
            fullWidth
            label={t("mobility.label")}
            placeholder={t("mobility.label")}
            {...props}
        >
            <MenuItem value="1" key="1">
                {t("mobility.national.label")}
            </MenuItem>
            <MenuItem value="2" key="2">
                {t("mobility.international.label")}
            </MenuItem>
            <MenuItem value="3" key="3">
                {t("mobility.regional.label")}
            </MenuItem>
        </Select>
    )
}