import { Field, useField } from "formik";
import React from "react";
import { Textarea as NextTextarea } from "@nextui-org/react";

interface InputPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean | undefined;
  fullWidth?: boolean;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  onChange?: (e: any) => void;
}

export default function Textarea(props: InputPropsInterface): JSX.Element {
  const [field, meta] = useField(props);

  return (
    <Field
      variant="underlined"
      {...field}
      {...props}
      as={NextTextarea}
      errormessage={meta?.error}
      isInvalid={meta?.error && meta?.touched}
      disabled={props?.isLoading || props?.disabled}
      error={meta?.error}
      helperText={meta.error}
    />
  );
}
