import {useField} from "formik";
import React, {useEffect, useRef, useState} from "react";
import EmojiPicker, { Emoji } from "emoji-picker-react";

export interface InputPropsInterface {
    name: string,
    type?: string,
    variant?: "standard" | "outlined" | "filled",
    disabled?: boolean,
    className?: string,
    required?: boolean,
    onChange?: (e: any) => void,
    isLoading?: boolean;
    endContent?: JSX.Element | undefined
}

export default function InputEmoji(props: InputPropsInterface): JSX.Element {

    const [field, meta, helpers] = useField(props);
    const wrapperRef = useRef(null);
    const [showPicker, setShowPicker] = useState(false);

    const onEmojiClick = (emojiObject, event) => {
        helpers.setValue(field.value + emojiObject.emoji)
        setShowPicker(false);
    };

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (showPicker && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowPicker(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, showPicker]);

    if (navigator?.userAgentData?.mobile) {
        return <></>;
    }

    return (
        <div ref={wrapperRef}>
            <span

                className={`px-2 block ${props?.className} ${props?.disabled ? "cursor-not-allowed" : "cursor-pointer"}`}
                onClick={() => {
                    if (props?.disabled) {
                        return;
                    }
                    setShowPicker((val) => !val)
                }}
                title={"emoji picker trigger"}
            >
                <Emoji emojiStyle="native" unified="1f642" size="20" />
            </span>

            <EmojiPicker emojiStyle={"native"}
                         open={showPicker}
                         className={"bottom-[55px] w-full"} style={{position: "absolute", width: "100%"}}
                         onEmojiClick={onEmojiClick}
            />
        </div>
    )

}