import {createApi} from '@reduxjs/toolkit/query/react'
import {apiPlatformResponseTransform, globalFetchBaseQuery, providesList} from "../../fetchBaseQuery";
import {filterObjToString} from "../../../_helpers/urlHelpers";
import {CvXLanguageInterface} from "./cvXLanguageInterface";


// Define a service using a base URL and expected endpoints
export const languageApi = createApi({
    reducerPath: 'languagesApi',
    tagTypes: ['languages', 'language'],
    baseQuery: globalFetchBaseQuery,
    endpoints: (builder) => ({
        getLanguages: builder.query<any, object>({
            query: (filters: object = {_page: 1}) => {
                return `api/params_languages?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'languages'),
            transformResponse: apiPlatformResponseTransform,
            invalidatesTags: ['languages']
        }),
        getSingleLanguage: builder.query<CvXLanguageInterface, number|undefined>({
            query: (id: number|undefined) => {
                return `api/cv_x_languages/${id}`
            },
            invalidatesTags: ['language']
        }),
    }),

})


export const {
    useGetLanguagesQuery,
    useGetSingleLanguageQuery
} = languageApi