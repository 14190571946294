import React, { useState } from "react";
import { mediaRoutes } from "../../../utils/mediaRoutes";
import { StudyLevelInterface } from "../../../_redux/features/studyLevels/types";
import { contractTypeInterface } from "../../../_redux/features/contractType/types";
import { salaryInterface } from "../../../_redux/features/salary/types";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SelectedJobButton from "../../../components/selectedJobButton/SelectedJobButton";
import CustomCompanyAvatar from "../../../components/customAvatar/CustomCompanyAvatar";
import { useTranslation } from "react-i18next";
import { CheckCircleTwoTone } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectToken } from "../../../_redux/auth/authSlice";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";
import { AppRoutes } from "../../../utils/appRoutes";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import VerifiedIcon from "@mui/icons-material/Verified";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@nextui-org/react";
import { useGetParamsExperiencesQuery } from "../../../_redux/features/experiences/services";
export interface JobSearchItemPropsInterface {
  isIconOnly?: boolean;
  job: JobSearchInterface;
}

export interface JobSearchInterface {
  "@id": string;
  id: number;
  title: string;
  logoUrl: string | null;
  companyName: string;
  externalLink: string | null | undefined;
  publishedDate: string;
  refreshedDate: string;
  missionDescription: string;
  profileDescription: string;
  contractTypes: [contractTypeInterface];
  studyLevel: StudyLevelInterface;
  region: regionInterface;
  salary: salaryInterface;
  logo: string;
  applied?: boolean;
  nbPosition: number;
  city: {
    name: string;
  };
}

export interface regionInterface {
  "@id": string;
  id: number;
  name: string;
}

const JobComponentItem = ({
  job,
  isIconOnly = false,
}: JobSearchItemPropsInterface) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);

  const { data } = useGetParamsExperiencesQuery({
    pagination: false,
  });
  const { list: experiences } = data || {};

  return (
    <div
      className="cursor-pointer w-full min-h-[200px] relative flex flex-row justify-between items-center hover:bg-gray-50 p-2 transition-all duration-150 rounded-sm"
      onClick={() =>
        navigate(
          AppRoutes[isAuth ? "PRIVATE_JOB_DETAILS" : "PUBLIC_JOB_DETAILS"]
            .replace(":id", `${job.id}`)
            .replace(":locale", i18n.language)
        )
      }
    >
      <div className="relative flex-shrink-0 max-md:w-[60px] w-[120px]">
        <CustomCompanyAvatar
          src={
            job?.logoUrl
              ? `${process.env.REACT_APP_MEDIA_BASE_URL}${job?.logoUrl}`
              : `${mediaRoutes.COMPANY_LOGO}${job?.logoUrl}`
          }
          className="relative z-0 max-md:max-h-[48px] max-md:max-w-[48px] max-h-[92px] max-w-[92px]"
          objectFit="fill"
        />
      </div>

      <div className="grow flex flex-col justify-start items-start gap-1">
        <div className="flex flex-wrap justify-start items-center gap-6">
          <p className="text-md text-black">{job.companyName}</p>
        </div>
        <div className="flex flex-wrap justify-start items-center gap-6">
          <p className="text-md text-black font-xing-bold truncate text-wrap">
            {job?.title}
          </p>
        </div>
        <div className="flex flex-row gap-1 items-center py-2">
          <LocationOnIcon fontSize="medium" />
          <p className="first-letter:uppercase text-black text-sm">
            {job?.region?.name}
          </p>
        </div>

        <div className="flex flex-row justify-between first-letter:capitalize">
          <div className="flex flex-wrap justify-end gap-2 items-center">
            <div className="flex flex-wrap justify-start items-center gap-3">
              <div className="flex flex-col max-lg:flex-row justify-between items-start max-lg:items-center gap-1 font-xing">
                <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                  <ApartmentOutlinedIcon fontSize="small" />
                  <p className="first-letter:uppercase text-black text-xs">
                    {job?.workplace?.name}
                  </p>
                </div>
              </div>

              <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                <WorkspacePremiumOutlinedIcon fontSize="small" />
                <p className="first-letter:uppercase text-black text-xs">
                  {job?.studyLevel?.name}
                </p>
              </div>
              {job?.contractTypes?.map((contract) => (
                <div
                  className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2"
                  key={contract?.id + contract?.name}
                >
                  <ArticleOutlinedIcon fontSize="small" />
                  <p className="first-letter:uppercase text-black text-xs">
                    {contract?.name}
                  </p>
                </div>
              ))}
              <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                <VerifiedIcon fontSize="small" />
                <p className="first-letter:uppercase text-black text-xs">
                  {experiences?.find(exp => exp?.id == job?.nbMonthExperience)?.label}
                </p>
              </div>
              <div className="flex flex-row justify-between gap-3 items-center bg-gray-100 rounded-full px-2">
                <GroupsOutlinedIcon fontSize="small" />
                <p className="first-letter:uppercase text-black text-xs">
                  {t("position.label", { count: job?.nbPosition ?? 1 })}
                </p>
              </div>
            </div>
          </div>
        </div>
        {job?.description && (
          <div
            className="font-xing text-md text-black max-md:hidden"
            dangerouslySetInnerHTML={{ __html: job?.shortDescription || "" }}
          />
        )}
      </div>

      <div className="w-fit flex flex-col justify-center items-center sm:justify-start sm:items-end gap-6">
        {isAuth && <SelectedJobButton iconOnly job={job} />}
        {job?.applied ? (
          <p className="flex gap-2 float-end text-[#8938f3] text-xs bg-[#f2e8ff] rounded-full py-1 px-2">
            <CheckCircleTwoTone fontSize={"small"} />
            <p className="text-xs sm:flex hidden first-letter:uppercase">
              {t("job.applied.label")}
            </p>
          </p>
        ) : (
          <>
            <Button
              isIconOnly
              className={`w-fit hidden sm:flex gap-4 justify-center items-center p-4 bg-[#FFC965] text-lg text-black first-letter:uppercase font-xing-bold`}
              aria-label={t("update.label")}
              color="warning"
              variant="flat"
              radius="full"
              onClick={() =>
                navigate(
                  AppRoutes[
                    isAuth ? "PRIVATE_JOB_DETAILS" : "PUBLIC_JOB_DETAILS"
                  ]
                    .replace(":id", `${job.id}`)
                    .replace(":locale", i18n.language)
                )
              }
            >
              <OpenInNewIcon
                fontSize={"small"}
                className="text-black text-sm w-4 h-4 sm:mr-2"
              />
              <p className="w-4/5 sm:flex hidden text-black text-base first-letter:uppercase font-xing-bold">
                {t("job.apply.label")}
              </p>
            </Button>
            <OpenInNewIcon
              fontSize={"small"}
              className="flex sm:!hidden text-[#FFC965] text-sm w-4 h-4 sm:mr-2"
            />
          </>
        )}
        <p className="w-fit text-nowrap font-xing first-letter:capitalize text-sm text-gray-600">
          {moment().locale(`${i18n.language}-DZ`).to(job?.refreshedDate)}
        </p>
      </div>
    </div>
  );
};

export default JobComponentItem;
