export const publicHome = {
  title: "Emploipartner - Find Your Dream Job",
  description:
    "Emploipartner helps candidates find jobs at top companies. Browse job offers, create a profile, and make the right career connections.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/homepage-banner.jpg",
  url: "https://emploipartner.com",
};
export const policyProps = {
  title: "Terms of Use - Emploipartner",
  description:
    "Read the terms of use for Emploipartner. Learn more about your rights, responsibilities, and the rules for using our job platform.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/terms-of-use.jpg",
  url: "https://emploipartner.com/terms-of-use",
};

export const jobSearchPublic = {
  title: "Search Jobs - Emploipartner",
  description:
    "Find job opportunities that match your skills and career goals. Use Emploipartner's job search tool to discover your next job.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/job-search.jpg",
  url: "https://emploipartner.com/job-search",
};

export const blog = {
  title: "News",
};
export const profilerTitle = {
  title: "My Profile",
};
export const applicationTitle = {
  title: "Application",
};
export const professionalExpectationsTitle = {
  title: "Professional Expectations",
};
export const faqTitle = {
  title: "FAQ",
};
export const topCompanies = {
  title: "Top Companies Hiring - Emploipartner",
  description:
    "Explore job openings at top companies in various industries. Apply now through Emploipartner and take the next step in your career.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/top-companies.jpg",
  url: "https://emploipartner.com/top-companies",
};

export const letTheJobComeToYou = {
  title: "Let the Job Find You - Emploipartner",
  description:
    "With Emploipartner, top employers can reach out to you directly. Create a profile and get matched with job offers that suit you.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/job-come-to-you.jpg",
  url: "https://emploipartner.com/let-the-job-find-you",
};

export const topViewedOffers = {
  title: "Most Viewed Job Offers - Emploipartner",
  description:
    "Check out the most popular job offers on Emploipartner. These top-viewed positions are waiting for your application!",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/top-offers.jpg",
  url: "https://emploipartner.com/top-viewed-offers",
};

export const makeTheRightContacts = {
  title: "Make the Right Contacts - Emploipartner",
  description:
    "Expand your professional network and make the right connections with top recruiters and companies on Emploipartner.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/right-contacts.jpg",
  url: "https://emploipartner.com/right-contacts",
};

export const smartPublicOffers = {
  title: "Smart Job Offers - Emploipartner",
  description:
    "Find smart job offers tailored to your skills and career interests. Emploipartner connects you with top recruiters.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/smart-public-offers.jpg",
  url: "https://emploipartner.com/smart-offers",
};

export const filterByRegionAndFunction = {
  title: "Filter Jobs by Region and Function - Emploipartner",
  description:
    "Narrow down your job search by region and function to find the perfect job. Emploipartner makes it easy to filter job offers.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/filter-by-region.jpg",
  url: "https://emploipartner.com/filter-by-region",
};

export const createAnEmploiPartnerProfile = {
  title: "Create Your Emploipartner Profile",
  description:
    "Create a profile on Emploipartner and get noticed by top employers. Let companies find you and reach out with job offers.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/create-profile.jpg",
  url: "https://emploipartner.com/create-profile",
};

export const downloadEmploiPartnerApplication = {
  title: "Download Emploipartner Mobile App",
  description:
    "Stay connected to the latest job offers and updates with the Emploipartner mobile app. Download now on iOS and Android.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/download-app.jpg",
  url: "https://emploipartner.com/download-app",
};

export const jobDetails = (job: {
  title?: string;
  description?: string;
  company?: string;
  image?: string;
  url?: string;
}) => ({
  title: `${job.title} at ${job.company} - Emploipartner`,
  description: job.description,
  name: "Emploipartner",
  type: "website",
  image: job.image || "https://emploipartner.com/images/default-job.jpg", // Fallback image if not provided
  url: job.url || "https://emploipartner.com/jobs", // Fallback URL if not provided
});

export const loginPage = {
  title: "Login - EmploiPartner",
  description:
    "Access your EmploiPartner account and start applying for job opportunities. Secure and fast login to your dashboard.",
  name: "EmploiPartner",
  type: "website",
  image: "https://emploipartner.com/images/login-page.png", // An appropriate image representing the login page
  url: "https://emploipartner.com/login",
};

export const registerPage = {
  title: "Register - EmploiPartner",
  description:
    "Create an EmploiPartner account and start finding job opportunities today. Join us to connect with top companies.",
  name: "EmploiPartner",
  type: "website",
  image: "https://emploipartner.com/images/register-page.png", // An appropriate image representing the register page
  url: "https://emploipartner.com/register",
};

export const privacyPolicyProps = {
  title: "Privacy Policy - Emploipartner",
  description:
    "Learn how Emploipartner collects, uses, and protects your personal data. Read our privacy policy to understand your rights.",
  name: "Emploipartner",
  type: "website",
  image: "https://emploipartner.com/images/terms-of-use.jpg",
  url: "https://emploipartner.com/terms-of-use",
};
