import React from "react";
import ColoredContainer from "../containers/ColoredContainer";
import {Edit} from "@mui/icons-material";
import CertificateAddModal from "../modals/Profile/Certificate/CertificateAddModal";
import SkillsIllustration from "../illustration/SkillsIllustration";
import {useTranslation} from "react-i18next";

export default function NoDiplomasAndCertificatesFound() {

    const {t} = useTranslation()

    return (
        <ColoredContainer className="w-full flex flex-row max-lg:flex-col justify-evenly items-center gap-3 p-3">
            <SkillsIllustration primary="#ffb836" secondary="#d9d9d9" />
            <div className="flex flex-col justify-between items-start gap-3">
                <h1 className="font-xing-bold">
                    {t("profile.certificates.be.found.more.easily.by.companies.and.recruiters.label")}
                </h1>
                <p>
                    {t("profile.certificates.your.skills.and.knowledge.label")}
                </p>
                <CertificateAddModal
                    isIconOnly={false}
                    variant="bordered"
                    color="primary"
                    radius="full"
                    className="font-xing-bold"
                    text={t("profile.certificates.add.label")}
                    icon={ <Edit height={10} width={10} /> }
                />
            </div>
        </ColoredContainer>
    )

}