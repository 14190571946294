import React, { useEffect, ChangeEvent, useState } from "react";
import TitledContainer from "../../../components/containers/TitledContainer";
import {
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  useGetArticlesQuery,
  useGetCategoriesQuery,
} from "../../../_redux/features/blog/services";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik, FormikValues } from "formik";
import { SearchIcon } from "@nextui-org/shared-icons";
import { Button } from "@nextui-org/react";
import CustomSpinner from "../../../components/spinner/CustomSpinner";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { useSearchParams } from "react-router-dom";
import { CloseOutlined, ExpandLess } from "@mui/icons-material";
import { Input as NextInput } from "@nextui-org/input";
import BlogListLading from "./ui/BlogListLoading";
import ArticleItem from "./ui/ArticleItem";
import CategoriesFilter from "./categories/CategoriesFilter";
import { removeInvalidKeys } from "../../../_helpers/urlHelpers";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { values } from "lodash";
import { NoResultFound } from "../../../components/noResultFound/NoResultFound";
import SEO from "../../../SEO";
import { blog } from "../../../utils/seoProps";
import { removeEmptyProperty } from "../../../_redux/features/jobs/services";
import { Paginator } from "../../../components/paginator/Paginator";
export default function Blog() {

  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading, isFetching } = useGetArticlesQuery({
    keyword: searchParams?.get("keyword"),
    type: searchParams?.get("type"),
    _page: searchParams?.get("_page") ?? 1,
  });
  const { list: articles, pagination, totalItems } = data || {};

  const [isOpen, setIsOpen] = useState(false);

  const initialValues = {
    keyword: searchParams?.get("keyword") ?? "",
    type: searchParams?.get("type") ?? null,
  };
  const handleOpen = () => setIsOpen(!isOpen);

  const handleSubmit = (values: FormikValues) => {
    setSearchParams(removeInvalidKeys(values));
  };

  function scrollHandler(e: ChangeEvent<unknown>, _page: number) {
    if (pagination?.last?._page >= _page) {
      const params = removeEmptyProperty({ ...searchParams, _page: _page });
      setSearchParams(params);
    }
  }

  return (
    <Container maxWidth="lg">
      <SEO {...blog} />
      <TitledContainer title={t("sidebar.news")} className="flex flex-col">
        <div className="flex flex-row max-xl:flex-col-reverse justify-between items-start gap-12">
          <div className="max-xl:w-full xl:w-4/6 flex flex-wrap gap-y-12">
            {isLoading || isFetching ? (
              <BlogListLading />
            ) : articles?.length > 0 ? (
              <>
                {articles?.map((article: any, i: number) => (
                <ArticleItem
                  id={article?.id}
                  index={i}
                  picture={article?.publicMediaMedium}
                  slug={article?.slug}
                  title={article?.title}
                  description={article?.description}
                />
                ))}
                <div className="w-full">
                  <Paginator
                    count={pagination?.last?._page ?? 1}
                    disabled={isFetching || isLoading}
                    onChange={scrollHandler}
                    page={searchParams?.get("_page") ?? 1}
                  />
                </div>
              </>
            ) : (
              <NoResultFound
                message={t("articles.label", { count: 0 })}
                height="320"
              />
            )}
          </div>
          <div className="max-xl:w-full xl:w-2/6 flex flex-col justify-start items-start gap-9 xl:sticky lg:top-[90px]">
            <Formik
              initialValues={initialValues as never}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="w-full flex flex-col justify-start gap-9">
                  <div className="w-full flex flex-row justify-between items-center gap-3">
                    <div className="grow">
                      <Field
                        disabled={isLoading || isFetching}
                        radius="full"
                        classNames={{
                          input: [
                            "text-lg",
                            "placeholder:font-xing placeholder:text-lg placeholder:text-[#8a9da6] dark:placeholder:text-white/60",
                          ],
                          innerWrapper: [
                            "hover:bg-transparent",
                            "bg-transparent",
                            "focus:bg-transparent focus-within:bg-transparent h-[42px] text-lg",
                          ],
                          inputWrapper: [
                            "h-[42px] shadow-none",
                            "bg-[#f4f4f5] hover:bg-[#e9eef0] focus-visible:bg-red-600 focus-within:bg-white",
                            "!cursor-text",
                            "border-0  border-white",
                            "hover:border-black hover:border-0",
                            "valid:border-solid valid:border-2 valid:border-black",
                          ],
                        }}
                        fullWidth={true}
                        as={NextInput}
                        variant="outlined"
                        type="search"
                        name="keyword"
                        placeholder={t("keywords.label", { count: 1 })}
                      />
                    </div>
                    <div>
                      <Button
                        type="submit"
                        isIconOnly={true}
                        className="h-[42px] font-xing-bold text-lg"
                        radius="full"
                        variant="flat"
                        color="warning"
                        spinner={
                          isSubmitting || isFetching ? <CustomSpinner /> : null
                        }
                      >
                        <SearchIcon height={18} width={18} />
                      </Button>
                    </div>
                  </div>
                  <CategoriesFilter />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </TitledContainer>
    </Container>
  );
}
