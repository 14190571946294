import React from "react";
import {useSelector} from "react-redux";
import {useGetEventsQuery} from "../../../_redux/features/events/services";
import EventList from "./EventList";
import NextEventItem from "../../../components/eventsComponent/nextEventItem/NextEventItem";
import {useTranslation} from "react-i18next";
import EventItemModal from "../../../components/modals/eventItemModal/EventItemModal";
import {EventSearchForm} from "../../../components/forms/events/EventSearchForm";
import {selectEventsSearch} from "../../../_redux/features/events/eventsSlice";
import {NoResultFound} from "../../../components/noResultFound/NoResultFound";
import {Container} from "@mui/material";

export default function Events() {

    const {t} = useTranslation()
    const eventSearch = useSelector(selectEventsSearch)
    const {data, error, isLoading, isFetching} = useGetEventsQuery({...eventSearch, _page: 1})
    const {list: events, pagination, totalItems} = data || {}
    
    return (
        <Container maxWidth="lg">
            <div className="w-full min-w-full max-w-full flex flex-col justify-start gap-6">

                <div className="w-full flex flex-row justify-start items-start text-3xl font-xing-bold">
                    {t("events.search.label")}
                </div>

                <EventSearchForm />

                { (events?.length === 0) ?
                    <NoResultFound message={t("events.label", {count: 0})} height="320" />
                    :
                    <>
                        <div className="w-full flex flex-row justify-start items-start text-3xl font-xing-bold">
                            {t("events.next.label")}
                        </div>

                        <NextEventItem />

                        <div className="w-full flex flex-row justify-start items-start text-3xl font-xing-bold">
                            {t("events.label")}
                        </div>

                        <EventList />

                        <EventItemModal />
                    </>
                }

            </div>
        </Container>
    )
}