import React, { ReactNode } from "react";
import { Button } from "@nextui-org/react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import ExperienceAddForm from "../../../_form/Experience/ExperienceAddForm";
import { experienceSchema } from "../../../_form/_schema/experienceSchema";
import {
  useAddCvExperienceMutation,
  useGetProfileQuery,
} from "../../../../_redux/auth/services";
import { ExperienceInterface } from "../../../../pages/private/profile/ui/myProfile/Experience";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

type ExperienceAddModalProps = {
  text?: string;
  icon?: ReactNode;
};

export default function ExperienceAddModal({
  icon,
  text,
  ...props
}: ExperienceAddModalProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { data: profile } = useGetProfileQuery();
  const [addExperienceAction, triggers] = useAddCvExperienceMutation();

  const handelSubmit = async (values: ExperienceInterface) => {
    addExperienceAction(values)
      .unwrap()
      .then((response) => {
        toast.success(
          t("add.message.specific", { value: t("experience.title.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  let initialValues = {
    cv: `/api/candidates/${profile?.id}/cv`,
    companyName: "",
    jobTitle: "",
    startDate: "",
    endDate: "",
    current: false,
    comment: "",
    manager: false,
    companyType: "",
    function: "",
    sectorGroup: "",
  };
  const shema = experienceSchema();

  return (
    <>
      <Button
        {...props}
        aria-label={t("delete.label")}
        title={t("add.specific", { value: t("professionalExperience.label") })}
        onClick={handleOpen}
      >
        {icon && icon}

        {text && <p className="font-xing-bold">{text}</p>}
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("add.specific", { value: t("professionalExperience.label") })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("add.specific", {
                    value: t("professionalExperience.label"),
                  })}
                </h1>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handelSubmit}
                  validationSchema={shema}
                >
                  <ExperienceAddForm
                    triggers={triggers}
                    onCancel={handleClose}
                  />
                </Formik>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
