import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { useGetLanguagesQuery } from "../../../../_redux/features/languages/services";
import { MenuItem } from "@mui/material";
import { languageInterface } from "../../../../_redux/features/languages/types";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../_redux/auth/services";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectLanguages({
  name,
  ...props
}: SelectPropsInterface): JSX.Element {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const { data: cv, isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });
  const {
    data: languages,
    isLoading,
    isFetching,
  } = useGetLanguagesQuery({ pagination: false });
  const { list } = languages || {};

  return (
    <Select
      variant="standard"
      readOnly
      name={name}
      disabled={isLoading || isFetching}
      isLoading={isLoading || isFetching || isCvLoading}
      fullWidth
      label={t("language.label")}
      placeholder={t("language.label")}
      {...props}
    >
      {list
        ?.filter(
          (item: any) =>
            !cv?.languages.some(
              (language: any) =>
                language?.languageName.toLowerCase() ===
                item?.name.toLowerCase()
            )
        )
        ?.map((language: languageInterface) => (
          <MenuItem
            style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
            className="w-full"
            key={language["@id"]}
            value={language["codeName"]}
          >
            {language?.name}
          </MenuItem>
        ))}
    </Select>
  );
}
