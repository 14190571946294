import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { regionInterface } from "../../../../_redux/features/region/types";
import {
  useGetCardinalsQuery,
  useGetRegionsQuery,
} from "../../../../_redux/features/region/services";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  countryId: number | null;
  type?: string;
  disabled?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectRegion(props: SelectPropsInterface): JSX.Element {
  const { t } = useTranslation();
  // const {data, isLoading} = useGetRegionsQuery({
  //     pagination: false,
  //     country: props?.countryId
  // }, {skip: !props?.countryId});
  // const {list} = data || {}

  const { data, isLoading } = useGetCardinalsQuery(
    {
      pagination: false,
    },
    { skip: !props?.countryId }
  );
  const { list } = data || {};
  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("find.jobs.announcement.by.region.label", { count: 1 })}
      placeholder={t("find.jobs.announcement.by.region.label", { count: 1 })}
      {...props}
    >
      {list
        ?.map((region: any) => (
          <MenuItem key={region["1"]} value={region["1"]}>
            {t(`cardinals.${region["1"]}.label`)}
          </MenuItem>
        ))
        .sort((a, b) => (a?.id > b?.id ? -1 : 1))}
    </Select>
  );
}
