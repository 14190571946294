import React, { useEffect } from "react";
import { Button, Modal, ModalBody, ModalContent } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../_redux/store";
import { useTranslation } from "react-i18next";
import {
  useGetSingleEventQuery,
  useEventApplyMutation,
} from "../../../_redux/features/events/services";
import { setSingleEvent } from "../../../_redux/features/events/eventsSlice";
import Countdown from "../../countdown/Countdown";
import moment from "moment/moment";
import { mediaRoutes } from "../../../utils/mediaRoutes";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import i18n from "i18next";
import { CandidateInterface } from "../../../_redux/features/events/interfaces";
import Candidate from "../../eventsComponent/candidate/Candidate";
import { toast } from "react-toastify";
import EventItemModalLoading, {
  BackgroundHeroLoading,
} from "./EventItemModalLoading";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

export default function EventOfferModal() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const { item } = useSelector((state: RootState) => state.events);
  const { data: event, isLoading } = useGetSingleEventQuery(item?.id, {
    skip: !item,
  });

  const [
    eventApplyAction,
    { isLoading: isApplying, isError, error: applyError, isSuccess },
  ] = useEventApplyMutation();

  function eventApply() {
    eventApplyAction({
      event: event?.["@id"],
      cv: `/api/candidates/${profile?.id}/cv`,
    })
      .unwrap()
      .then((response) => {
        toast.success(t("events.messages.success.label"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        dispatch(setSingleEvent({ event: undefined }));
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  }

  return (
    <Modal
      hideCloseButton={false}
      closeButton={
        <div className="h-[20px] w-[20px] bg-white rounded-full">
          <XMarkIcon
            className="text-black bg-white rounded-full p-1"
            color="primary"
            width={24}
            height={24}
          />
        </div>
      }
      size="full"
      dir={i18n.dir()}
      backdrop="blur"
      placement="top"
      scrollBehavior="inside"
      isOpen={!!item}
      onClose={() => {
        dispatch(setSingleEvent({ event: undefined }));
      }}
      classNames={{
        backdrop: "bg-[#FFC965]/10 backdrop-blur-[10px]",
        closeButton: "z-50",
      }}
      className="min-h-full min-w-full"
    >
      <ModalContent>
        {() => (
          <>
            {isLoading ? (
              <BackgroundHeroLoading />
            ) : (
              <div
                className="relative p-0 h-[35%]"
                style={{
                  backgroundSize: "cover",
                  backgroundImage: `url(${
                    event?.template?.heroAreaImage
                      ? `${mediaRoutes.EVENT_TEMPLATE}${event?.template?.heroAreaImage}`
                      : "/images/prototype-event-background.jpg"
                  })`,
                }}
              >
                <div className="absolute start-0 bottom-[10px] rounded-xl ms-7 p-2 bg-white">
                  <Countdown
                    backgroundColor="bg-transparent"
                    alignContainers="row"
                    startDate={event?.startDate as string}
                    endDate={event?.endDate as string}
                  />
                </div>
              </div>
            )}
            <ModalBody className="p-0">
              {isLoading ? (
                <EventItemModalLoading />
              ) : (
                <div className="flex flex-col justify-start gap-3">
                  <div className="flex-1 relative w-full flex flex-col justify-start gap-6 items-center overflow-hidden py-6 px-10 first-letter:capitalize">
                    <div className="w-full flex flex-wrap justify-between items-center gap-3">
                      <div className="shrink-0 flex flex-col justify-center">
                        <p className="text-black font-xing-bold text-2xl">
                          {event?.name}
                        </p>
                        <p className="text-black font-medium text-xl">
                          {t("created.by.label", {
                            user: event?.createdBy?.fullname,
                          })}
                        </p>
                      </div>
                      <div className="flex flex-row justify-center">
                        <p className="text-black font-xing-bold text-2xl">
                          {event?.template?.title}
                        </p>
                      </div>
                      <div className="shrink-0">
                        <Button
                          onClick={event?.isParticipant ? () => {} : eventApply}
                          disabled={
                            isApplying || isLoading || event?.isParticipant
                          }
                          radius="full"
                          variant="flat"
                          className="bg-[#61F1A4] text-black font-xing-bold rounded-full first-letter:capitalize"
                        >
                          {event?.isParticipant
                            ? t("events.participated.already")
                            : t("events.participate")}
                        </Button>
                      </div>
                    </div>
                    <div className="w-full flex flex-col justify-evenly items-start first-letter:capitalize">
                      <div className="font-xing-bold flex flex-row justify-start gap-3">
                        <span>
                          {t("events.state.from.label", {
                            date: moment(event?.startDate).format("ll"),
                          })}
                        </span>
                        <span>
                          {t("events.state.to.label", {
                            date: moment(event?.endDate).format("ll"),
                          })}
                        </span>
                      </div>
                      <div className="text-gray-200 first-letter:capitalize">
                        {event?.location}
                      </div>
                    </div>
                    <div className="w-full flex flex-col justify-evenly items-start">
                      <div className="text-black first-letter:capitalize text-justify">
                        {event?.template?.description}
                      </div>
                    </div>
                    <div className="w-full flex flex-col justify-evenly items-start">
                      <p className="text-black font-xing-bold text-2xl">
                        {t("events.members.who.participate.label")}
                      </p>
                    </div>
                    <div className="w-full">
                      {event?.candidates?.length > 0 ? (
                        <Swiper
                          watchSlidesProgress
                          slidesPerView={2}
                          spaceBetween={20}
                          grabCursor={true}
                          keyboard={{ enabled: true }}
                          pagination={{ clickable: true }}
                          scrollbar={{ draggable: true }}
                          centeredSlidesBounds={true}
                          centeredSlides={true}
                          breakpoints={{
                            360: { slidesPerView: 1, spaceBetween: 20 },
                            430: { slidesPerView: 2, spaceBetween: 20 },
                            640: { slidesPerView: 3, spaceBetween: 20 },
                            768: { slidesPerView: 4, spaceBetween: 30 },
                            1024: { slidesPerView: 9, spaceBetween: 30 },
                            1675: { slidesPerView: 11, spaceBetween: 30 },
                            1920: { slidesPerView: 11, spaceBetween: 30 },
                          }}
                          modules={[Pagination]}
                          className="mySwiper"
                        >
                          {event?.candidates?.map(
                            (candidate: CandidateInterface, index: number) => (
                              <SwiperSlide key={index}>
                                <Candidate
                                  you={
                                    profile?.id === candidate?.id
                                      ? t("events.you.label")
                                      : ""
                                  }
                                  candidate={candidate}
                                />
                              </SwiperSlide>
                            )
                          )}
                        </Swiper>
                      ) : (
                        <div className="flex flex-row justify-center items-center">
                          {t("events.members.label_zero")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </ModalBody>
          </>
        )}
      </ModalContent>
    </Modal>
  );
}
