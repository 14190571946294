import React from "react";
import { Divider } from "@mui/joy";
import CustomUserAvatar from "../../../components/customAvatar/CustomUserAvatar";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import FriendShipButton from "../../../components/networkUserComponent/Buttons/FriendShipButton";
import { useTranslation } from "react-i18next";

export interface visitorInterface {
  isLoading?: boolean;
  src?: string | undefined | null;
  userId?: string | number | undefined | null;
  fullName?: string | undefined;
  job?: string | undefined;
  company?: string | undefined;
  viewedDate?: string | undefined;
  isFriend?: boolean | undefined;
}

export default function Visitor({
  isLoading = false,
  src,
  userId,
  fullName,
  job,
  company,
  viewedDate,
  isFriend,
}: visitorInterface) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  return (
    <div className="w-full flex flex-row max-lg:flex-colitems-center gap-6 py-9">
      <Divider />
      <div
        className="flex flex-row justify-between items-center gap-6 hover:bg-gray-100 p-1"
        onClick={(e) => {
          e.preventDefault();
          navigate(
            AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
              ":locale",
              `${i18n.language}`
            ).replace(":id", userId)
          );
        }}
      >
        {isLoading ? (
          <Skeleton animation="wave" variant="rounded" width={92} height={92} />
        ) : (
          <CustomUserAvatar className="h-[92px] w-[92px]" src={src} />
        )}
        <div className="flex flex-col justify-evenly items-start">
          {isLoading ? (
            <>
              <Skeleton animation="wave" width={200} />
              <Skeleton animation="wave" width={200} />
              <Skeleton animation="wave" width={200} />
            </>
          ) : (
            <>
              <p className="font-xing-bold">{fullName}</p>
              <p>{job}</p>
              <p>{company}</p>
            </>
          )}
        </div>
      </div>

      <div className="flex flex-col items-center gap-6">
        <p className="text-gray-400 italic">
          {isLoading ? <Skeleton animation="wave" width={200} /> : viewedDate}
        </p>
        {isLoading ? (
          <Skeleton
            animation="wave"
            variant="rounded"
            className={"w-full"}
            height={30}
          />
        ) : (
          <FriendShipButton
            user={{
              imageUrl: src,
              id: userId,
              fullName,
              job,
              company,
              viewedDate,
              alreadyFriend: isFriend,
            }}
            showViewProfile
          />
        )}
      </div>
    </div>
  );
}
