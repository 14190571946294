import React from "react";
import AnniversaryIllustration from "../../../components/illustration/AnnivarsaryIllustration";
import BorderedRoundedContainer from "../../../components/containers/BorderedRoundedContainer";
import { useTranslation } from "react-i18next";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { useGetClosestAnniversariesQuery } from "../../../_redux/features/network/services";
import { AppRoutes } from "../../../utils/appRoutes";

export default function ClosestAnniversary() {
  const { t, i18n } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const {
    data: anniversariesData,
    isLoading,
    isFetching,
  } = useGetClosestAnniversariesQuery({ id: profile?.id }, { skip: !profile });
  const { list: users } = anniversariesData || {};

  return (
    <BorderedRoundedContainer className="flex flex-row justify-start items-center gap-3 first-letter:uppercase font-xing">
      {" "}
      {isLoading || isFetching ? (
        <div className="w-full flex flex-row justify-center items-center">
          <p>{t("loading.label")}</p>
        </div>
      ) : (
        <>
          <div>
            <AnniversaryIllustration primary="#ffb836" />
          </div>

          <div className="flex flex-wrap justify-around items-start first-letter:uppercase gap-1">
            {users?.length > 0 ? (
              <>
                <h1 className="font-xing text-lg first-letter:uppercase">
                  {t("its.the.birthday.of.label")}
                </h1>
                {users?.map((user, i) => (
                  <h1 className="font-xing-bold text-lg">
                    {" "}
                    {i === 0 ? " " : i + 1 === user?.length ? " et " : " , "}
                    <a
                      href={AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
                        ":locale",
                        `${i18n.language}`
                      ).replace(":id", user?.id)}
                      aria-label={user?.fullname}
                      title={user?.fullname}
                    >
                      {user?.fullname}
                    </a>
                  </h1>
                ))}
              </>
            ) : (
              <h1 className="font-xing text-lg">
                {" "}
                {t("no.birthday.today.label")}{" "}
              </h1>
            )}
          </div>
        </>
      )}
    </BorderedRoundedContainer>
  );
}
