export const localLanguages = ["fr", "en", "ar"];

export const deleteLocalFromPath = ({
  pathname,
  locale,
}: {
  pathname: string;
  locale: string;
}) => {
  return pathname
    .split("/")
    .slice(localLanguages.includes(locale || "") ? 2 : 1)
    .join("/");
};
