import React, { useState } from "react";
import {
  profileApi,
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../../_redux/auth/services";
import { useTranslation } from "react-i18next";
import {
  Download,
  Upload,
  GroupOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import AnimatedProfileAvatar from "../../../../../components/animatedProfileAvatar/AnimatedProfileAvatar";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ProfileInformationEditModal from "../../../../../components/modals/Profile/ProfileInformationEditModal";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { uploadCv } from "../../../../../_redux/auth/api";
import { useAppDispatch, useAppSelector } from "../../../../../_redux/hooks";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import CustomSpinner from "../../../../../components/spinner/CustomSpinner";
import { Button } from "@nextui-org/react";

export default function MyProfileHeader() {
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { data: profile } = useGetProfileQuery();
  const { data: cv } = useGetMyCvQuery(profile?.id, { skip: !profile });
  const currentExperience = cv?.experiences?.find((exp: any) => exp?.current);
  const [newCvUrl, setNewCvUrl] = useState<string | null>(null);
  const handleUploadCv = async (event: any) => {
    setIsUploading(true);
    uploadCv(event.target.files[0], cv?.id)
      .then((res) => {
        toast.success(t("profile.header.uploadCv.success"));
        const newLink = cv?.attachementPath?.replace(
          /[^/]*$/,
          res.data.filename
        );
        setNewCvUrl(newLink);
        dispatch(profileApi.util.invalidateTags(["cv"]));
      })
      .catch((error) => {
        console.error(error);
        toast.error(t("profile.header.uploadCv.error"));
      })
      .finally(() => setIsUploading(false));
  };

  return (
    <div className="relative w-full flex flex-col justify-between h-[500px]">
      <div
        className="flex-1 rounded-t-xl"
        style={{
          backgroundImage: "url('/images/prototype-profile-background.png')",
          backgroundSize: "100%",
          backgroundPosition: "center end",
          backgroundRepeat: "none",
        }}
      />
      <div className="flex-1">
        <div className="relative flex flex-col justify-between">
          <div className="relative w-full h-full flex flex-col justify-between items-start p-6">
            <div className="absolute top-[-97px] max-lg:top-[-130px] max:md:start-[50%] max:md:translate-x-[50%] z-10">
              <AnimatedProfileAvatar />
            </div>
            <div className="w-full max-lg:pt-[50px] lg:ps-[214px] flex flex-wrap justify-between items-start gap-3">
              <div className="w-full flex flex-wrap justify-between items-start">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-row justify-start items-center gap-3">
                    <p className="text-2xl bg-gray font-xing-bold">
                      {profile?.fullname}
                    </p>
                    <div className="flex justify-stretch">
                      <h1 className="text-xs bg-gray-200 font-xing-bold px-2 py-1 flex justify-stretch rounded-sm">
                        {t("profile.header.standard.label")}
                      </h1>
                    </div>
                    <ProfileInformationEditModal
                      key={1}
                      isIconOnly={true}
                      color="default"
                      variant="flat"
                      radius="full"
                      size="sm"
                      className="p-0"
                      icon={<EditOutlinedIcon height={10} width={10} />}
                    />
                  </div>
                  <div className="flex flex-col justify-between items-start gap-3">
                    <div className="flex flex-col justify-start items-start gap-3">
                      <div className="flex flex-col justify-between items-start gap-1">
                        <div className="flex flex-row justify-start gap-3">
                          <WorkOutlineOutlinedIcon height={18} width={18} />
                          <div className="flex flex-row justify-start gap-1 items-center">
                            {currentExperience?.jobTitle && (
                              <b>{currentExperience?.jobTitle} </b>
                            )}
                            {currentExperience?.companyName && (
                              <p>, {currentExperience?.companyName} </p>
                            )}
                          </div>
                        </div>
                        {profile?.address && (
                          <div className="flex flex-row justify-start gap-3">
                            <LocationOnOutlined height={18} width={18} />
                            <p>
                              {profile?.address}{" "}
                              {profile?.country &&
                                ", " + profile?.country?.name}
                            </p>
                          </div>
                        )}
                        <div className="flex flex-row justify-start gap-3 text-wrap max-w-[300px]">
                          <GroupOutlined height={18} width={18} />
                          <p>
                            {t("profile.views.search.label", {
                              count: profile?.nbSearch,
                            })}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap gap-3 items-stretch">
                  <Button
                    color="primary"
                    variant="solid"
                    radius="full"
                    className="text-medium text-black bg-[#c6f16d] font-xing-bold rounded-full py-3 px-4 w-full"
                    disabled={isUploading}
                    isLoading={isUploading}
                    spinner={<CustomSpinner />}
                    onClick={() => {
                      document.getElementById("upload-cv")?.click();
                    }}
                  >
                    <Upload />
                    <span className="text-lg font-semibold">
                      {t("profile.header.uploadCv.label")}
                    </span>
                    <input
                      hidden
                      type="file"
                      id="upload-cv"
                      accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      className="absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                      onChange={cv?.id ? handleUploadCv : () => ""}
                    />
                  </Button>
                  {cv?.attachementPath && (
                    <a
                      href={`${process.env.REACT_APP_API_BASE_URL}/${
                        newCvUrl ?? cv?.attachementPath
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button
                        color="primary"
                        variant="solid"
                        radius="full"
                        className="w-full text-medium text-[#c6f16d] bg-black font-xing-bold rounded-full py-3 px-4"
                        spinner={<CustomSpinner />}
                      >
                        <Download />
                        <span className="text-lg font-semibold">
                          {t("profile.header.downloadCv.label")}
                        </span>
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
