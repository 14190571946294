import React from "react";
import {CvXSkillInterface} from "../../../../../_redux/features/skills/cvXSkillInterface";

export default function SkillItem({skill}: { skill: CvXSkillInterface }) {


    return <div className="max-h-[44px] group flex p-2 items-center rounded-full bg-white border-solid border-2 border-[#bcc9d1] text-black font-medium">
        <div className="text-[#1d2124]">
            {skill?.competence?.name} ({skill?.level})
        </div>
    </div>;
}