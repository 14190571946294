import React from "react";
import { useTranslation } from "react-i18next";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../../_redux/auth/services";
import Loading from "../../../../../Loading";
import ProfileResumeEditModal from "../../../../../components/modals/Profile/ProfileResumeEditModal";
import ColoredTitledContainer from "../../../../../components/containers/ColoredTitledContainer";
import { Edit } from "@mui/icons-material";
import ResumeIllustration from "../../../../../components/illustration/ResumeIllustration";

export default function MySummary() {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const {
    data: cv,
    isLoading: isCvLoading,
    isFetching: isCvFetching,
  } = useGetMyCvQuery(profile?.id, { skip: !profile });

  if (isCvLoading) {
    return <Loading />;
  }

  return (
    <ColoredTitledContainer
      title={t("summary.label")}
      className="p-3 flex flex-col justify-between gap-1"
      isLoading={isCvLoading || isCvFetching}
      buttons={[
        <ProfileResumeEditModal
          key={1}
          isIconOnly={true}
          color="default"
          variant="flat"
          radius="full"
          size="sm"
          className="p-0"
          icon={<Edit height={10} width={10} />}
        />,
      ]}
    >
      {cv?.careerObjective ? (
        <div className="w-full flex flex-row justify-between items-center">
          <p className="text-justify text-md font-xing-bold first-letter:capitalize">
            {cv?.careerObjective}
          </p>
        </div>
      ) : (
        <div className="w-full flex flex-row justify-evenly items-center gap-3">
          <ResumeIllustration primary="#ffb836" secondary="#d9d9d9" />
          <div className="flex flex-col justify-between items-start gap-3">
            <h1 className="font-xing-bold">
              {t("profile.summary.who.are.you.what.defines.you.label")}
            </h1>
            <p>
              {t(
                "profile.summary.present.yourself.in.your.best.light.to.visitors.to.your.profile.label"
              )}
            </p>
            <ProfileResumeEditModal
              isIconOnly={false}
              variant="bordered"
              color="primary"
              radius="full"
              className="font-xing-bold gap-2 h-[auto] px-4 py-2 w-full sm:w-auto !whitespace-normal flex flex-row items-center "
              text={t("profile.summary.add.brief.presentation.label")}
              icon={<Edit height={10} width={10} />}
            />
          </div>
        </div>
      )}
    </ColoredTitledContainer>
  );
}
