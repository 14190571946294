import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";

interface ArticleItemInterface {
  id: number;
  index: number;
  picture: string;
  slug: string;
  title: string;
  description: string;
}

export default function ArticleItem({
  id,
  index,
  picture,
  slug,
  title,
  description,
}: ArticleItemInterface) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const seeArticleDetails = () =>
    navigate(
      AppRoutes.PRIVATE_BLOG_ARTICLE_DETAILS.replace(
        ":locale",
        `${i18n.language}`
      ).replace(":id", id)
    );

  return (
    <div
      key={index}
      className={`max-2xl:w-1/2 xl:w-1/2 max-lg:w-1/2 md:w-1/2" max-md:w-full ${
        index > 0 ? "w-1/2" : "w-full"
      } flex flex-col justify-between items-start gap-3 p-3`}
    >
      <div className="w-full">
        <img
          className="object-cover h-[190px] w-full"
          src={`${process.env.REACT_APP_MEDIA_BASE_URL}${picture}`}
          onError={(e) => {
            e.target.src = "/images/prototype-event-background.jpg";
          }}
          alt={slug}
        />
      </div>
      <div className="flex flex-col justify-between items-start gap-3">
        <p className="shrink-0 font-xing-bold items-center text-start text-xl first-letter:uppercase line-clamp-3">
          {title}
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: description || "" }}
          className="grow font-xing text-justify text-md first-letter:uppercase line-clamp-3"
        />
        <a
          href="https://www.emploipartner.com"
          className="shrink-0 font-xing text-start text-sm text-[#dbe2e6]"
        >
          <span className="text-yellow-400">Emploi</span>Partner
        </a>
      </div>
      <div className="w-full flex flex-row justify-end">
        <p
          onClick={seeArticleDetails}
          className="font-xing-bold hover:underline text-black cursor-pointer"
        >
          {t("find.jobs.see.more.label")}
        </p>
      </div>
    </div>
  );
}
