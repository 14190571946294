import React from "react";
import JobsLoading from "../loading";
import JobComponentItem, {JobInterface} from "../../../../components/jobComponent/jobComponentItem/jobComponentItem";
import TitledContainer from "../../../../components/containers/TitledContainer";
import {useGetSuggestionJobsQuery} from "../../../../_redux/features/jobs/services";
import {useTranslation} from "react-i18next";

export default function SuggestionOffers() {

    const {t} = useTranslation()
    const {data, isLoading} = useGetSuggestionJobsQuery({limit: 9})
    const {list: suggestionsJobs} = data || {}

    return (
        <TitledContainer
            title={t("find.jobs.job.offer.suggestions.label")}
            subtitle={t("find.jobs.your.search.history.and.your.professional.preferences.label")}
        >
            {
                isLoading ?
                    <JobsLoading />
                    :
                    suggestionsJobs?.length <= 0 ?
                        <div>
                            {t("job.label", {count: suggestionsJobs?.length})}
                        </div>
                        :
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center justify-center">
                            {suggestionsJobs?.map((job: JobInterface) =>
                                <JobComponentItem
                                    key={job?.id}
                                    job={job}
                                />
                            )}
                        </div>
            }
        </TitledContainer>
    )

}