import React from "react";
import TitledContainer from "../../../components/containers/TitledContainer";
import {useTranslation} from "react-i18next";
import {Container, FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import BackButton from "../../../components/backButton/BackButton";

export default function PrivateLanguage() {

    const {t, i18n} = useTranslation()

    return (
        <Container maxWidth="md">
            <div className="w-full flex flex-col justify-start items-start gap-3 min-h-screen">
                <BackButton />
                <TitledContainer
                    title={t("language.label")}
                    className="flex flex-col gap-3"
                >
                    <p>
                        { t("in.which.language.would.you.like.to.use.label") }
                    </p>
                    <FormControl>
                        <RadioGroup
                            color="primary"
                            aria-labelledby="language-radio-buttons-group-label"
                            defaultValue={i18n.language}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel className="select-none" onClick={() => { i18n.changeLanguage('fr') }} value="fr" control={<Radio color="primary" />} label="Francais" />
                            <FormControlLabel className="select-none" onClick={() => { i18n.changeLanguage('ar') }} value="ar" control={<Radio color="primary" />} label="Arabe" />
                            <FormControlLabel className="select-none" onClick={() => { i18n.changeLanguage('en') }} value="en" control={<Radio color="primary" />} label="Anglais" />
                        </RadioGroup>
                    </FormControl>
                </TitledContainer>
            </div>
        </Container>
    )
}