import React from "react";
import {Stack} from "@mui/joy";
import {LineChart} from "@mui/x-charts";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import {useGetVisitsEachDayQuery} from "../../../_redux/features/network/services";
import moment from "moment";
import {Skeleton} from "@mui/material";

export default function VisitorsLineChart({lastDays = 90, height = 320}) {

    const {data: profile} = useGetProfileQuery()
    const {data, isFetching} = useGetVisitsEachDayQuery({
        id: profile?.id,
        filters: {last_days: lastDays}
    }, {skip: !profile})

    const sx = {

        '.MuiMarkElement-faded': {
            stroke: '#000',
            strokeWidth: 3,
        },
        '.MuiMarkElement-highlighted': {
            stroke: '#000',
            strokeWidth: 3,
        },
        '.MuiChartsAxis-tickContainer': {
            stroke: '#000',
            strokeWidth: 0,
        },
        '.MuiChartsAxis-line': {
            stroke: '#000',
            strokeWidth: 0,
        },
        '.MuiLineElement-root': {
            stroke: '#000',
            strokeWidth: 3,
        },
        '.MuiMarkElement-root': {
            stroke: '#000',
            scale: '1.5',
            fill: '#fff',
            strokeWidth: 2,
        },
    }

    if (!data) {
        return <></>
    }

    const labels = data?.map((item: object) => moment(item.viewedDate).fromNow())
    const yAxis = data?.map((item: object) => item.nbViews)

    return (
        <Stack sx={{width: '100%'}}>
            {isFetching ?
                <Skeleton className={"w-full"} variant="rectangular" height={height}/>
                :
                <LineChart
                    series={[
                        {curve: "linear", data: [...yAxis]},
                    ]}
                    xAxis={[{scaleType: 'point', data: [...labels]}]}
                    yAxis={[{data: [...yAxis], hideTooltip: true}]}
                    height={height}
                    sx={sx}
                />
            }
        </Stack>
    )
}
