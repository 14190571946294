import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import TitledContainer from "../../../components/containers/TitledContainer";
import ColoredContainer from "../../../components/containers/ColoredContainer";
import BorderedRoundedContainer from "../../../components/containers/BorderedRoundedContainer";
import InvitationIllustration from "../../../components/illustration/InvitationIllustration";
import SubscriptionModal from "../../../components/modals/SubscriptionModal/SubscriptionModal";
import DevelopYourNetwork from "./DevelopYourNetwork";
import YourNetwork from "./YourNetwork";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import {
  useAcceptInvitationMutation,
  useGetMyInvitationsQuery,
} from "../../../_redux/features/network/services";
import MyNetworkForm from "./MyNetworkForm";
import CustomUserAvatar from "../../../components/customAvatar/CustomUserAvatar";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import { CircularProgress } from "@nextui-org/react";
import ClosestAnniversary from "./ClosestAnniversary";

export default function Network() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: profile } = useGetProfileQuery();
  const [acceptAction, acceptTriggers] = useAcceptInvitationMutation();

  const { data: invitationData } = useGetMyInvitationsQuery(
    {
      id: profile?.id,
      filters: { status: "PENDING", _page: 1 },
    },
    { skip: !profile }
  );
  const { totalItems, list: invitations } = invitationData || {};

  const invitation = invitations?.[0] || {};
  const { invitedBy } = invitation || {};

  return (
    <>
      <Container maxWidth="md">
        <div className="w-full flex flex-col justify-start items-center gap-6">
          <TitledContainer
            title={t("network.label")}
            className="flex flex-col justify-start gap-6"
          >
            <MyNetworkForm />

            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
              <BorderedRoundedContainer className="flex flex-row justify-between items-center gap-3">
                <div>
                  <InvitationIllustration primary="#ffb836" />
                </div>
                <div className="flex flex-col justify-around items-start gap-2">
                  <h1
                    className={`font-xing text-lg ${
                      totalItems ? "font-extrabold" : ""
                    }`}
                  >
                    {t("invitations.received.label", { count: totalItems })}
                  </h1>
                  {invitedBy && (
                    <div className="flex flex-row w-full gap-2">
                      <div>
                        <CustomUserAvatar
                          src={invitedBy?.imageUrl}
                          className="h-[48px] w-[48px]"
                        />
                      </div>
                      <div
                        className={
                          "flex flex-row justify-between items-center w-full"
                        }
                      >
                        <div>
                          <p>{invitedBy?.fullname}</p>
                        </div>

                        <div>
                          <Fab
                            className="shadow-none"
                            size="small"
                            aria-label={t("accept.label")}
                            disabled={
                              acceptTriggers?.isLoading ||
                              acceptTriggers?.isFetching
                            }
                            onClick={() => acceptAction(invitation?.id)}
                            color="primary"
                          >
                            {acceptTriggers?.isLoading ||
                            acceptTriggers?.isFetching ? (
                              <CircularProgress color="inherit" size={10} />
                            ) : (
                              <CheckIcon />
                            )}
                          </Fab>
                        </div>
                      </div>
                    </div>
                  )}
                  <p
                    className="text-sm cursor-pointer"
                    onClick={() =>
                      navigate(
                        AppRoutes.PRIVATE_NETWORK_REQUESTS.replace(
                          ":locale",
                          `${i18n.language}`
                        )
                      )
                    }
                  >
                    {t("invitations.see.all")}
                  </p>
                </div>
              </BorderedRoundedContainer>

              <ClosestAnniversary />
            </div>
          </TitledContainer>
        </div>
      </Container>

      <YourNetwork />

      <Container maxWidth="md">
        <div className="w-full flex flex-col justify-start items-center gap-6">
          <ColoredContainer className="flex flex-col justify-between gap-3 p-5">
            <div>
              <span className="font-xing-bold rounded-md bg-[#940f62] text-white text-xs py-1 px-2">
                ProJobs
              </span>
            </div>
            <div>
              <b className="font-xing-bold">{t("sidebar.opt.for.pro.jobs")}</b>
              <p className="font-xing">
                Avec ProJobs, boostez votre visibilité et soyez trouvé(e) plus
                facilement.
              </p>
            </div>
            <SubscriptionModal />
          </ColoredContainer>
        </div>
      </Container>

      <DevelopYourNetwork />
    </>
  );
}
