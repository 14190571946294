import React from "react";
import { useTranslation } from "react-i18next";
import ColoredTitledContainer from "../../../../components/containers/ColoredTitledContainer";
import { useGetCvQuery } from "../../../../_redux/auth/services";
import { useParams } from "react-router-dom";
import { useGetSingleCandidateQuery } from "../../../../_redux/features/network/services";
import { Skeleton } from "@mui/material";

export default function Hobbies() {
  const { id } = useParams();
  const { data: profile } = useGetSingleCandidateQuery(parseInt(id), {
    skip: !id,
  });
  const { data: cv, isLoading: isCvLoading } = useGetCvQuery(profile?.id, {
    skip: !profile?.id,
  });
  const { t } = useTranslation();

  if (isCvLoading) {
    return (
      <div className="flex flex-col justify-between gap-3">
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"70%"}
          height={30}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"80%"}
          height={20}
        />
      </div>
    );
  }

  if (!cv?.hobby || cv?.hobby === "") {
    return null;
  }

  return (
    <ColoredTitledContainer title={t("center.of.interest.label")}>
      <div className=" w-full flex flex-row justify-between items-center p-3">
        <p>{cv?.hobby}</p>
      </div>
    </ColoredTitledContainer>
  );
}
