import {Button} from "@nextui-org/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAcceptInvitationMutation} from "../../../_redux/features/network/services";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import {CandidateInterface} from "../../../_redux/features/events/interfaces";

export default function AcceptInvitationButton({user}: {user: CandidateInterface}) {

    const {t} = useTranslation()
    const [acceptAction, acceptTriggers] = useAcceptInvitationMutation()
    const {isLoading: isProfileLoading} = useGetProfileQuery()

    return (
        <Button
            radius="full"
            variant="bordered"
            color="success"
            disabled={isProfileLoading || acceptTriggers?.isLoading || acceptTriggers?.isFetching}
            isLoading={acceptTriggers?.isLoading || acceptTriggers?.isFetching}
            className="font-xing-bold text-sm"
            onClick={() => acceptAction(user?.friendShip?.id)}
        >
            {t("accept.label")}
        </Button>
    )
}