import { createSlice } from "@reduxjs/toolkit";
import { JobInterface } from "../../../components/jobComponent/jobComponentItem/jobComponentItem";
import { RootState } from "../../store";
import { fetchJobs } from "./actions";
import { urlHelpers } from "../../../_helpers/urlHelpers";

export interface JobSearchInterface {
  _page?: number;
  keyword?: string;
  location?: string;
  [key: `order[${string}]`]: "asc" | "desc";
  alertId?: number;
}
export interface PageFilterInterface {
  _page?: number;
}
export interface PaginationInterface {
  first: PageFilterInterface | undefined;
  current: PageFilterInterface | undefined;
  next: PageFilterInterface | undefined;
  last: PageFilterInterface | undefined;
}

export const STATUS_IDLE = "idle";
export const STATUS_LOADING = "loading";
export const STATUS_FAILED = "failed";
export const STATUS_SUCCEED = "succeeded";

export interface JobsState {
  search: JobSearchInterface;
  list: JobInterface[] | undefined;
  item: JobInterface | undefined;
  pagination: PaginationInterface | undefined;
  totalItems: number | undefined;
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | undefined;
}

const initialState: JobsState = {
  search: {
    keyword: undefined,
    location: undefined,
    "order[publishedDate]": "asc",
    _page: 1,
  },
  list: undefined,
  item: undefined,
  pagination: undefined,
  totalItems: 0,
  status: STATUS_IDLE,
  error: undefined,
};

export const jobs = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      const { search } = action.payload;
      state.search = search;
    },
    setJobs: (state, action) => {
      const { jobs, totalItems } = action.payload;
      return { ...state, list: jobs, totalItems };
    },
    setPagination: (state, action) => {
      const { pagination } = action.payload;
      return { ...state, pagination };
    },
    setSingleJob: (state: JobsState, action) => {
      const { job } = action.payload;
      return { ...state, item: job };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state: JobsState) => {
        state.status = STATUS_LOADING;
      })
      .addCase(
        fetchJobs.fulfilled,
        (state: JobsState, action: { payload: any }) => {
          const {
            "hydra:member": data,
            "hydra:view": pagination,
            "hydra:totalItems": totalItems,
          } = action.payload || {};

          const {
            "hydra:first": first,
            "hydra:last": last,
            "hydra:next": next,
            "@id": current,
          } = pagination || {};

          state.status = STATUS_SUCCEED;
          state.list = data;
          state.totalItems = totalItems;
          state.pagination = {
            first: urlHelpers(first),
            last: urlHelpers(last),
            next: urlHelpers(next),
            current: urlHelpers(current),
          };

          state.search = { ...state.search, ...state?.pagination?.current };
        }
      )
      .addCase(fetchJobs.rejected, (state: JobsState, action) => {
        state.status = STATUS_FAILED;
        state.error = action?.error?.message;
      });
  },
});

export const selectAllJobs = (state: RootState) => state.jobs.list;
export const selectJobsTotalItems = (state: RootState) => state.jobs.totalItems;
export const selectJobsPagination = (state: RootState) => state.jobs.pagination;
export const selectJobsState = (state: RootState): string => state.jobs.status;
export const selectJobsError = (state: RootState) => state.jobs.error;
export const selectJobsSearch = (state: RootState) => state.jobs.search;

export const { setSearch, setJobs, setSingleJob, setPagination } = jobs.actions;

export default jobs.reducer;
