import {Image} from "@nextui-org/react";
import React from "react";

export function NoResultFound ({message = "No Result Found", height = "320"} : {message: string, height: string}) {

    return (
        <div className={`relative flex flex-col justify-center items-center w-full max-w-full min-w-full h-[${height}px] min-h-[${height}px] max-h-[${height}px] border-1 border-gray-100 bg-white overflow-hidden rounded-3xl p-3`}>
            <img height={200} width={200} src="/svg/prototype-icon-no-result.svg" />
            <h1 className="text-xl first-letter:capitalize font-xing-bold">
                { message }
            </h1>
        </div>
    )
}