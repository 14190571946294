import React from "react";
import SkillItem from "./SkillItem";
import NoSkillsFound from "../../../../../components/noResultFound/NoSkillsFound";
import {CvXSkillInterface} from "../../../../../_redux/features/skills/cvXSkillInterface";

export function SkillsList({skills}:  {skills: CvXSkillInterface[]}) {



    return (
        <div className="flex flex-wrap justify-start items-center gap-3">
            {
                skills?.length > 0
                    ?
                    skills.map(skill => <SkillItem skill={skill} key={skill["@id"]}/>)
                    :
                    <NoSkillsFound />
            }
        </div>
    )
}