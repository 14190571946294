import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import React from "react";
import {useNavigate} from "react-router-dom";
import {Skeleton} from "@mui/material";
import SendMessageButton from "../../../../components/networkUserComponent/Buttons/SendMessageButton";
import CustomUserAvatar from "../../../../components/customAvatar/CustomUserAvatar";
import CustomContainer from "../../../../components/containers/CustomContainer";

export default function ContactItem({user, isLoading = false}: { user: any, isLoading?: boolean }) {

    const navigate = useNavigate()
    const viewProfile = () => navigate(`/profile/${user?.id}`)

    return (
        <CustomContainer className="bg-white flex flex-row justify-between items-center gap-3 my-1">
            {
                isLoading
                    ?
                    <Skeleton animation="wave" variant="circular" width={122} height={122}/>
                    :
                    <CustomUserAvatar
                        className="h-[122px] w-[122px] cursor-pointer"
                        src={user?.imageUrl}
                        onClick={viewProfile}
                    />
            }

            <div className="w-full">

                {isLoading ?
                    <Skeleton animation="wave" width={"80%"}/>
                    :
                    <p className="text-md font-xing-bold cursor-pointer"
                       onClick={viewProfile}
                    >
                        {user?.fullname}
                    </p>
                }

                {isLoading ?
                    <Skeleton animation="wave" width={"70%"}/>
                    :
                    <p className="text-sm font-xing">
                        {user?.company?.job}
                    </p>
                }
                {
                    isLoading ?
                        <Skeleton animation="wave" width={"90%"}/>
                        :
                        <>
                            {
                                user?.description &&
                                <div>
                                    <p className="font-xing text-sm text-gray-700">
                                        <BoltOutlinedIcon className={"text-fuchsia-300"}/>
                                        {user?.description}
                                    </p>
                                </div>
                            }
                        </>
                }
            </div>

            <div className="flex flex-row justify-center items-center">
                {
                    isLoading ?
                        <Skeleton animation="wave" variant="rounded" width={80} height={30}/>
                        :
                        <SendMessageButton user={user}/>
                }
            </div>

        </CustomContainer>
    )
}
