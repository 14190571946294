import React, { useState } from "react";
import { Field, Form, Formik } from "formik";
import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../_redux/store";
import { setSearch } from "../../../_redux/features/jobs/jobsSlice";
import { SearchIcon } from "@nextui-org/shared-icons";
import { Input as NextInput } from "@nextui-org/react";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import { useNavigate, useLocation } from "react-router-dom";
import { removeEmptyProperty } from "../../../_redux/features/jobs/services";
import { AppRoutes } from "../../../utils/appRoutes";
import { useSelector } from "react-redux";
import { selectToken } from "../../../_redux/auth/authSlice";

export interface Values {
  keyword: string;
  location: string;
}

export function JobSearchPublicForm() {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  const dispatch = useAppDispatch();

  const sx = {
    "& .MuiSvgIcon-root": {
      zIndex: 1,
    },

    "& .PrivateSwitchBase-input": {
      width: "auto",
      height: "auto",
      top: "auto",
      left: "auto",
      opacity: "1",
      visibility: "hidden", // optional

      "&::before": {
        content: '""',
        position: "absolute",
        background: "white",
        height: "100%",
        width: "100%",
        visibility: "visible", // optional
      },
    },
  };

  let initialValues = {
    keyword: "",
    location: "",
  };

  const handelSubmit = async (values: Values) => {
    const params = removeEmptyProperty({
      ...values,
      _page: 1,
      alertId: undefined,
    });
    dispatch(setSearch({ search: params }));
    return navigate(
      isAuth
        ? AppRoutes.PRIVATE_JOBS_SEARCH[0].replace(
            ":locale",
            `${i18n.language}`
          )
        : AppRoutes.JOBS[0].replace(":locale", `${i18n.language}`)
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handelSubmit}>
      {() => (
        <Form className="relative w-full">
          <div className="w-full flex flex-row max-md:flex-col justify-center items-center gap-6">
            <div className="w-full max-md:max-w-96 md:max-w-80 grow">
              <Field
                aria-describedby=""
                id="keyword"
                radius="full"
                startContent={<SearchIcon height={24} width={24} />}
                classNames={{
                  input: ["text-lg placeholder:text-lg"],
                  innerWrapper: "bg-white h-[50px] text-lg",
                  inputWrapper: [
                    "h-[60px]",
                    "bg-white",
                    "!cursor-text",
                    "border-2 hover:border-2 border-white hover:border-black active:border-2 focus:border-2",
                  ],
                }}
                variant="bordered"
                fullWidth={true}
                as={NextInput}
                type="search"
                name="keyword"
                placeholder={t("search.keyword")}
              />
            </div>
            <div className="w-full max-md:max-w-96 md:max-w-80 grow">
              <Field
                id="location_search_input"
                aria-describedby=""
                radius="full"
                startContent={<PlaceOutlinedIcon height={24} width={24} />}
                classNames={{
                  input: ["text-lg placeholder:text-lg"],
                  innerWrapper: "bg-white h-[50px] text-lg",
                  inputWrapper: [
                    "h-[60px]",
                    "bg-white",
                    "!cursor-text",
                    "border-2 hover:border-2 border-white hover:border-black active:border-2 focus:border-2",
                  ],
                }}
                variant="bordered"
                fullWidth={true}
                as={NextInput}
                type="search"
                name="location"
                placeholder={t("search.location")}
              />
            </div>
            <div className="w-full max-md:max-w-96 md:max-w-52 flex-1">
              <Button
                type="submit"
                fullWidth={true}
                className="h-[60px] font-xing-bold text-lg"
                radius="full"
                variant="solid"
                color="primary"
              >
                {t("find.jobs.find.offers.label")}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
