import React from "react";
import CustomAvatar from "../../customAvatar/CustomAvatar";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import { useTranslation } from "react-i18next";

export default function SidebarAvatar() {
  const navigate = useNavigate();
  const { data: profile } = useGetProfileQuery();
  const { i18n } = useTranslation();
  return (
    <div
      className="flex max-lg:flex-col flex-row justify-start items-center gap-3 cursor-pointer"
      onClick={() =>
        navigate(
          AppRoutes.PRIVATE_PROFILE[0].replace(":locale", `${i18n.language}`)
        )
      }
    >
      <CustomAvatar
        src={profile?.imageUrl}
        className="h-[68px] w-[68px]"
        strokeWidth={3}
      />

      <div className="flex flex-col justify-between max-lg:items-center">
        <div className="flex justify-stretch">
          <h1 className="text-xs bg-gray-200 font-xing-bold px-2 py-1 flex justify-stretch rounded-sm">
            Standard
          </h1>
        </div>
        <p className="text-sm bg-gray font-xing-bold">{profile?.fullname}</p>
      </div>
    </div>
  );
}
