import React from "react";
import { Skeleton } from "@mui/material";
type sideChatSkeletonsModel = {
  count?: number;
};

export default function SideChatSkeletons({
  count = 5,
}: sideChatSkeletonsModel) {
  return [...Array(count).keys()].map((item) => (
    <div key={item} className="flex w-full gap-3">
      <div className="flex-none">
        <Skeleton variant="rounded" width={45} height={45} />
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <Skeleton variant="rounded" width={"60%"} height={12} />
        <Skeleton variant="rounded" width={"50%"} height={10} />
        <Skeleton variant="rounded" width={"40%"} height={10} />
      </div>
    </div>
  ));
}
