import React from "react";
import { mediaRoutes } from "../../utils/mediaRoutes";

interface CustomAvatarInterface {
  className?: string;
  src?: string | null | undefined;
  stroke?: string;
  strokeWidth?: number;
  strokeDashoffset?: number | null | undefined;
  strokeDasharray?: number;
}

export default function CustomAvatar({
  className,
  src,
  stroke,
  strokeWidth = 6,
  strokeDashoffset = 2760,
  strokeDasharray = 1000,
  ...props
}: CustomAvatarInterface) {
  const addDefaultImg = (e: any): void => {
    e.target.src = "/svg/prototype-user-placeholder.svg";
  };
  return (
    <div {...props} className={className}>
      <div
        style={{
          display: "block",
          height: "100%",
          maskImage: "url('/svg/prototype-super-ellipse-mask-.svg')",
          maskPosition: "center center",
          maskRepeat: "no-repeat",
          position: "relative",
          width: "100%",
        }}
      >
        <svg viewBox="0 0 256 256" className="absolute z-1">
          <path
            d="M18.947 18.947C5.627 32.267 1 61.517 1 128s4.626 95.732 17.947 109.053C32.267 250.373 61.517 255 128 255s95.732-4.626 109.053-17.947C250.373 223.733 255 194.483 255 128s-4.626-95.732-17.947-109.053C223.733 5.627 194.483 1 128 1S32.268 5.626 18.947 18.947z"
            fill="transparent"
            vectorEffect="non-scaling-stroke"
            style={{
              stroke: stroke ?? "#f4f4f5",
              strokeWidth: strokeWidth,
              strokeDashoffset: strokeDashoffset as number,
              strokeDasharray: 1000,
            }}
          ></path>
        </svg>

        <img
          style={{
            aspectRatio: "1 / 1",
            borderRadius: "4px",
            display: "block",
            height: "100%",
            margin: "0px",
            padding: "0px",
            position: "relative",
            width: "100%",
            zIndex: "calc(-1)",
          }}
          alt="user-profile"
          aria-label="user-profile"
          onError={addDefaultImg}
          src={
            src
              ? `${mediaRoutes.USER_PROFILE}${src}`
              : "/svg/prototype-user-placeholder.svg"
          }
        />
      </div>
    </div>
  );
}
