import React from "react";
import { SvgIllustrationInterface } from "./AttentesProIllustration";
export default function RecruitersIllustration({
  primary,
  secondary,
}: SvgIllustrationInterface) {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="92px"
      height="92px"
      data-xds="IllustrationPremiumSpotRecruiter"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.034 3.862c10.642 0 23.985 10.82 23.985 24.166 0 5.494-1.82 10.56-4.885 14.619l16.493 17.088a2.529 2.529 0 01-.007 3.52l-.127.121a2.451 2.451 0 01-3.34-.075L43.18 46.768a23.776 23.776 0 01-15.145 5.426c-13.247 0-23.985-10.819-23.985-24.166S14.787 3.862 28.034 3.862z"
        fill="#FFFFFE"
      ></path>
      <path
        d="M40.167 15.267c4.297 8.01 7.544 21.789 2.718 25.335-.535.393-1.242.74-2.059 1.033l-.055 5.62-1.5-.015.052-5.159c-4.189 1.02-9.943.775-11.64-1.708.596-2.05 1.257-4.107 1.822-5.804l.159-.474c.65-1.934 1.143-3.308 1.213-3.501l.009-.024 1.585.06a2.833 2.833 0 00.207-5.661l-2.475-.09s-10.726-3.257-10.055-13.227c-1.679 1.12-3.035 2.908-4.093 5.28 1.982.2 3.264 1.406 3.277 3.746.011 1.85-.51 3.503-1.466 5.28-.088.166-.18.329-.279.503l-.541.924a.964.964 0 00.38 1.34l.107.05 2.04.785-.539 1.4-2.04-.786a2.464 2.464 0 01-1.32-3.404l.103-.186.359-.61.283-.494.126-.232c.848-1.576 1.296-3 1.287-4.561-.009-1.609-.762-2.233-2.224-2.281h-.145c-.675 1.867-1.2 4.028-1.58 6.454-.666 4.232-.815 8.713-.652 12.808l.047 1.008.03.545 8.737.085-.001.104h2.862l-2.88 1.86-.056 5.8-1.5-.015.06-6.264-8.607-.083-.082-.963-.04-.612-.032-.576c-.222-4.404-.097-9.297.632-13.93.347-2.206.812-4.22 1.403-6.016l-.091.02-.283.074-.236.068-.251.083-.52-1.407.198-.068.121-.039.284-.082a10.188 10.188 0 011.376-.284c1.453-3.583 3.511-6.118 6.262-7.352 2.297-1.904 14.808-3.11 19.503 5.643zM22.894 31.69l1.397.548c-.468 1.19-1.249 2.016-2.242 2.51-.81.402-1.636.54-2.364.525l-.292-.015-.08-.008.185-1.488.05.004a3.64 3.64 0 001.833-.361c.607-.302 1.09-.782 1.42-1.494l.094-.221zm-1.067-9.305c.38.004.69.289.736.656l.006.101-.01 1.002a.75.75 0 01-1.494.087l-.006-.102.01-1.002a.75.75 0 01.758-.742zm-6.206-.06c.38.003.691.288.737.655l.006.102-.01 1.002a.75.75 0 01-1.494.087l-.006-.102.01-1.002a.75.75 0 01.757-.743z"
        fill="#1D2124"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.385 47.01c-3.339-2.29-8.038-3.963-14.624-3.957-8.488.008-15.478 2.619-20.29 6.069-1.41 1.01 3.916 6.868 17.134 6.868s18.541-8.458 17.78-8.98z"
        fill={primary}
      ></path>
      <path
        d="M10.06 10.295c9.846-9.742 25.806-9.742 35.653 0a24.781 24.781 0 012.405 32.56L64 58.57c1.597 1.582 1.808 4.03.348 5.474-1.404 1.39-3.747 1.243-5.34-.192l-.161-.153L42.952 47.97c-9.874 7.3-23.921 6.51-32.893-2.366-9.852-9.75-9.852-25.561 0-35.31zM46.843 44.41a25.462 25.462 0 01-2.322 2.3l15.732 15.567c.86.853 2.072.955 2.688.346.577-.571.516-1.66-.22-2.495l-.13-.136L46.845 44.41zm-2.538-32.693c-9.067-8.972-23.773-8.972-32.84 0a22.785 22.785 0 000 32.466c9.067 8.972 23.773 8.972 32.84 0a22.785 22.785 0 000-32.466z"
        fill="#1D2124"
      ></path>
    </svg>
  );
}
