import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BorderedRoundedContainer from "../../../components/containers/BorderedRoundedContainer";
import NoteIllustration from "../../../components/illustration/NoteIllustration";
import { SearchIcon } from "@nextui-org/shared-icons";
import { Input as NextInput } from "@nextui-org/input";
import { style } from "../jobsSearch/filters/constants";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { useGetUserNetworkQuery } from "../../../_redux/features/network/services";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";

export default function MyNetworkForm() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: profile } = useGetProfileQuery();

  const [onChangeTimer, setOnChangeTimer] = useState<
    NodeJS.Timeout | undefined
  >();

  useEffect(() => {
    return () => {
      clearTimeout(onChangeTimer);
    };
  }, []);

  const onChangeHandler = (value: { keyword: string; _page: number }) => {
    clearTimeout(onChangeTimer);
    const timer = setTimeout(() => {
      navigate(
        AppRoutes.PRIVATE_YOUR_NETWORK.replace(":locale", `${i18n.language}`),
        { state: { filters: value } }
      );
    }, 500);
    setOnChangeTimer(timer);
  };

  const { data: contacts } = useGetUserNetworkQuery(
    { id: profile?.id },
    { skip: !profile }
  );
  const { totalItems: totalContacts } = contacts || {};

  return (
    <BorderedRoundedContainer className="flex flex-row justify-between items-center px-3 py-1">
      <div className="flex-1 flex flex-row justify-start items-center px-3 py-1 gap-3">
        <div className="flex flex-col justify-center items-center">
          <NoteIllustration primary="#ffb836" />
        </div>
        <div className="flex flex-col justify-between items-start">
          <h1
            className="text-lg font-xing cursor-pointer"
            onClick={() =>
              navigate(
                AppRoutes.PRIVATE_YOUR_NETWORK.replace(
                  ":locale",
                  `${i18n.language}`
                )
              )
            }
          >
            {t("you.have")}{" "}
            <span className="font-xing-bold">
              {t("network.contacts.label", { count: totalContacts })}
            </span>
          </h1>
        </div>
      </div>

      <div className="flex-1">
        <div className="w-full flex flex-col justify-start gap-9">
          <div className="w-full flex flex-row max-md:flex-col justify-center items-center gap-6">
            <div className="w-full grow ">
              <NextInput
                radius="full"
                startContent={<SearchIcon height={24} width={24} />}
                classNames={style}
                variant="bordered"
                fullWidth={true}
                type="search"
                name="keyword"
                onChange={(e) =>
                  onChangeHandler({ keyword: e.target.value, _page: 1 })
                }
                placeholder={t("network.contacts.yours.search")}
              />
            </div>
          </div>
        </div>
      </div>
    </BorderedRoundedContainer>
  );
}
