import React from 'react';

interface BorderedRoundedContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

const BorderedRoundedContainer: React.FC<BorderedRoundedContainerProps> = ({ className, children, ...props }) => {
    const customClassName = "w-full rounded-2xl border-2 border-[#bcc9d1] p-3";

    // Combine custom className with any className passed as props
    const combinedClassName = `${customClassName} ${className || ''}`;

    return (
        <div className={combinedClassName} {...props}>
            {children}
        </div>
    );
}

export default BorderedRoundedContainer;