import React from "react";
import {Edit} from "@mui/icons-material";
import ProfessionalExpectationsIllustration from "../../../../../components/illustration/AttentesProIllustration";
import TitledBorderedRoundedContainer from "../../../../../components/containers/TitledBorderedRoundedContainer";
import {useTranslation} from "react-i18next";
import SelectionCriteriaEditModal from "../../../../../components/modals/Profile/SelectionCriteriaEditModal";
import {useGetMyCvQuery, useGetProfileQuery} from "../../../../../_redux/auth/services";

export default function MyProfessionalExpectations() {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const {isLoading: isCvLoading, isFetching: isCvFetching} = useGetMyCvQuery(profile?.id, {skip: !profile})

    return (
        <TitledBorderedRoundedContainer
            className="p-3 flex flex-col justify-between gap-1"
            title={t("profile.professional.expectations.your.professional.expectations.label")}
            buttons={[
                <SelectionCriteriaEditModal
                    key={1}
                    isIconOnly={true}
                    color="default"
                    variant="flat"
                    radius="full"
                    size="sm"
                    icon={<Edit height={10} width={10}/>}
                />
            ]}
            isLoading={isCvLoading || isCvFetching}

        >
            <div className="flex flex-row max-lg:flex-col-reverse justify-between items-start">
                <div className="flex flex-col justify-between gap-3">
                    <div>
                        <p className="text-xs text-gray-600">
                            {t("profile.professional.expectations.your.professional.situation.label")}
                        </p>
                    </div>
                    <div className="flex flex-row justify-start">
                        <h1 className="text-md font-xing-bold">
                            {t("profile.professional.expectations.not.looking.for.a.job.but.open.to.offers.label")}
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start">
                        <h1 className="text-md">
                            {t("profile.professional.expectations.let.us.and.recruiters.know.what.you.want.from.your.next.job.to.receive.better.recommendations.label")}
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start">
                        <p className="text-md">
                            {t("profile.professional.expectations.visible.to.label")}
                            <b>
                                {t("profile.professional.expectations.internal.recruitment.managers.work.label")}
                            </b>
                        </p>
                    </div>
                    <div className="flex flex-row justify-start">
                        <SelectionCriteriaEditModal
                            variant="bordered"
                            color="primary"
                            radius="full"
                            className="font-xing-bold"
                            icon={<Edit height={10} width={10}/>}
                            text={t("profile.professional.expectations.edit.job.preferences.label")}
                        />
                    </div>
                </div>
                <div>
                    <ProfessionalExpectationsIllustration primary="#ffb836" secondary="#d9d9d9"/>
                </div>

            </div>
        </TitledBorderedRoundedContainer>
    )
}