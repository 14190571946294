import React, { useEffect, useState } from "react";
import { useGetFunctionTypesQuery } from "../../../../_redux/features/functionType/services";
import { useFormikContext } from "formik";
import CustomCheckbox from "../../../../components/_form/_field/Checkbox";
import { useTranslation } from "react-i18next";
import DropDownFilter from "./DropDownFilter";
import { useSelector } from "react-redux";
import { selectJobsSearch } from "../../../../_redux/features/jobs/jobsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../../utils/appRoutes";
import { selectToken } from "../../../../_redux/auth/authSlice";

export default function FunctionFilter() {
  const { functionFilterParams } = useParams();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { values, handleChange, submitForm, setFieldValue } =
    useFormikContext();
  const { data: functionTypesData, isLoading } = useGetFunctionTypesQuery({
    pagination: false,
  });
  const { list: functionTypes } = functionTypesData || {};
  const search = useSelector(selectJobsSearch);
  useEffect(() => {
    if (functionFilterParams) {
      navigate(
        AppRoutes[isAuth ? "PRIVATE_JOBS_SEARCH" : "JOBS"][0].replace(
          ":locale",
          `${i18n.language}`
        ),
        { replace: true }
      );
    }
  }, [isAuth, navigate, i18n.language, functionFilterParams]);
  return (
    <DropDownFilter
      hasBadge={values?.function?.length}
      isLoading={isLoading}
      label={t("profile.function.label")}
    >
      {functionTypes && (
        <div className="flex flex-col justify-between px-3">
          {functionTypes?.map((func: any, index: number) => (
            <CustomCheckbox
              key={index}
              disabled={isLoading}
              defaultChecked={values?.function?.find((f: any) => f == func?.id)}
              value={func?.id}
              onChange={handleChange}
              label={func?.name}
              name="function"
            />
          ))}
        </div>
      )}
    </DropDownFilter>
  );
}
