import {createSlice} from '@reduxjs/toolkit';
import {RootState} from "../../store";
import {fetchEvents, getParamsFromUrl} from "./actions";
import {EventInterface} from "./interfaces";

export interface EventsState {
    search: EventSearchInterface
    list: EventInterface[]|undefined
    item: EventInterface|undefined
    pagination: PaginationInterface|undefined
    totalItems: number|undefined
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string|undefined
}

export interface EventSearchInterface {
    name?: string,
    "startDate[after]"?: string,
    "endDate[before]"?: string,
    _page?: number
}
export interface PageFilterInterface {
    _page?: number
}
export interface PaginationInterface {
    first: PageFilterInterface|undefined,
    current: PageFilterInterface|undefined,
    next: PageFilterInterface|undefined,
    last: PageFilterInterface|undefined,
}

export const STATUS_IDLE = "idle"
export const STATUS_LOADING = "loading"
export const STATUS_FAILED = "failed"
export const STATUS_SUCCEED = "succeeded"

export interface EventsState {
    search: EventSearchInterface
    list: EventInterface[]|undefined
    item: EventInterface|undefined
    pagination: PaginationInterface|undefined
    totalItems: number|undefined
    status: 'idle' | 'loading' | 'succeeded' | 'failed'
    error: string|undefined
}

const initialState: EventsState = {
    search: {
        name: undefined,
        "startDate[after]": undefined,
        "endDate[before]": undefined,
        _page: 1
    },
    list: undefined,
    item: undefined,
    pagination: undefined,
    totalItems: 0,
    status: STATUS_IDLE,
    error: undefined
}

export const events = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setSearch: (state, action) => {
            const {search} = action.payload
            return { ...state, search };

        },
        setEvents: (state, action) => {
            const { events, totalItems } = action.payload;
            return { ...state, list: events, totalItems };
        },
        setPagination: (state, action) => {
            const { pagination } = action.payload;
            return { ...state, pagination };
        },
        setSingleEvent: (state: EventsState, action) => {
            const { event } = action.payload;
            return { ...state, item: event };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchEvents.pending, (state: EventsState) => {
            state.status = STATUS_LOADING
        }).addCase(fetchEvents.fulfilled, (state: EventsState, action: {payload: any}) => {

            const {
                "hydra:member": data,
                "hydra:view": pagination,
                "hydra:totalItems": totalItems
            } = action.payload || {};

            const {
                "hydra:first": first,
                "hydra:last": last,
                "hydra:next": next,
                "@id": current,
            } = pagination || {};

            state.status = STATUS_SUCCEED
            state.list = data
            state.totalItems = totalItems
            state.pagination = {
                first: getParamsFromUrl(first),
                last: getParamsFromUrl(last),
                next: getParamsFromUrl(next),
                current: getParamsFromUrl(current),
            }

            state.search = {...state.search, ...state?.pagination?.current}

        }).addCase(fetchEvents.rejected, (state: EventsState, action) => {
            state.status = STATUS_FAILED
            state.error = action?.error?.message
        })
    }
})

export const selectAllEvents = (state: RootState) => state.events.list;
export const selectEventsTotalItems = (state: RootState) => state.events.totalItems;
export const selectEventsPagination = (state: RootState) => state.events.pagination;
export const selectEventsState = (state: RootState): string => state.events.status;
export const selectEventsError = (state: RootState) => state.events.error;
export const selectEventsSearch = (state: RootState) => state.events.search;

export const {
    setSearch,
    setEvents,
    setSingleEvent,
    setPagination
} = events.actions;

export default events.reducer;