import React from "react";
import { Helmet } from "react-helmet-async";

interface SEOProps {
  title?: string;
  description?: string;
  name?: string;
  type?: string;
  image?: string;
  url?: string;
}

const SEO: React.FC<SEOProps> = ({
  title,
  description,
  name,
  type,
  image,
  url,
}) => {
  return (
      <Helmet>
        {/* Standard metadata tags */}
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description}/>}
        {name && <meta name="author" content={name}/>}
        <meta name="robots" content="index, follow"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        {/* Open Graph / Facebook / LinkedIn tags */}
        {type && <meta property="og:type" content={type}/>}
        {title && <meta property="og:title" content={title}/>}
        {description && <meta property="og:description" content={description}/>}
        {image && <meta property="og:image" content={image}/>}
        {url && <meta property="og:url" content={url}/>}
        {name && <meta property="og:site_name" content={name}/>}

        <meta name="google-signin-client_id" content="139468034933-qri0a9d87ctcjo0agoal7kjreoaor7sv.apps.googleusercontent.com"/>
        <meta name="google-signin-scope" content="email"/>

        {/* Twitter tags */}
        {/* {name && <meta name="twitter:creator" content={name} />}
      {type && <meta name="twitter:card" content={type} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />} */}

        {/* Instagram tags (uses Open Graph) */}
        {/* {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />} */}

        {/* LinkedIn tags (also uses Open Graph) */}
        {/* {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image && <meta property="og:image" content={image} />}
      {url && <meta property="og:url" content={url} />} */}
      </Helmet>
  );
};

export default SEO;
