import React from "react";
import { Form, Formik } from "formik";
import RegionFilter from "../filters/RegionFilter";
import ActivitySectorFilter from "../filters/ActivitySectorFilter";
import FunctionFilter from "../filters/FunctionFilter";
import StudyLevelFilter from "../filters/StudyLevelFilter";
import ExperienceFilter from "../filters/ExperienceFilter";
import ContratTypeFilter from "../filters/ContratTypeFilter";
import WorkplaceFilter from "../filters/WorkplaceFilter";
import JobsFormInputs from "../filters/JobsFormInputs";
import { selectJobsSearch } from "../../../../_redux/features/jobs/jobsSlice";
import { useSelector } from "react-redux";

export default function JobsSearchForm({
  initialValues,
  handleSubmit,
}: {
  initialValues: any;
  handleSubmit: any;
}) {
  const search = useSelector(selectJobsSearch);
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm, values, dirty, touched }) => (
        <Form
          onChange={(e) => {
            e.preventDefault();

            if (e.target.name !== "keyword" && e.target.name !== "location") {
              submitForm();
            }
          }}
          className="w-full flex flex-col justify-start gap-9"
        >
          <JobsFormInputs />
          <div className="w-ful flex flex-wrap justify-start gap-9">
            <RegionFilter />
            <ActivitySectorFilter />
            <FunctionFilter />
            <StudyLevelFilter />
            <ExperienceFilter />
            <ContratTypeFilter />
            <WorkplaceFilter />
          </div>
        </Form>
      )}
    </Formik>
  );
}
