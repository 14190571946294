import {Form, useFormikContext} from "formik";
import {Button} from "@nextui-org/react";
import AutoCompleteSkills from "../_field/Specified/AutoCompleteSkills";
import SelectSkillsLevel from "../_field/Specified/SelectSkillsLevel";
import {PlusCircleIcon, XMarkIcon} from "@heroicons/react/24/outline";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {MutationResultSelectorResult} from "@reduxjs/toolkit/query";
import CustomSpinner from "../../spinner/CustomSpinner";

interface SkillAddFormPropsInterface {
    triggers: MutationResultSelectorResult<any>;
    onCancel?: () => void|undefined;
}

export default function SkillAddForm({triggers, onCancel = undefined}: SkillAddFormPropsInterface) {

    const {t} = useTranslation()
    const {setErrors: setErrorsHook, setSubmitting, initialValues, isSubmitting} = useFormikContext();
    const {isSuccess, reset, isLoading, error, isError} = triggers


    useEffect(() => {
        if (isSuccess) {
            toast.success(t("add.message.specific", {value: t("skills.label", {count: 1})}), {
                position: toast.POSITION.BOTTOM_LEFT
            });
            onCancel && onCancel()
        }

        return () => {
            reset()
        }
    }, [isSuccess]);

    useEffect(() => {
        setSubmitting(isLoading)
    }, [isLoading]);

    useEffect(() => {

        if (isError) {
            let errors = {}
            // @ts-ignore
            error?.data?.violations?.forEach((violation: {
                propertyPath: any;
                message: any;
            }) => {
                if (violation?.propertyPath in initialValues) {
                    errors[violation?.propertyPath] = violation?.message;
                } else {
                    toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            });

            if (errors) {
                setErrorsHook(errors)
            }
        }
    }, [isError, error]);
    
    return (
        <Form className="w-full flex flex-col gap-4">
            <AutoCompleteSkills
                disabled={isSubmitting}
                name="competence"
                isRequired
            />
            <SelectSkillsLevel
                name="level"
                isRequired
            />
            <div className="flex flex-row justify-center items-center gap-6">
                {
                    (typeof onCancel === "function") &&
                    <Button
                    onClick={onCancel}
                    disabled={isSubmitting || isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    startContent={
                        <XMarkIcon height={24} width={24}/>
                    }
                >
                    {t("close.label")}
                </Button>
                }
                <Button
                    type="submit"
                    spinner={<CustomSpinner />}
                    disabled={isSubmitting || isLoading}
                    isLoading={isSubmitting || isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    color="warning"
                    startContent={ (isSubmitting || isLoading) ? null : <PlusCircleIcon height={24} width={24}/> }
                >
                    {t("add.label")}
                </Button>
            </div>
        </Form>
    )
}