import {useTranslation} from "react-i18next";
import React, {ChangeEvent, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

export default function SearchUsersChat() {

    const {t} = useTranslation()
    let [searchParams, setSearchParams] = useSearchParams();
    const [onChangeTimer, setOnChangeTimer] = useState<NodeJS.Timeout | undefined>()
    const [value, setValue] = useState<object>(Object.fromEntries(searchParams) || {keyword: ''})

    useEffect(() => {
        return () => {
            clearTimeout(onChangeTimer)
        }
    }, []);

    const onChangeHandler = (values: { keyword: string }) => {
        setValue(values)
        clearTimeout(onChangeTimer)
        const timer = setTimeout(() => {
            setSearchParams(values)
        }, 500)
        setOnChangeTimer(timer);
    }

    return (
        <div className={"relative w-full flex flex-row justify-between items-center gap-3"}>
            <div
                className="relative w-full flex flex-row justify-between items-center bg-gray-50 placeholder-gray-300 rounded-md after:caret-amber-50 focus:outline-none">
                <input
                    className="h-12 w-full p-3 bg-transparent"
                    name="keyword"
                    value={value?.keyword}
                    type="search"
                    placeholder={t("network.yours.label")}
                    onChange={(e: ChangeEvent) => onChangeHandler({keyword: e.target.value})}
                />
            </div>
            {/*
            <div className="flex flex-row justify-end  items-center gap-3">
                <IconButton fontSize="small">
                    <TuneIcon width={30} height={30}/>
                </IconButton>
            </div>
            */}
        </div>
    )
}