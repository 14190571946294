import axios from "axios";
import i18n from "i18next";
import {Capacitor} from "@capacitor/core";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common["Accept"] = "application/ld+json";
axios.defaults.headers.common["Authorization"] =
  "bearer" +
    (typeof window !== "undefined" && localStorage.getItem("i18nextLng")) ??
  undefined;
axios.defaults.headers.common["accept-Language"] =
  i18n.language ??
  (typeof window !== "undefined" && localStorage.getItem("i18nextLng")) ??
  "fr";
axios.defaults.headers.post["Content-Type"] = "application/json";