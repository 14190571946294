import React from "react";
import { SvgIllustrationInterface } from "./AttentesProIllustration";
export default function AnniversaryIllustration({
  primary,
}: SvgIllustrationInterface) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="Layer 1"
      width="72"
      height="72"
      viewBox="0 0 779.83762 621.85999"
    >
      <path
        d="M985.106,155.77q-14.595,9.42-29.31006,18.08c-.71.42-1.41992.84-2.12988,1.25q-18.3003,10.71-36.75,20.2c-.60986.32-1.22021.63-1.83008.94q-30.78,15.75-61.77978,28.09c-.7002.28-1.40039.56-2.1001.82995Q832.02106,232.72,812.816,238.94c-.68994.22-1.39013.45-2.08008.66-.79.26-1.58007.51-2.37011.76a617.18833,617.18833,0,0,1-65.76953,16.85c-.82032.16-1.63038.32-2.44043.48q-22.6648,4.38-45.46973,7c-.68994.09-1.37012.17-2.06006.24a585.80952,585.80952,0,0,1-65.06006,3.63c-51.25976,0-103.62011-6.78-156.00976-20.18-.75-.19-1.5-.38-2.25-.59q-13.26051-3.42-26.53028-7.43-8.3247-2.52-16.70019-5.27c-.66992-.22-1.33985-.44-2.00977-.67q-35.61034-11.805-71.71-27.8c-.73-.32-1.46-.65-2.20019-.98q-21.73462-9.69-43.56983-20.88-.93018-.465-1.85986-.96-25.31982-13.02-50.66992-28.01l1.01953-1.72Q281.3111,169.58,307.44587,182.94l.05029.02q21.09009,10.79993,42.10986,20.18a789.38488,789.38488,0,0,0,74.3999,28.91c1.66993.55,3.33008,1.1,5,1.62q21.15015,6.84,42.23,12.21c.76026.2,1.51026.39,2.27.58q110.38551,27.81,218.16993,16.07c.68994-.06,1.37011-.14,2.06005-.23q21.46509-2.385,42.8003-6.38c2.4497-.46,4.8999-.93,7.34961-1.43a617.46974,617.46974,0,0,0,65.21045-16.77c.68994-.22,1.38964-.44,2.07959-.67q20.20532-6.435,40.28027-14.33c.69971-.28,1.3999-.55,2.1001-.83q30.22485-11.98507,60.1499-27.24c.60986-.3,1.21973-.61,1.83008-.93994q19.25976-9.855,38.37988-21.08c.71973-.41,1.43018-.83,2.14014-1.26q14.02441-8.28,27.96972-17.28Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M349.606,203.14l-42.10986-20.18-.05029-.02-2.33985-1.12-.37988,1.98-11.58008,60.21,59.21-37.39,1.9502-1.23ZM295.9859,239.85l10.6001-55.09v-.02l43.58985,20.89-.02.01Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M471.2359,245.88l-1.92968,1.91-41.88037,41.29-1.3501-53.99-.04981-2.12,2.98.7c-1.66992-.52-3.33007-1.07-5-1.62l.06006,2.37,1.48,59.32,46.01026-45.36,1.9497-1.92C472.74616,246.27,471.99616,246.08,471.2359,245.88Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M736.5362,255.92l4.71972-.57-1.10009,2.34L713.876,313.31,694.6861,264.69l-.9502-2.39c-.68994.09-1.37011.17-2.06005.23l.95019,2.4,21.08984,53.41,28.88038-61.13,1.28955-2.72C741.4361,254.99,738.9859,255.46,736.5362,255.92Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M851.45612,222.72l-.25,2.44-5.25,51.83L812.816,238.94l-1.64013-1.89c-.68995.23-1.38965.45-2.07959.67l1.63964,1.88,36.74024,42.19,5.83008-57.46.25-2.44C852.856,222.17,852.15583,222.44,851.45612,222.72Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M953.91608,172.63l-.25,2.47L947.86579,231l-30.94971-35.7-1.37988-1.59c-.61035.33-1.22022.64-1.83008.93994l1.37988,1.59,34.29,39.56,6.41992-61.95.26026-2.48C955.34626,171.8,954.63581,172.22,953.91608,172.63Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M693.40807,526.17989c-12.27263-10.37028-19.72722-26.75227-17.77226-42.70026s14.0634-30.60908,29.7698-33.9957,33.61062,5.9881,38.38117,21.33091c2.62546-29.58033,5.65083-60.40007,21.37763-85.59049,14.24023-22.80931,38.90458-39.13026,65.63515-42.05006s54.87444,8.108,71.54532,29.2061,20.75269,51.77038,9.07684,75.99276c-8.6012,17.84379-24.44762,31.12335-41.05073,41.927A264.93891,264.93891,0,0,1,689.16962,529.941Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M875.91148,351.89356a433.47893,433.47893,0,0,0-60.4096,59.98657,434.7423,434.7423,0,0,0-68.04074,113.703c-1.22519,3.00233,3.65008,4.30924,4.86164,1.3403A432.29719,432.29719,0,0,1,879.47653,355.45861c2.517-2.04959-1.0688-5.59774-3.56505-3.56505Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#fff"
      />
      <path
        d="M438.99128,691.94q-3.75.555-7.52.99A265.10681,265.10681,0,0,1,257.79108,652.3c-16.6001-10.8-32.4502-24.08-41.0498-41.93-11.68018-24.22-7.59034-54.89,9.08007-75.99a75.82313,75.82313,0,0,1,22.02979-18.78c1.26025-.73,2.54-1.41,3.83984-2.06a82.63105,82.63105,0,0,1,45.67041-8.37c26.72949,2.92,51.3999,19.25,65.63965,42.05,15.72022,25.19,18.75,56.01,21.37012,85.59.21-.68.45019-1.35.71-2a26.9083,26.9083,0,0,1,2-4.02v49.14a15.01828,15.01828,0,0,0,15,15h35.77Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M252.25075,513.89356a433.47846,433.47846,0,0,1,60.40961,59.98657,439.67328,439.67328,0,0,1,47.64017,70.50692,437.87167,437.87167,0,0,1,20.40057,43.19612c1.22519,3.00233-3.65008,4.30924-4.86164,1.3403a430.2743,430.2743,0,0,0-39.31557-74.38666,432.98041,432.98041,0,0,0-52.72839-65.13828,431.94381,431.94381,0,0,0-35.10979-31.93992c-2.517-2.04959,1.06879-5.59774,3.565-3.56505Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#fff"
      />
      <path
        d="M731.58112,578.93h-259.5v-28.5a9.5,9.5,0,0,0-19,0v38a9.52788,9.52788,0,0,0,9.5,9.5h269a9.5,9.5,0,0,0,0-19Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M988.72813,775.86871h-738.294a1.19069,1.19069,0,0,1,0-2.38137h738.294a1.19069,1.19069,0,0,1,0,2.38137Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#3f3d56"
      />
      <path
        d="M901.08,690.93h-563a17.02419,17.02419,0,0,0-17,17v51a17.02418,17.02418,0,0,0,17,17h563a17.02419,17.02419,0,0,0,17-17v-51A17.02419,17.02419,0,0,0,901.08,690.93Zm15,68a15.01832,15.01832,0,0,1-15,15h-563a15.01828,15.01828,0,0,1-15-15v-51a15.01828,15.01828,0,0,1,15-15h563a15.01832,15.01832,0,0,1,15,15Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#3f3d56"
      />
      <path
        d="M873.58112,744.93h-520.5v-28.5a9.5,9.5,0,0,0-19,0v38a9.52788,9.52788,0,0,0,9.5,9.5h530a9.5,9.5,0,0,0,0-19Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M780.58112,656.93h-362.5v-28.5a9.5,9.5,0,0,0-19,0v38a9.52788,9.52788,0,0,0,9.5,9.5h372a9.5,9.5,0,0,0,0-19Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#f2f2f2"
      />
      <path
        d="M816,723c-80-20-250,8-253,8-2.93,0-188.91,27.65-182.78-38.07.06006-.66.14-1.32.24-2H879.69c.12.59.24,1.26.35,2C881.97,705.96,881.02,739.26,816,723Z"
        transform="translate(-210.08119 -154.07001)"
        fill={primary}
      />
      <path
        d="M838.08,607.93h-436a17.02419,17.02419,0,0,0-17,17v51a17.03918,17.03918,0,0,0,17,17h436a17.06353,17.06353,0,0,0,17-17v-51A17.02419,17.02419,0,0,0,838.08,607.93Zm15,68a15.03109,15.03109,0,0,1-15,15h-436a15.02977,15.02977,0,0,1-15-15v-51a15.01828,15.01828,0,0,1,15-15h436a15.01832,15.01832,0,0,1,15,15Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#3f3d56"
      />
      <path
        d="M782.61138,636.031c-69.92453-17.48113-218.51413,6.99245-221.1363,6.99245-2.561,0-165.118,24.16763-159.76008-33.27534.0525-.5769.12239-1.15376.20982-1.74811H838.28c.10488.51566.20976,1.10132.30589,1.74811C840.27286,621.137,839.44255,650.24317,782.61138,636.031Z"
        transform="translate(-210.08119 -154.07001)"
        fill={primary}
      />
      <path
        d="M784.08112,608.93h-329a17.01908,17.01908,0,0,1-17-17v-51a17.01909,17.01909,0,0,1,17-17h329a17.01917,17.01917,0,0,1,17,17v51A17.01916,17.01916,0,0,1,784.08112,608.93Zm-329-83a15.017,15.017,0,0,0-15,15v51a15.017,15.017,0,0,0,15,15h329a15.017,15.017,0,0,0,15-15v-51a15.017,15.017,0,0,0-15-15Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#3f3d56"
      />
      <path
        d="M744.76055,545.53281c-53.61894-13.40473-167.55917,5.3619-169.56988,5.3619-1.96379,0-126.6144,18.532-122.50588-25.51592.04025-.44238.09384-.88472.16089-1.34047H787.44792c.08043.39542.16085.8445.23457,1.34047C788.976,534.112,788.33935,556.43087,744.76055,545.53281Z"
        transform="translate(-210.08119 -154.07001)"
        fill={primary}
      />
      <ellipse cx="409.91881" cy="371.92999" rx="34" ry="3" fill="#3f3d56" />
      <path
        d="M618.08109,414.64693a10.52653,10.52653,0,0,0,.04985,1.65674L580.14309,448.1849l-11.32833-4.10164-8.42464,16.2874L585.15753,472.16l41.52974-46.60321a10.4958,10.4958,0,1,0-8.60618-10.90981Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#ffb8b8"
      />
      <path
        d="M567.13278,468.18309,530.4493,454.94222a13.49994,13.49994,0,1,1,9.16671-25.39615l36.68348,13.24086a4.499,4.499,0,0,1,2.70463,5.75989l-6.11079,16.93132a4.50633,4.50633,0,0,1-5.76055,2.70495Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#2f2e41"
      />
      <path
        d="M617.08112,389.93a1,1,0,0,1-1-1v-37a1,1,0,0,1,2,0v37A1,1,0,0,1,617.08112,389.93Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#3f3d56"
      />
      <path
        d="M634.08112,343.93c9.30615,8.64606-2.29745,27-15,27a23,23,0,0,1-23-23c0-12.70255,24.8584-68.73687,20-57C614.53034,294.67645,623.42389,334.02856,634.08112,343.93Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#ff6584"
      />
      <path
        d="M626.08105,489.42969h-17a4.50493,4.50493,0,0,1-4.5-4.5V382.93018a4.50492,4.50492,0,0,1,4.5-4.5h17a4.50491,4.50491,0,0,1,4.5,4.5V484.92969A4.50492,4.50492,0,0,1,626.08105,489.42969Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#ccc"
      />
      <polygon
        points="330.185 443.105 342.445 443.105 348.277 395.817 330.183 395.818 330.185 443.105"
        fill="#ffb8b8"
      />
      <path
        d="M537.63953,593.67167h38.53073a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H552.52639a14.88686,14.88686,0,0,1-14.88686-14.88686v0A0,0,0,0,1,537.63953,593.67167Z"
        transform="translate(903.75603 1048.13479) rotate(179.99738)"
        fill="#2f2e41"
      />
      <polygon
        points="299.887 427.268 309.916 434.32 341.887 398.992 327.085 388.585 299.887 427.268"
        fill="#ffb8b8"
      />
      <path
        d="M502.04686,586.68813h38.53073a0,0,0,0,1,0,0V601.575a0,0,0,0,1,0,0H516.93371a14.88686,14.88686,0,0,1-14.88686-14.88686v0A0,0,0,0,1,502.04686,586.68813Z"
        transform="translate(395.9645 1225.9235) rotate(-144.88912)"
        fill="#2f2e41"
      />
      <path
        d="M567.09284,511.99156s30.59493-3.60971,25.10353,16.24372-66.68124,36.99959-66.68124,36.99959Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#ffb8b8"
      />
      <circle cx="335.95362" cy="232.05856" r="24.56103" fill="#ffb8b8" />
      <path
        d="M467.708,561.16944c-13.97559-16.27344-18.86621-41.249,3.42139-62.02148a73.4599,73.4599,0,0,1,18.48437-12.27539l17.96289-50.23438a27.37366,27.37366,0,0,1,25.29663-18.19629,26.19654,26.19654,0,0,1,25.41236,16.34277,22.73865,22.73865,0,0,1,.70556,16.10352,258.67061,258.67061,0,0,1-20.95874,47.9541l.92871,7.43067,45.40332,4.03515-.42358.68848c-.221.35937-22.44214,34.21582-50.8689,56.68848-9.72,7.68359-19.771,10.86816-29.25732,10.86718C489.42992,578.55128,476.34667,571.228,467.708,561.16944Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#2f2e41"
      />
      <path
        d="M619.31172,448.94675a10.52651,10.52651,0,0,0-.65905,1.52083l-47.93639,12.71187-8.51078-8.52766L547.657,465.814l17.408,21.19845L622.46476,462.48a10.49579,10.49579,0,1,0-3.153-13.53324Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#ffb8b8"
      />
      <path
        d="M550.43969,475.75147l-27.57715-27.57715a13.49994,13.49994,0,1,1,19.0918-19.09179l27.57715,27.57714a4.499,4.499,0,0,1,0,6.36329L556.8037,475.75147a4.50633,4.50633,0,0,1-6.364,0Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#2f2e41"
      />
      <path
        d="M463.42284,393.83405c4.34522-5.17785,6.209-11.95044,7.7085-18.54156,2.37207-10.42884,4.208-21.21265,9.47852-30.51917,5.27-9.30646,14.95556-17.01776,25.63476-16.43078,10.61816.58361,19.20264,9.12634,23.75928,18.84625a8.45232,8.45232,0,0,1,6.86377.33093c3.28418,1.79846,4.5122,6.91028,1.77832,9.4693a22.69055,22.69055,0,0,1,18.47754-.46063,22.335,22.335,0,0,1,13.52929,18.95428,12.17669,12.17669,0,0,1-3.23779,9.13575c-4.7124,4.85125-11.37744,3.17785-17.30371,1.942-5.59766-1.16723-12.41553-1.48236-15.56885,3.851a9.99641,9.99641,0,0,0-.97265,7.30518c1.208,5.3241,5.1499,9.03259,8.97412,12.58179l.62549,1.16192c-13.50245,2.63306-28.24707-6.58837-31.78907-19.88128s4.65918-28.62818,17.68164-33.06269h0a4.86967,4.86967,0,0,1-4.37207-7.2965c-3.749-1.87439-7.68652-3.21857-11.81494-2.94776-6.72656.44129-12.54785,5.22949-16.04834,10.9903-3.501,5.76086-5.08594,12.44745-6.69189,18.99444-1.60547,6.54706-3.34326,13.21936-7.08643,18.82575a29.95887,29.95887,0,0,1-47.999,2.462A23.90872,23.90872,0,0,0,463.42284,393.83405Z"
        transform="translate(-210.08119 -154.07001)"
        fill="#2f2e41"
      />
    </svg>
  );
}
