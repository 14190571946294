import {Button} from "@nextui-org/react";
import KeyboardArrowTopOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import React from "react";

interface ScrollTopButtonInterface {
    isVisible?: boolean

}

const ScrollTopButton = ({ isVisible } : ScrollTopButtonInterface) => {

    return (
        isVisible && (
            <Button
                className={`fixed animate-bounce bottom-3 end-5 max-md:bottom-14 z-10 transition-all duration-300`}
                variant="shadow"
                color="warning"
                isIconOnly={true}
                radius="full"
                onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
            >
                <KeyboardArrowTopOutlinedIcon />
            </Button>
        )
    )

}
export default ScrollTopButton