import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { regionInterface } from "../../../../_redux/features/region/types";
import { useGetCitiesQuery } from "../../../../_redux/features/city/services";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  regionId: number | null;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectCity(props: SelectPropsInterface): JSX.Element {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCitiesQuery(
    { pagination: false, region: props?.regionId },
    { skip: !props?.regionId }
  );
  const { list } = data || {};
  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("cities.label", { count: 1 })}
      placeholder={t("cities.label", { count: 1 })}
      {...props}
    >
      {list
        ?.map((city: regionInterface) => (
          <MenuItem key={city["@id"]} value={city["@id"]}>
            {city?.name}
          </MenuItem>
        ))
        .sort((a: any, b: any) => (a?.name > b?.name ? -1 : 1))}
    </Select>
  );
}
