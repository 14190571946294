import React from "react";
import { useEffect, useState } from "react";
export default function PercentageBar({ percentage }: { percentage: number }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (count < percentage) {
        setCount((prevCount) => prevCount + 0.5);
      } else if (count > percentage) {
        setCount((prevCount) => prevCount - 0.5);
      } else {
        clearInterval(intervalId);
      }
    }, 20);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [count, percentage]);

  return (
    <div className="w-full lg:w-1/2 relative rounded-full flex flex-col justify-between items-end bg-[#f4f4f5] h-[8px] max-h-[8px] min-h-[8px] max-md:mt-12">
      <div
        style={{ width: count + "%" }}
        className={`absolute rounded-full ${
          count < process.env.REACT_APP_CV_PROGRESSION
            ? "bg-[#fe7a6c]"
            : "bg-[#c6f16d]"
        } start-0 top-0 bottom-0 h-[8px] max-h-[8px] min-h-[8px]`}
      >
        <div
          className={`absolute transition-all duration-100 top-[-45px] ${
            count < 25 ? "start-[0]" : "end-[0]"
          }`}
        >
          <p className="text-5xl font-xing-bold">{parseInt(count)}%</p>
        </div>
      </div>
    </div>
  );
}
