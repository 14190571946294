import {Form, useFormikContext} from "formik";
import {Button} from "@nextui-org/react";
import SelectLanguagesLevel from "../_field/Specified/SelectLanguagesLevel";
import {PencilIcon} from "@heroicons/react/24/outline";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {MutationResultSelectorResult} from "@reduxjs/toolkit/query";
import CustomSpinner from "../../spinner/CustomSpinner";

interface LanguageAddFormPropsInterface {
    triggers: MutationResultSelectorResult<any>;
    onCancel?: () => void|undefined;
}

export default function LanguageEditForm({triggers, onCancel = undefined}: LanguageAddFormPropsInterface) {

    const {t} = useTranslation()
    const {setErrors: setErrorsHook, setSubmitting, initialValues, isSubmitting} = useFormikContext();

    const {isLoading, isSuccess, isError, reset, error} = triggers

    useEffect(() => {
        if (isSuccess) {
            toast.success(t("update.message.specific", {value: t("languages.label", {count: 1})}), {
                position: toast.POSITION.BOTTOM_LEFT
            });

            if (typeof onCancel == "function") {
                onCancel();
            }

        }

        return () => {
            reset()
        }
    }, [isSuccess]);

    useEffect(() => {
        setSubmitting(triggers.isLoading)
    }, [triggers.isLoading]);

    useEffect(() => {

        if (isError) {
            let errors = {}
            // @ts-ignore
            error?.data?.violations?.forEach((violation: {
                propertyPath: any;
                message: any;
            }) => {
                if (violation?.propertyPath in initialValues) {
                    errors[violation?.propertyPath] = violation?.message;
                } else {
                    toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            });

            if (errors) {
                setErrorsHook(errors)
            }
        }
    }, [isError, error]);
    
    return (
        <Form className="w-full flex flex-col gap-4">
            <div className="flex flex-col justify-start items-center">
                <SelectLanguagesLevel
                    readOnly={true}
                    name="level"
                    isRequired
                />
            </div>
            <div className="flex flex-row justify-center items-center gap-6">
                {
                    (typeof onCancel === "function") &&
                    <Button
                        onClick={onCancel}
                        disabled={isSubmitting}
                        className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    >
                        {t("close.label")}
                    </Button>
                }
                <Button
                    type="submit"
                    spinner={<CustomSpinner />}
                    disabled={isSubmitting || isLoading}
                    isLoading={isSubmitting || isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    color="warning"
                    startContent={ (!isLoading || isSubmitting) ? <PencilIcon height={18} width={18} /> : null }
                >
                    {t("update.label")}
                </Button>
            </div>
        </Form>
    )
}