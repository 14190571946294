import Loading from "../../Loading";
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { Freeze } from "react-freeze";

type FreezeHandlerType = (value: boolean) => void;

export function useFreezer(): FreezeHandlerType {
  const context = useContext(FreezerContext);
  if (context === null) {
    throw new Error("useFreezer must be used within a FreezerProvider");
  }
  return context;
}

export const FreezerContext = createContext<FreezeHandlerType | null>(null);

export default function FreezerProvider({ children }: { children: ReactNode }) {
  const [isClient, setIsClient] = useState(false);

  const [freeze, setFreeze] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);
  const freezeHandler = (value: boolean): void => {
    setFreeze(value);
  };
  return (
    <FreezerContext.Provider value={freezeHandler}>
      {isClient ? (
        <Freeze
          freeze={freeze}
          placeholder={<Loading fullscreen={true} label={"connecting.label"} />}
        >
          {children}
        </Freeze>
      ) : (
        <>{children}</>
      )}
    </FreezerContext.Provider>
  );
}
