import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AutocompleteItem} from "@nextui-org/react";
import {useGetSkillsQuery} from "../../../../_redux/features/skills/services";
import AutoComplete from "../AutoComplete";
import {useFormikContext} from "formik";
import {SkillInterface} from "../../../../_redux/features/skills/cvXSkillInterface";

export interface AutoCompletePropsInterface {
    name: string;
    type?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    onInputChange?: (e: any) => void;
    inputInitValue?: string | undefined;
}

export default function AutoCompleteSkills({name, ...props}: AutoCompletePropsInterface): JSX.Element {

    const {isSubmitting, values, setFieldValue} = useFormikContext();
    const {t} = useTranslation()
    const [skillName, setSkillName] = useState(values?.[name]["name"] ?? "")
    const [selectedKey, setSelectedKey] = useState((typeof values?.[name] === "string") ? values?.[name] : values?.[name]["@id"])
    const [onChangeTimer, setOnChangeTimer] = useState<NodeJS.Timeout | undefined>()
    const [onSelectTimer, setOnSelectTimer] = useState<NodeJS.Timeout | undefined>()
    const {data, isLoading: isSkillsLoading, isFetching: isSkillsFetching} = useGetSkillsQuery({name: skillName})
    const {list: skills} = data || {}

    useEffect(() => {
        return () => {
            clearTimeout(onChangeTimer)
            clearTimeout(onSelectTimer)
        }
    }, []);

    const onInputChangeHandler = (value: string) => {
        clearTimeout(onChangeTimer)
        const timer = setTimeout(() => {
            setSkillName(value)
            setTimeout(() => {
                const selectedSkill = skills?.find((skill: SkillInterface) => skill?.name === value)
                setFieldValue(name, selectedSkill ? selectedSkill["@id"] : {name: value, type: null})
            }, 500)
        }, 1000)
        setOnChangeTimer(timer);
    }

    const onSelectionChangeHandler = (key: string) => {
        clearTimeout(onSelectTimer)
        const timer = setTimeout(() => {
            const selectedSkill = skills.find((skill: SkillInterface) => skill["@id"] === key)
            if (selectedSkill) {
                setFieldValue(name, selectedSkill["@id"])
            }
        }, 500)
        setOnSelectTimer(timer);
    }

    return (
        <AutoComplete
            isLoading={isSkillsLoading || isSkillsFetching}
            placeholder={t("skills.placeholder", {count: 1})}
            label={t("skills.label", {count: 1})}
            disabled={isSubmitting || isSkillsLoading || isSkillsFetching}
            onInputChange={onInputChangeHandler}
            onSelectionChange={onSelectionChangeHandler}
            onBlur={(e) => onInputChangeHandler(e.target.value)}
            defaultSelectedKey={selectedKey}
            selectedKeys={[skills ?? {}]}
            name={name}
            {...props}
        >
            {
                skills?.map((skill: SkillInterface) => (
                    <AutocompleteItem key={skill["@id"]} value={skill}>
                        {skill?.name ?? ""}
                    </AutocompleteItem>
                ))
            }
        </AutoComplete>
    )
}