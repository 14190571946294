import React from "react";
import {Container} from "@mui/material";
import ProfileHeader from "./ui/ProfileHeader";
import Skills from "./ui/Skills";
import Experiences from "./ui/Experiences";
import Languages from "./ui/Languages";
import Hobbies from "./ui/Hobbies";

export default function Profile() {

    return (
        <Container maxWidth="md">
            <div className="w-full flex flex-col justify-start gap-3">

                <ProfileHeader/>

                <div className="flex flex-col justify-between gap-3 md:px-12">

                    <Skills/>

                    <Experiences/>

                    <Languages/>

                    <Hobbies/>

                </div>

            </div>
        </Container>
    )
}
