import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { JobAlertInterface, JobAlertItemPropsInterface } from "./JobAlertItem";
import {
  useGetJobAlertQuery,
  useUpdateJobAlertMutation,
} from "../../../../../_redux/features/jobs/services";
import { TransitionProps } from "@mui/material/transitions";
import { DialogContent, Slide } from "@mui/material";
import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { BorderColor, Close } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Form, Formik } from "formik";
import Input from "../../../../../components/_form/_field/Input";
import SelectRegion from "../../../../../components/_form/_field/Specified/SelectRegion";
import SelectLanguages from "../../../../../components/_form/_field/Specified/SelectLanguages";
import SelectFunctionType from "../../../../../components/_form/_field/Specified/SelectFunctionType";
import SelectActivitySectorGroup from "../../../../../components/_form/_field/Specified/SelectActivitySectorGroup";
import SelectJobAlertFrequency from "../../../../../components/_form/_field/Specified/SelectJobAlertFrequency";
import SelectExperienceLevel from "../../../../../components/_form/_field/Specified/SelectExperienceLevel";
import { object, string } from "yup";
import Loading from "../../../../../Loading";
import { toastMessageError } from "../../../../../_helpers/toastErrorMessage";

export interface SimpleDialogProps {
  alert: JobAlertInterface;
  open: boolean;
  onClose: (value: string) => void;
}

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide timeout={4000} direction="up" ref={ref} {...props} />;
});

interface Values {
  indexOf(value: any): any;
  keyword: string;
  location?: string;
  cardinals: Array<string> | undefined;
  function: Array<number> | undefined;
  sectorGroup: Array<number> | undefined;
  contractTypes: Array<number> | undefined;
}

function UpdateJobAlertDialog(props: SimpleDialogProps) {
  const { onClose, open, alert } = props;
  const { data: singleAlert, isLoading } = useGetJobAlertQuery(alert?.id, {
    skip: !alert?.id,
  });
  const [updateJobAlertAction, updateJobAlertTriggers] =
    useUpdateJobAlertMutation();
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    onClose(true);
  };

  let initialValues = {
    name: singleAlert?.name,
    frequency: singleAlert?.frequency,
    keyword: singleAlert?.keyword,
    // language: singleAlert?.language,
    function: singleAlert?.function
      ? singleAlert?.function?.map((fn) => `${fn}`)
      : [],
    experience: singleAlert?.experience ? singleAlert?.experience : [],
    region: singleAlert?.region
      ? singleAlert?.region?.map((reg) => `${reg}`)
      : [],
    sector: singleAlert?.sector
      ? singleAlert?.sector?.map((sec) => `${sec}`)
      : [],
  };

  const handelSubmit = async (values: Values) => {
    const payload = {
      function: values?.function ? values?.function : [],
      region: values?.region
        ? values?.region?.map((reg) => reg.replace("/api/params_regions/", ""))
        : [],
      sector: values?.sector ? values?.sector : [],
    };

    updateJobAlertAction({ ...values, ...payload, id: alert?.id })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", { value: t("search.alert.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );

        onClose(true);
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  const schema = object().shape({
    name: string().required(i18n.t("validation.field.required.unspecific")),
    frequency: string().required(
      i18n.t("validation.field.required.unspecific")
    ),
  });

  return (
    <Dialog
      fullWidth
      lang={i18n.language}
      open={open}
      TransitionComponent={Transition}
      keepMounted={
        updateJobAlertTriggers?.isLoading || updateJobAlertTriggers?.isFetching
      }
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {t("update.specific", { value: t("search.alert.label") })}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Loading />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handelSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="w-full flex flex-col justify-between items-center gap-4">
                  <div className="w-full">
                    <Input
                      name="name"
                      placeholder={t("name.label", { count: 1 })}
                      label={t("name.label", { count: 1 })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectJobAlertFrequency
                      name="frequency"
                      placeholder={t("frequency.label", { count: 1 })}
                      label={t("frequency.label", { count: 1 })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      name="keyword"
                      placeholder={t("keywords.label", { count: 1 })}
                      label={t("keywords.label", { count: 1 })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="w-full">
                    <SelectFunctionType
                      id="id"
                      name={"function"}
                      multiple
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectExperienceLevel
                      id="id"
                      name="experience"
                      multiple
                      placeholder={t("professionalExperience.label", {
                        count: 1,
                      })}
                      label={t("professionalExperience.label", { count: 1 })}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <SelectRegion name={"region"} countryId={3} multiple />
                  </div>
                  <div className="w-full">
                    <SelectActivitySectorGroup
                      name={"sector"}
                      id="id"
                      multiple
                      label={t("activity.sector.group.placeholder")}
                      placeholder={t("activity.sector.group.placeholder")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>

                  <div className="w-full flex-row-reverse flex gap-1 ">
                    <Button
                      type="submit"
                      startContent={
                        updateJobAlertTriggers?.isLoading ||
                        updateJobAlertTriggers?.isFetching ||
                        isSubmitting ? (
                          <CircularProgress
                            color="warning"
                            size={17}
                            className={"mx-1"}
                          />
                        ) : (
                          <BorderColor />
                        )
                      }
                      variant="flat"
                      color="warning"
                      disabled={
                        updateJobAlertTriggers?.isLoading ||
                        updateJobAlertTriggers?.isFetching ||
                        isSubmitting
                      }
                    >
                      {t("update.label")}
                    </Button>
                    <Button
                      variant="flat"
                      color="primary"
                      onClick={handleClose}
                      disabled={
                        updateJobAlertTriggers?.isLoading ||
                        updateJobAlertTriggers?.isFetching ||
                        isSubmitting
                      }
                      startContent={<Close />}
                    >
                      {t("close.label")}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default function UpdateJobAlertButton({
  alert,
}: JobAlertItemPropsInterface) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        radius="full"
        variant="light"
        color="primary"
      >
        <BorderColorIcon fontSize="small" />
        <p className="font-xing-bold text-sm text-black first-letter:capitalize">
          {t("update.label")}
        </p>
      </Button>
      <UpdateJobAlertDialog open={open} onClose={handleClose} alert={alert} />
    </>
  );
}
