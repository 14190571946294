import React from "react";
import SideBarItem from "../../components/sidebar/sidebarItem/sidebarItem";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "../../utils/appRoutes";
import { setSingleEvent } from "../../_redux/features/events/eventsSlice";
import { useAppDispatch } from "../../_redux/store";
import SidebarAvatar from "./sidbarAvatar/SidebarAvatar";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import GroupIcon from "@mui/icons-material/Group";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import DiamondIcon from "@mui/icons-material/Diamond";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import { NewspaperOutlined, NewspaperSharp } from "@mui/icons-material";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { useUnreadMessagesMercureHub } from "../../_hooks/useUnreadMessagesMercureHub";
import "./sidebar.module.css";

export default function SideBar() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const unreadMessages = useUnreadMessagesMercureHub();
  const unreadMessages = null;

  const items = [
    {
      title: t("sidebar.jobs"),
      enabledIcon: <WorkOutlinedIcon width={18} height={18} />,
      disabledIcon: <WorkOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_JOBS[0],
      className: undefined,
      disabled: false,
    },
    {
      title: t("sidebar.your.job.offers"),
      enabledIcon: <BookmarkOutlinedIcon width={18} height={18} />,
      disabledIcon: <BookmarkBorderOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_APPLICATIONS,
      className: undefined,
      disabled: false,
    },
    {
      title: t("network.yours.label"),
      enabledIcon: <GroupIcon width={18} height={18} />,
      disabledIcon: <GroupOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_NETWORK,
      className: undefined,
      disabled: true,
    },
    {
      title: t("sidebar.home"),
      enabledIcon: <ArticleIcon width={18} height={18} />,
      disabledIcon: <ArticleOutlinedIcon width={18} height={18} />,
      path: AppRoutes.HOME,
      className: undefined,
      disabled: true,
    },
    {
      title: t("sidebar.chat"),
      enabledIcon: <ForumIcon width={18} height={18} />,
      disabledIcon: <ForumOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_CHAT,
      className: undefined,
      disabled: true,
      badgeContent: unreadMessages,
    },
    {
      title: t("profile.andCv.label"),
      enabledIcon: <PersonIcon width={18} height={18} />,
      disabledIcon: <PersonOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_PROFILE[0],
      className: "max-sm:hidden",
      disabled: false,
    },
    {
      title: t("profile.visitors.and.statistics.label"),
      enabledIcon: <DonutSmallIcon width={18} height={18} />,
      disabledIcon: <DonutSmallOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_PROFILE_VISITORS_AND_STATISTICS,
      className: "max-sm:hidden",
      disabled: true,
    },
    {
      title: t("sidebar.professional.expectations"),
      enabledIcon: <TuneIcon width={18} height={18} />,
      disabledIcon: <TuneIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_PROFESSIONAL_EXPECTATIONS,
      className: undefined,
      disabled: false,
    },
    {
      title: t("job.applications.your.search.alerts.label"),
      enabledIcon: <LightbulbIcon width={18} height={18} />,
      disabledIcon: <LightbulbOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT,
      className: undefined,
      disabled: false,
    },
    {
      title: t("sidebar.subscribers"),
      enabledIcon: <GroupAddIcon width={18} height={18} />,
      disabledIcon: <GroupAddOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_SUBSCRIPTIONS,
      className: "max-sm:hidden",
      disabled: true,
    },
    {
      title: t("sidebar.your.publications"),
      enabledIcon: <BorderColorIcon width={18} height={18} />,
      disabledIcon: <BorderColorOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_MY_POSTS,
      className: "max-sm:hidden",
      disabled: true,
      onClick: () => {
        dispatch(setSingleEvent({ event: undefined }));
      },
    },
    {
      title: t("sidebar.events"),
      enabledIcon: <WorkOutlinedIcon width={18} height={18} />,
      disabledIcon: <WorkOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_EVENTS,
      className: undefined,
      disabled: true,
      onClick: () => {
        dispatch(setSingleEvent({ event: undefined }));
      },
    },
    {
      title: t("sidebar.news"),
      enabledIcon: <NewspaperSharp width={18} height={18} />,
      disabledIcon: <NewspaperOutlined width={18} height={18} />,
      path: AppRoutes.PRIVATE_BLOG,
      className: "max-sm:hidden",
      disabled: false,
    },
    {
      title: t("sidebar.become.premium.member"),
      enabledIcon: <DiamondIcon width={18} height={18} />,
      disabledIcon: <DiamondOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_BECOME_PREMIUM,
      className: "text-warning max-sm:hidden",
      disabled: true,
    },
    {
      title: t("sidebar.opt.for.pro.jobs"),
      enabledIcon: <ScatterPlotIcon width={18} height={18} />,
      disabledIcon: <ScatterPlotOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_OPT_FOR_PRO_JOBS,
      className: "text-pink-900 max-sm:hidden",
      disabled: true,
    },
  ];

  return (
    <div className="max-lg:hidden max-md:w-36 w-50 bg-white h-full flex-none shrink-0 flex flex-col justify-evenly gap-6">
      <SidebarAvatar />
      <div className="max-sm:w-full max-md:w-36 w-50 bg-white h-full flex-none shrink-0 flex flex-col max-sm:flex-row justify-evenly gap-1">
        {items
          ?.filter((item) => item.disabled === false)
          .map((item, i) => (
            <SideBarItem
              key={i}
              title={item?.title}
              enabledIcon={item?.enabledIcon}
              disabledIcon={item?.disabledIcon}
              path={item?.path}
              onClick={item?.onClick}
              className={item?.className}
              disabled={item?.disabled}
              badgeContent={item?.badgeContent}
            />
          ))}
      </div>
    </div>
  );
}
