import React, { useEffect, useRef } from "react";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { useTranslation } from "react-i18next";
import { Container } from "@mui/material";
import ApplicationsIllustration from "../../../components/illustration/ApplicationsIllustration";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { AppRoutes } from "../../../utils/appRoutes";
import SEO from "../../../SEO";
import { applicationTitle } from "../../../utils/seoProps";

export default function Applications() {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data: profile } = useGetProfileQuery();
  const containerRef = useRef<HTMLDivElement>(null);
  const activeTabRef = useRef<HTMLButtonElement>(null);

  const tabs = [
    {
      title: t("job.applications.selected.offers.label"),
      path: AppRoutes.PRIVATE_APPLICATIONS_SELECTED_OFFERS,
    },
    {
      title: t("job.recently.viewed.offers.label"),
      path: AppRoutes.PRIVATE_RECENTLY_VIEWED_OFFERS,
    },
    {
      title: t("job.applications.your.search.alerts.label"),
      path: AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT,
    },
    {
      title: t("job.applications.your.applications.label"),
      path: AppRoutes.PRIVATE_APPLICATIONS_MY_APPLICATIONS,
    },
  ];

  function redirection(redirect: string) {
    if (redirect !== pathname) {
      return navigate(redirect);
    }
  }

  useEffect(() => {
    const isMobile = window.innerWidth < 768;
    if (isMobile && containerRef.current && activeTabRef.current) {
      containerRef.current.scrollTo({
        left: activeTabRef.current.offsetLeft - 40,
        behavior: "smooth",
      });
    }
  }, [pathname]);

  return (
    <Container maxWidth="md" style={{ padding: 0, paddingBlock: 10 }}>
      <SEO {...applicationTitle} />
      <div className="max-md:p-3 w-full min-h-screen flex flex-col justify-start gap-16">
        <div className="flex flex-row justify-between items-center">
          <div className="grow flex flex-col justify-center items-start gap-6">
            <div className="text-[42px] font-xing-bold first-letter:capitalize leading-[1.2]">
              {t("job.applications.welcome.to.your.employment.area.label", {
                user: profile?.firstName ?? "user",
              })}
            </div>
            <div className="text-xl font-xing first-letter:capitalize">
              {t(
                "job.applications.here.you.will.find.everything.you.need.label"
              )}
            </div>
          </div>
          <div className="max-w-[260] w-[260] max-md:hidden">
            <ApplicationsIllustration primary="#FFB836" secondary={""} />
          </div>
        </div>

        <div
          ref={containerRef}
          className="flex overflow-x-auto md:overflow-hidden md:flex-wrap justify-start items-center gap-3 scrollbar-hide"
        >
          {tabs?.map((tab) => {
            const isActive =
              pathname === tab?.path.replace(":locale", `${i18n.language}`);
            return (
              <Button
                key={tab?.path}
                ref={isActive ? activeTabRef : null}
                onClick={() =>
                  redirection(tab?.path.replace(":locale", `${i18n.language}`))
                }
                color={isActive ? "primary" : "default"}
                radius="full"
                variant="solid"
                className="font-xing-bold text-md min-w-fit"
              >
                {tab?.title}
              </Button>
            );
          })}
        </div>

        <Outlet />
      </div>
    </Container>
  );
}
