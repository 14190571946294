import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { object, ref, string } from "yup";
import ChangePasswordForm from "../../_form/settings/ChangePasswordForm";
import { useUpdatePasswordMutation } from "../../../_redux/settings/services";
import { ChangePasswordInterface } from "../../../_redux/settings/api";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

interface UseDisclosureProps {
  isOpen?: boolean;
  onClose?(): void;
}

export default function ChangePasswordModal({
  isOpen,
  onClose,
}: UseDisclosureProps) {
  const { t } = useTranslation();
  const [updatePasswordAction, triggers] = useUpdatePasswordMutation();
  let initialValues = {
    oldPassword: undefined,
    plainPassword: undefined,
    confirmationPassword: undefined,
  };
  const passwordSchema = object({
    oldPassword: string().required("Password is required"),
    plainPassword: string().required("Password is required"),
    confirmationPassword: string()
      .required("Password is required")
      .oneOf([ref("plainPassword")], "Passwords must match"),
  });

  const handelUpdatePassword = async (values: ChangePasswordInterface) => {
    updatePasswordAction(values)
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", {
            value: t("settings.password.label", { count: 1 }),
          }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        onClose?.();
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  return (
    <Modal
      className="min-h-full"
      open={isOpen}
      onClose={onClose}
      title={t("update.specific", { value: t("professionalExperience.label") })}
    >
      <ModalOverflow>
        <ModalDialog variant="plain" layout="fullscreen">
          <ModalClose onClick={onClose} />
          <Container maxWidth="md">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              flex={3}
              maxWidth="md"
            >
              <h1 className="text-4xl font-xing-bold">
                {t("update.specific", { value: t("settings.password.label") })}
              </h1>
              <Formik
                initialValues={initialValues as never}
                onSubmit={handelUpdatePassword}
                validationSchema={passwordSchema}
              >
                <ChangePasswordForm triggers={triggers} onCancel={onClose} />
              </Formik>
            </Stack>
          </Container>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
