import React from 'react';
import CustomLoader from "../customLoader/CustomLoader";

interface ColoredTitledContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string,
    isLoading?: boolean,
    buttons?: JSX.Element[]
}

const ColoredTitledContainer: React.FC<ColoredTitledContainerProps> = ({ title, className, buttons, isLoading = false, ...props }) => {
    const customClassName = "w-full bg-[#f4f4f5] rounded-2xl p-1 gap-3";

    // Combine custom className with any className passed as props
    const combinedClassName = `${customClassName} ${className || ''}`;

    return (
        <div className="flex flex-col justify-between items-start">
            <div className="w-full flex flex-row justify-start max-md:justify-between items-center gap-3 h-[120px]">
                <div className="text-3xl text-star first-letter:capitalize font-xing-bold">
                    {title}
                </div>
                { (isLoading) ? <CustomLoader /> : buttons && buttons.map((button) => button) }
            </div>
            <div className={combinedClassName} {...props}>
                { props.children }
            </div>
        </div>
    );
}

export default ColoredTitledContainer;