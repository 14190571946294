import React, { useEffect } from "react";
import { Button } from "@nextui-org/react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDeleteCvCertificateMutation } from "../../../../_redux/auth/services";
import { ArchiveBoxXMarkIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CertificateInterface } from "../../../../pages/private/profile/ui/myProfile/MyCertificates";

import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

type CertificateEditModalProps = {
  certificate: CertificateInterface;
};

export default function CertificateDeleteModal({
  certificate,
}: CertificateEditModalProps) {
  const { t } = useTranslation();
  const [deleteCertificateAction, { isSuccess, isError, error, isLoading }] =
    useDeleteCvCertificateMutation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function deleteHandler(): void {
    deleteCertificateAction(certificate?.id)
      .unwrap()
      .then((response) => {
        toast.success(
          t("delete.message.specific", { value: t("certificate.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  }

  useEffect(() => {
    if (isSuccess) {
      toast.success(
        t("delete.message.specific", { value: t("certificate.label") }),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );
      handleClose();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      error?.data?.violations?.forEach(
        (violation: { propertyPath: string; message: any }) => {
          toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      );
    }
  }, [isError, error]);

  return (
    <>
      <Button
        isIconOnly
        onClick={handleOpen}
        color="danger"
        aria-label={t("delete.label")}
        className="p-0"
        size="sm"
        title={t("delete.label")}
        variant="light"
      >
        <ArchiveBoxXMarkIcon height={18} width={18} />
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("delete.specific", { value: t("certificate.label") })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("delete.specific", { value: t("certificate.label") })}
                </h1>
                <div className="flex flex-col justify-start items-center">
                  {t("delete.message.confirmation.specific", {
                    context: "female",
                    value: certificate.title,
                  })}
                </div>
                <div className="flex flex-row justify-center items-center py-24 gap-3">
                  <Button
                    onClick={handleClose}
                    disabled={isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    startContent={<XMarkIcon height={24} width={24} />}
                  >
                    {t("close.label")}
                  </Button>
                  <Button
                    isLoading={isLoading}
                    onClick={() => deleteHandler()}
                    disabled={isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    color="danger"
                    startContent={
                      <ArchiveBoxXMarkIcon height={24} width={24} />
                    }
                  >
                    {t("delete.label")}
                  </Button>
                </div>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
