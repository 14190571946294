import React, { useState } from "react";
import { Image } from "@nextui-org/react";
import { useNavigate, useRouteError } from "react-router-dom";
import { AppRoutes } from "./utils/appRoutes";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectToken } from "./_redux/auth/authSlice";

export default function RootError() {
  const { t, i18n } = useTranslation();
  const error = useRouteError();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  console.log(error);
  return (
    <div className="h-screen w-screen flex flex-col justify-center items-center">
      <div className="w-full flex flex-row justify-center items-start mt-20">
        <Image
          src="/svg/server_down.svg"
          alt="error 500"
          height={256}
          width={512}
        />
      </div>
      <button
        className="text-2xl font-xing-bold"
        onClick={() => window.location.reload()}
      >
        {t("try.again.label")}
      </button>
      {t("or.label")}

      <button
        className="text-2xl font-xing-bold"
        onClick={() =>
          navigate(
            AppRoutes[isAuth ? "PRIVATE_HOME" : "HOME"].replace(
              ":locale",
              i18n.language
            )
          )
        }
      >
        {t("back.home.label")}
      </button>
    </div>
  );
}
