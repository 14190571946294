import React from "react";
import { Spinner } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export default function Loading({
  label = "loading.label",
  fullscreen = false,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`${
        fullscreen ? "w-full h-screen" : ""
      } flex flex-row justify-center items-center`}
    >
      <Spinner
        label={t(label)}
        className="h-full w-full"
        size="lg"
        color="warning"
      />
    </div>
  );
}
