import React from "react";
import { NavLink } from "react-router-dom";
import MultiDirectionIcon from "../../../../components/multiDirectionsIcons/ArrowMultiDirectionIcon";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { useTranslation } from "react-i18next";

export default function MenuNavLink({ item }: { item: any }) {
  const { i18n } = useTranslation();
  return (
    <NavLink
      onClick={item?.onClick}
      to={
        item?.onClick
          ? undefined
          : item?.path?.replace(":locale", `${i18n.language}`)
      }
      className="flex flex-row justify-start items-start cursor-pointer gap-3"
    >
      <div className="shrink-0">{item?.icon}</div>
      <div className="grow flex flex-col justify-evenly items-start first-letter:capitalize">
        <h1 className="text-xl font-xing-bold">{item?.title}</h1>
        {item?.subtitle && <h1 className="text-lg">{item?.subtitle}</h1>}
      </div>
      <div className="hover:animate-spin">
        <MultiDirectionIcon
          rtlIcon={
            <ArrowRightAltOutlinedIcon
              fontSize="large"
              className="scale-x-[-1]"
            />
          }
          ltrIcon={<ArrowRightAltOutlinedIcon fontSize="large" />}
        />
      </div>
    </NavLink>
  );
}
