import SeeMore from "../../../../components/seeMore/SeeMore";
import TitledContainer from "../../../../components/containers/TitledContainer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setSearch } from "../../../../_redux/features/jobs/jobsSlice";
import { useAppDispatch } from "../../../../_redux/store";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";

import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ChildFriendlyOutlinedIcon from "@mui/icons-material/ChildFriendlyOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import PetsOutlinedIcon from "@mui/icons-material/PetsOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import AccessibleOutlinedIcon from "@mui/icons-material/AccessibleOutlined";
import HealthAndSafetyOutlinedIcon from "@mui/icons-material/HealthAndSafetyOutlined";
import CableOutlinedIcon from "@mui/icons-material/CableOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import _ from "lodash";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../_redux/auth/services";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";

export default function SmartOffers() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const { data: cv, isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });
  const [expandedSmartOffers, setExpandedSmartOffers] = useState(false);

  function isEmptyValue(val) {
    return (
      !val || (Array.isArray(val) && (val?.length === 0 || _.isEmpty(val)))
    );
  }

  const removeEmptyProperty = (arg: object): object => {
    return _.omitBy(arg, isEmptyValue);
  };

  const filterHandler = (values: object) => {
    const params = removeEmptyProperty({
      ...values,
      _page: 1,
      alertId: undefined,
    });
    dispatch(setSearch({ search: params }));
    navigate(
      AppRoutes.PRIVATE_JOBS_SEARCH[0].replace(":locale", `${i18n.language}`)
    );
  };

  const smartOffers = [
    {
      icon: <AccessibleOutlinedIcon />,
      title: t("inclusion.jobs.for.all.without.exception"),
      subtitle: t("inclusion.every.talent.counts.join.us"),
      backgroundColor: "#E8F0FF89",
      textColor: "#00346e",
      hidden: false,
      onClick: () => filterHandler({ acceptDisabledPeople: true }),
    },
    {
      icon: <HistoryToggleOffIcon />,
      title: t("flexible.hours.work.at.your.own.pace"),
      subtitle: t("flexible.hours.positions.with.flexible.hours"),
      backgroundColor: "rgba(232,241,241,0.51)",
      textColor: "#009c77",
      hidden: false,
      onClick: () => filterHandler({ isPartialTime: true }),
    },
    {
      icon: <RocketLaunchOutlinedIcon />,
      title: t("growth.develop"),
      subtitle: t("growth.opportunities"),
      backgroundColor: "#F3FAE683",
      textColor: "#007b80",
      hidden: false,
      onClick: () => filterHandler({ withHigherCareerLevel: true }),
    },
    {
      icon: <LanguageOutlinedIcon />,
      title: t("remote.work.work.where.you.want"),
      subtitle: t(
        "remote.work.enjoy.the.freedom.to.work.from.home.or.another.location"
      ),
      backgroundColor: "#f2e8ff",
      textColor: "#8a2ce8",
      hidden: false,
      onClick: () => filterHandler({ withRemote: true }),
    },
    {
      icon: <ScheduleOutlinedIcon />,
      title: t("part.time.the.perfect.balance.work.part.time"),
      subtitle: t(
        "part.time.offers.suitable.for.those.who.want.to.work.part.time"
      ),
      backgroundColor: "rgba(232,241,241,0.51)",
      textColor: "#009c77",
      hidden: false,
      onClick: () => filterHandler({ acceptDisabledPeople: true }),
    },
    {
      icon: <PaymentsOutlinedIcon />,
      title: t(
        "competitive.salary.value.your.talent.with.a.competitive.salary"
      ),
      subtitle: t(
        "competitive.salary.opportunities.exceeding.salary.expectations"
      ),
      backgroundColor: "#f2e8ff",
      textColor: "#8a2ce8",
      hidden: !cv?.salary,
      onClick: () => filterHandler({ withHigherSalary: true }),
    },
    {
      icon: <FactoryOutlinedIcon />,
      title: t("industry.work"),
      subtitle: t("industry.find"),
      backgroundColor: "#E8F0FF89",
      textColor: "#00346e",
      hidden: !cv?.function,
      onClick: () => filterHandler({ withPreferredActivitySector: true }),
    },
    {
      icon: <HealthAndSafetyOutlinedIcon />,
      title: t("social.benefits.a.job.that.takes.care.of.you"),
      subtitle: t("social.benefits.benefits.that.make.a.difference"),
      backgroundColor: "#F3FAE683",
      textColor: "#007b80",
      hidden: false,
      onClick: () => filterHandler({ withSocialAdvantage: true }),
    },
    {
      icon: <AttachMoneyOutlinedIcon />,
      title: t("performance.bonuses.perform.well.earn.more"),
      subtitle: t(
        "performance.bonuses.offers.including.performance.based.bonuses"
      ),
      backgroundColor: "#E8F0FF89",
      textColor: "#00346e",
      hidden: false,
      onClick: () => filterHandler({ withPerformanceBonus: true }),
    },
    {
      icon: <CallMadeOutlinedIcon />,
      title: t("training.grow.while.working"),
      subtitle: t(
        "training.access.to.jobs.offering.continuous.training.programs"
      ),
      backgroundColor: "rgba(232,241,241,0.51)",
      textColor: "#009c77",
      hidden: false,
      onClick: () => filterHandler({ withTraining: true }),
    },
    {
      icon: <AirportShuttleOutlinedIcon />,
      title: t("car.drive.a.company.car"),
      subtitle: t("car.offers.including.a.company.vehicle"),
      backgroundColor: "#f2e8ff",
      textColor: "#8a2ce8",
      hidden: false,
      onClick: () => filterHandler({ withCompanyVehicle: true }),
    },
    {
      icon: <CableOutlinedIcon />,
      title: t("freelance.work.freelance"),
      subtitle: t("freelance.offers.for.those.who.want.to.work.independently"),
      backgroundColor: "#E8F0FF89",
      textColor: "#00346e",
      hidden: false,
      onClick: () => filterHandler({ areFreelance: true }),
    },
    {
      icon: <AccessibleOutlinedIcon />,
      title: t("local.jobs.work.close.to.home"),
      subtitle: t("local.jobs.job.opportunities.near.you"),
      backgroundColor: "#f2e8ff",
      textColor: "#8a2ce8",
      hidden: !cv?.region,
      onClick: () => filterHandler({ onMyCity: true }),
    },
  ];

  const handleExpandSmartOffersClick = () => {
    setExpandedSmartOffers(!expandedSmartOffers);
  };

  return (
    <TitledContainer
      title={t("find.jobs.smart.way.to.find.job.offers.label")}
      subtitle={t(
        "find.jobs.discover.job.offers.that.meet.different.criteria.label"
      )}
    >
      <div className="flex flex-col items-start gap-6">
        <div className="cursor-pointer grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4 gap-4">
          {smartOffers
            ?.sort((a, b) => {
              if (a.onClick === undefined && b.onClick === undefined) {
                return 0;
              } else if (a.onClick === undefined) {
                return 1;
              } else if (b.onClick === undefined) {
                return -1;
              } else {
                return a?.onClick - b?.onClick;
              }
            })
            ?.slice(0, expandedSmartOffers ? 12 : 4)
            ?.filter((offert) => offert.hidden === false)
            ?.map((offer, i) => (
              <div
                key={i}
                onClick={offer?.onClick}
                style={{
                  backgroundColor: offer.backgroundColor,
                  color: offer.textColor,
                }}
                className={`flex ${
                  offer.onClick
                    ? "hover:scale-105"
                    : "cursor-not-allowed opacity-50"
                } transition-all duration-150 flex-col justify-between gap-1 items-start rounded-2xl p-4`}
              >
                {offer.icon && offer.icon}
                <p className="font-xing-bold">{offer.title}</p>
                <p className="font-xing text-xs">{offer.subtitle}</p>
              </div>
            ))}
        </div>

        <SeeMore
          handler={handleExpandSmartOffersClick}
          expanded={expandedSmartOffers}
        />
      </div>
    </TitledContainer>
  );
}
