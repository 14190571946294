import { Container } from "@mui/material";
import TitledContainer from "../../../components/containers/TitledContainer";
import { useTranslation } from "react-i18next";
import { useGetArticleQuery } from "../../../_redux/features/blog/services";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectToken } from "../../../_redux/auth/authSlice";
import React, { useState } from "react";
import { useGetSingleJobQuery } from "../../../_redux/features/jobs/services";
import BackButton from "../../../components/backButton/BackButton";
import BlogListLoading, {
  BlogArticleDetailsLoading,
} from "../blog/ui/BlogListLoading";
import moment from "moment";
import { useTransition } from "react";

export default function ArticleDetails() {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const {
    data: article,
    isLoading,
    isFetching,
  } = useGetArticleQuery(id, { skip: !id });

  return (
    <Container maxWidth="md">
      <div className="w-full flex flex-col justify-start items-start">
        <BackButton />
        <TitledContainer
          title={article?.title ?? "Article"}
          className="flex flex-col"
        >
          <div
            className={`w-full flex flex-col justify-between items-start gap-3`}
          >
            {isLoading ? (
              <BlogArticleDetailsLoading />
            ) : (
              <>
                <p className="italic text-sm first-letter:uppercase text-gray-500">
                  {moment()
                    .locale(`${i18n.language}-DZ`)
                    .to(article?.publishedDate)}
                </p>
                <div className="w-full">
                  <img
                    className="object-cover h-[190px] w-full"
                    src={`${process.env.REACT_APP_MEDIA_BASE_URL}${article?.publicMediaLarge}`}
                    onError={(e) => {
                      e.target.src = "/images/prototype-event-background.jpg";
                    }}
                    alt={article?.slug}
                  />
                </div>
                <div className="flex flex-col justify-between items-start gap-3">
                  <p className="shrink-0 font-xing-bold items-center text-start text-xl first-letter:uppercase line-clamp-3">
                    {article?.title}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: article?.content || "",
                    }}
                    className="grow font-xing text-justify text-md first-letter:uppercase"
                  />
                  <a
                    href="https://www.emploipartner.com"
                    className="shrink-0 font-xing text-start text-sm text-[#dbe2e6]"
                  >
                    <span className="text-yellow-400">Emploi</span>Partner
                  </a>
                </div>
              </>
            )}
          </div>
        </TitledContainer>
      </div>
    </Container>
  );
}
