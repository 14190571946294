import React, {useState} from "react";
import {Container} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useGetUserNetworkQuery} from "../../../../_redux/features/network/services";
import {useGetProfileQuery} from "../../../../_redux/auth/services";
import EmptyNetwork from "../EmptyNetwork";
import {Paginator} from "../../../../components/paginator/Paginator";
import ContactItem from "./ContactItem";

export default function MyContactsList({filters = { _page: 1 }}: {filters?: { _page: number }}) {

    const {t} = useTranslation();
    const [page, setPage] = useState<number>(filters?._page || 1);
    const {data: profile} = useGetProfileQuery()
    const {data, isLoading, isFetching} = useGetUserNetworkQuery(
        {id: profile?.id, filters: filters}, {skip: !profile}
    )
    const {list: users, pagination} = data || {}

    return (
        <Container maxWidth="md">
                {
                    isLoading
                        ?
                        <>
                            <ContactItem user={null} isLoading/>
                            <ContactItem user={null} isLoading/>
                            <ContactItem user={null} isLoading/>
                        </>
                        :
                        <>
                            {
                                users?.length
                                    ?
                                    users?.map((user: any, i: number) => <ContactItem user={user} key={i}/>)
                                    :
                                    <EmptyNetwork placeholder={t("network.yours.label", {count: users?.length})}/>
                            }
                        </>
                }

                {
                    <Paginator
                        count={pagination?.last?._page ?? 1}
                        disabled={isFetching || isLoading}
                        onChange={(e, page) => setPage(page)}
                        page={page ?? 1}
                    />
                }
        </Container>
    )
}