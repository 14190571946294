import {Form, useFormikContext} from "formik";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {MutationResultSelectorResult} from "@reduxjs/toolkit/query";
import {FormControl, FormControlLabel, RadioGroup} from "@mui/material";
import BlackRadio from "../../blackEdition/BlackRadio";
import {toast} from "react-toastify";
import {Button} from "@nextui-org/react";
import CustomSpinner from "../../spinner/CustomSpinner";
import SendIcon from "@mui/icons-material/Send";

interface CoordinatesFormInterface {
    triggers: MutationResultSelectorResult<any>;
    onCancel?: () => void|undefined;
}

export default function CoordinatesForm({triggers, onCancel}: CoordinatesFormInterface) {

    const {t} = useTranslation()
    const { isSuccess, isLoading, isError, error, reset } = triggers
    const {setErrors: setErrorsHook, setSubmitting, initialValues, setFieldValue, resetForm, isSubmitting} = useFormikContext();

    const options = [
        { title: t("settings.confidentiality.types.public.label"), value: "public" },
        { title: t("settings.confidentiality.types.invisible.label"), value: "invisible" },
        { title: t("settings.confidentiality.types.private.label"), value: "private" }
    ]

    useEffect(() => {

        if (isSuccess) {
            toast.success(t("operation.success.label"), {
                position: toast.POSITION.BOTTOM_LEFT
            });


            if (typeof onCancel == "function") {
                onCancel();
            }

            return () => {
                reset()
                resetForm()
            }

        }

    }, [isSuccess]);

    useEffect(() => {
        setSubmitting(isLoading)
    }, [isLoading]);

    useEffect(() => {
        if (isError) {

            let errors = {}

            error?.data?.violations?.forEach((violation: { propertyPath: string; message: any; }) => {
                if (violation?.propertyPath in initialValues) {
                    errors[violation?.propertyPath] = violation?.message;
                } else {
                    toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            });

            if (errors) {
                setErrorsHook(errors)
            }
        }
    }, [isError]);

    return (
        <Form className="w-full flex flex-col justify-between gap-4 font-xing">

            <div>
                <div>
                    <p>
                        { t("settings.confidentiality.whoCanSeeYourCoordinates.label") }
                    </p>
                </div>

                <div className="p-3">
                    <FormControl className="p-3">

                        <RadioGroup
                            defaultValue={initialValues?.visibility}
                            name="options-group"
                        >
                            { options.map((detail) =>
                                <FormControlLabel
                                    key={detail.value}
                                    value={detail.value}
                                    control={ <BlackRadio /> }
                                    label={detail.title}
                                    name="visibility"
                                    onChange={(e) => {
                                        setFieldValue("visibility", detail.value)
                                    }}
                                    className="select-none"
                                />
                            )}
                        </RadioGroup>

                    </FormControl>
                </div>
            </div>

            <div className="w-full flex flex-row justify-center items-center gap-6 col-span-2">
                <Button
                    type="submit"
                    spinner={<CustomSpinner />}
                    disabled={isSubmitting || isLoading}
                    isLoading={isSubmitting || isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    color="warning"
                    startContent={ (isSubmitting || isLoading) ? null : <SendIcon fontSize="small" /> }
                >
                    { t("update.label") }
                </Button>
            </div>

        </Form>
    )
}