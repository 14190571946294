import React, {useState} from "react";
import {Container} from "@mui/material";
import TitledContainer from "../../../../components/containers/TitledContainer";
import MyContactSearchForm from "./MyContactSearchForm";
import MyContactsList from "./MyContactsList";
import {useTranslation} from "react-i18next";
import {useGetProfileQuery} from "../../../../_redux/auth/services";
import {useGetUserNetworkQuery} from "../../../../_redux/features/network/services";
import {useLocation} from "react-router-dom";

export default function MyContacts() {

    const {state} = useLocation();
    const [filters, setFilters] = useState(state?.filters || {_page: 1});
    const {t} = useTranslation();
    const {data: profile} = useGetProfileQuery()

    const {data: contacts, isLoading, isFetching} = useGetUserNetworkQuery(
        {id: profile?.id, filters}, {skip: !profile}
    )
    const {totalItems: totalContacts} = contacts || {totalItems: 0}

    return (
        <>
            <Container maxWidth="md">
                <div className="flex flex-col justify-start items-center gap-6">
                    <TitledContainer
                        title={t("network.contacts.yours.label", {count: totalContacts})}
                        className="flex flex-col justify-start gap-6"
                    >
                        <MyContactSearchForm onChange={setFilters} isLoading={isLoading || isFetching}/>
                        <MyContactsList filters={filters}/>
                    </TitledContainer>
                </div>
            </Container>
        </>
    )
}