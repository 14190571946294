import React from "react";
import { Button, Container } from "@mui/material";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EmptyInvitationsIllustration from "./EmptyInvitationsIllustration";

export default function NoInvitations() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full min-h-[360px] bg-white rounded-xl ">
      <Container>
        <div className="flex flex-col justify-around items-center gap-3">
          <EmptyInvitationsIllustration />
          <p className="text-lg font-xing-bold text-center">
            {t("invitations.label", { count: 0 })}
          </p>
          <Button
            color="warning"
            variant="contained"
            onClick={() =>
              navigate(
                AppRoutes.PRIVATE_NETWORK.replace(":locale", `${i18n.language}`)
              )
            }
          >
            {t("network.find.on.label")}
          </Button>
        </div>
      </Container>
    </div>
  );
}
