import {ButtonGroup} from "@nextui-org/react";
import React from "react";
import {CandidateInterface} from "../../../_redux/features/events/interfaces";
import SendInvitationButton from "./SendInvitationButton";
import CancelInvitationButton from "./CancelInvitationButton";
import ViewProfileButton from "./ViewProfileButton";
import RefuseInvitationButton from "./RefuseInvitationButton";
import AcceptInvitationButton from "./AcceptInvitationButton";
import SendMessageButton from "./SendMessageButton";

export default function FriendShipButton({user, showViewProfile = false}: {
    user: CandidateInterface,
    showViewProfile?: boolean
}) {

    const sendInvitationButton = <SendInvitationButton user={user}/>

    const cancelButton = <CancelInvitationButton user={user}/>

    const profileViewButton = showViewProfile ? <ViewProfileButton user={user}/> : <SendMessageButton user={user}/>

    const acceptOrRefuse = <ButtonGroup>
        <AcceptInvitationButton user={user}/>
        <RefuseInvitationButton user={user}/>
    </ButtonGroup>

    if (user?.isFriend) {
        return profileViewButton;
    }

    switch (user?.friendShip?.status) {
        case 'ACCEPTED':
            return profileViewButton;
        case 'PENDING':
            let invitedUri = null;
            if (typeof user?.friendShip?.invited === "string") {
                invitedUri = user?.friendShip?.invited;
            } else if (typeof user?.friendShip?.invited === "object") {
                invitedUri = user?.friendShip?.invited["@id"];
            }
            return (invitedUri === user["@id"]) ? cancelButton : acceptOrRefuse;
        default:
            return sendInvitationButton;
    }
}