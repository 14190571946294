import React from "react";
import TitledContainer from "../../../components/containers/TitledContainer";
import { Container } from "@mui/material";
export default function OptForProJobs() {
  return (
    <Container maxWidth="md">
      <TitledContainer title="OptForProJobs" className="h-screen flex flex-col">
        <p>OptForProJobs</p>
      </TitledContainer>
    </Container>
  );
}
