import React from "react";
import { useTranslation } from "react-i18next";
import { SkillsList } from "./myProfile/SkillsList";
import TitledContainer from "../../../../components/containers/TitledContainer";
import { useGetCvQuery } from "../../../../_redux/auth/services";
import { useParams } from "react-router-dom";
import { useGetSingleCandidateQuery } from "../../../../_redux/features/network/services";
import { Skeleton } from "@mui/material";

export default function Skills() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: profile } = useGetSingleCandidateQuery(parseInt(id), {
    skip: !id,
  });
  const { data: cv, isLoading: isCvLoading } = useGetCvQuery(profile?.id, {
    skip: !profile?.id,
  });

  if (isCvLoading) {
    return (
      <>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={"40%"}
          height={30}
        />
        <div className="flex flex-row gap-2">
          <Skeleton animation="wave" variant="rounded" width={80} height={20} />
          <Skeleton animation="wave" variant="rounded" width={60} height={20} />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={120}
            height={20}
          />
          <Skeleton animation="wave" variant="rounded" width={60} height={20} />
          <Skeleton animation="wave" variant="rounded" width={80} height={20} />
        </div>
      </>
    );
  }

  if (!cv?.competences?.length && !isCvLoading) {
    return null;
  }

  return (
    <TitledContainer
      className="py-3 flex flex-row gap-1"
      title={t("skills.label")}
    >
      <SkillsList skills={cv?.competences} />
    </TitledContainer>
  );
}
