import React from "react";
import { SvgIllustrationInterface } from "./AttentesProIllustration";

export default function PersonalInformationIllustration({
  primary,
  secondary,
}: SvgIllustrationInterface) {
  return (
    <>
      <svg
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="85"
        height="85"
        data-xds="IllustrationSpotLockedData"
        className="sc-1p2vrgg-1 idmBTu"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.292 2.087V62.77l38.047.344 10.175-10.2V2.086H8.292z"
          fill="#FFFFFE"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37.312 26.862l-.02-6.203a4.719 4.719 0 00-9.437.092l.015 5.705-2.349 1.108-.016-6.83a7.068 7.068 0 1114.135-.138l.021 7.245-2.349-.98zm8.828 35.204v-9.561h9.561l-9.56 9.56z"
          fill={secondary}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.734 1.087v6.94l2.754.001v58.885h-45.32l-.001-3.084-7.655-.068V1.087h50.222zm-2 2H9.512v58.692l36.634.33 9.588-9.61V3.087zm-23.15 21.387c7.313 0 13.24 5.928 13.24 13.241s-5.927 13.241-13.24 13.241c-7.313 0-13.242-5.928-13.242-13.24 0-7.314 5.929-13.242 13.241-13.242zm0 2c-6.209 0-11.242 5.033-11.242 11.241 0 6.209 5.033 11.241 11.241 11.241 6.209 0 11.241-5.032 11.241-11.24 0-6.21-5.032-11.242-11.24-11.242z"
          fill="#1D2124"
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.354 40.9l-1.365-2.806a2.994 2.994 0 001.555-3.09 3.009 3.009 0 00-2.604-2.525 2.995 2.995 0 00-1.764 5.615L29.812 40.9a.536.536 0 00.482.77h4.578a.536.536 0 00.482-.77z"
          fill={primary}
        ></path>
      </svg>
    </>
  );
}
