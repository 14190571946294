import React from "react";
import {Container} from "@mui/material";

const LoadingSettings = () => {
    return (
        <Container maxWidth="md">
            <div className="w-full min-h-screen flex flex-col justify-start items-stretch animate-pulse gap-6">
                <div className="h-[20px] w-36 bg-gray-100" />
                <div className="h-[150px] w-[150px] rounded-3xl bg-gray-100" />
                <div className="h-[20px] w-36 bg-gray-100" />
                <div className="h-[20px] w-44 bg-gray-100" />
                <div className="h-[20px] w-32 bg-gray-100" />
                <div className="h-[32px]" />
                <div className="h-[32px] w-[423px] bg-gray-100" />
                <div className="h-[32px] w-[423px] bg-gray-100" />
                <div className="h-[32px] w-[423px] bg-gray-100" />
                <div className="h-[32px] w-[423px] bg-gray-100" />
            </div>
        </Container>
    )
}

export default LoadingSettings