import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useState,
} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { JobAlertInterface, JobAlertItemPropsInterface } from "./JobAlertItem";
import { useDeleteJobAlertMutation } from "../../../../../_redux/features/jobs/services";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { TransitionProps } from "@mui/material/transitions";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
} from "@mui/material";
import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Close, Delete } from "@mui/icons-material";
import { toastMessageError } from "../../../../../_helpers/toastErrorMessage";

export interface SimpleDialogProps {
  alert: JobAlertInterface;
  open: boolean;
  onClose: (value: string) => void;
}

export const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any, any>;
  },
  ref: Ref<unknown>
) {
  return <Slide timeout={4000} direction="up" ref={ref} {...props} />;
});

function DeleteJobAlertDialog(props: SimpleDialogProps) {
  const { onClose, open, alert } = props;
  const [deleteJobAlertAction, deleteJobAlertTriggers] =
    useDeleteJobAlertMutation();
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    onClose(true);
  };

  function handleDeleteAlert() {
    deleteJobAlertAction(alert?.id)
      .unwrap()
      .then((response) => {
        toast.success(
          t("delete.message.specific", { value: t("search.alert.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        onClose(true);
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  }

  return (
    <Dialog
      lang={i18n.language}
      open={open}
      TransitionComponent={Transition}
      keepMounted={
        deleteJobAlertTriggers?.isLoading || deleteJobAlertTriggers?.isFetching
      }
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        {t("delete.specific", { value: t("search.alert.label") })}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t("delete.message.confirmation.specific", {
            context: "female",
            value: t("search.alert.label"),
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          startContent={
            deleteJobAlertTriggers?.isLoading ||
            deleteJobAlertTriggers?.isFetching ? (
              <CircularProgress color="error" size={17} className={"mx-1"} />
            ) : (
              <Delete />
            )
          }
          variant="flat"
          color="danger"
          disabled={
            deleteJobAlertTriggers?.isLoading ||
            deleteJobAlertTriggers?.isFetching
          }
          onClick={() => handleDeleteAlert(alert?.id)}
        >
          {t("delete.label")}
        </Button>
        <Button
          variant="flat"
          color="primary"
          onClick={handleClose}
          startContent={<Close />}
        >
          {t("close.label")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default function DeleteJobAlertButton({
  alert,
}: JobAlertItemPropsInterface) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        radius="full"
        variant="light"
        color="primary"
      >
        <DeleteOutlinedIcon fontSize="small" />
        <p className="font-xing-bold text-sm text-black first-letter:capitalize">
          {t("delete.label")}
        </p>
      </Button>
      <DeleteJobAlertDialog open={open} onClose={handleClose} alert={alert} />
    </>
  );
}
