import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import {
  useGetSingleEventQuery,
  useEventApplyMutation,
} from "../../../_redux/features/events/services";
import moment from "moment/moment";
import { mediaRoutes } from "../../../utils/mediaRoutes";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { CandidateInterface } from "../../../_redux/features/events/interfaces";
import { toast } from "react-toastify";
import Countdown from "../../../components/countdown/Countdown";
import EventItemModalLoading, {
  BackgroundHeroLoading,
} from "../../../components/modals/eventItemModal/EventItemModalLoading";
import Candidate from "../../../components/eventsComponent/candidate/Candidate";
import { Container } from "@mui/material";
import SeeMore from "../../../components/seeMore/SeeMore";
import "swiper/css/pagination";
import "swiper/css";
import BackButton from "../../../components/backButton/BackButton";
import { useParams } from "react-router-dom";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

export function EventDetails() {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const { id } = useParams();
  const { data: event, isLoading } = useGetSingleEventQuery(id, { skip: !id });

  const [
    eventApplyAction,
    { isLoading: isApplying, isError, error: applyError, isSuccess },
  ] = useEventApplyMutation();

  const [expandedSmartOffers, setExpandedSmartOffers] = useState(false);
  const handleExpandSmartOffersClick = () => {
    setExpandedSmartOffers(!expandedSmartOffers);
  };

  function eventApply() {
    eventApplyAction({
      event: event?.["@id"],
      cv: `/api/candidates/${profile?.id}/cv`,
    })
      .unwrap()
      .then((response) => {
        toast.success(t("events.messages.success.label"), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  }

  return (
    <Container maxWidth="lg">
      <div className="min-h-screen w-full">
        <div className="w-full bg-white sticky z-20 max-md:top-[56px] top-[64px] min-h-[60px] flex flex-col justify-center">
          <div className="flex flex-row justify-between items-center">
            <BackButton />
          </div>
        </div>
        {isLoading ? (
          <BackgroundHeroLoading />
        ) : (
          <div className="w-full flex flex-col justify-between items-start p-0">
            <img
              className="object-cover h-[300px] w-full"
              src={`${mediaRoutes.EVENT_TEMPLATE}${event?.template?.heroAreaImage}`}
              onError={(e) => {
                e.target.src = "/images/prototype-event-background.jpg";
              }}
              alt={event?.name}
            />
            <div className="rounded-xl ms-7 p-2 bg-white">
              <Countdown
                backgroundColor="bg-transparent"
                alignContainers="row"
                startDate={event?.startDate as string}
                endDate={event?.endDate as string}
              />
            </div>
          </div>
        )}
        <div className="w-full p-0">
          {isLoading ? (
            <EventItemModalLoading />
          ) : (
            <div className="flex flex-col justify-start gap-3">
              <div className="relative w-full flex flex-col justify-start gap-6 items-center overflow-hidden px-0 first-letter:capitalize">
                <div className="w-full flex flex-wrap justify-between items-center gap-3">
                  <div className="shrink-0 flex flex-col justify-center">
                    <p className="text-black font-xing-bold text-2xl">
                      {event?.name}
                    </p>
                    <p className="text-black font-medium text-xl">
                      {t("created.by.label", {
                        user: event?.createdBy?.fullname,
                      })}
                    </p>
                  </div>
                  <div className="flex flex-row justify-center">
                    <p className="text-black font-xing-bold text-2xl">
                      {event?.template?.title}
                    </p>
                  </div>
                  <div className="shrink-0">
                    <Button
                      onClick={event?.isParticipant ? () => {} : eventApply}
                      disabled={isApplying || isLoading || event?.isParticipant}
                      isLoading={isApplying || isLoading}
                      radius="full"
                      variant="flat"
                      className="bg-[#61F1A4] text-black font-xing-bold rounded-full first-letter:capitalize"
                    >
                      {event?.isParticipant
                        ? t("events.participated.already")
                        : t("events.participate")}
                    </Button>
                  </div>
                </div>
                <div className="w-full flex flex-col justify-evenly items-start first-letter:capitalize">
                  <div className="font-xing-bold flex flex-row justify-start gap-3">
                    <span>
                      {t("events.state.from.label", {
                        date: moment(event?.startDate).format("ll"),
                      })}
                    </span>
                    <span>
                      {t("events.state.to.label", {
                        date: moment(event?.endDate).format("ll"),
                      })}
                    </span>
                  </div>
                  <div className="text-gray-200 first-letter:capitalize">
                    {event?.location}
                  </div>
                </div>
                <div className="w-full flex flex-col justify-evenly items-start">
                  <div className="text-black first-letter:capitalize text-justify">
                    {event?.template?.description}
                  </div>
                </div>
                <div className="w-full flex flex-col justify-evenly items-start">
                  <p className="text-black font-xing-bold text-2xl">
                    {t("events.members.who.participate.label")}
                  </p>
                </div>
                <div className="w-full">
                  {event?.candidates?.length > 0 ? (
                    <>
                      <div className="grid max-sm:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-5 gap-4">
                        {event?.candidates?.length > 0 &&
                          event?.candidates
                            ?.slice(0, expandedSmartOffers ? 12 : 5)
                            .map(
                              (
                                candidate: CandidateInterface,
                                index: number
                              ) => (
                                <Candidate
                                  key={index}
                                  you={
                                    profile?.id === candidate?.id
                                      ? t("events.you.label")
                                      : ""
                                  }
                                  candidate={candidate}
                                />
                              )
                            )}
                      </div>
                      {event?.candidates?.length > 5 && (
                        <SeeMore
                          handler={handleExpandSmartOffersClick}
                          expanded={expandedSmartOffers}
                        />
                      )}
                    </>
                  ) : (
                    <div className="flex flex-row justify-center items-center">
                      {t("events.members.label_zero")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
