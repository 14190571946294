import {createApi} from '@reduxjs/toolkit/query/react'
import {apiPlatformResponseTransform, globalFetchBaseQuery, invalidatesList, providesList} from "../../fetchBaseQuery";
import {filterObjToString} from "../../../_helpers/urlHelpers";
import {ChatRoomInterface} from "./chatRoomInterface";
import {MessageInterface} from "../../../pages/private/conversation/Conversation";
import _ from "lodash";
import {removeEmptyProperty} from "../jobs/services";

let previousArgs = undefined
let previousChatRoomArgs = undefined

export const prepareArgs = (previousArgs: any|undefined, arg: any|undefined): [any|undefined, any|undefined] => {
    return [removeEmptyProperty(previousArgs), removeEmptyProperty(arg)]
}


// Define a service using a base URL and expected endpoints
export const chatRoomApi = createApi({
    reducerPath: 'chatRoomsApi',
    credentials: true,
    tagTypes: ['chatRooms', 'chatRoom', 'messages', 'message'],
    baseQuery: globalFetchBaseQuery,
    endpoints: (builder) => ({
        getUserChatRooms: builder.query<any, object>({
            query: ({id, filters = {_page: 1}}: {id: number, filters: {_page: number}}) => {
                return `/api/users/${id}/chat_rooms?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'chatRooms'),
            transformResponse: apiPlatformResponseTransform,
            keepUnusedDataFor: 10,
            forceRefetch: ({ currentArg, previousArg }) => {
                return !_.isEqual(currentArg, previousArg);
            },
            merge: (currentCache, newItems, { arg }) => {

                [previousChatRoomArgs, arg] = prepareArgs(previousChatRoomArgs, arg)
                _.unset(previousChatRoomArgs, '_page');
                _.unset(arg, '_page');

                if (currentCache?.list && !_.isEqual(previousChatRoomArgs, arg) && previousChatRoomArgs?.id === arg?.id) {

                    return   {
                        ...currentCache,
                        ...newItems,
                        list: _.uniqBy([...currentCache?.list, ...newItems?.list], "@id")
                            .sort((a: MessageInterface, b: MessageInterface) => b?.id - a?.id)
                    };
                }

                previousChatRoomArgs = arg

                return newItems;
            },
            serializeQueryArgs: ({ endpointName }) => {
                const newEndpointName = endpointName;
                if (newEndpointName.page) {
                    delete newEndpointName.page;
                }
                return newEndpointName;
            },
        }),
        getChatRoomWithFriend: builder.query<any, number|undefined>({
            query: (id: number) => {
                return `/api/chat_rooms/with_friend/${id}`
            },
            providesTags: (result) => providesList(result, 'chatRooms'),
        }),
        getChatRoom: builder.query<any, number>({
            query: (id: number) => {
                return `/api/chat_rooms/${id}`
            },
            providesTags: (result) => providesList(result, 'chatRooms'),
        }),
        getChatRoomMessages: builder.query<any, object>({
            query: ({id, filters = {_page: 1}}: {id: number, filters: {_page: number}}) => {
                return `/api/chat_rooms/${id}/messages?${filterObjToString(filters)}`
            },
            providesTags: (result) => providesList(result, 'messages'),
            transformResponse: apiPlatformResponseTransform,
            forceRefetch: ({ currentArg, previousArg }) => {
                return !_.isEqual(currentArg, previousArg);
            },
            merge: (currentCache, newItems, { arg }) => {

                [previousArgs, arg] = prepareArgs(previousArgs, arg)
                _.unset(previousArgs, '_page');
                _.unset(arg, '_page');

                if (currentCache?.list && !_.isEqual(previousArgs, arg) && previousArgs?.id === arg?.id) {

                    return   {
                        ...currentCache,
                        ...newItems,
                        list: _.uniqBy([...currentCache?.list, ...newItems?.list], "@id")
                            .sort((a: MessageInterface, b: MessageInterface) => b?.id - a?.id)
                    };
                }

                previousArgs = arg

                return newItems;
            },
            serializeQueryArgs: ({ endpointName }) => {
                const newEndpointName = endpointName;
                if (newEndpointName.page) {
                    delete newEndpointName.page;
                }
                return newEndpointName;
            },
        }),
        sendMessage: builder.mutation<ChatRoomInterface, number>({
            query: (body) => {
                return {
                    url: `/api/messages`,
                    method: 'POST',
                    body,
                }
            },
        }),
        setSeenMessages: builder.mutation<ChatRoomInterface, number>({
            query: (id) => {
                return {
                    url: `/api/messages/${id}/seen`,
                    method: 'PATCH',
                    body: {}
                }
            },
            invalidatesTags: (result, error, arg) => invalidatesList(["chatRooms", "messages"], arg.id),
        }),
        getUnreadMessages: builder.query<any, number|undefined>({
            query: (id: number) => {
                return `api/users/${id}/unread_messages`
            },
            providesTags: (result) => providesList(result, 'chatRooms'),
            keepUnusedDataFor: 0,
        }),
    }),

})


export const {
    useSendMessageMutation,
    useSetSeenMessagesMutation,
    useGetUserChatRoomsQuery,
    useGetChatRoomQuery,
    useGetChatRoomMessagesQuery,
    useGetUnreadMessagesQuery,
    useGetChatRoomWithFriendQuery,
} = chatRoomApi