import React from "react";
import {Image} from "@nextui-org/react";

export default function EventsLoading () {
    return (
        <div className="grid max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-2 max-xl:grid-cols-1 max-2xl:grid-cols-2 2xl:grid-cols-3 gap-6">
            { Array.from(Array(3).keys()).map(() => <EventsItemLoading />) }
        </div>
    )
}

export function EventsFetching () {

    return (
        <>
            { Array.from(Array(6).keys()).map(() => <EventsItemLoading />) }
        </>
    )
}

export function EventsItemLoading () {
    return (
        <div className="relative animate-pulse h-[420px] min-h-[420px] max-h-[420px] border-1 border-gray-100 bg-white overflow-hidden rounded-3xl cursor-pointer p-3">
            <div className="relative w-full h-full flex flex-col justify-between items-center gap-1">
                <div className="w-full shrink-0 flex flex-row justify-between items-center">
                    <div className="rounded-xl h-[40px] w-[117px] bg-gray-100 focus:outline-none" />
                    <div className="rounded-full h-[40px] w-[40px] bg-gray-100 focus:outline-none" />
                </div>
                <div className="w-full flex flex-col justify-center gap-6">
                    <div className="w-full flex  flex-col justify-evenly items-start gap-1">
                        <div className="rounded-xl w-[140px] h-[20px] bg-gray-100 focus:outline-none" />
                        <div className="rounded-xl w-[100px] h-[20px] bg-gray-100 focus:outline-none" />
                    </div>
                    <div className=" w-full grow flex flex-row justify-between items-center gap-1">
                        <div className="flex flex-col justify-evenly items-start gap-1">
                            <div className="rounded-xl w-[90px] h-[20px] bg-gray-100 focus:outline-none" />
                            <div className="rounded-xl w-[70px] h-[20px] bg-gray-100 focus:outline-none" />
                        </div>
                        <div className="rounded-full h-[60px] w-[60px] bg-gray-100 focus:outline-none" />
                    </div>
                </div>
                <div className="w-full flex flex-col justify-between items-start gap-1 px-3">
                    <div className="rounded-xl w-[240px] h-[20px] bg-gray-100 focus:outline-none" />
                    <div className="rounded-xl w-[200px] h-[20px] bg-gray-100 focus:outline-none" />
                    <div className="rounded-xl w-[220px] h-[20px] bg-gray-100 focus:outline-none" />
                </div>
            </div>
        </div>
    )
}

export function EventsItemNotFound ({message, height = "320"} : {message: string, height: string}) {

    return (
        <div className={`relative flex flex-col justify-center items-center w-full max-w-full min-w-full h-[${height}px] min-h-[${height}px] max-h-[${height}px] border-1 border-gray-100 bg-white overflow-hidden rounded-3xl p-3`}>
            <Image height={200} width={200} src="/svg/prototype-icon-no-result.svg" />
            <h1 className="text-xl font-xing-bold">
                { message }
            </h1>
        </div>
    )
}

export function NextEventItemLoading () {
    return (
        <div className="relative animate-pulse w-full max-w-full min-w-full h-[320px] min-h-[320px] max-h-[320px] border-1 border-gray-100 bg-white overflow-hidden rounded-3xl cursor-pointer p-3">
            <div className="relative w-full h-full flex flex-col justify-between items-center gap-1">
                <div className="w-full shrink-0 flex flex-row justify-between items-center">
                    <div className="rounded-xl h-[40px] w-[117px] bg-gray-100 focus:outline-none" />
                    <div className="rounded-full h-[40px] w-[40px] bg-gray-100 focus:outline-none" />
                </div>
                <div className="w-full flex flex-col justify-center">
                    <div className=" w-full grow flex flex-row justify-between items-center gap-1">
                        <div className="flex flex-col justify-evenly items-start gap-1">
                            <div className="rounded-xl w-[90px] h-[20px] bg-gray-100 focus:outline-none" />
                            <div className="rounded-xl w-[70px] h-[20px] bg-gray-100 focus:outline-none" />
                        </div>
                        <div className="flex flex-row justify-between gap-3 max-xl:hidden">
                            <div className="rounded-xl h-[40px] w-[110px] bg-gray-100 focus:outline-none" />
                            <div className="rounded-xl h-[40px] w-[110px] bg-gray-100 focus:outline-none" />
                            <div className="rounded-xl h-[40px] w-[110px] bg-gray-100 focus:outline-none" />
                        </div>
                        <div className="rounded-full h-[60px] w-[60px] bg-gray-100 focus:outline-none" />
                    </div>
                </div>
                <div className="w-full flex flex-col justify-between items-center gap-1 px-3">
                    <div className="rounded-xl w-1/2 h-[20px] bg-gray-100 focus:outline-none" />
                    <div className="rounded-xl w-1/3 h-[20px] bg-gray-100 focus:outline-none" />
                </div>
            </div>
        </div>
    )
}