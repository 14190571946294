import React from "react";
import CustomUserAvatar from "../../customAvatar/CustomUserAvatar";
import {NavLink, useParams} from "react-router-dom";
import {ChatRoomInterface} from "../../../_redux/features/chatRoom/chatRoomInterface";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import {CandidateInterface} from "../../../_redux/features/events/interfaces";
import {Badge} from "@mui/material";
import moment from "moment";

type sideChatModel = {
    chatRoom: ChatRoomInterface,
}

export default function SideChatItem({chatRoom}: sideChatModel) {
    const {id} = useParams()
     const {data: profile} = useGetProfileQuery();
    const chatWith = chatRoom?.users?.find((user: CandidateInterface) => user?.id !== profile?.id);



    return (
        <div
            className={` ${chatRoom?.id === parseInt(id) ? " bg-gray-100 rounded-lg " : null} w-full cursor-pointer hover:bg-gray-200 transition-all duration-250 hover:rounded-lg my-1 p-1 select-none`}>
            <NavLink to={`/chat/${chatRoom?.id}`} className="flex flex-row justify-start items-center gap-3">
                <div className="shrink-0">
                    <Badge badgeContent={chatRoom?.unreadMessages} color="error">
                        <CustomUserAvatar
                            className="object-cover overflow-hidden h-12 w-12"
                            src={chatWith?.imageUrl}
                        />
                    </Badge>
                </div>
                <div>
                    <div className="text-sm font-xing-bold text-black">{chatWith?.fullname}</div>
                    <p className={`text-[#1d2124] text-sm ${(!chatRoom?.lastMessage?.seenDate && chatRoom?.lastMessage?.receiver?.id === profile?.id) ? "font-semibold" : ""}`}>
                        {chatRoom?.lastMessage?.content?.length > 15  ? `${chatRoom?.lastMessage?.content?.slice(0,15)}...` : chatRoom?.lastMessage?.content }
                    </p>
                    <p className="italic text-xs text-gray-400">
                        {moment().to(chatRoom?.lastMessage?.sendDate)}
                    </p>
                </div>
            </NavLink>
        </div>
    )
}