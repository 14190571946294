import React, { useEffect } from "react";
import { Button } from "@nextui-org/react";
import { toast } from "react-toastify";
import { ExperienceInterface } from "../../../../pages/private/profile/ui/myProfile/Experience";
import { useTranslation } from "react-i18next";
import { useDeleteCvExperienceMutation } from "../../../../_redux/auth/services";
import { ArchiveBoxXMarkIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { Container, Modal } from "@mui/material";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

type ExperienceEditModalProps = {
  experience: ExperienceInterface;
};

export default function ExperienceDeleteModal({
  experience,
}: ExperienceEditModalProps) {
  const { t } = useTranslation();
  const [deleteExperienceAction, { isLoading, isError, error, isSuccess }] =
    useDeleteCvExperienceMutation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function deleteHandler(): void {
    deleteExperienceAction(experience?.id)
      .unwrap()
      .then((response) => {
        toast.success(
          t("delete.message.specific", { value: t("experience.title.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  }

  return (
    <>
      <Button
        isIconOnly
        onClick={handleOpen}
        color="danger"
        aria-label={t("delete.label")}
        className="p-0"
        size="sm"
        title={t("delete.label")}
        variant="light"
      >
        <ArchiveBoxXMarkIcon height={18} width={18} />
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("delete.specific", {
          value: t("professionalExperience.label"),
        })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <div className="sticky top-10">
              <ModalClose onClick={handleClose} />
            </div>
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold first-letter:capitalize">
                  {t("delete.specific", {
                    value: t("professionalExperience.label"),
                  })}
                </h1>
                <div className="flex flex-col justify-start items-center first-letter:capitalize">
                  {t("delete.message.confirmation.specific", {
                    value: experience.jobTitle,
                  })}
                </div>
                <div className="flex flex-row justify-center items-center py-24 gap-3">
                  <Button
                    onClick={handleClose}
                    disabled={isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around first-letter:capitalize"
                    startContent={
                      isLoading ? null : <XMarkIcon height={24} width={24} />
                    }
                  >
                    <p className="first-letter:capitalize">
                      {t("close.label")}
                    </p>
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => deleteHandler()}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around first-letter:uppercase"
                    startContent={
                      isLoading ? null : (
                        <ArchiveBoxXMarkIcon height={24} width={24} />
                      )
                    }
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    <p className="first-letter:capitalize">
                      {t("delete.label")}
                    </p>
                  </Button>
                </div>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
