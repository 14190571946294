import React, { MouseEventHandler } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Badge } from "@mui/material";

interface sideBarModel {
  enabledIcon: any;
  disabledIcon: any;
  title: string;
  path: string;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  className?: string | undefined;
  disabled?: boolean;
  badgeContent?: number | null;
}

export default function SideBarItem({
  enabledIcon,
  disabledIcon,
  title,
  path,
  onClick,
  disabled = false,
  className,
  badgeContent = null,
}: sideBarModel) {
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <NavLink
      onClick={disabled ? () => null : onClick}
      to={disabled ? pathname : path.replace(":locale", `${i18n.language}`)}
      className={({ isActive }) => {
        return `flex-1 p-3 max-sm:p-1 ${
          disabled ? "" : "hover:bg-[#f4f4f5]"
        } rounded-lg ${
          isActive && disabled === false ? "font-xing-bold" : "front-xing"
        } ${disabled ? " text-gray-200" : null} ${className}`;
      }}
    >
      <div className="max-w-sm mx-auto flex max-lg:flex-col flex-row items-center text-center gap-3 max-sm:gap-1">
        <Badge badgeContent={badgeContent} color="error">
          <div className="shrink-0">
            {pathname === path.replace(":locale", `${i18n.language}`) &&
            disabled === false
              ? enabledIcon
              : disabledIcon}
          </div>
        </Badge>

        <div
          className={`max-md:text-xs text-sm ${
            pathname === path.replace(":locale", `${i18n.language}`) &&
            disabled === false
              ? "font-xing-bold"
              : "font-xing"
          }`}
        >
          <div className={className}> {title} </div>
        </div>

        {disabled && (
          <small
            style={{ fontSize: 11 }}
            className="text-xs py-0.5 px-2 bg-red-300 text-white rounded-full"
          >
            {t("coming.soon.label")}
          </small>
        )}
      </div>
    </NavLink>
  );
}
