import CustomUserAvatar from "../../../components/customAvatar/CustomUserAvatar";
import { Field, FieldProps, Form, Formik } from "formik";
import CustomSpinner from "../../../components/spinner/CustomSpinner";
import { IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import React, { useEffect, useRef, useState } from "react";
import { object, string } from "yup";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { TextareaAutosize } from "@mui/base";
import useCurrentDimension, {
  getCurrentDimension,
} from "../../../_hooks/useCurrentDimension";
import {
  chatRoomApi,
  useGetChatRoomMessagesQuery,
  useGetChatRoomQuery,
  useSendMessageMutation,
} from "../../../_redux/features/chatRoom/services";
import { CandidateInterface } from "../../../_redux/features/events/interfaces";
import Messages from "./Messages";
import { AppRoutes } from "../../../utils/appRoutes";
import IsWriting from "../../../components/isWriting/IsWriting";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import InputEmoji from "../../../components/_form/_field/InputEmoji";

export interface MessageInterface {
  id: number;
  "@id": string;
  content: string;
  sender: string;
  receiver: string;
  chatRoom: string;
  sendDate: string;
  seenDate: string | null;
}

export default function Conversation() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: profile, isLoading: profileLoading } = useGetProfileQuery();
  const {
    data: chatRoom,
    isLoading: chatRoomLoading,
    isSuccess: chatRoomMessagesSuccess,
  } = useGetChatRoomQuery(parseInt(id), { skip: !id });
  const chatWith = chatRoom?.users?.find(
    (user: CandidateInterface) => user?.id !== profile?.id
  );
  const [isWriting, setIsWriting] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [sendMessageAction] = useSendMessageMutation();
  const inputRef = useRef(null);

  const seeProfile = () =>
    navigate(
      AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
        ":locale",
        `${i18n.language}`
      ).replace(":id", chatWith?.id)
    );

  const itemsPerPage = 20;
  const {
    currentData: currentMessages,
    data: chatRoomMessagesData,
    isLoading: chatRoomMessagesLoading,
    isFetching: chatRoomMessagesFetching,
  } = useGetChatRoomMessagesQuery(
    { id: id, filters: { itemsPerPage, _page: page } },
    { skip: !id }
  );
  let { list: messages, pagination } = chatRoomMessagesData || {};

  let initialValues: MessageInterface = {
    content: "",
    sender: `/api/users/${profile?.id}`,
    receiver: chatWith?.["@id"],
    chatRoom: chatRoom?.["@id"],
  };

  const messageSchema = object().shape({
    content: string().required().trim(),
    sender: string().required().trim(),
    receiver: string().required().trim(),
    chatRoom: string().required().trim(),
  });

  const [screenSize, setScreenSize] = useCurrentDimension();

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const handleSubmit = async (values: MessageInterface) => {
    setIsSending(true);
    setIsWriting(true);

    sendMessageAction(values)
      .then((response) => {
        dispatch(
          chatRoomApi.util.updateQueryData(
            "getChatRoomMessages",
            {
              id: id,
              filters: { pagination: false },
            },
            (draftPosts) => {
              if (
                !draftPosts?.list?.find(
                  (item) => item?.id === response?.data?.id
                )
              ) {
                draftPosts?.list?.unshift(response?.data);
              }
            }
          )
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        inputRef?.current?.focus();
        values.content = "";
        setIsWriting(false);
        setIsSending(false);
      });
  };

  return (
    <div
      className={`relative h-full ${
        id ? "" : "max-md:hidden"
      } max-md:w-full w-3/5 bg-white`}
    >
      <div className="absolute z-10 top-0 left-0 right-0 w-full flex flex-row justify-start items-start bg-white">
        <div className="flex flex-row justify-start items-center gap-3">
          <div className="md:hidden">
            <NavLink to="/chat">
              <KeyboardBackspaceIcon height={18} width={18} />
            </NavLink>
          </div>
          <div className="shrink-0 h-12 w-12">
            <CustomUserAvatar
              onClick={seeProfile}
              className="object-cover cursor-pointer overflow-hidden h-12 w-12"
              src={chatWith?.imageUrl}
            />
          </div>
          <div>
            <div className="text-sm font-semibold text-black">
              {chatWith?.fullname}
            </div>
            <p className="text-gray-500 font-medium text-sm">
              {chatWith?.["@type"]}
            </p>
          </div>
        </div>
      </div>

      <Messages
        messages={messages}
        canFetch={currentMessages?.list?.length === itemsPerPage}
        isFetching={chatRoomMessagesFetching}
        isSuccess={chatRoomMessagesSuccess}
        dataFetch={() =>
          setPage((prevState) =>
            pagination?.last?._page > prevState
              ? parseInt(prevState) + 1
              : prevState
          )
        }
      />

      <div className="absolute z-10 left-0 right-0 bottom-0 w-full">
        <div className="flex flex-row justify-end items-center">
          {isWriting && <IsWriting />}
        </div>

        <div className=" bg-white rounded-t-xl border-solid border-2 border-gray-300 rounded-br-xl">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={messageSchema}
          >
            <Form className="flex flex-row justify-between items-center gap-3">
              <InputEmoji
                disabled={
                  isSending ||
                  chatRoomLoading ||
                  profileLoading ||
                  chatRoomMessagesLoading
                }
                name="content"
              />
              <Field
                name="content"
                render={(props: FieldProps) => {
                  return (
                    <TextareaAutosize
                      {...props.field}
                      ref={inputRef}
                      readOnly={
                        isSending ||
                        chatRoomLoading ||
                        profileLoading ||
                        chatRoomMessagesLoading
                      }
                      disabled={
                        isSending ||
                        chatRoomLoading ||
                        profileLoading ||
                        chatRoomMessagesLoading
                      }
                      label=""
                      autoComplete="off"
                      type="text"
                      variant={"outlined"}
                      className="border-0 shadow-none focus:shadow-none resize-none w-full text-sm font-normal font-sans leading-normal p-3 rounded-xl rounded-br-none shadow-slate-100 dark:shadow-slate-900 bg-white dark:bg-slate-900 text-slate-900 dark:text-slate-300 focus-visible:outline-0 box-border"
                      autoFocus
                      placeholder={t("messages.write.label")}
                    />
                  );
                }}
              />
              <IconButton
                aria-label="chat"
                className={"z-100"}
                type="submit"
                title={t("actions.send.label")}
                disabled={
                  isSending ||
                  chatRoomLoading ||
                  profileLoading ||
                  chatRoomMessagesLoading
                }
              >
                {isSending ? (
                  <CustomSpinner className={"z-40"} />
                ) : (
                  <SendIcon className="cursor-pointer" width={30} height={30} />
                )}
              </IconButton>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
}
