import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function VisitItem({label, value = "?", icon = null, isLoading = false}: {label: string, isLoading?: boolean, value?: string, icon?: JSX.Element|null}) {

    return (
        <div
            className="w-full min-h-[200px] flex flex-row justify-between items-center gap-6 rounded-xl border-2 border-solid border-gray-100 p-6">
            <div
                className="max-lg:w-full flex flex-col justify-center max-lg:justify-start items-center gap-1 max-lg:gap-6">
                <h1 className="text-6xl font-xing-bold">
                    {
                        isLoading ?
                            <CircularProgress color={"inherit"} size={25} thickness={10} className={"mx-1"}/>
                            :
                        value
                    }
                </h1>
                <p className="text-xl text-gray-400 text-center">{label}</p>
            </div>
            <div className="max-lg:hidden">
                {icon}
            </div>
        </div>
    )
}
