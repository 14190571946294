import React from 'react';

interface ColoredContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    buttons?: JSX.Element[]
}

const ColoredContainer: React.FC<ColoredContainerProps> = ({ className, ...props }) => {
    const customClassName = "w-full bg-[#f4f4f5] rounded-2xl p-1 my-3 gap-3";

    // Combine custom className with any className passed as props
    const combinedClassName = `${customClassName} ${className || ''}`;

    return (
        <div className={combinedClassName} {...props}>
            { props.children }
        </div>
    );
}

export default ColoredContainer;