import React from "react";
import CustomUserAvatar from "../../../components/customAvatar/CustomUserAvatar";
import moment from "moment";
import { useGetUserCommonFriendsWithQuery } from "../../../_redux/features/network/services";
import { AppRoutes } from "../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export interface visitorInterface {
  isLoading?: boolean;
  src?: string | undefined;
  fullName?: string | undefined;
  job?: string | undefined;
  company?: string | undefined;
  viewedDate?: string | undefined;
  isFriend?: boolean | undefined;
}

export default function VisitorDetails({ view }: { view: visitorInterface }) {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { data } = useGetUserCommonFriendsWithQuery(
    {
      id: view?.user?.id,
      filters: { _page: 1, itemsPerPage: 3 },
    },
    { skip: !view?.user?.id }
  );

  const { list: commonFriends, totalItems } = data || {};

  return (
    <div className="w-full flex flex-col gap-6">
      <div className="w-full flex flex-row justify-between">
        <div className="flex-1 flex flex-col gap-3">
          <p className="font-xing-bold text-xl">{view?.nbViews} visite</p>
          <p>{moment().locale(`${i18n.language}-DZ`).to(view?.viewedDate)}</p>
        </div>
        <div className="flex-1 flex flex-col gap-3">
          <p className="font-xing-bold text-xl">{totalItems} contact commun</p>
          <div className="flex gap-3">
            {commonFriends?.map((item) => (
              <CustomUserAvatar
                onClick={(e) => {
                  e.preventDefault();
                  navigate(
                    AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
                      ":locale",
                      `${i18n.language}`
                    ).replace(":id", item?.id)
                  );
                }}
                title={item?.fullname}
                className="h-[48px] w-[48px] cursor-pointer"
                src={item.imageUrl}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-xing-bold text-xl">Autres points communs</p>
        <p className="font-xing-bold text-lg">
          Vous partagez ces centres d'intérêts :
        </p>
        <div className="flex flex-row justify-start items-center gap-3">
          <p className="rounded-full border-1 border-solid border-gray-400 px-3 py-1">
            Excel
          </p>
        </div>
      </div>
    </div>
  );
}
