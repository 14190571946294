import React from "react";

interface CustomUserAvatarInterface {
  className?: string;
  objectFit?: string;
  src?: string;
}

export default function CustomCompanyAvatar({
  className,
  src,
  objectFit = "cover",
  ...props
}: CustomUserAvatarInterface) {
  const addDefaultImg = (e: any): void => {
    e.target.src = "/images/company-placeholder.png";
  };
  return (
    <div {...props} className={className}>
      <div
        style={{
          display: "block",
          height: "100%",
          maskImage: "url('/svg/prototype-super-ellipse-mask-.svg')",
          maskPosition: "center center",
          maskRepeat: "no-repeat",
          position: "relative",
          width: "100%",
        }}
      >
        <img
          style={{
            aspectRatio: "1 / 1",
            backgroundColor: "#f4f4f5",
            borderRadius: "4px",
            display: "block",
            height: "100%",
            margin: "0px",
            padding: "0px",
            position: "relative",
            width: "100%",
            zIndex: "calc(-1)",
          }}
          onError={addDefaultImg}
          //className={`object-${objectFit}`}
          alt="company-logo"
          aria-label="company-logo"
          src={src ? src : "/svg/prototype-user-placeholder.svg"}
        />
      </div>
    </div>
  );
}
