import React from "react";
import { SvgIllustrationInterface } from "./AttentesProIllustration";
export default function VisitorsIllustration({
  primary,
}: SvgIllustrationInterface) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="Layer 1"
      width="400"
      height="270"
      viewBox="0 0 792 398.62458"
    >
      <path
        d="M839.05636,330.50929h-58a4.50508,4.50508,0,0,1-4.5-4.5v-21a33.5,33.5,0,1,1,67,0v21A4.50508,4.50508,0,0,1,839.05636,330.50929Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <polygon
        points="544.851 385.91 533.108 382.388 541.102 335.418 558.434 340.615 544.851 385.91"
        fill="#a0616a"
      />
      <path
        d="M748.43228,648.87907l-37.86469-11.35461.14361-.47894a15.38606,15.38606,0,0,1,19.15719-10.319l.00093.00028,23.12635,6.93507Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <polygon
        points="681.636 386.307 669.376 386.307 663.544 339.019 681.638 339.02 681.636 386.307"
        fill="#a0616a"
      />
      <path
        d="M888.7622,648.87907l-39.53052-.00146v-.5a15.38605,15.38605,0,0,1,15.38647-15.38623h.001l24.1438.001Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <circle cx="607.40282" cy="55.05386" r="24.56103" fill="#a0616a" />
      <path
        d="M782.109,609.14991a5.21561,5.21561,0,0,1-1.31177-.16741l-49.22018-12.79774a5.285,5.285,0,0,1-3.62169-6.95672c29.84067-80.26083,54.276-149.49319,51.00007-183.79633a66.14142,66.14142,0,0,1-6.8991-30.01353l.3503-34.68039a6.79218,6.79218,0,0,1,8.29371-6.55654l55.0658,12.46751.31189,1.03887a72.24969,72.24969,0,0,1-1.26217,45.0782c32.46248,29.30719,54.22874,125.52134,66.78368,201.199A5.31154,5.31154,0,0,1,898.301,599.76l-.15537.03784-38.60766,3.816a5.28352,5.28352,0,0,1-6.89423-3.42274l-33.172-109.466-32.259,114.57543A5.3213,5.3213,0,0,1,782.109,609.14991Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <path
        d="M834.7485,306.50929H802.322a2.5036,2.5036,0,0,1-2.48071-2.19043l-.41992-3.3584a1.49989,1.49989,0,0,0-2.84766-.44824l-2.12573,4.55469a2.50968,2.50968,0,0,1-2.26538,1.44238H787.257a2.49954,2.49954,0,0,1-2.48877-2.73828l1.94214-20.28614a2.51923,2.51923,0,0,1,1.42871-2.03613c14.85034-6.93262,29.90967-6.92383,44.76.02637a2.5036,2.5036,0,0,1,1.41455,1.91894l2.90943,20.25977a2.49931,2.49931,0,0,1-2.47461,2.85547Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <path
        d="M761.58288,615.46372,453.666,589.614a8.50951,8.50951,0,0,1-7.75913-9.18128L457.53506,441.92a8.5095,8.5095,0,0,1,9.18128-7.75913L774.6332,460.01054a8.50951,8.50951,0,0,1,7.75913,9.18128L770.76416,607.70459A8.50951,8.50951,0,0,1,761.58288,615.46372Z"
        transform="translate(-204 -250.68771)"
        fill="#ccc"
      />
      <path
        d="M757.35331,606.077,459.40139,581.06386a4.50507,4.50507,0,0,1-4.10777-4.86067l10.79161-128.54782a4.50507,4.50507,0,0,1,4.86068-4.10777l297.95191,25.01312a4.50508,4.50508,0,0,1,4.10778,4.86067L762.214,601.96921A4.50506,4.50506,0,0,1,757.35331,606.077Z"
        transform="translate(-204 -250.68771)"
        fill="#fff"
      />
      <path
        d="M680.60482,557.82091q.02868.0024.05751.003a38.65969,38.65969,0,0,0,6.45519-76.8932.88189.88189,0,0,0-.68248.18238.87262.87262,0,0,0-.33069.6167l-6.30778,75.13717a.885.885,0,0,0,.80825.954Z"
        transform="translate(-204 -250.68771)"
        fill="#3f3d56"
      />
      <path
        d="M655.05426,491.101a1.13844,1.13844,0,0,1,.77221.39829l23.96375,28.356a1.12611,1.12611,0,0,1,.26383.82644l-3.0183,35.95349a1.12217,1.12217,0,0,1-.42519.79279,1.13933,1.13933,0,0,1-.87569.23452,38.91252,38.91252,0,0,1-21.571-66.24012,1.13968,1.13968,0,0,1,.84842-.32419Z"
        transform="translate(-204 -250.68771)"
        fill="#e6e6e6"
      />
      <path
        d="M682.30439,480.2719a1.14293,1.14293,0,0,1,.75164.37287,1.123,1.123,0,0,1,.28748.85348l-2.7233,32.43948a1.13343,1.13343,0,0,1-1.995.63681l-20.91133-24.74412a1.13581,1.13581,0,0,1,.18008-1.63641,38.99426,38.99426,0,0,1,24.34015-7.92579C682.25751,480.26871,682.2811,480.27,682.30439,480.2719Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M663.91866,572.28432a6.94365,6.94365,0,1,1-6.33844-7.50019A6.95157,6.95157,0,0,1,663.91866,572.28432Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M683.68814,573.944a6.94365,6.94365,0,1,1-6.33844-7.5002A6.95156,6.95156,0,0,1,683.68814,573.944Z"
        transform="translate(-204 -250.68771)"
        fill="#3f3d56"
      />
      <path
        d="M703.45762,575.60361a6.94365,6.94365,0,1,1-6.33844-7.50019A6.95157,6.95157,0,0,1,703.45762,575.60361Z"
        transform="translate(-204 -250.68771)"
        fill="#e6e6e6"
      />
      <path
        d="M580.02565,491.29512,516.539,485.9654a4.725,4.725,0,1,0-.79055,9.41689L579.2351,500.712a4.725,4.725,0,1,0,.79055-9.41688Z"
        transform="translate(-204 -250.68771)"
        fill="#e6e6e6"
      />
      <path
        d="M577.86565,517.02462,514.379,511.6949a4.7216,4.7216,0,0,0-2.689,8.836,4.66207,4.66207,0,0,0,1.89849.58084l63.48664,5.32972a4.725,4.725,0,1,0,.79055-9.41689Z"
        transform="translate(-204 -250.68771)"
        fill="#e6e6e6"
      />
      <path
        d="M575.70566,542.75412,512.219,537.4244a4.725,4.725,0,1,0-.79055,9.41689l63.48664,5.32972a4.725,4.725,0,1,0,.79055-9.41689Z"
        transform="translate(-204 -250.68771)"
        fill="#e6e6e6"
      />
      <path
        d="M561.95883,489.7784l-45.41982-3.813a4.725,4.725,0,1,0-.79055,9.41689l44.47344,3.73355a5.37929,5.37929,0,0,0,1.73693-9.33744Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M538.82281,513.747,514.379,511.6949a4.7216,4.7216,0,0,0-2.689,8.836l22.4513,1.88479a5.38175,5.38175,0,0,0,5.81109-4.91109A5.27063,5.27063,0,0,0,538.82281,513.747Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M570.96209,542.3559,512.219,537.4244a4.725,4.725,0,1,0-.79055,9.41689l59.12162,4.96327a5.36761,5.36761,0,0,0,.412-9.44866Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M734.99086,466.5294a10.74268,10.74268,0,0,0,7.44457-14.69442l34.62093-90.82569-17.67316-4.35235L727.579,446.39928a10.80091,10.80091,0,0,0,7.41184,20.13012Z"
        transform="translate(-204 -250.68771)"
        fill="#a0616a"
      />
      <path
        d="M778.719,379.31886a5.319,5.319,0,0,1-2.25391-.501h-.00024l-22.03345-10.31347a5.31849,5.31849,0,0,1-2.1792-7.752l13.39038-20.22265a13.584,13.584,0,0,1,7.77637-7.03516,13.88007,13.88007,0,0,1,17.2915,18.9375l-6.898,23.09473a5.31747,5.31747,0,0,1-5.0935,3.792Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <path
        d="M764.50265,468.07863a10.74272,10.74272,0,0,0,10.152-12.97244l56.19618-80.09735-16.79449-8.99955-52.9225,80.88424a10.80091,10.80091,0,0,0,3.36879,21.1851Z"
        transform="translate(-204 -250.68771)"
        fill="#a0616a"
      />
      <path
        d="M825.33761,391.10109a5.31316,5.31316,0,0,1-3.1438-1.03028l-19.616-14.38965a5.31594,5.31594,0,0,1-.63549-8.02539l17.05688-17.24316a13.58422,13.58422,0,0,1,8.99268-5.39551,13.88036,13.88036,0,0,1,13.29223,21.93067L830.04,388.26612a5.31991,5.31991,0,0,1-4.70239,2.835Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <path
        d="M469.56874,448.86976a10.05576,10.05576,0,0,1-5.277-14.48823l-23.35887-27.04334,18.41436-2.3969,19.35885,26.04829a10.11027,10.11027,0,0,1-9.13732,17.88018Z"
        transform="translate(-204 -250.68771)"
        fill="#ffb8b8"
      />
      <path
        d="M337.90463,454.58428a10.05577,10.05577,0,0,1,2.36251-15.23727L332.842,404.39209l17.28821,6.77877,4.40057,32.15453a10.11027,10.11027,0,0,1-16.62615,11.25889Z"
        transform="translate(-204 -250.68771)"
        fill="#ffb8b8"
      />
      <polygon
        points="217.569 386.069 229.829 386.068 235.661 338.78 217.567 338.781 217.569 386.069"
        fill="#ffb8b8"
      />
      <path
        d="M418.4416,632.75392l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53052.00146Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <polygon
        points="157.569 386.069 169.829 386.068 175.661 338.78 157.567 338.781 157.569 386.069"
        fill="#ffb8b8"
      />
      <path
        d="M358.4416,632.75392l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38647,15.38623v.5l-39.53052.00146Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <path
        d="M420.11374,612.06727a4.52551,4.52551,0,0,1-4.46923-3.957L403.10642,504.78016a1.49976,1.49976,0,0,0-2.96069-.10937L380.21067,606.08a4.50994,4.50994,0,0,1-4.41577,3.63183h-14.634a4.49955,4.49955,0,0,1-4.49926-4.585l2.92895-156.20214.23877-.14258c21.34766-12.71094,46.50977-13.543,76.926-2.54l.31933.11523,4.88941,160.37891a4.51414,4.51414,0,0,1-4.31787,4.63379l-17.35474.69433C420.23215,612.0663,420.17258,612.06727,420.11374,612.06727Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <circle cx="199.40801" cy="30.4611" r="24.56103" fill="#ffb8b8" />
      <path
        d="M460.25272,429.61688a4.505,4.505,0,0,1-3.52808-1.00768l-25.18508-21.13438A46.37352,46.37352,0,0,1,415.51567,379.034l-5.66482-36.60153a14.49652,14.49652,0,1,1,27.01712-10.52l11.9859,57.511,19.132,25.96774a4.51467,4.51467,0,0,1,.11893,5.16938L463.359,427.6622a4.50564,4.50564,0,0,1-2.09059,1.68576A4.45444,4.45444,0,0,1,460.25272,429.61688Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M338.01326,431.48451a4.505,4.505,0,0,1-1.98249-3.08748L331.09,395.89258a46.37349,46.37349,0,0,1,6.76343-31.93587l19.93125-31.2171a14.49652,14.49652,0,1,1,27.23067,9.95418l-29.00182,51.08889-2.7964,32.13311a4.51469,4.51469,0,0,1-3.32625,3.95888l-8.25448,2.195a4.50565,4.50565,0,0,1-2.68308-.11591A4.45476,4.45476,0,0,1,338.01326,431.48451Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M430.27268,324.76606a41.82251,41.82251,0,0,0-33.53711-13.55957q-.616.0381-1.22705.08594H395.508a41.73428,41.73428,0,0,0-30.15967,16.64649,42.362,42.362,0,0,0-7.5249,34.14453,132.361,132.361,0,0,1-3.49658,67.876l-6.58887,20.459a4.49852,4.49852,0,0,0,4.042,5.87207l33.68506,1.811,8.62012-14.88971V458.565l40.94092,2.20111c.08154.00489.1626.00684.24316.00684a4.5,4.5,0,0,0,4.48389-4.86133l-4.62744-57.38183,5.8042-38.97266A42.08923,42.08923,0,0,0,430.27268,324.76606Z"
        transform="translate(-204 -250.68771)"
        fill={primary}
      />
      <path
        d="M386.33817,304.59581c2.274,1.30843,11.26246,2.176,11.90278-.36827l.01372-.0559c.44576-1.86411.0756-3.81068-.05233-5.72308s.05926-4.01326,1.36175-5.41937c2.428-2.62114,6.74756-1.23165,10.25844-1.89439a8.66429,8.66429,0,0,0,6.74006-9.25292c-.05-.3975-.12841-.79544-.21633-1.19315a6.67288,6.67288,0,0,1,6.92676-8.08129c3.5481.20288,7.378,1.27789,10.31357-1.35012,2.2701-2.03223,2.824-7.32236,1.82653-10.20133-1.67623-4.83831-12.4137-7.37125-17.10185-8.76367a40.62027,40.62027,0,0,0-34.43036,5.64954c-2.96119,2.08724-5.72662,4.70984-6.98873,8.10576s-.65323,7.68029,2.19277,9.92206a18.332,18.332,0,0,0-4.24618,17.80643C376.24445,298.30556,382.95321,302.64814,386.33817,304.59581Z"
        transform="translate(-204 -250.68771)"
        fill="#2f2e41"
      />
      <path
        d="M995,649.31229H205a1,1,0,0,1,0-2H995a1,1,0,0,1,0,2Z"
        transform="translate(-204 -250.68771)"
        fill="#ccc"
      />
      <path
        d="M918.7133,469.6076h0a11.35767,11.35767,0,1,1,10.409-12.233A11.35769,11.35769,0,0,1,918.7133,469.6076Zm-.1403-1.74169h0a9.61034,9.61034,0,1,0-10.351-8.80764A9.61034,9.61034,0,0,0,918.573,467.86591Z"
        transform="translate(-204 -250.68771)"
        fill="#f2f2f2"
      />
      <circle cx="753.82222" cy="182.78774" r="6.98934" fill="#f2f2f2" />
      <circle cx="788.17732" cy="160.8291" r="3.67143" fill="#f2f2f2" />
      <path
        d="M605.34138,387.71458a11.35768,11.35768,0,1,1-3.45443,15.68631h0A11.37027,11.37027,0,0,1,605.34138,387.71458Zm11.291,17.66838a9.61034,9.61034,0,1,0-13.273-2.923h0A9.62121,9.62121,0,0,0,616.63234,405.383Z"
        transform="translate(-204 -250.68771)"
        fill="#f2f2f2"
      />
      <circle cx="403.0349" cy="99.71754" r="6.98934" fill="#f2f2f2" />
      <circle cx="399.76439" cy="59.07571" r="3.67143" fill="#f2f2f2" />
      <path
        d="M296.07037,516.39442A11.35767,11.35767,0,1,1,311.85,519.39393h0A11.37027,11.37027,0,0,1,296.07037,516.39442Zm17.33458-11.797a9.61034,9.61034,0,1,0-2.538,13.352h0A9.62121,9.62121,0,0,0,313.405,504.5974Z"
        transform="translate(-204 -250.68771)"
        fill="#f2f2f2"
      />
      <circle cx="54.72767" cy="265.0925" r="6.98934" fill="#f2f2f2" />
      <circle cx="14.19737" cy="269.53657" r="3.67143" fill="#f2f2f2" />
    </svg>
  );
}
