import React, { SyntheticEvent, useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import useCurrentDimension, {
  getCurrentDimension,
} from "../../_hooks/useCurrentDimension";
import { useSelector } from "react-redux";
import { selectToken } from "../../_redux/auth/authSlice";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { AppRoutes } from "../../utils/appRoutes";

export default function Footer() {
  const { t, i18n } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [screenSize, setScreenSize] = useCurrentDimension();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const accordiansProps = [
    {
      id: "panel1",
      label: t("footer.company.space"),
      details: [
        {
          label: t("footer.company.recrutement.Annonces.label"),
          link: "/profile",
          details: [
            // {
            //   label: t("footer.company.recrutement.Annonces.annonces"),
            //   link: "/profile",
            // },
            {
              label: t("footer.company.recrutement.Annonces.mesure"),
              link: "https://www.huntead.com",
            },
            {
              label: t("footer.company.recrutement.Annonces.disposition"),
              link: "http://interimeo.com/",
            },
          ],
        },
        {
          label: t("footer.company.evaluation.talents.label"),
          link: "https://smile.best/",
          details: [
            {
              label: t("footer.company.evaluation.talents.test.technique"),
              link: "https://smile.best/",
            },
            {
              label: t(
                "footer.company.evaluation.talents.test.psychotechnique"
              ),
              link: "https://smile.best/",
            },
            {
              label: t("footer.company.evaluation.talents.test.360"),
              link: "https://smile.best/",
            },
            {
              label: t("footer.company.evaluation.talents.competences"),
              link: "https://smile.best/",
            },
          ],
        },
        {
          label: t("footer.company.engagement.performance.label"),
          link: "/profile",
          details: [
            {
              label: t(
                "footer.company.engagement.performance.engagement.collaborateur"
              ),
              link: "https://smile.best/",
            },
            {
              label: t("footer.company.engagement.performance.sondages"),
              link: "https://smile.best/",
            },
          ],
        },
        // {
        //   label: t("footer.company.marque.employeur.label"),
        //   link: "/profile",
        //   details: [
        //     {
        //       label: t("footer.company.marque.employeur.label"),
        //       link: "/profile",
        //     },
        //   ],
        // },
      ],
    },
    {
      id: "panel2",
      label: t("footer.comunity.label"),
      details: [
        {
          label: t("footer.comunity.actuality"),
          link: AppRoutes["PRIVATE_BLOG"].replace(":locale", i18n.language),
        },
        // { label: t("footer.comunity.historic"), link: "/profile" },
        // {
        //   label: t("footer.comunity.events"),
        //   link: "/profile",
        // },
      ],
    },

    {
      id: "panel3",
      label: t("footer.links.label"),
      details: [
        // { label: t("footer.links.who we are"), link: "/profile" },
        // { label: t("footer.links.chart.recrutement"), link: "/profile" },
        {
          label: t("footer.links.terms.of.use"),
          link: AppRoutes.POLICY[0].replace(":locale", i18n.language),
        },
        // {
        //   label: t("footer.links.politic.confidentiality"),
        //   link: "/profile",
        // },
      ],
    },

    {
      id: "panel4",
      label: t("footer.soutien.label"),
      details: [
        {
          label: t("footer.soutien.FAQ"),
          link: AppRoutes.FAQ.replace(":locale", i18n.language),
        },
        // {
        //   label: t("footer.soutien.help.center"),
        //   link: AppRoutes.FAQ.replace(":locale", i18n.language),
        // },
      ],
    },
    {
      id: "panel5",
      label: t("footer.contact.label"),
      details: [
        { label: t("footer.contact.address"), link: "#" },
        { label: t("footer.contact.tel"), link: "#" },
        {
          label: t("footer.contact.email"),
          link: "#",
        },
      ],
    },
  ];

  return (
    <div className="max-md:hidden flex flex-col justify-between p-9 bg-white border-t-2 border-gray-200">
      <div className="w-full flex flex-wrap  flex-row max-md:flex-col justify-between items-start p-9 max-md:p-3">
        {accordiansProps.map((accordiansProp, index) => {
          return (
            <div
              key={`accordiansProp_${accordiansProp.id}_${index}`}
              className="flex-1 w-full min-w-[250px]"
            >
              <Accordion
                elevation={0}
                expanded={true}
                onChange={() => handleChange(accordiansProp.id)}
              >
                <AccordionSummary expandIcon={null}>
                  <h1 id={accordiansProp.id} className="font-xing-bold">
                    {accordiansProp.label}
                  </h1>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    paddingRight: "0",
                    paddingLeft: "0",
                  }}
                >
                  <ul>
                    {accordiansProp.details.map(
                      (accordiansPropDetail, index) => {
                        if (
                          accordiansPropDetail?.details &&
                          accordiansPropDetail?.details?.length > 0
                        )
                          return (
                            <li key={`accordion_nested_lev_2_${index}`}>
                              <Accordion elevation={0}>
                                <AccordionSummary
                                  expandIcon={<ArrowDropDownIcon />}
                                >
                                  {accordiansPropDetail?.label}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ul className="list-disc list-inside">
                                    {accordiansPropDetail.details.map(
                                      (ele, i) => (
                                        <li
                                          key={`accordion_nested_lev_0_${i}`}
                                          className="mb-3"
                                        >
                                          <a
                                            key={`accordiansProp_Details_Details_${i}`}
                                            className="underline"
                                            href={ele.link}
                                            // target="_blank"
                                            // rel="noopener noreferrer"
                                          >
                                            {ele.label}
                                          </a>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            </li>
                          );
                        return (
                          <li key={`accordion_nested_lev_1_${index}`}>
                            <Accordion expanded={false} elevation={0}>
                              <AccordionSummary>
                                <NavLink
                                  key={`accordiansProp_Details_${index}`}
                                  className="hover:underline"
                                  to={accordiansPropDetail.link}
                                >
                                  {accordiansPropDetail.label}
                                </NavLink>
                              </AccordionSummary>
                              <AccordionDetails
                                style={{
                                  maxWidth: "none",
                                }}
                              ></AccordionDetails>
                            </Accordion>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>

      <div className=" text-gray-400 text-sm p-9 flex flex-wrap flex-col justify-start gap-3 items-start max-w-[900px]">
        <NavLink to="/">
          <img
            height={40}
            width={214}
            className="max-w-[214px] max-h-[40px] object-contain"
            src="/logo.jpg"
            alt="menu"
          />
        </NavLink>
        <div className="flex justify-start flex-wrap gap-5">
          <p className="w-[500px]">{t("footer.presentation.content")}</p>
          <p className="min-w-[190px]">
            {t("footer.all.right.received.label")}
          </p>
          <div className="flex flex-row justify-start items-start gap-3">
            <p>{t("language.label")}</p>
            <NavLink className="uppercase hover:underline" to="/language">
              {i18n.language}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
