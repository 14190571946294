import React from "react";
import { useTranslation } from "react-i18next";
import { LanguagesList } from "./LanguagesList";
import TitledContainer from "../../../../components/containers/TitledContainer";
import { useGetCvQuery } from "../../../../_redux/auth/services";
import { useParams } from "react-router-dom";
import { useGetSingleCandidateQuery } from "../../../../_redux/features/network/services";
import { Skeleton } from "@mui/material";

export default function Languages() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: profile } = useGetSingleCandidateQuery(parseInt(id), {
    skip: !id,
  });
  const { data: cv, isLoading: isCvLoading } = useGetCvQuery(profile?.id, {
    skip: !profile?.id,
  });

  if (isCvLoading) {
    return (
      <div className="flex flex-col justify-between gap-3">
        <Skeleton animation="wave" variant="rounded" width="70%" height={30} />
        <Skeleton animation="wave" variant="rounded" width="80%" height={20} />
        <Skeleton animation="wave" variant="rounded" width="60%" height={20} />
      </div>
    );
  }

  if (!cv?.languages?.length) return null;

  return (
    <TitledContainer
      className="p-3 flex flex-col justify-between gap-1"
      title={t("profile.languages.label")}
    >
      <LanguagesList languages={cv?.languages} />
    </TitledContainer>
  );
}
