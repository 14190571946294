import React, { ReactNode, useEffect, useState } from "react";
import { ExpandLess } from "@mui/icons-material";

interface ExpectationAccordionInterface {
  icon: ReactNode;
  title: string;
  subtitle: string;
  input: ReactNode;
}

export default function ExpectationAccordion({
  icon,
  title,
  subtitle,
  input,
  isOpenAccordion,
}: ExpectationAccordionInterface) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(!isOpen);
  useEffect(() => {
    isOpenAccordion && setIsOpen(true);
  }, [isOpenAccordion]);
  return (
    <div className="w-full flex flex-col justify-between items-start gap-3 border-gray-200 rounded-2xl border-2 border-solid font-xing select-none cursor-pointer transition-all duration-150">
      <div className="w-full p-4 flex flex-row justify-between items-start gap-3">
        <div className="flex-none w-10 flex flex-row justify-end items-center">
          {icon}
        </div>
        <div className="grow">
          <div onClick={handleOpen} className="w-full flex flex-col gap-1">
            <div className="w-full flex flex-row justify-between items-start">
              <h6 className="font-xing-bold text-medium first-letter:uppercase">
                {title}
              </h6>
              <div
                className={`transition-all duration-100 ${
                  isOpen ? "" : "rotate-180"
                }`}
              >
                <ExpandLess height={18} width={18} />
              </div>
            </div>
            <h1 className="font-xing text-sm text-gray-500 first-letter:uppercase">
              {subtitle}
            </h1>
          </div>
        </div>
      </div>

      <div
        className={`w-full p-4 flex flex-row justify-center items-center gap-3 ${
          isOpen ? "" : "hidden h-0"
        }`}
      >
        <div className="flex-none w-10" />
        <div className="grow">{input}</div>
      </div>
    </div>
  );
}
