import { toast } from "react-toastify";
interface Violation {
  propertyPath: string;
  message: string;
  code: string;
}

interface ErrorData {
  "@context": string;
  "@type": string;
  "hydra:title": string;
  "hydra:description"?: string;
  violations?: Violation[];
  message?: string;
  statusText?: string;
}

interface ErrorResponse {
  status: number;
  data: ErrorData;
}
interface ToastMessageError {
  error: ErrorResponse;
  defaultMessage?: string;
}
export const toastMessageError = ({
  error,
  defaultMessage = "error.message",
}: ToastMessageError) => {
  console.log("error", error);
  if (error?.data?.message) {
    toast.error(`${error?.data?.message}`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  } else if (error?.data?.["hydra:description"]) {
    toast.error(`${error?.data?.["hydra:description"]}`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  } else if (error?.data?.violations) {
    error?.data?.violations?.forEach(
      (violation: { propertyPath: any; message: any }) => {
        toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
  } else if (error?.data?.statusText) {
    toast.error(`${error?.data?.statusText}`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  } else {
    toast.error(`${defaultMessage}`, {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }
};
