import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { useGetSectorGroupsQuery } from "../../../../_redux/features/activitySectorGroup/services";
import CustomCheckbox from "../../../../components/_form/_field/Checkbox";
import { useTranslation } from "react-i18next";
import DropDownFilter from "./DropDownFilter";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useSelector } from "react-redux";
import { selectToken } from "../../../../_redux/auth/authSlice";

export default function ActivitySectorFilter() {
  const { sectorFilterParams } = useParams();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { values, handleChange, setFieldValue, submitForm } =
    useFormikContext();
  const { data: sectorGroupsData, isLoading } = useGetSectorGroupsQuery({
    pagination: false,
  });
  const { list: sectorGroups } = sectorGroupsData || {};
  useEffect(() => {
    // 0 for the id and 1 for the name
    if (sectorFilterParams) {
      navigate(
        AppRoutes[isAuth ? "PRIVATE_JOBS_SEARCH" : "JOBS"][0].replace(
          ":locale",
          `${i18n.language}`
        ),
        { replace: true }
      );
    }
  }, [isAuth, navigate, i18n.language, sectorFilterParams]);
  return (
    <DropDownFilter
      isLoading={isLoading}
      label={t("activity.sector.label")}
      hasBadge={values?.sectorGroup?.length}
    >
      {sectorGroups && (
        <div className="flex flex-col justify-between px-3">
          {sectorGroups?.map((sector: any, index: number) => (
            <CustomCheckbox
              key={index}
              disabled={isLoading}
              defaultChecked={values?.sectorGroup?.find(
                (sec: any) => sec == sector?.id
              )}
              value={sector?.id}
              onChange={handleChange}
              label={sector?.name}
              name="sectorGroup"
            />
          ))}
        </div>
      )}
    </DropDownFilter>
  );
}
