import React from "react";
import {Button} from "@nextui-org/react";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

export default function EventItemModalLoading () {

    const {t} = useTranslation()

    return (
        <div className="grow flex flex-col justify-between">

            <div
                className="flex-1 relative w-full flex flex-col justify-start gap-6 items-center overflow-hidden py-6 px-0 first-letter:capitalize"
            >
                <div className="w-full bg-gray-100 h-[300px]" />
                <div className="w-full flex flex-wrap justify-between items-center">
                    <div className="shrink-0 flex flex-col justify-center gap-2">
                        <div className="bg-gray-100 h-[20px] w-[150px] rounded-lg" />
                        <div className="bg-gray-100 h-[20px] w-[100px] rounded-lg" />
                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-gray-100 h-[20px] w-[150px] rounded-lg" />
                    </div>
                    <div className="shrink-0">
                        <Button
                            disabled
                            radius="full"
                            variant="flat"
                            color="default"
                            className="bg-gray-100 rounded-full"
                        />
                    </div>

                </div>
                <div className="w-full flex flex-col justify-evenly items-start first-letter:capitalize gap-2">
                    <div className="font-xing-bold flex flex-row justify-start gap-3">
                        <div className="bg-gray-100 h-[20px] w-[100px] rounded-lg" />
                        <div className="bg-gray-100 h-[20px] w-[100px] rounded-lg" />
                    </div>
                    <div className="bg-gray-100 h-[20px] w-[100px] rounded-lg" />
                </div>
                <div className="w-full flex flex-col justify-evenly items-start">
                    <div className="bg-gray-100 h-[20px] w-[300px] rounded-lg" />
                </div>
                <div className="w-full flex flex-col justify-evenly items-start">
                    <p className="text-black font-xing-bold text-2xl">
                        {t("events.members.who.participate.label")}
                    </p>
                </div>
                <div className="w-full flex flex-row justify-evenly items-center">
                    { [1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => <CandidateLoading key={index} /> ) }
                </div>

            </div>
        </div>
    )
}

export function CandidateLoading() {
    return (
        <div className="select-none w-[200px] h-[150px] flex flex-col justify-around items-center gap-1">
            <div className="bg-gray-100 rounded-full h-[60px] max-h-[60px] min-h-[60px] w-[60px] max-w-[60px] min-w-[60px]"
            />
            <div className="bg-gray-100 h-[20px] w-[150px] rounded-lg" />
            <Button
                disabled
                radius="full"
                variant="flat"
                color="default"
                className="bg-gray-100 rounded-full"
            />
        </div>
    )

}

export function BackgroundHeroLoading() {
    return (
        <div
            className="animate-pulse flex-1 relative z-[-1] h-[35%] max-h-[35%] w-full object-cover object-center overflow-hidden">
            <div
                className="h-full w-full bg-gray-100"
            >
                <div className="absolute bottom-[10px] mx-7">
                    <div className="relative bg-white h-[20px] w-[150px]" />
                </div>
            </div>
        </div>
    )
}