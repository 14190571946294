import React from 'react';
import CustomLoader from "../customLoader/CustomLoader";

interface TitledBorderedRoundedContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string,
    isLoading?: boolean,
    buttons?: JSX.Element[],
    subtitle: string,
    isFullWidth?: boolean
}

const TitledBorderedRoundedContainer: React.FC<TitledBorderedRoundedContainerProps> =
    ({title, className, buttons, isLoading = false, isFullWidth = false,  subtitle, ...props}) => {

    const customClassName = "w-full rounded-2xl border-2 border-[#bcc9d1] p-1 gap-3";

    // Combine custom className with any className passed as props
    const combinedClassName = `${customClassName} ${className || ''}`;

    return (
        <div className="flex flex-col justify-between items-start">
            <div className="flex flex-row justify-start max-md:justify-between items-center justify-items-start gap-3 h-[120px]">
                <div className={`${(isFullWidth ? "w-full" : null)} flex flex-col justify-between items-start`}>
                    <div className="text-3xl font-xing-bold first-letter:capitalize">
                        {title}
                    </div>
                    <div>
                        <p className="font-xing text-medium text-[#5e737d]">
                            {subtitle}
                        </p>
                    </div>
                </div>
                { (isLoading) ? <CustomLoader /> : buttons && buttons.map((button) => button) }
            </div>
            <div className={combinedClassName} {...props}>
                { props.children }
            </div>
        </div>
    );
}

export default TitledBorderedRoundedContainer;