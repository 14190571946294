import axios from "axios";
import {JobSearchInterface} from "./jobsSlice";

export async function jobListApiRequest(filters: JobSearchInterface = {"_page" : 1}) {

    const params = new URLSearchParams();

    Object.keys(filters).forEach(key => {
        if (filters[key] !== "" && filters[key] !== undefined ) {
            params.append(key, filters[key])
        }
    })

    try {
        const response = await axios.get(`/api/jobs?${params.toString()}`)
        return response?.data
    } catch (error: any) {
        console.error(error)
        return error?.message || "error unknown"
    }
}

export async function getJobItem(id: number) {
    return axios.get(`/api/jobs/${id}`)
}

export async function applyToJob(jobId: number|undefined, candidateId: number|undefined ) {
    return axios.post(`/api/application_x_jobs`, {
        job: `api/jobs/${jobId}`,
        candidate: `/api/candidates/${candidateId}`
    })
}