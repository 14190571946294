import { Button } from "@nextui-org/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { CandidateInterface } from "../../../_redux/features/events/interfaces";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import { useGetChatRoomWithFriendQuery } from "../../../_redux/features/chatRoom/services";

export default function SendMessageButton({
  user,
}: {
  user: CandidateInterface;
}) {
  const {
    data: chatRoom,
    isLoading: isChatRoomLoading,
    isFetching: isChatRoomFetching,
  } = useGetChatRoomWithFriendQuery(user?.id, { skip: !user });
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const goToSendMessage = () =>
    navigate(
      AppRoutes.PRIVATE_CONVERSATION.replace(
        ":locale",
        `${i18n.language}`
      ).replace(":id", chatRoom?.id)
    );

  if (!chatRoom?.id) {
    return null;
  }

  return (
    <Button
      onClick={goToSendMessage}
      radius="full"
      variant="bordered"
      isLoading={isChatRoomLoading}
      disabled={isChatRoomFetching}
      color="primary"
      className="font-xing-bold text-sm"
    >
      {t("messages.new.message.label")}
    </Button>
  );
}
