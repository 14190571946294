import React, { useEffect } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export default function LanguageDropdown() {
  const { i18n } = useTranslation();

  const languages = [
    {
      name: "ar",
      label: "العربية",
      flag: "ع",
    },
    {
      name: "fr",
      label: "Français",
      flag: "FR",
    },
    {
      name: "en",
      label: "English",
      flag: "EN",
    },
  ];
  const handelChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <Dropdown
      closeOnSelect={true}
      className="bg-white mx-3 shadow-2xl rounded-md"
      aria-label="Language selection dropdown"
    >
      <DropdownTrigger
        id="language_dropdown_trigger"
        role="button"
        aria-label="Current language"
      >
        <div className="flex flex-col items-center justify-center cursor-pointer">
          {languages.filter((e) => e.name === i18n.language).map((e) => e.flag)}
        </div>
      </DropdownTrigger>
      <DropdownMenu
        variant="flat"
        color="default"
        aria-label="List of available languages"
      >
        {languages.map((language) => (
          <DropdownItem
            className="text-sm font-xing first-letter:uppercase cursor-pointer"
            key={language.name}
            onClick={() => handelChange(language.name)}
          >
            <div className="flex flex-row justify-start items-center gap-3">
              {language.label}
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
