import React, {MouseEventHandler, ReactNode} from "react";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import SettingsHeader from "./ui/SettingsHeader";
import {Outlet} from "react-router-dom";
import {Container} from "@mui/material";
import LoadingSettings from "./LoadingSettings";

export interface MenuTitle {
    title: string,
    subtitle?: string | undefined,
    icon: ReactNode | undefined,
    onClick?: MouseEventHandler<HTMLDivElement> | undefined;
    items: SingleLevelMenuInterface
}

export interface SingleLevelMenuInterface {
    items: Array<MenuTitle>
}

export default function Settings () {

    const {isLoading} = useGetProfileQuery()

    if (isLoading) return <LoadingSettings />

    return (
        <Container maxWidth="md">

            <div className="min-h-screen w-full min-w-full flex flex-col justify-start gap-6">

                <SettingsHeader />

                <Outlet />

            </div>

        </Container>
    )
}
