import { Form, useFormikContext } from "formik";
import { Button } from "@nextui-org/react";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import Input from "../_field/Input";
import SelectAvailability, {
  AVAILABILITY_WITH_NOTICE,
} from "../_field/Specified/SelectAvailability";
import Textarea from "../_field/Textarea";
import SelectSalary from "../_field/Specified/SelectSalary";
import SelectCountry from "../_field/Specified/SelectCountry";
import SelectCity from "../_field/Specified/SelectCity";
import CustomCheckbox from "../_field/Checkbox";
import CustomSpinner from "../../spinner/CustomSpinner";

interface ProfileCvTitleEditFormInterface {
  trigger: MutationResultSelectorResult;
  onCancel: () => void;
}

export default function ProfileCvTitleEditForm({
  trigger,
  onCancel = () => undefined,
}: ProfileCvTitleEditFormInterface) {
  const { t } = useTranslation();
  const {
    setErrors: setErrorsHook,
    setSubmitting,
    setFieldValue,
    isSubmitting,
    values,
    initialValues,
  } = useFormikContext();

  useEffect(() => {
    return () => trigger.reset();
  }, []);

  useEffect(() => {
    if (trigger?.isSuccess) {
      toast.success(
        t("update.message.specific", { value: t("position.current.label") }),
        {
          position: toast.POSITION.BOTTOM_LEFT,
        }
      );

      trigger.reset();

      if (typeof onCancel === "function") {
        onCancel();
      }
    }
  }, [trigger?.isSuccess]);

  useEffect(() => {
    setSubmitting(trigger?.isLoading);
  }, [trigger?.isLoading]);

  useEffect(() => {
    if (trigger?.isError) {
      let errors = {};
      // @ts-ignore
      trigger?.error?.data?.violations?.forEach(
        (violation: { propertyPath: string; message: any }) => {
          if (violation?.propertyPath in initialValues) {
            errors[violation?.propertyPath] = violation?.message;
          } else {
            toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          }
        }
      );

      if (errors) {
        setErrorsHook(errors);
      }
    }
  }, [trigger?.isError, trigger?.error]);

  return (
    <Form className="w-full flex flex-col justify-between items-center gap-4">
      <Input
        fullWidth
        label={t("website.label")}
        placeholder={t("website.placeholder")}
        name="website"
        type={"url"}
        disabled={isSubmitting}
      />

      <SelectAvailability
        name={"disponibility"}
        label={t("frequency.label")}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          setFieldValue("disponibility", e.target.value);
          if (e.target.value !== AVAILABILITY_WITH_NOTICE) {
            setFieldValue("disponibilityDuration", 0);
          }
        }}
      />
      {values?.disponibility === AVAILABILITY_WITH_NOTICE && (
        <Input
          fullWidth
          label={t("availability.duration.label")}
          placeholder={t("availability.duration.placeholder")}
          name="disponibilityDuration"
          type={"number"}
          endContent={t("days.label", { count: values.disponibilityDuration })}
          disabled={
            isSubmitting || values?.disponibility !== AVAILABILITY_WITH_NOTICE
          }
        />
      )}
      <CustomCheckbox
        fullWidth
        color="warning"
        name="hasCar"
        label={t("hasCar.label")}
        defaultChecked={initialValues?.hasCar}
        disabled={isSubmitting}
        onChange={(e: any) => {
          setFieldValue("hasCar", e.target.checked);
        }}
      />

      <CustomCheckbox
        fullWidth
        color="warning"
        name="militaryService"
        label={t("military.service.label")}
        defaultChecked={initialValues?.militaryService}
        disabled={isSubmitting}
        onChange={(e: any) => {
          setFieldValue("militaryService", e.target.checked);
        }}
      />

      <CustomCheckbox
        fullWidth
        color="warning"
        name="handicap"
        label={t("handicap.label")}
        defaultChecked={initialValues?.handicap}
        disabled={isSubmitting}
        onChange={(e: any) => {
          setFieldValue("handicap", e.target.checked);
          if (!e.target.checked) {
            setFieldValue("handicapAdaptation", false);
            setFieldValue("handicapAdaptationDesc", "");
          }
        }}
      />

      {values?.handicap && (
        <>
          <CustomCheckbox
            fullWidth
            color="warning"
            name="handicapAdaptation"
            label={t("handicap.adaptation.label")}
            defaultChecked={initialValues?.handicapAdaptation}
            disabled={isSubmitting || !values?.handicap}
            onChange={(e) => {
              setFieldValue("handicapAdaptation", e.target.checked);
              if (!e.target.checked) {
                setFieldValue("handicapAdaptationDesc", "");
              }
            }}
          />

          {values?.handicapAdaptation && (
            <Textarea
              name={"handicapAdaptationDesc"}
              label={t("handicap.adaptation.description.label")}
              placeholder={t("handicap.adaptation.description.placeholder")}
              disabled={
                isSubmitting || !values?.handicapAdaptation || !values?.handicap
              }
            />
          )}
        </>
      )}

      <div className="flex flex-row justify-center items-center gap-6 col-span-2">
        {typeof onCancel === "function" && (
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
            startContent={<XMarkIcon height={24} width={24} />}
          >
            {t("close.label")}
          </Button>
        )}
        <Button
          type="submit"
          spinner={<CustomSpinner />}
          disabled={isSubmitting || trigger?.isLoading}
          isLoading={isSubmitting || trigger?.isLoading}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          color="warning"
          startContent={
            isSubmitting || trigger?.isLoading ? null : (
              <PencilIcon height={24} width={24} />
            )
          }
        >
          {t("update.label")}
        </Button>
      </div>
    </Form>
  );
}
