import { date, object, string } from "yup";
import i18n from "i18next";

export const profileInformationSchema = () =>
  object().shape({
    firstName: string().required(
      i18n.t("validation.field.required.unspecific")
    ),
    lastName: string().required(i18n.t("validation.field.required.unspecific")),
    phone: string()
      .nullable()
      .matches(/^(00213|\+213|0)(21|24)[0-9]{6}$/, {
        message: i18n.t("validation.field.invalid.label", {
          value: i18n.t("phone.label"),
        }),
        excludeEmptyString: false,
      }),
    mobile: string()
      .required(i18n.t("validation.field.required.unspecific"))
      .matches(/^(00213|\+213|0)(5|6|7)[0-9]{8}$/, {
        message: i18n.t("validation.field.invalid.label", {
          value: i18n.t("phone.label"),
        }),
        excludeEmptyString: false,
      }),
    fax: string()
      .nullable()
      .matches(/^(00213|\+213|0)(21|24)[0-9]{6}$/, {
        message: i18n.t("validation.field.invalid.label", {
          value: i18n.t("phone.label"),
        }),
        excludeEmptyString: true,
      }),
    zipcode: string()
      .required(i18n.t("validation.field.required.unspecific"))
      .matches(/^[0-9]{5}$/, {
        message: i18n.t("validation.field.invalid.label", {
          value: i18n.t("zipcode.label"),
        }),
      }),
    birthday: date().required(i18n.t("validation.field.required.unspecific")),
    address: string().required(i18n.t("validation.field.required.unspecific")),
  });
