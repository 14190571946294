import React from "react";
import { Image } from "@nextui-org/react";
import { CertificateInterface } from "./MyCertificates";
import CertificateEditModal from "../../../../../components/modals/Profile/Certificate/CertificateEditModal";
import CertificateDeleteModal from "../../../../../components/modals/Profile/Certificate/CertificateDeleteModal";

export default function Certificate({
  certificate,
}: {
  certificate: CertificateInterface;
}) {
  return (
    <div className="w-full p-3 flex flex-row justify-between items-start gap-9">
      <div className="max-lg:hidden flex flex-col justify-start items-center shrink-0">
        <Image
          radius="none"
          src="/svg/prorotype-illustration-company-placeholder.svg"
          height={50}
          width={50}
        />
      </div>

      <div className="grow flex flex-col justify-start items-start gap-3 text-lg">
        <div className="flex flex-col justify-evenly items-start">
          <p>{certificate?.school}</p>
          <div className="flex flex-row justify-start items-center gap-1 text-gray-400">
            <p>{certificate.startYear}</p>
            <p>-</p>
            <p>{certificate.endDate}</p>
          </div>
        </div>

        <div className="flex flex-col justify-evenly items-start">
          <p className="font-xing-bold">{certificate?.title}</p>
          <pre className="first-letter:capitalize text-pretty">
            {certificate?.description}
          </pre>
        </div>
        {/* <div className="flex flex-col justify-evenly items-start">
          <p className="font-xing-bold first-letter:capitalize">plus</p>
        </div> */}
      </div>

      <div className="shrink-0 flex flex-col justify-start items-center">
        <div className={"flex-nowrap flex"}>
          <CertificateEditModal certificate={certificate} />
          <CertificateDeleteModal certificate={certificate} />
        </div>
      </div>
    </div>
  );
}
