import React, { useCallback, useEffect } from "react";
import {
  useGetProfileQuery,
  useUpdateProfilePictureMutation,
} from "../../_redux/auth/services";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import CustomAvatar from "../customAvatar/CustomAvatar";
import Loading from "../../Loading";
import { toastMessageError } from "../../_helpers/toastErrorMessage";

interface ProfileAvatarInterface {
  strokeWidth?: number | null | undefined;
  strokeDashoffset?: number | null | undefined;
}

export default function ProfileAvatar({
  strokeDashoffset,
}: ProfileAvatarInterface) {
  const {
    data: profile,
    isLoading: isProfileLoading,
    isSuccess: isProfileSuccess,
    isError: isProfileError,
  } = useGetProfileQuery();
  const [updateProfilePicture, updateProfilePictureTriggers] =
    useUpdateProfilePictureMutation();

  const { t } = useTranslation();
  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          const binaryStr = reader.result;
        };
        const data = new FormData();
        data.append("picture", file);
        data.append("id", profile?.id);
        updateProfilePicture(data)
          .unwrap()
          .then((response) => {
            toast.success(
              t("update.message.specific", { value: t("profile.label") }),
              {
                position: toast.POSITION.BOTTOM_LEFT,
              }
            );
          })
          .catch((error) => {
            toastMessageError({ error });
          });
      });
    },
    [profile?.id, t, updateProfilePicture]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    noDrag: true,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
    },
  });

  if (
    isProfileError ||
    !isProfileSuccess ||
    isProfileLoading ||
    updateProfilePictureTriggers.isLoading ||
    updateProfilePictureTriggers.isError
  ) {
    return <Loading />;
  }

  return (
    <div
      {...getRootProps()}
      className={"cursor-pointer brightness-100 hover:brightness-75"}
      title={t("update.specific", { value: t("profile.picture.label") })}
    >
      <input {...getInputProps()} />
      <CustomAvatar
        className="h-[194px] w-[194px]"
        src={profile?.imageUrl}
        stroke="#c6f16d"
        strokeWidth={9}
        strokeDashoffset={strokeDashoffset}
        strokeDasharray={1000}
      />
    </div>
  );
}
