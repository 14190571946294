import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useTranslation } from "react-i18next";

export default function ResumeItem({ resume }: any) {
  const { t } = useTranslation();
  const isTrue = resume?.gained < resume?.score;

  const style = { height: "12px", width: "12px" };
  const className = `font-xing h-[24px] w-[24px] flex flex-row justify-center items-center ${
    isTrue ? "bg-warning" : "bg-success"
  } rounded-full p-1`;

  return (
    <div className="w-full lg:w-1/2 flex flex-row justify-start gap-3 p-2">
      <div className={className}>
        {isTrue ? (
          <PriorityHighIcon style={style} />
        ) : (
          <CheckIcon style={style} />
        )}
      </div>
      <div>
        <p className={`${isTrue ? "font-xing" : "font-xing-bold"}`}>
          {t(`profile.${resume.label}.label`)}
        </p>
      </div>
    </div>
  );
}
