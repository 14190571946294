import { Form, useFormikContext } from "formik";
import { Button } from "@nextui-org/react";
import Input from "../_field/Input";
import { PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import SelectCivility from "../_field/Specified/SelectCivility";
import SelectMartialStatus from "../_field/Specified/SelectMartialStatus";
import CustomCheckbox from "../_field/Checkbox";
import CustomSpinner from "../../spinner/CustomSpinner";

interface ProfileInformationEditFormInterface {
  triggers: MutationResultSelectorResult<any>;
  onCancel: () => void;
}

export default function ProfileInformationEditForm({
  triggers,
  onCancel = () => undefined,
}: ProfileInformationEditFormInterface) {
  const { t } = useTranslation();
  const { isSuccess, isLoading, isError, error, reset } = triggers;
  const { initialValues, isSubmitting } = useFormikContext();
  return (
    <Form className="w-full flex flex-col gap-4">
      <SelectCivility name={"civility"} />
      <Input
        label={t("lastName.label")}
        name="lastName"
        required
        disabled={isSubmitting}
      />
      <Input
        label={t("firstName.label")}
        name="firstName"
        required
        disabled={isSubmitting}
      />
      <Input
        label={t("email.label")}
        name="email"
        isReadOnly
        required
        disabled={true}
      />
      <Input
        label={t("phone.label")}
        name="phone"
        type="phone"
        required={false}
        disabled={isSubmitting}
      />
      <Input
        label={t("mobile.label")}
        name="mobile"
        type="phone"
        required
        disabled={isSubmitting}
      />
      <Input
        label={t("fax.label")}
        name="fax"
        type="phone"
        disabled={isSubmitting}
      />
      <Input
        label={t("zipcode.label")}
        name="zipcode"
        disabled={isSubmitting}
      />
      <Input
        label={t("address.label")}
        name="address"
        required
        disabled={isSubmitting}
      />
      <Input
        label={t("birthday.label")}
        type="date"
        name="birthday"
        disabled={isSubmitting}
      />
      <Input
        label={t("birthplace.label")}
        name="birthplace"
        disabled={isSubmitting}
      />
      <SelectMartialStatus name="martialStatus" disabled={isSubmitting} />
      <CustomCheckbox
        name="subAnem"
        label={t("anem.registered.label")}
        defaultChecked={initialValues?.subAnem}
        disabled={isSubmitting}
      />
      <div className="flex flex-row justify-center items-center gap-6 col-span-2">
        <Button
          onClick={onCancel}
          disabled={isSubmitting}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          startContent={<XMarkIcon height={24} width={24} />}
        >
          {t("close.label")}
        </Button>
        <Button
          type="submit"
          spinner={<CustomSpinner />}
          disabled={isSubmitting || isLoading}
          isLoading={isSubmitting || isLoading}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          color="warning"
          startContent={
            isSubmitting || isLoading ? null : (
              <PencilIcon height={24} width={24} />
            )
          }
        >
          {t("update.label")}
        </Button>
      </div>
    </Form>
  );
}
