import CustomUserAvatar from "../../../../components/customAvatar/CustomUserAvatar";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Close, Done } from "@mui/icons-material";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { CandidateInterface } from "../../../../_redux/features/events/interfaces";
import { Skeleton, Button, Container } from "@mui/material";
import {
  useAcceptInvitationMutation,
  useRefuseInvitationMutation,
} from "../../../../_redux/features/network/services";
import CircularProgress from "@mui/material/CircularProgress";

export interface InvitationInterface {
  "@id": number | string;
  "@type": number | string;
  id: number | string;
  invitedDate: Date;
  updatedDate: Date;
  invitedBy: CandidateInterface;
  invited: CandidateInterface;
  status: "PENDING" | "ACCEPT" | "REFUSED" | "CANCELED";
}

interface InvitationPropsInterface {
  invitation: InvitationInterface;
  isLoading?: boolean;
}

export default function InvitationItem({
  invitation,
  isLoading = false,
}: InvitationPropsInterface) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [acceptAction, acceptTriggers] = useAcceptInvitationMutation();
  const [refuseAction, refuseTriggers] = useRefuseInvitationMutation();
  const viewProfile = () => navigate(`/profile/${invitation?.invitedBy?.id}`);

  return (
    <Container maxWidth="lg">
      <div className="w-full flex flex-row justify-start items-start gap-3">
        <div className="flex flex-col justify-start items-center">
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rounded"
              width={48}
              height={48}
            />
          ) : (
            <CustomUserAvatar
              src={invitation?.invitedBy?.imageUrl}
              className="h-[48px] w-[48px]"
            />
          )}
        </div>

        {isLoading ? (
          <div className="flex flex-col justify-between items-start gap-2 w-full">
            <Skeleton
              className={"pb-1"}
              animation="wave"
              variant="rounded"
              width={"40%"}
              height={10}
            />
            <Skeleton
              className={"pb-1"}
              animation="wave"
              variant="rounded"
              width={"70%"}
              height={10}
            />
            <Skeleton
              className={"pb-1"}
              animation="wave"
              variant="rounded"
              width={"60%"}
              height={10}
            />
            <Skeleton
              className={"pb-1"}
              animation="wave"
              variant="rounded"
              width={"30%"}
              height={30}
            />
          </div>
        ) : (
          <div className="flex flex-row justify-between items-center gap-2 w-full">
            <div className={"cursor-pointer"} onClick={viewProfile}>
              <p className="text-black first-letter:capitalize">
                {invitation?.invitedBy?.fullname}
              </p>
              <p className="text-sm text-[#5e737d] first-letter:capitalize">
                {moment()
                  .locale(`${i18n.language}-DZ`)
                  .to(invitation?.invitedDate)}
              </p>
            </div>
            <div className="flex flex-row justify-between items-start gap-2">
              {invitation?.status === "PENDING" ? (
                <>
                  <Button
                    size="small"
                    color={"success"}
                    variant="outlined"
                    className="first-letter:capitalize font-xing-bold"
                    disabled={
                      acceptTriggers.isLoading || acceptTriggers.isFetching
                    }
                    onClick={() => acceptAction(invitation?.id)}
                  >
                    {acceptTriggers.isLoading || acceptTriggers.isFetching ? (
                      <CircularProgress color={"inherit"} size={17} />
                    ) : (
                      <Done />
                    )}
                    {t("accept.label")}
                  </Button>
                  <Button
                    size="small"
                    color={"error"}
                    variant="outlined"
                    onClick={() => refuseAction(invitation?.id)}
                    disabled={
                      refuseTriggers.isLoading || refuseTriggers.isFetching
                    }
                    className="first-letter:capitalize font-xing-bold p-0"
                    title={t("refuse.label")}
                  >
                    {refuseAction.isLoading || refuseAction.isFetching ? (
                      <CircularProgress color={"inherit"} size={17} />
                    ) : (
                      <Close />
                    )}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    size="small"
                    color={"primary"}
                    onClick={() =>
                      navigate(
                        AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
                          ":locale",
                          `${i18n.language}`
                        ).replace(":id", invitation?.invitedBy?.id)
                      )
                    }
                    variant="outlined"
                    className="first-letter:capitalize font-xing-bold"
                  >
                    {t("profile.view.label")}
                  </Button>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
