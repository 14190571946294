import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";
import { Typography } from "@mui/joy";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export const AVAILABILITY_IMMEDIATE = "1";
export const AVAILABILITY_WITH_NOTICE = "2";
export const AVAILABILITY_STANDBY = "3";
const items = [
  { label: "availability.immediate.label", value: AVAILABILITY_IMMEDIATE },
  { label: "availability.with_notice.label", value: AVAILABILITY_WITH_NOTICE },
  { label: "availability.standby.label", value: AVAILABILITY_STANDBY },
];
export default function SelectAvailability(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();

  return (
    <Select variant="standard" fullWidth {...props}>
      {items.map((e, i) => (
        <MenuItem
          key={`muiItemAvailability${i}`}
          style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
          className="w-full"
          value={e.value}
        >
          <Typography className="first-letter:uppercase">
            {t(e.label)}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}
