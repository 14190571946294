import {CloseSharp, ExpandLess} from "@mui/icons-material";
import {Container} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function PerformanceAnalysis () {

    const {t} = useTranslation()
    const [open, setIsOpen] = useState(false)

    return (
        <Container maxWidth="xs">

            <div className="flex flex-row justify-start items-center gap-3">
                <div className="text-xl first-letter:capitalize font-xing-bold">
                    {t("profile.performance.analysis.label")}
                </div>
                <div onClick={() => setIsOpen(!open)}
                     className="cursor-pointer flex flex-row justify-between items-center px-1 bg-success text-white font-xing-bold">
                    <p className="text-xs">
                        {t("profile.header.premium.label")}
                    </p>
                    <div className={`transition-all duration-100 ${open ? "rotate-180" : ""}`}>
                        <ExpandLess height={18} width={18}/>
                    </div>

                </div>
            </div>

            <div
                className={`transition-all duration-100 delay-100 ${open ? "" : "opacity-0 hidden"} display flex flex-row justify-between items-start rounded-xl bg-success p-3`}
            >
                <Container maxWidth="xs" className="p-0" style={{padding: 0}}>
                    <div className="py-3">
                        {t("profile.performance.discover.frequency.of.searches.label")}
                    </div>
                </Container>
                <div>
                    <CloseSharp onClick={() => setIsOpen(false)}/>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center gap-3">
                <div className="relative text-6xl font-xing-bold">
                    <div className="text-6xl font-xing-bold">
                        60 453
                    </div>
                    <div className="absolute bottom-0 left-0 right-0 bg-success h-1/3 w-full z-[-1]"/>
                </div>
                <Container maxWidth="xs" className="p-0" style={{padding: 0}}>
                    <div className="text-md text-justify">
                        {t("profile.performance.search.frequency.for.your.skills.label")}
                    </div>
                </Container>
            </div>

        </Container>
    )

}