import React from "react";
import TuneIcon from "@mui/icons-material/Tune";
import { useTranslation } from "react-i18next";
import TitledBorderedRoundedContainer from "../../../../components/containers/TitledBorderedRoundedContainer";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../_redux/auth/services";
import { Button } from "@nextui-org/react";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";

export default function FoundByRecruiters() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const { data: cv, isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });
  const initialValues = {
    title: cv?.title || "",
    mobility: cv?.mobility || "",
    targetJob: cv?.targetJob || "",
    disponibility: cv?.disponibility,
    disponibilityDuration: cv?.disponibilityDuration || 0,
    careerLevel: cv?.careerLevel?.["@id"] || null,
    salary: cv?.salary?.["@id"] || null,
    country: cv?.country?.["@id"] || null,
    region: cv?.region?.["@id"] || null,
    city: cv?.city?.["@id"] || null,
    function: cv?.function?.["@id"] || null,
  };
  const expectations = Object.values(initialValues).slice(3, 35);

  return (
    <TitledBorderedRoundedContainer
      title={t("find.jobs.be.found.by.recruiters.label")}
      subtitle={t("find.jobs.instead.of.looking.for.a.new.job.yourself.label")}
    >
      <div className="flex flex-col justify-between gap-3 items-start rounded-2xl select-none max-md:p-1 md:p-3 font-xing">
        <div className="flex flex-row justify-between items-center gap-1">
          {(!isCvLoading || !isProfileLoading) && (
            <div className="border-solid border-2 border-white bg-success font-xing-bold rounded-2xl text-xs py-1 px-2 select-none ">
              {initialValues && expectations?.filter((i) => i !== null)?.length}/
              {initialValues && expectations?.length}
            </div>
          )}

          <p className="text-sm">
            {t("find.jobs.added.job.expectations.label")}
          </p>
        </div>
        <p className="font-xing-bold text-lg">
          {t("find.jobs.improve.the.relevance.of.recruiter.messages.label")}
        </p>
        <div className="w-full flex flex-wrap justify-start items-center ">
          {Object.keys(initialValues)
            .slice(3, 35)
            .filter((i) => i !== null)
            .map((expectation, i) => (
              <div
                key={i}
                className="flex flex-row justify-start items-center bg-white rounded-2xl p-2 cursor-pointer"
              >
                <p className="text-sm rounded-full border-black border-2 px-2 py-1 font-xing-bold">
                  {t(`profile.${expectation}.label`)}
                </p>
              </div>
            ))}
        </div>
        <div className="w-full flex flex-row justify-start gap-3 cursor-pointer">
          <Button
            variant="light"
            className="font-xing-bold text-medium"
            aria-label={t("update.label")}
            onClick={() => {
              navigate(
                AppRoutes.PRIVATE_PROFESSIONAL_EXPECTATIONS.replace(
                  ":locale",
                  `${i18n.language}`
                )
              );
            }}
            title={t("update.label")}
          >
            <TuneIcon />
            <p className="font-xing-bold">
              {t(
                "profile.professional.expectations.edit.job.preferences.label"
              )}
            </p>
          </Button>
        </div>
      </div>
    </TitledBorderedRoundedContainer>
  );
}
