import React from "react";
import {Image, Textarea} from "@nextui-org/react";
import {useTranslation} from "react-i18next";

type User = {
    id: number,
    name: string,
    description: string,
    thumbnail: string
}

type Article = {
    id: number,
    title: string,
    body: string,
    picture: string,
    source: string,
    path: string
}

type Comment = {
    id: number,
    user: string,
    content: string,
    likes: number,
    liked: boolean
}

interface ArticleComponentProps {
    user: User,
    article: Article,
    comments: Comment[] | undefined,
    post: any
}

export default function ArticleComponent({post} :{ post: ArticleComponentProps }) {


    const {t} = useTranslation()

    const {user, article, comments} = post

    return (
        <div className="w-full flex flex-col justify-between gap-2">
            <div className="bg-white flex flex-row justify-start items-center gap-2">
                <div className="shrink-0 flex justify-center">
                    <div className="h-16 w-16 rounded-full overflow-hidden flex justify-center items-center">
                        <img
                            height={63}
                            width={63}
                            src={user?.thumbnail}
                            alt="avatar"
                        />
                    </div>
                </div>
                <div>
                    <div className="text-md font-semibold text-black">{user?.name}</div>
                    <p className="text-gray-500 font-medium text-sm">{user?.description}</p>
                </div>
            </div>

            <div className="md:ms-0 lg:ms-16 xl:ms-16 2xl:ms:16 flex flex-col justify-between gap-2">

                <div className="w-full min-w-full">
                    <Image
                        radius="none"
                        loading="lazy"
                        src={article?.picture}
                        alt="article"
                        srcSet={article?.picture}
                        classNames={{
                            wrapper: "min-w-full w-full",
                            img: "min-w-full w-full",
                            blurredImg: "backdrop-blur-[7px] backdrop-saturate-[180%] bg-white bg-opacity-80"
                        }}
                    />
                </div>

                <div className="w-full">
                    <h1 className="font-semibold">
                        {article?.title}
                    </h1>
                    <p className="text-justify">
                        {article?.body}
                    </p>
                    <p className="text-gray-200">
                        {article?.source}
                    </p>
                </div>

                <div className="flex justify-between gap-6 items-center font-medium text-gray-400">
                    <div className="first-letter:capitalize cursor-pointer flex justify-start gap-3 items-center">
                        <img
                            height={16}
                            width={16}
                            src="/svg/prototype-icon-likes.svg"
                            alt="likes"
                        />
                        <p className="first-letter:capitalize font-semibold cursor-pointer">10</p>
                    </div>
                    <div
                        className="first-letter:capitalize font-semibold cursor-pointer"> {t("home.comment.title.label", {count: comments?.length ?? 0})}</div>
                </div>

                <div className="flex justify-start gap-6 items-center font-medium">
                    <div
                        className="first-letter:capitalize font-semibold hover:underline cursor-pointer">{t("home.like.label")}</div>
                    <div
                        className="first-letter:capitalize font-semibold hover:underline cursor-pointer">{t("home.comment.action")}</div>
                    <div className="grow"></div>
                    <div className="first-letter:capitalize font-semibold hover:underline cursor-pointer">{t("home.share")}</div>
                </div>

                <div className="relative rounded-2xl bg-gray-100 flex flex-row justify-between">
                    <img
                        className="absolute h-[30px] w-[30px] shadow object-contain border-1 border-solid border-black rounded-full"
                        src={user?.thumbnail}
                        alt="avatar"
                    />
                    <Textarea
                        placeholder={t("home.comment.action")}
                    />
                </div>
                <div className="flex flex-col justify-evenly gap-5">
                    {
                        comments?.map((comment, i) =>
                            <div key={i} className="flex flex-row justify-start gap-3 items-center">
                                <div className="flex-shrink-0">
                                    <img
                                        className="rounded-full h-[40px] w-[40px] object-contain"
                                        src="/images/smile-logo.png"
                                        alt={comment?.user}
                                    />
                                </div>
                                <div className="flex flex-col justify-between">
                                    <h6 className="font-medium text-gray-500">
                                        {comment?.user}
                                    </h6>
                                    <p className="text-gray-400">
                                        {comment?.content}
                                    </p>
                                    <div className="flex flex-row justify-start gap-5">
                                        <div className="flex flex-row justify-between gap-1">
                                            <img
                                                height={12}
                                                width={12}
                                                src="/svg/prototype-icon-likes.svg"
                                                alt="likes"
                                            />
                                            <div
                                                className="first-letter:capitalize font-semibold hover:underline cursor-pointer">{comment?.likes}</div>
                                        </div>
                                        {
                                            comment?.liked ?
                                                <div
                                                    className="first-letter:capitalize font-semibold hover:underline cursor-pointer text-blue-500">
                                                    {t('home.liked.label')}
                                                </div>
                                                :
                                                <div
                                                    className="first-letter:capitalize font-semibold hover:underline cursor-pointer">
                                                    {t('home.like.label')}
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )

}