import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import CustomCheckbox from "../../../../components/_form/_field/Checkbox";
import { useGetCardinalsQuery } from "../../../../_redux/features/region/services";
import { useTranslation } from "react-i18next";
import DropDownFilter from "./DropDownFilter";
import { useSelector } from "react-redux";
import {
  selectJobsSearch,
  setSearch,
} from "../../../../_redux/features/jobs/jobsSlice";
import { removeEmptyProperty } from "../../../../_redux/features/jobs/services";
import { Values } from "../ui/JobsSearch";
import { useAppDispatch } from "../../../../_redux/store";
import { useNavigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../../utils/appRoutes";
import { selectToken } from "../../../../_redux/auth/authSlice";

export default function RegionFilter() {
  const { regionFilterParams } = useParams();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  const { t, i18n } = useTranslation();
  const { values, handleChange, submitForm, setFieldValue } =
    useFormikContext();
  const { data: cardinalsData, isLoading } = useGetCardinalsQuery({
    pagination: false,
  });
  const { list: cardinals } = cardinalsData || {};

  useEffect(() => {
    // 0 for the id and 1 for the name
    if (regionFilterParams) {
      navigate(
        AppRoutes[isAuth ? "PRIVATE_JOBS_SEARCH" : "JOBS"][0].replace(
          ":locale",
          `${i18n.language}`
        ),
        { replace: true }
      );
    }
  }, [isAuth, navigate, i18n.language, regionFilterParams]);

  return (
    <DropDownFilter
      isLoading={isLoading}
      label={t("profile.region.label")}
      hasBadge={values?.cardinals?.length}
    >
      {cardinals && (
        <div className="flex flex-col justify-between px-3">
          {cardinals?.map((region: any, index: number) => (
            <CustomCheckbox
              key={index}
              disabled={isLoading}
              defaultChecked={values?.cardinals?.find(
                (cardinal: any) => cardinal === region["1"]
              )}
              value={region["1"]}
              onChange={handleChange}
              label={t(`cardinals.${region["1"]}.label`)}
              name="cardinals"
            />
          ))}
        </div>
      )}
    </DropDownFilter>
  );
}
