import React from "react";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { Button } from "@mui/material";
import {
  useAddSelectedJobMutation,
  useDeleteSelectedJobMutation,
} from "../../_redux/features/jobs/services";
import { JobComponentItemPropsInterface } from "../jobComponent/jobComponentItem/jobComponentItem";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

export default function SelectedJobButton({
  job, iconOnly = false,
}: JobComponentItemPropsInterface) {
  const { t } = useTranslation();

  const [addSelectedAction, addSelectedTrigger] = useAddSelectedJobMutation();
  const [deleteSelectedAction, deleteSelectedTrigger] =
    useDeleteSelectedJobMutation();

  const selectedJobHandler = (e: EventListener): void => {
    e.stopPropagation();

    const payload = {
      id: job?.id,
      "@id": `/api/jobs/${job?.id}`,
    };

    job?.selected ? deleteSelectedAction(payload) : addSelectedAction(payload);
  };

  return (
    <Button
      disabled={
        addSelectedTrigger?.isFetching ||
        deleteSelectedTrigger?.isFetching ||
        addSelectedTrigger?.isLoading ||
        deleteSelectedTrigger?.isLoading
      }
      className="w-full flex flex-row justify-between items-center gap-3 relative z-3"
      onClick={selectedJobHandler}
    >
      {addSelectedTrigger?.isFetching ||
      deleteSelectedTrigger?.isFetching ||
      addSelectedTrigger?.isLoading ||
      deleteSelectedTrigger?.isLoading ? (
        <CircularProgress size={17} />
      ) : (
        <>
          {job?.selected ? (
            <BookmarkIcon color="primary" />
          ) : (
            <BookmarkBorderIcon color="primary" />
          )}
        </>
      )}

      {!iconOnly ?
          <p className="text-sm first-letter:capitalize normal-case max-lg:hidden truncate">
            {job?.selected
                ? t("find.jobs.selected.label")
                : t("find.jobs.select.this.offer.label")}
          </p> : null}
    </Button>
  );
}
