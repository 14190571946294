import React from "react";
import {useTranslation} from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MyContacts from "../network/MyContacts/MyContacts";
import BorderColorIcon from '@mui/icons-material/BorderColor';

export default function NewAsideMessageModal() {

    const {t} = useTranslation()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () =>  {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    return (
        <>
                <BorderColorIcon
                    onClick={handleClickOpen}
                    className={"cursor-pointer"}
                    title={t("messages.new.message.label")}
                />
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <MyContacts/>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

        </>
    )
}