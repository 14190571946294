import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { NavLink } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DeleteJobAlertButton from "./DeleteAlertDialog";
import UpdateJobAlertButton from "./UpdateAlertDialog";
import {
  removeEmptyProperty,
  useGetJobAlertQuery,
} from "../../../../../_redux/features/jobs/services";
import { setSearch } from "../../../../../_redux/features/jobs/jobsSlice";
import { useDispatch } from "react-redux";

export interface JobAlertItemPropsInterface {
  isIconOnly: boolean;
  alert: JobAlertInterface;
}

export interface JobAlertInterface {
  id: number;
  "@id": string;
  "@type": string;
  archived: boolean | null | undefined;
  frequency: string | null | undefined;
  keyword: string | null | undefined;
  candidate?: string | null;
  name: string | null | undefined;
  createdDate: string;
}

const JobAlertItem = ({ alert }: JobAlertItemPropsInterface) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { data: singleAlert } = useGetJobAlertQuery(alert?.id, {
    skip: !alert?.id,
  });

  const goAndSearch = () => {
    let search = { alertId: singleAlert?.id };

    if (singleAlert?.function?.length > 0) {
      search = { ...search, function: singleAlert?.function };
    }

    if (singleAlert?.sector?.length > 0) {
      search = {
        ...search,
        sector: singleAlert?.sector,
        sectorGroup: singleAlert.sector,
      };
    }

    if (singleAlert?.region?.length > 0) {
      search = {
        ...search,
        region: singleAlert?.region,
        cardinals: singleAlert.region,
      };
    }

    if (singleAlert?.experience?.length > 0) {
      search = {
        ...search,
        experience: singleAlert?.experience,
        nbMonthExperience: singleAlert?.experience,
      };
    }

    if (singleAlert?.keyword && singleAlert?.keyword !== "") {
      search = { ...search, keyword: singleAlert?.keyword };
    }

    const params = removeEmptyProperty({ ...search, _page: 1 });
    dispatch(setSearch({ search: params }));
  };

  return (
    <div className="cursor-pointer w-full min-h-[115px] relative flex flex-row justify-between items-center p-2 transition-all duration-150 rounded-sm">
      <div className="grow flex flex-col justify-start items-start gap-1">
        <p className="font-xing-bold text-lg text-black">{alert?.name}</p>
        <p className="font-xing first-letter:capitalize text-sm text-gray-600">
          {moment().locale(`${i18n.language}-DZ`).to(alert?.createdDate)}
        </p>
        <div className="flex flex-row justify-start items-center gap-1">
          <UpdateJobAlertButton alert={alert} isIconOnly />
          <DeleteJobAlertButton alert={alert} isIconOnly />
        </div>
      </div>

      <div className="flex flex-col justify-start items-end gap-6">
        <NavLink to="/jobs/search" onClick={goAndSearch}>
          <ArrowRightAltIcon
            className={`${i18n.dir() === "rtl" ? "scale-[-1]" : ""}`}
          />
        </NavLink>
      </div>
    </div>
  );
};

export default JobAlertItem;
