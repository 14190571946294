import React, { ReactNode } from "react";
import { Button } from "@nextui-org/react";
import { object, string } from "yup";
import { Formik } from "formik";
import Loading from "../../../Loading";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
  useUpdateCvMutation,
} from "../../../_redux/auth/services";
import ProfileResumeEditForm from "../../_form/Profile/ProfileResumeEditForm";

import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

export const careerObjectiveSchema = object().shape({
  careerObjective: string().required(
    i18n.t("validation.field.required.unspecific")
  ),
});

interface Values {
  careerObjective: string;
}
export default function ProfileResumeEditModal({
  text,
  icon,
  ...props
}: {
  text: string;
  icon: ReactNode;
}) {
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const {
    data: cv,
    isLoading: isCvLoading,
    isFetching: isCvFetching,
  } = useGetMyCvQuery(profile?.id, { skip: !profile });
  const [updateCvAction, trigger] = useUpdateCvMutation();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let initialValues = {
    careerObjective: cv?.careerObjective || "",
  };

  const handelSubmit = async (values: Values) => {
    updateCvAction({ id: profile?.id, ...values })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", { value: t("summary.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  if (isCvLoading || isProfileLoading) {
    return <Loading />;
  }

  return (
    <>
      <Button {...props} aria-label={t("update.label")} onClick={handleOpen}>
        {icon && icon}

        {text && <p className="font-xing-bold ">{text}</p>}
      </Button>

      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("summary.label")}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("summary.label")}
                </h1>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handelSubmit}
                  validationSchema={careerObjectiveSchema}
                >
                  <ProfileResumeEditForm
                    onCancel={handleClose}
                    trigger={trigger}
                    isCvLoading={isCvLoading || isCvFetching}
                  />
                </Formik>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
