import {Skeleton} from "@mui/material";

export default function MessageSkeletons({count = 3}: {count?: number}) {

    return ([...Array(count).keys()].map(item =>
        <div key={item} className={"flex flex-col gap-2 py-3"}>
            <div className={"flex justify-center"}>
                <Skeleton variant="rounded" width={210} height={10}/>
            </div>
            <div className={"flex gap-3 items-center"}>
                <Skeleton variant="rounded" width={40} height={40}/>
                <Skeleton variant="rounded" width={210} height={15}/>
            </div>
            <div>
                <Skeleton variant="rounded" width={120} height={50}/>
            </div>
        </div>)
    )
}