import { AnyObject } from "yup";

export function urlHelpers(uri: string) {
  const url = new URL(process.env.REACT_APP_API_BASE_URL + uri);
  const params = url.searchParams;
  const paramsObject = {};

  for (const [key, value] of params.entries()) {
    paramsObject[key] = value;
  }

  return paramsObject;
}
export function filterObjToString(filters: object): string {
  const params = new URLSearchParams();

  Object.keys(filters).forEach((key) => {
    if (
      filters[key] !== "" &&
      filters[key] !== undefined &&
      filters[key] !== null
    ) {
      params.append(key, filters[key]);
    }
  });

  return params.toString();
}

export function removeInvalidKeys(obj: AnyObject): AnyObject {
  for (const key in obj) {
    if (
      obj[key] === null ||
      obj[key] === undefined ||
      obj[key] === "" ||
      obj[key] === 0
    ) {
      delete obj[key];
    }
  }
  return obj;
}
