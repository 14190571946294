import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useGetProfileQuery} from "../../../../_redux/auth/services";
import {useGetJobApplicationsQuery} from "../../../../_redux/features/jobs/services";
import ApplicationsIllustration from "../../../../components/illustration/NoResultIllustration";
import {Container} from "@mui/material";
import {JobsSearchLoading, SimilarJobsLoading} from "../../jobs/loading";
import JobComponentItem from "../../jobsSearch/jobSearchItem";
import {Paginator} from "../../../../components/paginator/Paginator";

export default function MyApplications () {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const [page, setPage] = useState<number>(1);
    const {data, isLoading, isFetching} = useGetJobApplicationsQuery({id: profile?.id, filters: {_page: page}}, {skip: !profile})
    const {list: applications, pagination} = data || {}

    return (
        <div className="w-full">
            {
                applications?.length <= 0 ?
                    <div className="w-full min-h-[360px] bg-white rounded-xl ">
                        <Container>
                            <div className="flex flex-col justify-around items-center gap-3">
                                <ApplicationsIllustration primary="#ffb836" />
                                <p className="text-lg font-xing-bold text-center">
                                    { t("job.applications.your.applications.no.result.label") }
                                </p>
                                <p className="text-md font-xing text-center">
                                    { t("job.applications.your.applications.when.you.apply.for.an.offer.label") }
                                </p>
                            </div>
                        </Container>
                    </div>
                    :
                    isLoading ?
                        <SimilarJobsLoading/>
                        :
                        <div className="grid grid-cols-1 gap-4">
                            { isLoading ?
                                <JobsSearchLoading/>
                                :
                                applications?.map((job: any) => <JobComponentItem key={job["@id"]} job={job}/>) }
                            <Paginator
                                count={pagination?.last?._page ?? 1}
                                disabled={isFetching || isLoading}
                                onChange={(e, page) => setPage(page)}
                                page={page ?? 1}
                            />
                        </div>
            }
        </div>
    )
}