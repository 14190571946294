import React, {useEffect, useState} from "react";
import {Container} from "@mui/material";
import TitledContainer from "../../../components/containers/TitledContainer";
import NetworkBorderlessUserItem
    from "../../../components/networkUserComponent/networkUserItem/NetworkBorderlessUserItem";
import {useGetUserStrangersQuery} from "../../../_redux/features/network/services";
import {Paginator} from "../../../components/paginator/Paginator";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import EmptyNetwork from "./EmptyNetwork";
import {useTranslation} from "react-i18next";
import {Input as NextInput} from "@nextui-org/input";
import {SearchIcon} from "@nextui-org/shared-icons";
import {style} from "../jobsSearch/filters/constants";

export default function DevelopYourNetwork() {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()

    const [filters, setFilters] = useState({ keyword: undefined, itemsPerPage: 6, _page: 1 })
    const {data, isLoading, isFetching} = useGetUserStrangersQuery(
        {id: profile?.id, filters: filters}, {skip: !profile}
    )

    const {list: users, pagination} = data || {}
    const [onChangeTimer, setOnChangeTimer] = useState<NodeJS.Timeout | undefined>()

    useEffect(() => {
        return () => {
            clearTimeout(onChangeTimer)
        }
    }, []);

    const onChangeHandler = (value: { keyword: string, _page: number }) => {
        clearTimeout(onChangeTimer)
        const timer = setTimeout(() => {
            setFilters(value)
        }, 500)
        setOnChangeTimer(timer);
    }

    return (
        <Container maxWidth="md">
            <div className="w-full flex flex-col justify-start items-center gap-6">
                <TitledContainer
                    id="expandYourNetwork"
                    title={t("network.yours.expand.label")}
                    subtitle={t("network.yours.expand.label")}
                    className="flex flex-col gap-6 gap-y-9"
                >

                    <div className="w-full flex flex-col justify-start gap-9">
                        <div
                            className="w-full flex flex-row max-md:flex-col justify-center items-center gap-6">
                            <div className="w-full grow ">
                                <NextInput
                                    radius="full"
                                    startContent={<SearchIcon height={24} width={24}/>}
                                    classNames={style}
                                    variant="bordered"
                                    fullWidth={true}
                                    type="search"
                                    name="keyword"
                                    onChange={(e) => onChangeHandler({keyword: e.target.value, _page: 1})}
                                    placeholder={t("network.contacts.yours.search")}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-6 gap-y-9">
                        { isLoading ?
                            <>
                                <NetworkBorderlessUserItem isLoading user={null}/>
                                <NetworkBorderlessUserItem isLoading user={null}/>
                                <NetworkBorderlessUserItem isLoading user={null}/>
                                <NetworkBorderlessUserItem isLoading user={null}/>
                            </>
                            :
                            <>
                                {
                                    users?.length
                                        ?
                                        users?.map((user: any, i: number) =>
                                            <NetworkBorderlessUserItem user={user} key={i}/>)
                                            :
                                            <EmptyNetwork/>
                                 }
                            </>
                        }
                    </div>
                </TitledContainer>
                {
                    <Paginator
                        count={pagination?.last?._page ?? 1}
                        disabled={isFetching || isLoading}
                        onChange={(e, page) => setFilters(prev => ({...prev, _page: page}))}
                        page={filters?._page ?? 1}
                    />
                }
            </div>
        </Container>
    )
}