import React, { useEffect, Suspense, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "./Loading";
import { ToastContainer } from "react-toastify";
import { selectToken } from "./_redux/auth/authSlice";
import FreezerProvider from "./components/Freezer/FreezerProvider";
import { MuiThemeProvider } from "@material-ui/core";
import { theme } from "./utils/appTheme";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import "react-slick";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/ar";
import "moment/locale/fr";
import "leaflet/dist/leaflet.css";
import { Capacitor, PluginListenerHandle} from "@capacitor/core";
import {useTranslation} from "react-i18next";
import {buildRoutes} from "./BuildRoutes";
import {Network} from '@capacitor/network';
import NotConnected from "./pages/public/notConnected";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";

if( Capacitor.isNativePlatform() ) {
  GoogleAuth.initialize({
    clientId: "139468034933-qri0a9d87ctcjo0agoal7kjreoaor7sv.apps.googleusercontent.com",
    scopes: ["profile", "email"],
    grantOfflineAccess: true,
  })
}

const isPushNotificationsAvailable = Capacitor.isPluginAvailable("PushNotifications");

function App() {
  const {i18n} = useTranslation();
  const token = useSelector(selectToken);
  const [isAuth, setIsAuth] = useState(!!token);

  const [router, setRouter] = useState(
      createBrowserRouter(buildRoutes(i18n.language, isAuth), {
        basename: "/",
      })
  );

  useEffect(() => {
    setRouter(createBrowserRouter(buildRoutes(i18n.language, isAuth)));
  }, [i18n.language, isAuth]);

  useEffect(() => {
    setIsAuth(!!token);
  }, [token]);

  useEffect(() => {
    if (isPushNotificationsAvailable) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });

      // On success, we should be able to receive notifications
      PushNotifications.addListener("registration", (token: Token) => {
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", (error: any) => {
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
          }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notification: ActionPerformed) => {
          }
      );
    }
  }, []);
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const checkNetworkStatus = async () => {
      const status = await Network.getStatus();
      setIsConnected(status.connected);
    };

    // Create a variable to store the network listener handle
    let networkListenerHandle: PluginListenerHandle;

    const setupNetworkListener = async () => {
      networkListenerHandle = await Network.addListener('networkStatusChange', (status) => {
        setIsConnected(status.connected);
      });
    };

    // Set up network listener and initial status check
    checkNetworkStatus();
    setupNetworkListener();

    // Cleanup on component unmount
    return () => {
      if (networkListenerHandle) {
        networkListenerHandle.remove();
      }
    };
  }, []);

  if (!isConnected) {
    return <NotConnected /> // Render the custom error page when offline
  }

  return (
    <Suspense fallback={<Loading />}>
      <MuiThemeProvider theme={theme}>
        <FreezerProvider>
          {isClient && (
            <ToastContainer
              closeButton={true}
              theme="light"
              pauseOnHover={true}
              autoClose={1500}
              position="bottom-right"
              bodyClassName="font-xing"
            />
          )}
          <RouterProvider router={router} />
        </FreezerProvider>
      </MuiThemeProvider>
    </Suspense>
  );
}

export default App;
