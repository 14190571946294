import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PercentageBar from "../../../../../components/pourcentageBar/PercentageBar";
import {
  useGetMyCvQuery,
  useGetCvScoreQuery,
  useGetProfileQuery,
} from "../../../../../_redux/auth/services";
import ResumeItem from "../../../../../components/resumeItem/ResumeItem";
import BorderedRoundedContainer from "../../../../../components/containers/BorderedRoundedContainer";
import { ScoresInterface } from "../../../../../_redux/features/score/types";
import { useTranslation } from "react-i18next";

export default function ResumePercentage() {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const { data: cv } = useGetMyCvQuery(profile?.id, { skip: !profile });
  const { data: score } = useGetCvScoreQuery(profile?.id, { skip: !profile });

  function getTitleCvProgression(progression: number) {
    if (progression <= 30) {
      return t(
        "profile.resume.percentage.here.is.your.professional.business.card.label"
      );
    } else if (progression <= 50) {
      return t("profile.resume.percentage.one.more.effort.label");
    } else if (progression <= 70) {
      return t("profile.resume.percentage.stay.informed.label");
    } else if (progression <= 90) {
      return t("profile.resume.percentage.its.the.last.straight.line.label");
    } else if (progression <= 100) {
      return t("profile.resume.percentage.perfect.label");
    }
  }
  return (
    <BorderedRoundedContainer className="w-full rounded-lg border-2 border-[#bcc9d1]">
      <Accordion
        style={{ borderRadius: "0.75rem" }}
        elevation={0}
        classes={{ rounded: "rounded-xl" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="w-full flex flex-col gap-3">
            <h2 className="font-xing-bold text-2xl">
              {getTitleCvProgression(cv?.progression)}
            </h2>
            <div className="w-full min-w-full flex max-lg:flex-col flex-row justify-between items-center gap-3 max-lg:gap-12">
              <div className="flex-1">
                <p className="font-xing">
                  {t(
                    "profile.resume.percentage.introduce.yourself.by.emphasizing.your.skills.label"
                  )}
                </p>
              </div>
              <PercentageBar percentage={score?.progression as number} />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="w-full flex flex-wrap">
            {score &&
              Object.values(score as ScoresInterface)
                .filter((i) => i?.score > 0)
                .map((item, i) => <ResumeItem key={i} resume={item} />)}
          </div>
        </AccordionDetails>
      </Accordion>
    </BorderedRoundedContainer>
  );
}
