import React, {Key, useState} from "react";
import {Tabs, Tab, Button} from "@nextui-org/react";
import SubscriptionOfferItem from "../../../components/modals/SubscriptionModal/SubscriptionOfferItem/SubscriptionOfferItem";

import {Modal} from "@mui/material";
import {ModalClose, ModalDialog, ModalOverflow} from "@mui/joy";
import {useTranslation} from "react-i18next";

export default function SubscriptionModal () {

    const [selected, setSelected] = useState<Key>("sub-monthly");

    const {t} = useTranslation()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Button
                color="primary"
                variant="solid"
                radius="full"
                className="font-xing-bold text-md px-3"
                onClick={handleOpen}
            >
                { t("become.premium.label") }
            </Button>

            <Modal
                className="min-h-full"
                open={open}
                onClose={handleClose}
                title={"Choisissez votre abonnement"}
            >
                <ModalOverflow>
                    <ModalDialog variant="plain" layout="fullscreen">
                        <ModalClose onClick={handleClose} />
                        <div draggable={true} className="p-3">
                            <div className="flex flex-col justify-center items-center">
                                <div className="text-4xl font-xing-bold first-letter:capitalize">
                                    Choisissez votre abonnement
                                </div>
                            </div>
                            <div className="flex flex-col justify-start items-center gap-6 mt-20" >
                                <Tabs
                                    keyboardActivation="automatic"
                                    size="lg"
                                    radius="full"
                                    color="warning"
                                    aria-label="Tabs form"
                                    className="font-xing-bold text-xl p-6 h-[70px] flex flex-row justify-center items-center"
                                    variant="light"
                                    onSelectionChange={(key) => setSelected(key)}
                                >
                                    <Tab className="w-4/5 flex flex-wrap justify-evenly gap-6" key="sub-monthly" title="Abonnement Monsuel">
                                        <SubscriptionOfferItem
                                            title={"hunter"}
                                            icon={"/svg/prototype-sub-hunter.svg"}
                                            color="#6B1641"
                                            background="#8C266C"
                                            price={999}
                                            description={ ["Discussion", "Groupes", "Accompagnement CV"] }
                                        />
                                        <SubscriptionOfferItem
                                            title={"gold"}
                                            icon={"/svg/prototype-sub-gold.svg"}
                                            color="#6E3F0B"
                                            background="#FFA500"
                                            price={2999}
                                            description={ ["Discussion", "Groupes", "Accompagnement CV"] }
                                        />
                                        <SubscriptionOfferItem
                                            title={"platinum"}
                                            icon={"/svg/prototype-sub-platinum.svg"}
                                            color="#074D39"
                                            background="#0BE3A6"
                                            price={3999}
                                            description={ ["Discussion", "Groupes", "Accompagnement CV"] }
                                        />
                                    </Tab>
                                    <Tab className="w-4/5 flex flex-wrap justify-evenly gap-6" key="sub-yearly" title="Abonnement annuel">
                                        <SubscriptionOfferItem
                                            title={"hunter"}
                                            icon={"/svg/prototype-sub-hunter.svg"}
                                            color="#6B1641"
                                            background="#8C266C"
                                            price={19999}
                                            description={ ["Discussion", "Groupes", "Accompagnement CV"] }
                                        />
                                        <SubscriptionOfferItem
                                            title={"gold"}
                                            icon={"/svg/prototype-sub-gold.svg"}
                                            color="#6E3F0B"
                                            background="#FFA500"
                                            price={29999}
                                            description={ ["Discussion", "Groupes", "Accompagnement CV"] }
                                        />
                                        <SubscriptionOfferItem
                                            title={"platinum"}
                                            icon={"/svg/prototype-sub-platinum.svg"}
                                            color="#074D39"
                                            background="#0BE3A6"
                                            price={39999}
                                            description={ ["Discussion", "Groupes", "Accompagnement CV"] }
                                        />
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>
                    </ModalDialog>
                </ModalOverflow>
            </Modal>
        </>
    )
}