import React from "react";
import {useTranslation} from "react-i18next";
import SkillAddModal from "../../../../../components/modals/Profile/Skill/SkillAddModal";
import {Add} from "@mui/icons-material";
import TitledContainer from "../../../../../components/containers/TitledContainer";
import {useGetMyCvQuery, useGetProfileQuery} from "../../../../../_redux/auth/services";
import Loading from "../../../../../Loading";
import { MySkillsList } from "./MySkillsList";

export default function MySkills() {

    const {t} = useTranslation()

    const {data: profile} = useGetProfileQuery()
    const {data: cv, isLoading: isCvLoading, isFetching: isCvFetching} = useGetMyCvQuery(profile?.id, {skip: !profile})

    return (
            <TitledContainer
                className="p-3 flex flex-col justify-between gap-1"
                title={t("skills.label")}
                buttons={[
                    <SkillAddModal
                        key={1}
                        isIconOnly={true}
                        color="default"
                        variant="flat"
                        radius="full"
                        size="sm"
                        className="p-0"
                        icon={<Add height={10} width={10} />}
                    />
                ]}
                isLoading={isCvLoading || isCvFetching}
            >
                { isCvLoading ? <Loading/> : <MySkillsList skills={cv?.competences}/> }
            </TitledContainer>
    )
}