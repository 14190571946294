import React, { lazy } from "react";
import ProExpectations from "./pages/private/expectations/ProExpectations";
import Subscribers from "./pages/private/subscriptions/Subscribers";
import MyPosts from "./pages/private/MyPosts/MyPosts";
import BecomePremium from "./pages/private/becomePremium/BecomePremium";
import OptForProJobs from "./pages/private/optForProJobs/OptForProJobs";
import SkillsPerformance from "./pages/private/skillsPerformance/Skillsperformance";
import ProfileVisitorsAndStatistics from "./pages/private/visitorsAndStatistics/VisitorsAndStatistics";
import PrivateLanguage from "./pages/private/language/Language";
import PrivateNotifications from "./pages/private/notifications/Notifications";
import Conversation from "./pages/private/conversation/Conversation";
import JobsSearch from "./pages/private/jobsSearch/ui/JobsSearch";
import SelectedOffers from "./pages/private/applications/selectedOffers/SelectedOffers";
import SearchAlert from "./pages/private/applications/searchAlert/SearchAlert";
import MyApplications from "./pages/private/applications/myApplications/MyApplications";
import RecentlyViewed from "./pages/private/applications/recentlyViewed/RecentlyViewed";
import Profile from "./pages/private/profile/Profile";
import { EventDetails } from "./pages/private/event/EventDetails";
import Invitations from "./pages/private/network/Invitations/Invitations";
import MyContacts from "./pages/private/network/MyContacts/MyContacts";
import ReceivedInvitations from "./pages/private/network/Invitations/ReceivedInvitations";
import SentInvitations from "./pages/private/network/Invitations/SentInvitations";
import Home from "./pages/private/home/Home";
import Settings from "./pages/private/settings/Settings";
import Account from "./pages/private/settings/ui/menu/Account";
import Password from "./pages/private/settings/ui/menu/Password";
import Confidentiality from "./pages/private/settings/ui/menu/Confidentiality";
import SettingsMenu from "./pages/private/settings/ui/menu/SettingsMenu";
import { AppRoutes } from "./utils/appRoutes";
import PrivateRoutes from "./utils/PrivateRoutes";
import HomeLoading from "./pages/private/home/loading";
import RootError from "./Error";
import Jobs from "./pages/private/jobs/Jobs";
import Blog from "./pages/private/blog/Blog";
import Network from "./pages/private/network/Network";
import Chat from "./pages/private/chat/Chat";
import Events from "./pages/private/events/Events";
import Private from "./pages/private/Private";
import PublicRoutes from "./utils/PublicRoutes";
import MyProfile from "./pages/private/profile/MyProfile";
import { Navigate, RouteObject } from "react-router-dom";
import Applications from "./pages/private/applications/Applications";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ArticleDetails from "./pages/private/blogArticle/ArticleDetails";
import {
  loadJobData,
  loadPolicy,
  loadPublicHomeData,
  loadPublicJobsData,
} from "./_redux/loaders";
import RedirectJobDetails from "./components/redirectsFromV3/RedirectJobDetails";
import RedirectBlog from "./components/redirectsFromV3/RedirectBlog";
import RedirectRegister from "./components/redirectToV3/RedirectRegister";
import RedirectRecruiter from "./components/redirectToV3/RedirectRecruiter";
import RedirectAdamantumWebAdmin from "./components/redirectToV3/RedirectAdamantumWebAdmin";
import FaqPage from "./pages/public/faq";
import NotFound from "./NotFound";

const Offline = lazy(() => import("./pages/public/offline/Offline"));
const Public = lazy(() => import("./pages/public/Public"));
const PublicHome = lazy(() => import("./pages/public/home/PublicHome"));
const PublicJobs = lazy(() => import("./pages/public/jobs/page"));
const JobDetails = lazy(() => import("./pages/private/job/JobDetails"));
const PublicBlog = lazy(() => import("./pages/public/blog/PublicBlog"));
const ResetPassword = lazy(
  () => import("./pages/public/resetPassword/ResetPassword")
);
const Login = lazy(() => import("./pages/public/login/Login"));
const Register = lazy(() => import("./pages/public/register/Register"));
const SendEmail = lazy(() => import("./pages/public/sendEmail/SendEmail"));
const Language = lazy(() => import("./pages/public/language/Language"));
const ContactAndHelp = lazy(
  () => import("./pages/public/contactAndHelp/ContactAndHelp")
);
const AcceptPolicyPage = lazy(
  () => import("./pages/public/acceptPolicy/AcceptPolicyPage")
);
const PrivacyPolicyPage = lazy(
  () => import("./pages/public/acceptPolicy/PrivacyPolicyPage")
);

export const buildRoutes = (locale: string, isAuth: boolean) => {
  const privateRoutes: RouteObject[] = [
    {
      path: "/:locale",
      element: <Private />,
      loader: HomeLoading,
      errorElement: <RootError />,
      children: [
        ...AppRoutes.PRIVATE_JOB_DETAILS_FROM_v3.map((path) => ({
          path,
          key: path,
          element:
            typeof window !== "undefined" ? (
              <RedirectJobDetails key={path} />
            ) : null,
        })),
        ...AppRoutes.PRIVATE_JOBS.map((path) => ({
          path,
          element: <PrivateRoutes component={<Jobs />} />,
        })),
        {
          path: AppRoutes.OFFLINE,
          element: <PrivateRoutes component={<Offline />} />,
          loader: loadPublicHomeData,
        },
        {
          path: AppRoutes.PRIVATE_HOME,
          element: <PrivateRoutes component={<Home />} />,
        },

        ...AppRoutes.PRIVATE_JOBS_SEARCH.map((path) => ({
          path,
          element: <PrivateRoutes component={<JobsSearch />} />,
          loader: loadPublicJobsData,
        })),

        {
          path: AppRoutes.PRIVATE_JOB_DETAILS,
          element: <PrivateRoutes component={<JobDetails />} />,
        },
        {
          path: AppRoutes.FAQ,
          element: <PrivateRoutes component={<FaqPage />} />,
        },

        {
          path: AppRoutes.PRIVATE_NETWORK,
          element: <PrivateRoutes component={<Network />} />,
        },
        {
          path: AppRoutes.PRIVATE_CHAT,
          element: <PrivateRoutes component={<Chat />} />,

          children: [
            {
              path: AppRoutes.PRIVATE_CONVERSATION,
              element: <PrivateRoutes component={<Conversation />} />,
            },
          ],
        },
        {
          path: AppRoutes.PRIVATE_EVENTS,
          element: <PrivateRoutes component={<Events />} />,
        },
        {
          path: AppRoutes.PRIVATE_EVENT_DETAILS,
          element: <PrivateRoutes component={<EventDetails />} />,
        },
        {
          path: AppRoutes.PRIVATE_BLOG,
          element: <PrivateRoutes component={<Blog />} />,
        },
        {
          path: AppRoutes.PRIVATE_BLOG_ARTICLE_DETAILS,
          element: <PrivateRoutes component={<ArticleDetails />} />,
        },

        ...AppRoutes.PRIVATE_PROFILE.map((path) => ({
          path,
          key: path,

          element: <PrivateRoutes key={path} component={<MyProfile />} />,
        })),

        ...AppRoutes.POLICY.map((path) => ({
          path,
          key: path,
          element: (
            <PrivateRoutes key={path} component={<AcceptPolicyPage />} />
          ),
          loader: loadPolicy,
        })),
        {
          path: AppRoutes.PRIVACY_POLICY,
          element: <PublicRoutes component={<PrivacyPolicyPage />} />,
        },

        {
          path: AppRoutes.PRIVATE_VIEW_CANDIDATE,
          element: <PrivateRoutes component={<Profile />} />,
        },
        {
          path: AppRoutes.PRIVATE_PROFESSIONAL_EXPECTATIONS,
          element: <PrivateRoutes component={<ProExpectations />} />,
        },
        {
          path: AppRoutes.PRIVATE_SUBSCRIPTIONS,
          element: <PrivateRoutes component={<Subscribers />} />,
        },
        {
          path: AppRoutes.PRIVATE_APPLICATIONS,
          element: <PrivateRoutes component={<Applications />} />,

          children: [
            {
              index: true,
              path: AppRoutes.PRIVATE_APPLICATIONS_SELECTED_OFFERS,
              element: <PrivateRoutes component={<SelectedOffers />} />,
            },
            {
              path: AppRoutes.PRIVATE_RECENTLY_VIEWED_OFFERS,
              element: <PrivateRoutes component={<RecentlyViewed />} />,
            },

            {
              path: AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT,
              element: <PrivateRoutes component={<SearchAlert />} />,
            },
            {
              path: AppRoutes.PRIVATE_APPLICATIONS_MY_APPLICATIONS,
              element: <PrivateRoutes component={<MyApplications />} />,
            },
          ],
        },
        {
          path: AppRoutes.PRIVATE_MY_POSTS,
          element: <PrivateRoutes component={<MyPosts />} />,
        },
        {
          path: AppRoutes.PRIVATE_BECOME_PREMIUM,
          element: <PrivateRoutes component={<BecomePremium />} />,
        },
        {
          path: AppRoutes.PRIVATE_OPT_FOR_PRO_JOBS,
          element: <PrivateRoutes component={<OptForProJobs />} />,
        },
        {
          path: AppRoutes.PRIVATE_SKILLS_PERFORMANCE,
          element: <PrivateRoutes component={<SkillsPerformance />} />,
        },
        {
          path: AppRoutes.PRIVATE_PROFILE_VISITORS_AND_STATISTICS,
          element: (
            <PrivateRoutes component={<ProfileVisitorsAndStatistics />} />
          ),
        },
        {
          path: AppRoutes.PRIVATE_LANGUAGE,
          element: <PrivateRoutes component={<PrivateLanguage />} />,
        },
        {
          path: AppRoutes.PRIVATE_NOTIFICATIONS,
          element: <PrivateRoutes component={<PrivateNotifications />} />,
        },
        {
          path: AppRoutes.PRIVATE_NETWORK_REQUESTS,
          element: <PrivateRoutes component={<Invitations />} />,

          children: [
            {
              index: true,
              path: AppRoutes.PRIVATE_NETWORK_REQUESTS,
              element: <PrivateRoutes component={<ReceivedInvitations />} />,
            },
            {
              path: AppRoutes.PRIVATE_NETWORK_REQUESTS_SENT,
              element: <PrivateRoutes component={<SentInvitations />} />,
            },
          ],
        },
        {
          path: AppRoutes.PRIVATE_YOUR_NETWORK,
          element: <PrivateRoutes component={<MyContacts />} />,
        },
        {
          path: AppRoutes.PRIVATE_SETTINGS,
          element: <PrivateRoutes component={<Settings />} />,

          children: [
            {
              index: true,
              path: AppRoutes.PRIVATE_SETTINGS,
              element: <PrivateRoutes component={<SettingsMenu />} />,
            },
            {
              path: AppRoutes.PRIVATE_SETTINGS_ACCOUNT,
              element: <PrivateRoutes component={<Account />} />,
            },
            {
              path: AppRoutes.PRIVATE_SETTINGS_PASSWORD,
              element: <PrivateRoutes component={<Password />} />,
            },
            {
              path: AppRoutes.PRIVATE_SETTINGS_CONFIDENTIALITY,
              element: <PrivateRoutes component={<Confidentiality />} />,
            },
          ],
        },
        {
          path: AppRoutes.PRIVATE_CONTACT_AND_HELP,
          element: <PrivateRoutes component={<ContactAndHelp />} />,
        },
        // EP_V3_ROUTES
        {
          path: AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT_CANDIDATE,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT.replace(
                  ":locale",
                  locale
                )}
                replace
              />
            ) : null,
        },
        {
          path: AppRoutes.PRIVATE_APPLICATIONS_SELECTED_OFFERS_CANDIDATE,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.PRIVATE_APPLICATIONS_SELECTED_OFFERS.replace(
                  ":locale",
                  locale
                )}
                replace
              />
            ) : null,
        },
        {
          path: AppRoutes.PRIVATE_APPLICATIONS_MY_APPLICATIONS_CANDIDATE,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.PRIVATE_APPLICATIONS_MY_APPLICATIONS.replace(
                  ":locale",
                  locale
                )}
                replace
              />
            ) : null,
        },

        {
          path: AppRoutes.PRIVATE_EVENTS_CANDIDATE,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.PRIVATE_EVENTS.replace(":locale", locale)}
                replace
              />
            ) : null,
        },

        {
          path: AppRoutes.PRIVATE_PROFILE_FROM_V3,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.PRIVATE_PROFILE[0].replace(":locale", locale)}
                replace
              />
            ) : null,
        },

        {
          path: AppRoutes.PRIVATE_BLOG_ARTICLE_DETAILS_FROM_v3,
          element: typeof window !== "undefined" ? <RedirectBlog /> : null,
        },

        ...AppRoutes.PRIVATE_PROFILE.map((path) => ({
          index: true,
          path,
          key: path,
          element: <PrivateRoutes key={path} component={<MyProfile />} />,
        })),

        {
          path: AppRoutes.PRIVATE_BLOG_FILTER_FROM_v3,
          element: <PrivateRoutes component={<Blog />} />,
        },
        {
          path: AppRoutes.PRIVATE_BLOG_FROM_v3,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.PRIVATE_BLOG.replace(":locale", locale)}
                replace
              />
            ) : null,
        },

        {
          path: "*",
          element: <RootError />,
        },
      ],
    },
    // redirect to v3

    {
      path: AppRoutes.REGISTER_FROM_v4,
      element: typeof window !== "undefined" ? <RedirectRegister /> : null,
    },
    {
      path: AppRoutes.RECRUITER_FROM_v4,
      element: typeof window !== "undefined" ? <RedirectRecruiter /> : null,
    },
    {
      path: AppRoutes.ADAMANTIUMWEBADMIN_FROM_v4,
      element:
        typeof window !== "undefined" ? <RedirectAdamantumWebAdmin /> : null,
    },

    {
      path: "*",
      element:
        typeof window !== "undefined" ? (
          <Navigate to={`/${locale}`} replace />
        ) : null,
    },
  ];

  const publicRoutes: RouteObject[] = [
    {
      path: "/:locale",
      element: <Public />,
      loader: HomeLoading,
      errorElement: <RootError />,
      children: [
        {
          path: AppRoutes.OFFLINE,
          element: <PublicRoutes component={<Offline />} />,
          loader: loadPublicHomeData,
        },
        {
          path: AppRoutes.HOME,
          element: <PublicRoutes component={<PublicHome />} />,
          loader: loadPublicHomeData,
        },

        ...AppRoutes.PRIVATE_JOB_DETAILS_FROM_v3.map((path) => ({
          path,
          key: path,
          element:
            typeof window !== "undefined" ? (
              <RedirectJobDetails key={path} />
            ) : null,
        })),

        ...AppRoutes.JOBS.map((path) => ({
          path,
          element: <PublicRoutes component={<PublicJobs />} />,
          loader: loadPublicJobsData,
        })),

        {
          path: AppRoutes.PUBLIC_JOB_DETAILS,
          element: <PublicRoutes component={<JobDetails />} />,
          loader: loadJobData,
        },
        {
          path: AppRoutes.PRIVATE_BLOG,
          element: <PublicRoutes component={<Blog />} />,
        },

        {
          path: AppRoutes.BLOG,
          element: <PublicRoutes component={<PublicBlog />} />,
        },

        {
          path: AppRoutes.PRIVATE_BLOG_ARTICLE_DETAILS,
          element: <PublicRoutes component={<ArticleDetails />} />,
        },
        {
          path: AppRoutes.LINKEDIN_CALLBACK,
          element: <PublicRoutes component={<LinkedInCallback />} />,
        },
        {
          path: AppRoutes.RESET_PASSWORD,
          element: <PublicRoutes component={<ResetPassword />} />,
        },
        {
          path: AppRoutes.LOGIN,
          element: <PublicRoutes component={<Login />} />,
        },
        {
          path: AppRoutes.REGISTER,
          element: <PublicRoutes component={<Register />} />,
        },
        {
          path: AppRoutes.SEND_EMAIL,
          element: <PublicRoutes component={<SendEmail />} />,
        },
        {
          path: AppRoutes.LANGUAGE,
          element: <PublicRoutes component={<Language />} />,
        },
        {
          path: AppRoutes.CONTACT_AND_HELP,
          element: <PublicRoutes component={<ContactAndHelp />} />,
        },
        {
          path: AppRoutes.FAQ,
          element: <PublicRoutes component={<FaqPage />} />,
        },
        ...AppRoutes.POLICY.map((path) => ({
          path,
          key: path,
          element: <PublicRoutes key={path} component={<AcceptPolicyPage />} />,
          loader: loadPolicy,
        })),
        {
          path: AppRoutes.PRIVACY_POLICY,
          element: <PublicRoutes component={<PrivacyPolicyPage />} />,
        },
        {
          path: AppRoutes.CONTACT_AND_HELP_FROM_v3,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes[
                  isAuth ? "PRIVATE_CONTACT_AND_HELP" : "CONTACT_AND_HELP"
                ].replace(":locale", locale)}
                replace
              />
            ) : null,
        },
        {
          path: AppRoutes.REGISTER_FROM_v3,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.REGISTER.replace(":locale", locale)}
                replace
              />
            ) : null,
        },

        {
          path: AppRoutes.SEND_EMAIL_FROM_v3,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.SEND_EMAIL.replace(":locale", locale)}
                replace
              />
            ) : null,
        },

        {
          path: AppRoutes.RESET_PASSWORD_FROM_v3,
          element:
            typeof window !== "undefined" ? (
              <Navigate
                to={AppRoutes.RESET_PASSWORD.replace(":locale", locale)}
                replace
              />
            ) : null,
        },

        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },

    // redirect to v3

    {
      path: AppRoutes.REGISTER_FROM_v4,
      element: typeof window !== "undefined" ? <RedirectRegister /> : null,
    },
    {
      path: AppRoutes.RECRUITER_FROM_v4,
      element: typeof window !== "undefined" ? <RedirectRecruiter /> : null,
    },
    {
      path: AppRoutes.ADAMANTIUMWEBADMIN_FROM_v4,
      element:
        typeof window !== "undefined" ? <RedirectAdamantumWebAdmin /> : null,
    },
    {
      path: "*",
      element:
        typeof window !== "undefined" ? (
          <Navigate to={`/${locale}`} replace />
        ) : null,
    },
  ];

  return isAuth ? privateRoutes : publicRoutes;
};
