import { useGetCvQuery } from "../../../../_redux/auth/services";
import { useTranslation } from "react-i18next";
import CustomAvatar from "../../../../components/customAvatar/CustomAvatar";
import { LocationOnOutlined } from "@mui/icons-material";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import {
  useGetSingleCandidateQuery,
  useGetUserCommonFriendsWithQuery,
} from "../../../../_redux/features/network/services";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "@mui/material";
import React from "react";
import FriendShipButton from "../../../../components/networkUserComponent/Buttons/FriendShipButton";
import CustomUserAvatar from "../../../../components/customAvatar/CustomUserAvatar";
import { AppRoutes } from "../../../../utils/appRoutes";

export default function ProfileHeader() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: profile, isLoading: isProfileLoading } =
    useGetSingleCandidateQuery(parseInt(id), { skip: !id });
  const { data: cv } = useGetCvQuery(profile?.id, { skip: !profile?.id });
  const currentExperience = cv?.experiences?.find((exp: any) => exp?.current);

  const { data: commonFriendsData } = useGetUserCommonFriendsWithQuery(
    {
      id: profile?.id,
      filters: { _page: 1, itemsPerPage: 3 },
    },
    { skip: !profile?.id }
  );

  const { list: commonFriends, totalItems } = commonFriendsData || {};

  return (
    <div className="relative w-full flex flex-col justify-between h-[500px]">
      {isProfileLoading ? (
        <div className="w-full h-[250px] animate-pulse bg-gray-200 rounded-t-xl" />
      ) : (
        <div
          className="flex-1 rounded-t-xl"
          style={{
            backgroundImage: "url('/images/prototype-profile-background.png')",
            backgroundSize: "100%",
            backgroundPosition: "center end",
            backgroundRepeat: "none",
          }}
        />
      )}
      <div className="flex-1">
        <div className="relative flex flex-col justify-between">
          <div className="relative w-full h-full flex flex-col justify-between items-start p-6">
            <div className="absolute top-[-97px] max-lg:top-[-50px] max:md:start-[50%] max:md:translate-x-[50%] z-10">
              <CustomAvatar
                className="max-lg:h-[100px] max-lg:w-[100px] h-[194px] w-[194px]"
                src={profile?.imageUrl}
              />
            </div>
            <div className="w-full max-lg:pt-[50px] lg:ps-[214px] flex flex-wrap justify-between items-start gap-3">
              <div className="w-full flex flex-wrap justify-between items-center">
                {isProfileLoading ? (
                  <Skeleton animation="wave" width={"50%"} />
                ) : (
                  <div className="flex flex-row justify-start items-center gap-3">
                    <p className="text-2xl bg-gray font-xing-bold">
                      {profile?.fullname}
                    </p>
                    <div className="flex justify-stretch">
                      <h1 className="text-xs bg-gray-200 font-xing-bold px-2 py-1 flex justify-stretch rounded-sm">
                        {t("profile.header.standard.label")}
                      </h1>
                    </div>
                  </div>
                )}

                <div className="flex flex-row gap-3 items-center">
                  {isProfileLoading ? (
                    <Skeleton animation="wave" variant="rounded" width={80} />
                  ) : (
                    <>
                      <FriendShipButton user={profile} />
                      {/*<DropDownMoreActionsButton user={profile}/>*/}
                    </>
                  )}
                </div>
              </div>

              {isProfileLoading ? (
                <Skeleton animation="wave" width={"50%"} />
              ) : (
                <div className="flex flex-col justify-between items-start gap-3">
                  <div className="flex flex-col justify-between items-start gap-1">
                    {currentExperience && (
                      <div className="flex flex-row justify-start gap-3">
                        <WorkOutlineOutlinedIcon height={18} width={18} />
                        <div className="flex flex-row justify-start gap-1 items-center">
                          {currentExperience?.jobTitle && (
                            <b>{currentExperience?.jobTitle} </b>
                          )}
                          {currentExperience?.companyName && (
                            <p>, {currentExperience?.companyName} </p>
                          )}
                        </div>
                      </div>
                    )}
                    {(profile?.city || profile?.country) && (
                      <div className="flex flex-row justify-start gap-3">
                        <LocationOnOutlined height={18} width={18} />
                        <p>
                          {profile?.city && profile?.city?.name}{" "}
                          {profile?.country && profile?.country?.name}
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="flex-1 flex flex-col gap-3">
                    <p className="font-xing-bold text-xl">
                      {totalItems} contact commun
                    </p>
                    <div className="flex gap-3">
                      {commonFriends?.map((item) => (
                        <CustomUserAvatar
                          onClick={(e: MouseEvent) => {
                            e.preventDefault();
                            navigate(
                              AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
                                ":locale",
                                `${i18n.language}`
                              ).replace(":id", item?.id)
                            );
                          }}
                          title={item?.fullname}
                          className="h-[48px] w-[48px] cursor-pointer"
                          src={item.imageUrl}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
