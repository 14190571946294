import React from "react";
import {useTranslation} from "react-i18next";
import {useGetMyCvQuery, useGetProfileQuery} from "../../../../../_redux/auth/services";
import Loading from "../../../../../Loading";
import Certificate from "./Certificate";
import CertificateAddModal from "../../../../../components/modals/Profile/Certificate/CertificateAddModal";
import {CvInterface} from "../MyProfile";
import {StudyLevelInterface} from "../../../../../_redux/features/studyLevels/types";
import TitledContainer from "../../../../../components/containers/TitledContainer";
import NoDiplomasAndCertificatesFound from "../../../../../components/noResultFound/NoDiplomasAndCertificatesFound";
import {Add} from "@mui/icons-material";

export interface CertificateInterface {
    id: number,
    "@id": string,
    title: string,
    startYear: string,
    type: [],
    domain: [],
    studyLevel: StudyLevelInterface|undefined,
    endDate: string,
    school: string,
    description: string,
    cv?: string|CvInterface|undefined,
}

export default function MyCertificates() {

    const {data: profile} = useGetProfileQuery()
    const {data: cv, isLoading: isCvLoading, isFetching: isCvFetching} = useGetMyCvQuery(profile?.id, {skip: !profile})
    const {t} = useTranslation()

    if (isCvLoading) {
        return <Loading/>
    }

    return (
        <TitledContainer
            title={t("diplomas.and.certificates.label")}
            buttons={[
                <CertificateAddModal
                    key={1}
                    isIconOnly={true}
                    color="default"
                    variant="flat"
                    radius="full"
                    size="sm"
                    className="p-0"
                    icon={<Add height={10} width={10} />}
                />
            ]}
            isLoading={isCvLoading || isCvFetching}
        >

        {
            // @ts-ignore
            cv?.formations?.length > 0
                ?
                <div className="flex flex-col justify-between items-center gap-3">
                    {
                        cv?.formations?.map((certificate, key) =>
                           <Certificate certificate={certificate} key={key}/>
                        )
                    }
                </div>
                :
                <NoDiplomasAndCertificatesFound />
            }
        </TitledContainer>
    )
}