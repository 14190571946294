import { bool, date, object, ref, string } from "yup";
import i18n from "i18next";
import { format } from "date-fns";

export const experienceSchema = () =>
  object().shape({
    companyName: string().required(
      i18n.t("validation.field.required.unspecific")
    ),
    jobTitle: string().required(i18n.t("validation.field.required.unspecific")),
    startDate: date().required(i18n.t("validation.field.required.unspecific")),
    endDate: date()
      .when("current", {
        is: true,
        then: (schema) =>
          schema
            .test(
              "endDate",
              "${path} is not equal to " + format(new Date(), "yyyy-MM-dd"),
              (value) => {
                return true;
              }
            )
            .nullable(),
        otherwise: (schema) =>
          schema
            .required(i18n.t("validation.field.required.unspecific"))
            .max(new Date())
            .min(ref("startDate")),
      })
      .when("$startDate", ([other], schema) =>
        other
          ? schema.min(
              other,
              i18n.t("validation.field.min.unspecific", { min: other })
            )
          : schema
      ),
    comment: string().required(i18n.t("validation.field.required.unspecific")),
    current: bool(),
  });
