import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useTranslation } from "react-i18next";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { AppRoutes } from "../../utils/appRoutes";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";

export interface NavBarPropsInterface {
  window?: () => Window;
  children?: any;
}

export default function BottomNavbar(
  navBarPropsInterface: NavBarPropsInterface
) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [value, setValue] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  const items = [
    {
      title: t("sidebar.offers"),
      enabledIcon: <WorkOutlinedIcon width={18} height={18} />,
      disabledIcon: <WorkOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_JOBS,
      onClick: () =>
        navigate(
          AppRoutes.PRIVATE_JOBS[0].replace(":locale", `${i18n.language}`)
        ),
      className: undefined,
      disabled: false,
    },
    {
      title: t("sidebar.profile"),
      enabledIcon: <PersonIcon width={18} height={18} />,
      disabledIcon: <PersonOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_PROFILE,
      onClick: () =>
        navigate(
          AppRoutes.PRIVATE_PROFILE[0].replace(":locale", `${i18n.language}`)
        ),
      className: undefined,
      disabled: false,
    },
    {
      title: t("sidebar.alerts"),
      enabledIcon: <LightbulbIcon width={18} height={18} />,
      disabledIcon: <LightbulbOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT,
      onClick: () =>
        navigate(
          AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT.replace(
            ":locale",
            `${i18n.language}`
          )
        ),
      className: undefined,
      disabled: false,
    },
  ];

  return (
    <div className="w-full h-[56px] bg-white fixed left-0 right-0 bottom-0 z-[1100] grid grid-cols-3 items-center border-t-2 border-solid border-gray-200 md:hidden">
      {items.map((item, i) => (
        <div
          key={i}
          onClick={item?.onClick}
          className="flex-1 flex flex-col justify-center items-center gap-1"
        >
          {pathname === item.path ? item?.enabledIcon : item?.disabledIcon}
          <p
            className={`${
              pathname === item.path ? "font-xing-bold" : "font-xing"
            } text-xs truncate`}
          >
            {item?.title}
          </p>
        </div>
      ))}
    </div>
  );
}
