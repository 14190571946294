import {Form, useFormikContext} from "formik";
import {Button} from "@nextui-org/react";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {MutationResultSelectorResult} from "@reduxjs/toolkit/query";
import {ArrowPathIcon} from "@heroicons/react/20/solid";
import PasswordInput from "../../passwordInput/PasswordInput";
import CustomSpinner from "../../spinner/CustomSpinner";

interface ChangePasswordFormInterface {
    onCancel?: () => void|undefined;
    triggers: MutationResultSelectorResult<any>;
}
export default function ChangePasswordForm({triggers, onCancel = () => undefined}: ChangePasswordFormInterface) {

    const {t} = useTranslation()
    const {setErrors: setErrorsHook, setSubmitting, initialValues, isSubmitting} = useFormikContext();
    const {isSuccess, isLoading, isError, error, reset} = triggers

    useEffect(() => {
        return () => reset()
    }, [triggers]);

    useEffect(() => {
        if (isSuccess) {
            toast.success(t("update.message.specific", {value: t("password.label")}), {
                position: toast.POSITION.BOTTOM_LEFT
            });

            setErrorsHook({})
            reset();

            if (typeof onCancel === "function") {
                onCancel();
            }

        }
    }, [isSuccess]);

    useEffect(() => {
        setSubmitting(isLoading)
    }, [isLoading]);

    useEffect(() => {
        if (isError) {
            let errors = {}
            // @ts-ignore
            error?.data?.violations?.forEach((violation: { propertyPath: any; message: any; }) => {
                // @ts-ignore
                if (violation?.propertyPath in initialValues) {
                    errors[violation?.propertyPath] = violation?.message;
                } else {
                    toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            });

            if (errors) {
                setErrorsHook(errors)
            }
        }
    }, [isError, error]);

    return (
        <Form className="w-full flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center gap-6 max-sm:w-full max-md:w-3/4 max-lg:w-2/3 w-1/2">
                <div className="w-full flex flex-col justify-between items-start gap-2">
                    <h1 className="font-light">
                        {t("password.label")}
                    </h1>
                    <PasswordInput
                        variant="outlined"
                        label=""
                        name="oldPassword"
                        placeholder={t("password.placeholder")}
                        disabled={isSubmitting}
                    />
                </div>
                <div className="w-full flex flex-col justify-between items-start gap-2">
                    <h1 className="font-light">
                        {t("password.newPassword.label")}
                    </h1>
                    <PasswordInput
                        variant="outlined"
                        label={""}
                        placeholder={t("password.newPassword.placeholder")}
                        name="plainPassword"
                        disabled={isSubmitting}
                    />
                </div>
                <div className="w-full flex flex-col justify-between items-start gap-2">
                    <h1 className="font-light">
                        {t("password.confirmationNewPassword.label")}
                    </h1>
                    <PasswordInput
                        variant="outlined"
                        label=""
                        placeholder={t("password.confirmationNewPassword.placeholder")}
                        name="confirmationPassword"
                        disabled={isSubmitting}
                    />
                </div>

                <div className="flex flex-row justify-center items-center gap-6">
                    <Button
                        type="submit"
                        spinner={<CustomSpinner />}
                        disabled={isSubmitting}
                        isLoading={isSubmitting || isLoading}
                        className="bg-[#FFB836] text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                        startContent={ (isSubmitting || isLoading) ? null : <ArrowPathIcon height={24} width={24}/> }
                    >
                        {t("update.label")}
                    </Button>
                </div>
            </div>
        </Form>

    )
}