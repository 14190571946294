import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import useCurrentDimension, {
  getCurrentDimension,
  lg,
} from "../../_hooks/useCurrentDimension";
import {
  selectJobsSearch,
  setSearch,
} from "../../_redux/features/jobs/jobsSlice";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../_redux/store";
import { useSelector } from "react-redux";
import StraightOutlinedIcon from "@mui/icons-material/StraightOutlined";
import CheckIcon from "@mui/icons-material/Check";

export default function SortDropdown() {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const [screenSize, setScreenSize] = useCurrentDimension();
  let search = useSelector(selectJobsSearch);
  const initialSorts = [
    { title: "sort.by.latest.label", value: "publishedDate", order: "desc" },
    { title: "sort.by.popular.label", value: "nbView", order: "desc" },
  ];
  const [sorts, setSort] = useState(initialSorts);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);

  const switchSort = (sort: any, i: number) => {
    let newSorts = [...sorts];
    newSorts.some((ele) => {
      if (ele.value === sort.value) {
        ele.order = ele.order === "desc" ? "asc" : "desc";
        dispatch(
          setSearch({
            search: { ...search, [`order[${ele.value}]`]: ele.order },
          })
        );
        return true;
      }
      return false;
    });
    setSort(newSorts);
    setIndex(i);
  };

  return (
    <Dropdown
      backdrop="blur"
      className="min-w-full w-full bg-white mx-3 shadow-2xl rounded-md"
      classNames={{ backdrop: "bg-transparent opacity-0 backdrop-blur-[1px]" }}
    >
      <DropdownTrigger>
        <Button
          isIconOnly={screenSize.width < lg}
          radius="full"
          color="primary"
          variant="flat"
          fullWidth={true}
          className="text-md text-black first-letter:capitalize font-xing-bold"
        >
          <div
            className={`transition-all duration-100 ${
              sorts[index].order === "asc" ? "scale-y-[1]" : "scale-y-[-1]"
            }`}
          >
            <StraightOutlinedIcon fontSize="small" />
          </div>
          <p className="max-lg:hidden">{t(sorts[index].title)}</p>
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        closeOnSelect={false}
        variant="light"
        color="warning"
        aria-label="sort"
      >
        {sorts.map((sort: any, i: number) => (
          <DropdownItem
            textValue={sort.value}
            value={sort.value}
            hideSelectedIcon={false}
            selectedIcon={<CheckIcon fontSize="small" />}
            key={i}
            onClick={() => switchSort(sort, i)}
          >
            <div className="flex flex-row justify-between gap-1 items-center">
              <div className="shrink-0 min-w-6">
                {index === i ? <CheckIcon fontSize="small" /> : null}
              </div>
              <div
                className={`grow flex flex-row justify-start items-center ${
                  i === index ? "font-xing-bold" : "font-xing"
                }`}
              >
                {t(sort.title)}
              </div>
              <div className="shrink-0">
                <div
                  className={`transition-all duration-100 ${
                    sort.order === "asc" ? "scale-y-[1]" : "scale-y-[-1]"
                  }`}
                >
                  <StraightOutlinedIcon fontSize="small" />
                </div>
              </div>
            </div>
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
