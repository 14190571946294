import React from "react";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  useGetProfileQuery,
  useUpdateCvCompetenceMutation,
} from "../../../../_redux/auth/services";
import { skillSchema } from "../../../_form/_schema/skillSchema";
import { CvXSkillInterface } from "../../../../_redux/features/skills/cvXSkillInterface";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSingleSkill,
  setSingleSkill,
} from "../../../../_redux/auth/authSlice";
import SkillEditForm from "../../../_form/Skill/SkillEditForm";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

export default function SkillEditModal() {
  const dispatch = useDispatch();
  const { data: profile } = useGetProfileQuery();
  const skill = useSelector(selectSingleSkill);

  const [updateCompetenceAction, updateCompetenceTrigger] =
    useUpdateCvCompetenceMutation();

  const { t } = useTranslation();

  function onClose() {
    dispatch(setSingleSkill({ skill: undefined }));
  }

  const isOpen = !!skill;

  const initialValues = {
    id: skill?.id,
    cv: `/api/candidates/${profile?.id}/cv`,
    level: skill?.level ?? "",
    rank: skill?.rank ?? 0,
    competence: skill?.competence ?? {
      name: "",
    },
  };

  const handelSubmit = async (values: CvXSkillInterface) => {
    updateCompetenceAction(values)
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", {
            value: t("skills.label", { count: 1 }),
          }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        onClose();
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  return (
    <Modal
      className="min-h-full"
      open={isOpen}
      onClose={onClose}
      title={t("add.specific", {
        value: t("profile.languages.label", { count: 1 }),
      })}
    >
      <ModalOverflow>
        <ModalDialog variant="plain" layout="fullscreen">
          <ModalClose onClick={onClose} />
          <Container maxWidth="md">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              flex={3}
              maxWidth="md"
            >
              <h1>
                {t("update.specific", {
                  value: t("skills.label", { count: 1 }),
                })}
              </h1>
              <Formik
                initialValues={initialValues as never}
                onSubmit={handelSubmit}
                validationSchema={skillSchema}
              >
                {() => (
                  <SkillEditForm
                    onCancel={onClose}
                    triggers={updateCompetenceTrigger}
                  />
                )}
              </Formik>
            </Stack>
          </Container>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
