import React, { useEffect } from "react";
import { Button } from "@nextui-org/react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDeleteCvLanguageMutation } from "../../../../_redux/auth/services";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import {
  ArchiveBoxXMarkIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CvXLanguageInterface } from "../../../../_redux/features/languages/cvXLanguageInterface";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

type LanguageDeleteModalProps = {
  language: CvXLanguageInterface;
};

export default function LanguageDeleteModal({
  language,
}: LanguageDeleteModalProps) {
  const { t } = useTranslation();
  const [deleteLanguageAction, { isLoading, isError, error, isSuccess }] =
    useDeleteCvLanguageMutation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function deleteHandler(): void {
    deleteLanguageAction(language?.id)
      .unwrap()
      .then((response) => {
        toast.success(
          t("delete.message.specific", {
            value: t("profile.languages.label", { count: 1 }),
          }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        handleClose();
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  }

  return (
    <>
      <Button
        isIconOnly
        onClick={handleOpen}
        radius="full"
        color="danger"
        aria-label={t("delete.label")}
        className="p-0"
        size="sm"
        title={t("delete.label")}
        variant="light"
      >
        <XCircleIcon height={18} width={18} />
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("add.specific", {
          value: t("profile.languages.label", { count: 1 }),
        })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("delete.specific", {
                    value: t("profile.languages.label", { count: 1 }),
                  })}
                </h1>
                <div className="flex flex-col justify-start items-center">
                  {t("delete.message.confirmation.specific", {
                    context: "female",
                    value: language?.competence?.name,
                  })}
                </div>
                <div className="flex flex-row justify-center items-center py-24 gap-3">
                  <Button
                    onClick={handleClose}
                    disabled={isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    startContent={<XMarkIcon height={24} width={24} />}
                  >
                    {t("close.label")}
                  </Button>
                  <Button
                    onClick={() => deleteHandler()}
                    disabled={isLoading}
                    isLoading={isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    color="danger"
                    startContent={
                      !isLoading ? (
                        <ArchiveBoxXMarkIcon height={24} width={24} />
                      ) : null
                    }
                  >
                    {t("delete.label")}
                  </Button>
                </div>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
