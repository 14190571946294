import TitledContainer from "../../../components/containers/TitledContainer";
import {Container} from "@mui/material";

export default function BecomePremium() {
    return (
        <Container maxWidth="md">
            <TitledContainer
                title="BecomePremium"
                className="h-screen flex flex-col"
            >
                <p>BecomePremium</p>
            </TitledContainer>
        </Container>
    )
}