import React from "react";
import { ReactNode } from "react";
export interface CustomContainerPropsInterface {
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | "fluid";
  className?: string | undefined;
  children?: ReactNode | undefined;
}
export default function CustomContainer({
  children,
  maxWidth,
  className,
  ...props
}: CustomContainerPropsInterface) {
  function switchWidthValue() {
    switch (maxWidth) {
      case "xs":
        return "max-w-[540px]";
      case "sm":
        return "max-w-[720px]";
      case "md":
        return "max-w-[960px]";
      case "lg":
        return "max-w-[1140px]";
      case "xl":
        return "max-w-[1320px]";
      case "fluid":
        return "";
    }
  }

  return (
    <div
      className={`${switchWidthValue()} ${className} box-border m-auto w-full px-6`}
      {...props}
    >
      {children}
    </div>
  );
}
