import React from "react";
import { useTranslation } from "react-i18next";
import {Button} from "@nextui-org/react";
export default function NotConnected() {
    const { t } = useTranslation();

    const handleRefresh = () => {
        window.location.reload();
    }

    return (
        <div className="flex w-full h-[100dvh] flex-col justify-center items-center p-3 gap-3">
            <img
                src='/logo.jpg'
                className="max-w-[214px] max-h-[40px] object-contain"
            />
            <h1 className="text-2xl font-bold">
                {t("notConnected.title")}
            </h1>
            <p className="text-center">
                {t("notConnected.description")}
            </p>
            <Button
                onClick={handleRefresh}
                variant="solid"
                color="warning"
                radius="full"
                className="w-fit font-xing-bold first-letter:capitalize text-[16px] disabled:opacity-80 disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-black"
            >
                {t("notConnected.refresh")}
            </Button>

        </div>
    );
}
