import React from "react";
import { Container } from "@mui/material";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ContactsNoResultsIllustration from "../../../../components/illustration/ContactsNoResultsIllustration";
import { Button } from "@nextui-org/react";

export default function NoInvitationSent() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="w-full min-h-[360px] bg-white rounded-xl ">
      <Container>
        <div className="flex flex-col justify-around items-center gap-6">
          <ContactsNoResultsIllustration primary="#ffb836" />
          <p className="text-xl font-xing-bold text-center">
            {t("invitations.sent.label", { count: 0 })}
          </p>
          <Button
            size="md"
            radius="full"
            color="primary"
            variant="solid"
            onClick={() =>
              navigate(
                AppRoutes.PRIVATE_NETWORK.replace(":locale", `${i18n.language}`)
              )
            }
            className="font-xing-bold"
          >
            {t("network.find.on.label")}
          </Button>
        </div>
      </Container>
    </div>
  );
}
