import {lazy, mixed, number, object, string} from "yup";
import i18n from "i18next";

export const skillSchema = object().shape({
    level: string().required(i18n.t("validation.field.required.unspecific")),
    competence: lazy(value => {
        switch (typeof value) {
            case 'object':
                return object().shape({
                        name: string().required(i18n.t("validation.field.required.unspecific")),
                        type: number().nullable()
                    }).required(i18n.t("validation.field.required.unspecific")); // schema for object
            case 'string':
                return string().required(i18n.t("validation.field.required.unspecific"))
            default:
                return mixed(); // here you can decide what is the default
        }
    }),
});
