import { useState } from "react";

export function getCurrentDimension() {
  if (typeof window !== "undefined") {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  return {
    width: 0,
    height: 0,
  };
}

export default function useCurrentDimension() {
  return useState(getCurrentDimension());
}

export const lg = 1024;

export const xl = 1280;
