import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { Brand } from "../Brand";
import SidebarAvatar from "../sidebar/sidbarAvatar/SidebarAvatar";
import { useAppDispatch } from "../../_redux/store";
import { useTranslation } from "react-i18next";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import { AppRoutes } from "../../utils/appRoutes";
import BookmarkOutlinedIcon from "@mui/icons-material/BookmarkOutlined";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import GroupIcon from "@mui/icons-material/Group";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ArticleIcon from "@mui/icons-material/Article";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import ForumIcon from "@mui/icons-material/Forum";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { setSingleEvent } from "../../_redux/features/events/eventsSlice";
import { NewspaperOutlined, NewspaperSharp } from "@mui/icons-material";
import DiamondIcon from "@mui/icons-material/Diamond";
import DiamondOutlinedIcon from "@mui/icons-material/DiamondOutlined";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import ScatterPlotOutlinedIcon from "@mui/icons-material/ScatterPlotOutlined";
import { NavLink, useLocation } from "react-router-dom";
import { cloneElement, useState } from "react";
import NotificationsIconButton from "./notifications/notificationButton";
import SearchIconButton from "./search/searchButton";
import SettingsIconButton from "./settings/settingsButton";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import LanguageDropdown from "./languageDropdown/LanguageDropdown";
import { useSelector } from "react-redux";
import { selectToken } from "../../_redux/auth/authSlice";

export interface NavBarPropsInterface {
  window?: () => Window;
  children?: any;
}

export default function NavBar(navBarPropsInterface: NavBarPropsInterface) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  const items = [
    {
      title: t("sidebar.jobs"),
      enabledIcon: <WorkOutlinedIcon width={18} height={18} />,
      disabledIcon: <WorkOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_JOBS[0],
      className: undefined,
      disabled: false,
    },
    {
      title: t("sidebar.your.job.offers"),
      enabledIcon: <BookmarkOutlinedIcon width={18} height={18} />,
      disabledIcon: <BookmarkBorderOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_APPLICATIONS,
      className: undefined,
      disabled: false,
    },
    {
      title: t("network.yours.label"),
      enabledIcon: <GroupIcon width={18} height={18} />,
      disabledIcon: <GroupOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_NETWORK,
      className: undefined,
      disabled: true,
    },
    {
      title: t("sidebar.home"),
      enabledIcon: <ArticleIcon width={18} height={18} />,
      disabledIcon: <ArticleOutlinedIcon width={18} height={18} />,
      path: isAuth ? AppRoutes.PRIVATE_HOME : AppRoutes.HOME,
      className: undefined,
      disabled: true,
    },
    {
      title: t("sidebar.chat"),
      enabledIcon: <ForumIcon width={18} height={18} />,
      disabledIcon: <ForumOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_CHAT,
      className: undefined,
      disabled: true,
    },
    {
      title: t("profile.label"),
      enabledIcon: <PersonIcon width={18} height={18} />,
      disabledIcon: <PersonOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_PROFILE[0],
      className: undefined,
      disabled: false,
    },
    {
      title: t("profile.visitors.and.statistics.label"),
      enabledIcon: <DonutSmallIcon width={18} height={18} />,
      disabledIcon: <DonutSmallOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_PROFILE_VISITORS_AND_STATISTICS,
      className: undefined,
      disabled: true,
    },
    {
      title: t("sidebar.professional.expectations"),
      enabledIcon: <TuneIcon width={18} height={18} />,
      disabledIcon: <TuneIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_PROFESSIONAL_EXPECTATIONS,
      className: undefined,
      disabled: false,
    },
    {
      title: t("job.applications.your.search.alerts.label"),
      enabledIcon: <LightbulbIcon width={18} height={18} />,
      disabledIcon: <LightbulbOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_APPLICATIONS_SEARCH_ALERT,
      className: undefined,
      disabled: false,
    },
    {
      title: t("sidebar.subscribers"),
      enabledIcon: <GroupAddIcon width={18} height={18} />,
      disabledIcon: <GroupAddOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_SUBSCRIPTIONS,
      className: "max-sm:hidden",
      disabled: true,
    },
    {
      title: t("sidebar.your.publications"),
      enabledIcon: <BorderColorIcon width={18} height={18} />,
      disabledIcon: <BorderColorOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_MY_POSTS,
      className: "max-sm:hidden",
      disabled: true,
      onClick: () => {
        dispatch(setSingleEvent({ event: undefined }));
      },
    },
    {
      title: t("sidebar.events"),
      enabledIcon: <WorkOutlinedIcon width={18} height={18} />,
      disabledIcon: <WorkOutlineOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_EVENTS,
      className: "max-sm:hidden",
      disabled: true,
      onClick: () => {
        dispatch(setSingleEvent({ event: undefined }));
      },
    },
    {
      title: t("sidebar.news"),
      enabledIcon: <NewspaperSharp width={18} height={18} />,
      disabledIcon: <NewspaperOutlined width={18} height={18} />,
      path: AppRoutes.PRIVATE_BLOG,
      className: undefined,
      disabled: false,
    },
    {
      title: t("sidebar.become.premium.member"),
      enabledIcon: <DiamondIcon width={18} height={18} />,
      disabledIcon: <DiamondOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_BECOME_PREMIUM,
      className: "text-warning max-sm:hidden",
      disabled: true,
    },
    {
      title: t("sidebar.opt.for.pro.jobs"),
      enabledIcon: <ScatterPlotIcon width={18} height={18} />,
      disabledIcon: <ScatterPlotOutlinedIcon width={18} height={18} />,
      path: AppRoutes.PRIVATE_OPT_FOR_PRO_JOBS,
      className: "text-pink-900 max-sm:hidden",
      disabled: true,
    },
  ];

  const drawerWidth = 280;

  const { window } = navBarPropsInterface;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const { pathname } = useLocation();

  const drawer = (
    <div
      dir={i18n.dir()}
      onClick={handleDrawerToggle}
      className="bg-white flex flex-col justify-start p-3 py-6 gap-3 h-full"
    >
      <SidebarAvatar />
      <div className="flex flex-col justify-start items-start gap-3">
        {items
          ?.filter((item) => item.disabled === false)
          .map((item, i) => (
            <NavLink
              key={i}
              onClick={item?.disabled ? () => null : item?.onClick}
              to={
                item?.disabled
                  ? pathname
                  : item?.path.replace(":locale", `${i18n.language}`)
              }
              className={({ isActive }) =>
                `flex-1 p-3 max-sm:p-1 ${
                  item?.disabled ? "" : "hover:bg-[#f4f4f5]"
                } rounded-lg ${
                  isActive && item?.disabled === false ? "font-xing-bold" : ""
                } ${item?.disabled ? " text-gray-200" : null} ${
                  item?.className
                }`
              }
            >
              <div className="max-w-sm mx-auto flex flex-row items-center justify-start text-start gap-3">
                <div className="shrink-0">
                  {pathname === item?.path && item?.disabled === false
                    ? item?.enabledIcon
                    : item?.disabledIcon}
                </div>
                <div className="text-sm">
                  <div className={item?.className}> {item?.title} </div>
                </div>
                {item?.disabled && (
                  <small
                    style={{ fontSize: 11 }}
                    className="text-xs py-0.5 px-2 bg-red-300 text-white rounded-full"
                  >
                    {" "}
                    Soon{" "}
                  </small>
                )}
              </div>
            </NavLink>
          ))}
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  function ElevationScroll(props: NavBarPropsInterface) {
    const { children } = props;

    return cloneElement(children, {
      elevation: 0,
      className: "border-b-2 border-solid border-gray-200",
    });
  }

  return (
    <Box sx={{ display: "flex", paddingTop: "64px", overflow: "auto" }}>
      <CssBaseline />
      <ElevationScroll {...navBarPropsInterface}>
        <AppBar color="inherit" component="nav">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className="!block lg:!hidden"
            >
              <MenuIcon />
            </IconButton>
            <Box className="!hidden lg:!block" p={0}>
              <Brand />
            </Box>

            <Box p={0} flexGrow={1} />
            <Box display="flex" alignItems="center" gap={4} px={2}>
              <LanguageDropdown />
            </Box>

            <Box display="flex" alignItems="center" gap={4} px={2}>
              <SettingsIconButton />
            </Box>
            <Box display="flex" alignItems="center" gap={4} px={2}>
              <SearchIconButton />
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <nav>
        <Drawer
          anchor={i18n.language === "ar" ? "right" : "left"}
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {
              xs: "block",
              lg: "none",
            },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "0.4em",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}
