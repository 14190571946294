import React from "react";
import {mediaRoutes} from "../../utils/mediaRoutes";

interface CustomUserAvatarInterface {
    className?: string,
    onClick?: () => void,
    src?: string,
}

export default function CustomUserAvatar ({ className, src, ...props } : CustomUserAvatarInterface) {

    const addDefaultImg = (e: any): void => {
        e.target.src = "/svg/prototype-user-placeholder.svg"
    }

    return (
        <div
            {...props}
            className={className}>
            <div
                style={{
                    display: "block",
                    height: "100%",
                    maskImage: "url('/svg/prototype-super-ellipse-mask-.svg')",
                    maskPosition: "center center",
                    maskRepeat: "no-repeat",
                    position: "relative",
                    width: "100%"
                }}
            >
                <img
                    style={{
                        aspectRatio: "1 / 1",
                        backgroundColor: "#f4f4f5",
                        borderRadius: "4px",
                        display: "block",
                        height: "100%",
                        margin: "0px",
                        padding: "0px",
                        position: "relative",
                        width: "100%",
                        zIndex: "calc(-1)"
                    }}
                    onError={addDefaultImg}
                    alt="user-profile"
                    aria-label="user-profile"
                    src={src ? `${mediaRoutes.COMPANY_LOGO}${src}` : "/svg/prototype-user-placeholder.svg"}
                />
            </div>
        </div>
    )
}