import React, {useCallback, useMemo, useState} from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {StyledMenu} from "./constants";
import CircularProgress from "@mui/material/CircularProgress";
import {Badge} from "@mui/material";

export default function DropDownFilter({children, isLoading = false, hasBadge = false, label = ""}: {children: JSX.Element, isLoading?: boolean, hasBadge?: boolean, label?: string}) {

    const random = (Math.random() + 1).toString(36).substring(10);
    const id = `${random}-menu`;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const anchor = (elAnchor: HTMLElement | null) => {
        return Boolean(elAnchor);
    };

    const open = useMemo(() => anchor(anchorEl), [anchorEl]);

    const handleClick = useCallback((event: any) => {
        if (isLoading) {
            return;
        }
        setAnchorEl(event.currentTarget);
    }, [isLoading]);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    return (
        <div>
            <div
                className={`${isLoading ? "cursor-wait" : "cursor-pointer"} flex flex-row justify-between items-center gap-3cursor-pointer bg-white hover:bg-white font-xing`}
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                { hasBadge ?
                    <Badge color="error"
                           variant="dot"
                           className="me-2"
                           anchorOrigin={{
                               vertical: 'top',
                               horizontal: 'left',
                           }}
                    />
                    : null
                }
                <p className="font-xing-bold text-md first-letter:capitalize">
                    {label}
                </p>
                {
                    isLoading ?
                        <CircularProgress thickness={7} color={"inherit"} size={11} className={"mx-1"}/>
                        :
                        <>
                            {open ? <KeyboardArrowDownIcon fontSize="medium"/> :
                                <KeyboardArrowUpIcon fontSize="medium"/>}
                        </>
                }
            </div>
            <StyledMenu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{'aria-labelledby': `${random}-button`}}
            >
                { children }
            </StyledMenu>
        </div>
    )
}