import React from "react";
import SkillEditModal from "../../../../../components/modals/Profile/Skill/SkillEditModal";
import NoSkillsFound from "../../../../../components/noResultFound/NoSkillsFound";
import {CvXSkillInterface} from "../../../../../_redux/features/skills/cvXSkillInterface";
import MySkillItem from "./MySkillItem";

export function MySkillsList({skills}:  {skills: CvXSkillInterface[]}) {



    return (
        <div className="flex flex-wrap justify-start items-center gap-3">
            {
                skills?.length > 0
                    ?
                    skills.map(skill => <MySkillItem skill={skill} key={skill["@id"]}/>)
                    :
                    <NoSkillsFound />
            }

            <SkillEditModal/>
        </div>
    )
}