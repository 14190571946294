import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { useGetStudyLevelsQuery } from "../../../../_redux/features/studyLevels/services";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectStudyLevel(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const { data: studyLevelsData, isLoading } = useGetStudyLevelsQuery({
    pagination: false,
  });
  const { list: studyLevels } = studyLevelsData || {};

  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("certificate.studyLevel.label")}
      placeholder={t("certificate.studyLevel.placeholder")}
      {...props}
    >
      {studyLevels &&
        studyLevels?.map((studyLevel: any) => (
          <MenuItem
            style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
            className="w-full"
            key={studyLevel["@id"]}
            value={studyLevel["@id"]}
          >
            {studyLevel?.name}
          </MenuItem>
        ))}
    </Select>
  );
}
