import {Form, useFormikContext} from "formik";
import {Button} from "@nextui-org/react";
import {PencilIcon, XMarkIcon} from "@heroicons/react/24/outline";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import Textarea from "../_field/Textarea";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import CustomSpinner from "../../spinner/CustomSpinner";

interface ProfileHobbyEditFormInterface { trigger: MutationResultSelectorResult<any>, onCancel: () => void }

export default function ProfileHobbyEditForm({trigger, onCancel = undefined}:ProfileHobbyEditFormInterface) {

    const {t} = useTranslation()
    const {setErrors: setErrorsHook, setSubmitting, isSubmitting , initialValues} = useFormikContext();
    const { isSuccess, isError, error, isLoading, reset  } = trigger




    useEffect(() => {
        return () => reset()
    }, []);

    useEffect(() => {
        if (isSuccess) {
            toast.success(t("update.message.specific", {value: t("summary.label")}), {
                position: toast.POSITION.BOTTOM_LEFT
            });

            reset();

            if (typeof onCancel === "function") {
                onCancel();
            }
        }
    }, [isSuccess]);


    useEffect(() => {
        setSubmitting(isLoading)
    }, [isLoading]);

    useEffect(() => {
        if (isError) {
            let errors = {}
            // @ts-ignore
            error?.data?.violations?.forEach((violation: { propertyPath: string; message: any; }) => {
                if (violation?.propertyPath in initialValues) {
                    errors[violation?.propertyPath] = violation?.message;
                } else {
                    toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            });

            if (errors) {
                setErrorsHook(errors)
            }
        }
    }, [isError, error]);

    return (
        <Form className="w-full flex flex-col gap-6">

            <div className="flex flex-row justify-start items-center">
                <Textarea
                    fullWidth
                    label={t("center.of.interest.label")}
                    placeholder="écris un peu sur ton passe-temps"
                    name="hobby"
                    isRequired
                    disabled={isSubmitting}
                />
            </div>
            <div className="flex flex-row justify-center items-center gap-6">
                {
                    typeof onCancel === "function" &&
                    <Button
                        onClick={onCancel}
                        disabled={isSubmitting || isLoading}
                        className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                        startContent={ <XMarkIcon height={24} width={24}/> }
                    >
                        { t("close.label") }
                    </Button>
                }
                <Button
                    type="submit"
                    spinner={<CustomSpinner />}
                    disabled={isSubmitting || isLoading}
                    isLoading={isSubmitting || isLoading}
                    className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
                    color="warning"
                    startContent={ (isSubmitting || isLoading) ? null : <PencilIcon height={24} width={24}/> }
                >
                    { t("update.label") }
                </Button>
            </div>
        </Form>
    )
}