import React from "react";
import ReactDOM, { hydrateRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "./_redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";
import "./i18n";
import "./axios.config";
import "./index.css";
import { HelmetProvider } from "react-helmet-async";
import App from "./App";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_AUTH_TOKEN,
  environment: "production",
});
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
const AppProviders = () => (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleOAuthProvider clientId={clientId}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </GoogleOAuthProvider>
      </PersistGate>
    </Provider>
);
if (process.env.REACT_APP_SERVICE_TYPE === "csr") {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(<AppProviders />);
}
if (process.env.REACT_APP_SERVICE_TYPE === "ssr") {
  const root = document.getElementById("root") as HTMLElement;
  hydrateRoot(root, <AppProviders />);
}
// If you want to start measuring performance in your redux, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
