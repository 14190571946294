import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { companyTypeInterface } from "../../../../_redux/features/company/companyType/types";
import { useGetSectorGroupsQuery } from "../../../../_redux/features/activitySectorGroup/services";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
  id: "id" | "@id";
}

export default function SelectActivitySectorGroup(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const { data: sectorGroupsData, isLoading } = useGetSectorGroupsQuery({
    pagination: false,
  });
  const { list: sectorGroups } = sectorGroupsData || {};
  return (
    <Select
      variant="standard"
      label={t("activity.sector.group.placeholder")}
      placeholder={t("activity.sector.group.placeholder")}
      fullWidth
      isLoading={isLoading}
      {...props}
    >
      {sectorGroups?.map((sectorGroup: companyTypeInterface) => (
        <MenuItem
          style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
          className="w-full"
          key={sectorGroup["@id"]}
          value={sectorGroup[props.id]}
        >
          {sectorGroup?.name}
        </MenuItem>
      ))}
    </Select>
  );
}
