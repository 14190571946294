import React from "react";
import {useGetProfileQuery} from "../../../../../_redux/auth/services";
import {useTranslation} from "react-i18next";

export default function MyProfileMemberSince () {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()

    if (!profile?.registerDate) {
        return <></>
    }

    return (
        <div className={"font-black text-xl text-center my-10"}>
            {t("member.since", {date: profile?.registerDate})}
        </div>
    )
}