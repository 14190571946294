import React from "react";

export default function HomeLoading () {
    return (
        <>
            <div className="w-full animate-pulse mx-auto rounded-3xl px-6 py-3 resize-none bg-gray-100">
                <div className="flex-row justify-between">
                    <div className="flex flex-col justify-between">
                        <div
                            className="rounded-3xl p-6 py-10 resize-none min-w-full bg-gray-100 focus:outline-none"
                        >
                        </div>
                    </div>

                    <div className="h-10 w-10 rounded-full bg-gray-100"></div>
                </div>
            </div>

            <div className="w-full animate-pulse flex flex-col justify-between gap-2">

                <div className="bg-white flex flex-row justify-start items-center gap-2">
                    <div className="h-16 w-16 rounded-full overflow-hidden flex justify-center items-center bg-gray-100"></div>
                </div>

                <div className="ms-16 flex flex-col justify-start gap-3">

                    <div className="w-full">
                        <div className="h-[373px] w-full rounded-3xl bg-gray-100"></div>
                    </div>

                    <div className="w-full flex flex-col justify-between gap-2">
                        <div className="h-8 w-24 rounded-lg bg-gray-100"></div>
                        <div className="h-8 w-full rounded-lg bg-gray-100"></div>
                        <div className="h-8 w-full rounded-lg bg-gray-100"></div>
                    </div>

                    <div className="flex justify-between gap-6 items-center font-medium text-gray-400">
                        <div className="h-8 w-24 rounded-lg bg-gray-100"></div>
                        <div className="h-8 w-24 rounded-lg bg-gray-100"></div>
                    </div>

                    <div className="flex justify-start gap-6 items-center font-medium">
                        <div className="h-8 w-24 rounded-lg bg-gray-100"></div>
                        <div className="h-8 w-24 rounded-lg bg-gray-100"></div>
                        <div className="grow"></div>
                        <div className="h-8 w-24 rounded-lg bg-gray-100"></div>
                    </div>

                </div>
            </div>
        </>
    )
}