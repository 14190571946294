import {createSlice} from '@reduxjs/toolkit';

export interface MobileMenuState {
    value: boolean
}

const initialState: MobileMenuState = {
    value: false
}

export const isOpenSlice = createSlice({
    name: 'menuIsOpen',
    initialState,
    reducers: {
        toggle: (state, action) => { state.value = action.payload },
    }
})

export const { toggle } = isOpenSlice.actions;

export default isOpenSlice.reducer;