import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppRoutes } from "./appRoutes";
import { selectToken } from "../_redux/auth/authSlice";
import { useTranslation } from "react-i18next";

interface PublicRoutesProps {
  component: any;
}

export default function PublicRoutes({ component }: PublicRoutesProps) {
  const isAuth = useSelector(selectToken);
  const { i18n } = useTranslation();
  const { pathname, state } = useLocation();

  const nextUrl =
    (pathname === `/${i18n.language}/register` ||
      pathname === `/${i18n.language}/login`) &&
    state?.jobId
      ? AppRoutes.PRIVATE_JOB_DETAILS.replace(":id", state.jobId).replace(
          ":locale",
          i18n.language
        )
      : AppRoutes.PRIVATE_JOBS[0].replace(":locale", `${i18n.language}`);

  const from =
    (pathname === `/${i18n.language}/register` ||
      pathname === `/${i18n.language}/login`) &&
    state?.jobId
      ? "from_public"
      : "";
  return isAuth
    ? typeof window !== "undefined" && (
        <Navigate to={nextUrl} state={{ from }} />
      )
    : component;
}
