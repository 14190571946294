import React, {useState} from "react";
import {useGetProfileQuery} from "../../../../_redux/auth/services";
import JobComponentItem from "../../jobsSearch/jobSearchItem";
import {Container} from "@mui/material";
import ApplicationsIllustration from "../../../../components/illustration/NoResultIllustration";
import {useTranslation} from "react-i18next";
import {SimilarJobsLoading} from "../../jobs/loading";
import {useGetRecentlyViewedJobsQuery} from "../../../../_redux/features/jobs/services";
import {jobXViewInterface} from "../../../../components/jobComponent/jobComponentItem/jobComponentItem";
import {Paginator} from "../../../../components/paginator/Paginator";

export default function RecentlyViewed () {

    const [page, setPage] = useState<number>(1);
    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const {data, isLoading, isFetching} = useGetRecentlyViewedJobsQuery({id: profile?.id, filters: {_page: page}}, {skip: !profile})
    const {list: jobXViews, pagination, totalItems} = data || {}

    return (
        <div className="w-full flex flex-col justify-between items-center gap-3">
            { isLoading ?
                <SimilarJobsLoading />
                :
                totalItems <= 0 ?
                    <div className="w-full min-h-[360px] bg-white rounded-xl ">
                        <Container>
                            <div className="flex flex-col justify-around items-center gap-3">
                                <ApplicationsIllustration primary="#ffb836" />
                                <p className="text-lg font-xing-bold text-center">
                                    { t("job.recently.viewed.offers.no.result.label") }
                                </p>
                                <p className="text-md font-xing text-center">
                                    { t("job.recently.viewed.offers.no.result.label") }
                                </p>
                            </div>
                        </Container>
                    </div>
                    : isLoading ?
                        <SimilarJobsLoading />
                        :
                    <>
                        {
                            jobXViews?.map((jobXView: jobXViewInterface) =>
                                <JobComponentItem
                                    key={jobXView["@id"]}
                                    job={jobXView?.job}
                                    isIconOnly
                                />
                            )
                        }
                        <Paginator
                            count={pagination?.last?._page ?? 1}
                            disabled={isFetching || isLoading}
                            onChange={(e, page) => setPage(page)}
                            page={page ?? 1}
                        />
                    </>
            }
        </div>
    )
}