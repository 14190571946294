import React from "react";
import {useTranslation} from "react-i18next";
import {AppRoutes} from "../../../../../utils/appRoutes";
import MenuNavLink from "../MenuNavLink";
import Icon from "../../../../../components/icon/Icon";

export default function SettingsMenu() {

    const {t} = useTranslation()

    const menu = [
        {
            title: t("settings.account.label"),
            subtitle: undefined,
            icon: <Icon height={68} width={68} src="/svg/prototype-illustration-user.svg" alt="likes" />,
            path: AppRoutes.PRIVATE_SETTINGS_ACCOUNT,
            onClick: undefined
        },
        {
            title: t("settings.confidentiality.label"),
            subtitle: undefined,
            icon: <Icon height={78} width={78} src="/svg/prototype-illustration-confidentiality.svg" alt="likes" />,
            path: AppRoutes.PRIVATE_SETTINGS_CONFIDENTIALITY,
            onClick: undefined
        }
    ]

    return (
        <div className="w-full flex flex-col justify-start gap-6">
            <div className="h-[32px]" />
            <div className="w-full grid grid-cols-3 max-md:grid-cols-1 justify-between gap-12 bg-white max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full">
                { menu?.map((item: any, index: number) => <MenuNavLink key={index} item={item} /> ) }
            </div>
        </div>
    )

}