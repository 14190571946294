import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const RedirectAdamantumWebAdmin = () => {
  const { locale, slug } = useParams();
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_V3_BASE_URL}/${locale}/adamantiumwebadmin/${slug}`;
  }, [locale, slug]);
  return null;
};

export default RedirectAdamantumWebAdmin;
