import React from "react";
export interface SvgIllustrationInterface {
  primary?: string;
  secondary?: string;
}

export default function ProfessionalExpectationsIllustration({
  primary = "#ffb836",
  secondary,
}: SvgIllustrationInterface) {
  return (
    <svg
      viewBox={`0 0 68 68`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="92px"
      height="92px"
      data-xds="IllustrationExtEnvelope"
    >
      <path
        d="M25.801 10.088a.42.42 0 00.574.14l3.45-2.13a.417.417 0 10-.44-.708l-3.45 2.13a.417.417 0 00-.134.574v-.006zM24.887 7.858a.42.42 0 00.552-.217l.947-2.207a.424.424 0 00-.217-.552.424.424 0 00-.552.217l-.947 2.208a.424.424 0 00.217.551z"
        fill="#FFFFFE"
      ></path>
      <path
        d="M57.254 36.19s2.608 1.082 6.543 3.74c5.669-8.645-2.162-9.96-.379-14.536 1.767-4.543.72-9.403-3.182-13.026-2.77-2.57-6.605-2.045-8.997-.741l-.245-.535-1.588.73.033.067c-4.074-3.69-10.83-2.692-12.285-1.483a6.362 6.362 0 00-2.112 1.566c-.697.77-1.26 1.712-1.717 2.754-.685.1-1.22.3-1.265.318l.295.78c.14-.05.352-.117.603-.178-2.096 5.785-1.35 13.873-1.305 14.29l.04.38h5.528v4.063c-3.829 1.115-5.54 3.077-6.81 5.072l-2.821-4.359c-.63-1.856-1.299-3.667-1.84-4.982V19.029h-1.638v-7.731H8.249v7.73H6.61v11.299c-.602-.251-1.488-.485-2.095.078a1.223 1.223 0 00-.413 1.025c.017.229.1.457.223.692-.373-.112-.719-.162-1.014-.129-.33.045-.602.19-.78.43a.96.96 0 00-.162.774c.078.413.418.887.87 1.36-.079 0-.157 0-.23.011-.306.045-.562.19-.73.419-.774 1.07 1.043 2.485 3.15 4.118 1.282.993 2.736 2.124 3.07 2.854h.006v.006h-.01c.005.083.016.156.021.234v.039c1.823 22.083 17.909 20.467 17.909 20.467h33.649s2.915-23.867-9.96-28.22v-5.462c.785-.017 1.343-.134 1.343-.134s1.934-6.315.836-11.66l1.26-.58-.134-.296c.54-.089 1.103.023 1.416.697 1.471 3.15-1.149 9.804-1.149 13.868 0 4.063 3.568 5.267 3.568 5.267v.005zm-38.905 3.618l-1.037.228c-.123-.819-.24-1.6-.357-2.335l.095.05c1.182.636 2.503 1.343 3.099 1.929l-.022.01 2.034 5.837c-3.116-.876-3.812-5.725-3.812-5.725v.006z"
        fill="#FFFFFE"
      ></path>
      <path
        d="M20.361 39.925c-.424-.624-1.956-1.45-3.31-2.18-.313-.167-.625-.334-.926-.501l.401-.73c.296.161.608.328.914.496 1.578.847 3.072 1.65 3.607 2.447l-.692.468h.006zM18.07 35.817s-1.181-.63-2.29-.98l.25-.798c1.187.373 2.386 1.014 2.436 1.042l-.396.736zM27.378 37.127c-.802-2.497-1.79-5.217-2.503-6.94l.77-.323c.724 1.74 1.716 4.487 2.53 7l-.797.257v.006z"
        fill="#1D2124"
      ></path>
      <path
        d="M22.262 45.822l-2.135-6.13 7.458-4.6 4.643 7.163-8.88 5.133-1.086-1.566z"
        fill={primary}
      ></path>
      <path
        d="M25.745 33.727h-9.799v-.836h8.963V19.86h-.992v-.837h1.828v14.704zM12.876 33.727H6.61V19.023h1.895v.837h-1.06V32.89h5.43v.836z"
        fill="#1D2124"
      ></path>
      <path
        d="M9.085 20.707H8.25v-9.414h15.857v9.409h-.836v-8.573H9.085v8.578z"
        fill="#1D2124"
      ></path>
      <path
        d="M16.164 27l-9.386-7.224.507-.664 8.88 6.834 8.906-6.834.513.664-9.42 7.223zM50.125 34.402h-.836V21.42c.022-4.548-1.488-7.028-2.765-8.31-1.594-1.605-3.857-2.414-6.722-2.414-1.644 0-3.038.62-4.141 1.84-3.913 4.308-3.367 14.82-3.21 16.944h5.601v4.922h-.836v-4.086h-5.53l-.038-.379c-.05-.524-1.199-12.903 3.389-17.964 1.27-1.405 2.876-2.113 4.76-2.113 3.093 0 5.557.898 7.312 2.659 1.388 1.393 3.038 4.069 3.01 8.901v12.976l.006.006z"
        fill="#1D2124"
      ></path>
      <path
        d="M37.98 29.475h2.012l-2.263 1.493.25-1.493zM36.575 23.895l-1.371-.513a1.553 1.553 0 01-.925-.936 1.563 1.563 0 01.111-1.31l.112-.19c.54-.936 1.281-2.218 1.254-3.845-.011-.641-.195-1.076-.563-1.327-.797-.54-2.319-.145-2.843.056l-.295-.78c.094-.034 2.29-.853 3.606.038.602.407.914 1.082.93 2.007.029 1.862-.813 3.316-1.37 4.28l-.106.19a.74.74 0 00-.056.613c.072.2.229.363.435.435l1.37.513-.295.78.006-.011zM37.055 26.782c-.106 0-.2 0-.285-.017l.095-.83c.073.005 1.734.161 2.352-1.466l.78.3c-.612 1.606-2.062 2.013-2.942 2.013z"
        fill="#1D2124"
      ></path>
      <path
        d="M7.92 41.97l10.596-2.34s1.734 8.74 7.123 5.763c5.691-3.144 2.124-12.402 20.345-11.928 19.324.507 14.073 29.245 14.073 29.245H25.851s-16.22 1.628-17.93-20.74z"
        fill={primary}
      ></path>
      <path
        d="M26.158 10.277a.42.42 0 01-.223-.774l3.45-2.135a.417.417 0 11.44.708l-3.45 2.135a.39.39 0 01-.217.06v.006zM25.054 7.887a.415.415 0 01-.385-.585l.948-2.207a.42.42 0 01.77.334l-.948 2.207a.42.42 0 01-.385.251zM44.903 25.032c.345.039 1.125 0 1.125 0a2.011 2.011 0 100-4.025h-1.755s-8.54-2.257-7.369-10.311c.68-1.5 10.406-3.227 14.185 3.233 3.255 5.568.374 14.954.374 14.954s-6.087 1.248-6.56-3.863v.012z"
        fill="#1D2124"
      ></path>
      <path
        d="M49.033 14.035c.211-2.28 6.967-5.602 11.209-1.667 3.901 3.623 4.949 8.484 3.182 13.026-1.767 4.543 6.053 5.892.38 14.537-3.936-2.664-6.544-3.74-6.544-3.74s-3.568-1.199-3.568-5.268c0-4.068 2.62-10.712 1.148-13.867-.73-1.572-2.803-.106-2.803-.106s-3.133-1.483-3.004-2.915z"
        fill="#1D2124"
      ></path>
      <path
        d="M53.558 16.654l-2.566-5.562-1.59.733 2.566 5.562 1.59-.733z"
        fill={secondary}
      ></path>
      <path
        d="M25.6 63.156c-4.693 0-7.965-1.928-8.026-1.967l.435-.714c.507.307 12.546 7.358 23.588-7.591l.675.496c-5.775 7.82-11.945 9.776-16.672 9.776zM21.013 14.66h-9.72v.835h9.72v-.836zM21.041 17.012h-9.72v.836h9.72v-.836zM38.214 19.95a.421.421 0 01-.418-.419v-.724c0-.229.19-.418.418-.418.229 0 .418.19.418.418v.724c0 .229-.19.418-.418.418zM33.733 19.948a.421.421 0 01-.418-.418v-.724c0-.229.19-.418.418-.418.229 0 .418.19.418.418v.724c0 .229-.19.418-.418.418zM23.515 46.53c-.167 0-.334-.011-.507-.039-2.57-.373-4.052-3.478-4.119-3.612l.758-.356c.011.027 1.36 2.831 3.484 3.138 1.17.172 2.413-.463 3.673-1.884.435-.49.897-1.176 1.427-1.974 2.084-3.12 5.234-7.836 12.307-8.522l.078.83c-6.683.647-9.699 5.168-11.694 8.155-.552.825-1.026 1.538-1.5 2.068-1.293 1.455-2.608 2.19-3.918 2.19l.011.006zM8.51 41.96c-.333-.73-1.788-1.856-3.07-2.854-2.107-1.633-3.93-3.043-3.15-4.12.168-.233.419-.378.73-.417.073-.011.146-.017.23-.011-.458-.474-.792-.948-.87-1.36a.96.96 0 01.161-.775c.179-.24.452-.39.78-.43.29-.038.636.017 1.015.129a1.701 1.701 0 01-.223-.691c-.033-.39.112-.742.413-1.02.819-.764 2.168-.067 2.608.161l-.384.742c-.574-.301-1.333-.597-1.656-.296-.145.134-.156.251-.15.34.044.496.73 1.16 1.505 1.767a25.797 25.797 0 012.525 1.761l-.524.653c-.112-.09-.368-.257-.658-.446a28.2 28.2 0 01-1.823-1.277c-1.014-.607-2-1.059-2.541-.992-.134.017-.19.067-.218.106-.01.011-.028.04-.01.117.105.546 1.186 1.51 2.374 2.38a17.258 17.258 0 011.783 1.199l-.474.685a26.15 26.15 0 01-1.817-1.215c-.758-.435-1.505-.752-1.923-.691-.122.017-.156.067-.167.084-.3.418 1.918 2.14 2.988 2.965C7.457 39.608 8.862 40.7 9.28 41.62l-.763.345-.006-.005zM16.488 40.182c-.48-3.25-1.472-10.005-2.71-10.752a.252.252 0 00-.234-.028c-.167.179-.072 1.343-.022 1.973.095 1.16.184 2.252-.267 2.782a.889.889 0 01-.608.312c-.446.028-1.014-.195-1.912-.758l.446-.708c.864.546 1.232.652 1.4.635.267-.284.172-1.432.11-2.19-.11-1.371-.206-2.553.58-2.826.324-.111.647-.078.942.1 1.321.797 2.14 4.766 3.105 11.349l-.825.122-.005-.011z"
        fill="#1D2124"
      ></path>
    </svg>
  );
}
