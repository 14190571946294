import React, { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../utils/appRoutes";
import { useSelector } from "react-redux";
import { selectToken } from "../../_redux/auth/authSlice";

const RedirectJobDetails = () => {
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  const { locale, jobId } = useParams();
  if (!locale || !jobId) return <Navigate to="/" replace />;
  return (
    <Navigate
      to={AppRoutes[isAuth ? "PRIVATE_JOB_DETAILS" : "PUBLIC_JOB_DETAILS"]
        .replace(":id", jobId)
        .replace(":locale", locale)}
      replace
    />
  );
};

export default RedirectJobDetails;
