import React from "react";
import ExperienceEditModal from "../../../../../components/modals/Profile/Experience/ExperienceEditModal";
import ExperienceDeleteModal from "../../../../../components/modals/Profile/Experience/ExperienceDeleteModal";
import { CvInterface } from "../MyProfile";
import moment from "moment";
import { functionTypeInterface } from "../../../../../_redux/features/functionType/types";
import { companyTypeInterface } from "../../../../../_redux/features/company/companyType/types";
import { sectorGroupInterface } from "../../../../../_redux/features/activitySectorGroup/types";
import { Image } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

export interface ExperienceInterface {
  id: number;
  "@id": string;
  companyName: string;
  jobTitle: string;
  startDate: string;
  endDate: string;
  current: boolean;
  comment: string;
  manager: boolean;
  cv?: string | CvInterface | undefined;
  function?: string | functionTypeInterface | undefined;
  companyType?: string | companyTypeInterface | undefined;
  sectorGroup?: string | sectorGroupInterface | undefined;
}

export interface ExperiencePropsInterface {
  experience: ExperienceInterface;
  isLoading?: boolean;
}

export default function MyExperience({
  experience,
  isLoading = false,
}: ExperiencePropsInterface) {
  const { t } = useTranslation();
  return (
    <div className="w-full p-3 flex flex-row justify-between items-start gap-9">
      <div className="max-lg:hidden flex flex-col justify-start items-center shrink-0">
        <Image
          radius="none"
          src="/svg/prorotype-illustration-company-placeholder.svg"
          height={50}
          width={50}
        />
      </div>

      <div className="grow flex flex-col justify-start items-start gap-3 text-lg">
        <div className="flex flex-col justify-evenly items-start">
          <p>{experience?.companyName}</p>
          <div className="flex flex-row justify-start items-center gap-1 text-gray-400">
            <p>{moment(experience?.startDate).format("ll")}</p>
            <p>-</p>
            <p>
              {experience?.current
                ? t("current.label")
                : moment(experience?.endDate).format("ll")}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-evenly items-start">
          <p className="font-xing-bold">{experience?.jobTitle}</p>
          <p>{experience?.function?.name}</p>
        </div>
        <div className="flex flex-col justify-evenly items-start">
          <pre className="font-xing-bold first-letter:capitalize text-pretty">
            {experience.comment}
          </pre>
        </div>
      </div>

      <div className="shrink-0 flex flex-col justify-start items-center">
        {!isLoading && (
          <div className="flex-nowrap flex">
            <ExperienceEditModal experience={experience} />
            <ExperienceDeleteModal experience={experience} />
          </div>
        )}
      </div>
    </div>
  );
}
