import {Field, useField} from "formik";
import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

export interface InputPropsInterface {
    name: string,
    type?: string,
    variant?: "standard" | "outlined" | "filled",
    disabled?: boolean,
    className?: string,
    fullWidth?: boolean,
    label: string,
    placeholder?: string,
    required?: boolean,
    onChange?: (e: any) => void,
    isLoading?: boolean;
    InputProps?: object | undefined
}

export default function Input(props: InputPropsInterface): JSX.Element {

    const [field, meta] = useField(props);


    return (
        <Field
            fullWidth={true}
            {...field}
            {...props}
            variant={"standard"}
            disabled={props?.isLoading || props?.disabled}
            as={TextField}
            error={Boolean(meta.error)}
            helperText={meta.error}
            InputProps={{
                startAdornment: <InputAdornment position="start">
                        {props?.isLoading ?
                            <CircularProgress
                                color={ (meta?.error && meta?.touched)  ? "error" : "primary"}
                                size={17}
                                className="mx-1"
                            />
                            :
                            null
                        }
                    </InputAdornment>,
                ...props?.InputProps
            }}
        />

    )

}