import {createApi} from '@reduxjs/toolkit/query/react'
import {apiPlatformResponseTransform, globalFetchBaseQuery} from "../../fetchBaseQuery";
import {WorkplaceInterface} from "./types";
import {filterObjToString} from "../../../_helpers/urlHelpers";


// Define a service using a base URL and expected endpoints
export const workplaceApi = createApi({
    reducerPath: 'workplaceApi',
    tagTypes: ['workplace'],
    baseQuery: globalFetchBaseQuery,
    endpoints: (builder) => ({
        getWorkplaces: builder.query<any, object>({
            query: (filters: object = {_page: 1}) => {
                return `api/params_work_places?${filterObjToString(filters)}`
            },
            transformResponse: apiPlatformResponseTransform
        }),
        getSingleWorkplace: builder.query<WorkplaceInterface, number|undefined>({
            query: (id: number|undefined) => {
                return `api/params_work_places/${id}`
            },
        }),
    }),

})

export const { useGetWorkplacesQuery, useGetSingleWorkplaceQuery} = workplaceApi