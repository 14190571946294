import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import {ShieldCheckIcon} from "@heroicons/react/24/outline";

import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MenuNavLink from "../MenuNavLink";
import MultiDirectionIcon from "../../../../../components/multiDirectionsIcons/ArrowMultiDirectionIcon";
import ChangePasswordModal from "../../../../../components/modals/settings/ChangePasswordModal";
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';
import ResetPasswordModal from "../../../../../components/modals/settings/ResetPasswordModal";

export default function Password() {

    const [openChangePassword, setOpenChangePassword] = React.useState(false);
    const [openResetPassword, setOpenResetPassword] = React.useState(false);
    const handleOpenChangePassword = () => setOpenChangePassword(true);
    const handleOpenResetPassword = () => setOpenResetPassword(true);
    const handleCloseChangePassword = () => setOpenChangePassword(false);
    const handleCloseResetPassword = () => setOpenResetPassword(false);

    const {t} = useTranslation()
    const navigate = useNavigate()
    const password = [
        {
            title: t("settings.password.changePassword.label"),
            subtitle: "***********",
            icon: <ShieldCheckIcon width={38} height={38} color="#ffa500"/>,
            onClick: handleOpenChangePassword
        },
        {
            title: t("settings.password.resetPassword.label"),
            subtitle: "***********",
            icon: <RestartAltOutlinedIcon className="text-[#ffa500]" fontSize="large"/>,
            onClick: handleOpenResetPassword
        },
    ]

    return (
        <div className="w-full flex flex-col justify-start gap-6">
            <MultiDirectionIcon
                rtlIcon={
                    <ArrowRightIcon
                        onClick={() => { navigate(-1) }}
                        height={32}
                        width={32}
                        className="cursor-pointer"
                    />
                }
                ltrIcon={
                    <ArrowLeftIcon
                        onClick={() => { navigate(-1) }}
                        height={32}
                        width={32}
                        className="cursor-pointer"
                    />
                }
            />
            <div className="w-full grid grid-cols-3 max-md:grid-cols-1 justify-between gap-6 bg-white max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full">
                {
                    password?.map((item: any) => {
                        return ( <MenuNavLink item={item} /> )
                    })
                }
            </div>
            <ChangePasswordModal onClose={handleCloseChangePassword} isOpen={openChangePassword} />
            <ResetPasswordModal onClose={handleCloseResetPassword} isOpen={openResetPassword} />
        </div>
    )

}