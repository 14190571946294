import Message from "./Message";
import {
  chatRoomApi,
  useGetChatRoomQuery,
  useSetSeenMessagesMutation,
} from "../../../_redux/features/chatRoom/services";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { useParams } from "react-router-dom";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../_redux/store";
import { useMercureHub } from "../../../_hooks/useMercureHub";
import { IconButton } from "@mui/material";
import { Autorenew } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { MessageInterface } from "./Conversation";
import MessageSkeletons from "./MessageSkeletons";

export default function Messages({
  messages,
  isFetching,
  isSuccess,
  dataFetch,
  canFetch = false,
}: {
  messages: MessageInterface[];
  isFetching: boolean;
  canFetch: boolean;
  isSuccess: boolean;
  dataFetch: () => void;
}) {
  const { t } = useTranslation();
  const { id } = useParams();
  const [topics, setTopics] = useState<string[]>([]);
  const { data: chatRoom, isLoading: isChatRoomLoading } = useGetChatRoomQuery(
    parseInt(id),
    { skip: !id }
  );
  const { data: profile } = useGetProfileQuery();
  const dispatch = useAppDispatch();
  //   const mercureHubUrl = process.env.REACT_APP_MERCURE_PUBLIC_URL || "";
  const mercureHubUrl = "";
  //   const eventSource = useMercureHub(topics);
  const eventSource = "";
  const [seenMessageAction, seenMessageTriggers] = useSetSeenMessagesMutation();
  const [canScrollDown, setCanScrollDown] = useState(true);
  const messageRef = useRef<HTMLDivElement>(null);

  const updateSeenedMessages = () => {
    if (messages?.length > 0 && !seenMessageTriggers?.isLoading) {
      const unseenMessages = messages
        ?.toReversed()
        ?.filter((m) => !m?.seenDate && m?.receiver?.id === profile?.id);

      if (unseenMessages) {
        const latestMessage = unseenMessages[unseenMessages?.length - 1];

        if (latestMessage?.id) {
          seenMessageAction(latestMessage?.id);
        }
      }
    }
  };

  useEffect(() => {
    updateSeenedMessages();
  }, [messages]);

  useLayoutEffect(() => {
    if (
      messageRef.current != null &&
      messages?.length > 0 &&
      !messages[0]?.seenDate &&
      canScrollDown
    ) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    }
  }, [messages, mercureHubUrl]);

  useEffect(() => {
    if (!chatRoom || !profile) {
      return;
    }

    const roomUrl = new URL(
      `${process.env.REACT_APP_API_BASE_URL}${chatRoom?.["@id"]}/messages/`
    );
    roomUrl.searchParams.append(
      "topic",
      `${process.env.REACT_APP_API_BASE_URL}/api/candidates/${profile?.id}`
    );

    setTopics([roomUrl.toString()]);
  }, [chatRoom, profile?.id]);

  useEffect(() => {
    if (!chatRoom || !eventSource) {
      return;
    }

    eventSource.onmessage = (result) => {
      const data = JSON.parse(result.data);
      dispatch(
        chatRoomApi.util.updateQueryData(
          "getChatRoomMessages",
          {
            id: id,
            filters: { pagination: false },
          },
          (draftPosts) => {
            if (!draftPosts?.list?.find((item) => item?.id === data?.id)) {
              draftPosts?.list?.unshift(data);
            }
          }
        )
      );
    };

    eventSource.onerror = (err) => {
      console.log("EventSource error: ", err);
    };

    return () => {
      eventSource.close();
    };
  }, [chatRoom, profile?.id, eventSource]);

  const thresholdArray = (steps) =>
    Array(steps + 1)
      .fill(0)
      .map((_, index) => index / steps || 0);

  let previousY = 0;
  let previousRatio = 0;
  const handleIntersect = (entries) => {
    entries.forEach((entry) => {
      const currentY = entry.boundingClientRect.y;
      const currentRatio = entry.intersectionRatio;
      const isIntersecting = entry.isIntersecting;

      // Scrolling down/up
      if (currentY < previousY) {
        if (currentRatio > previousRatio && isIntersecting) {
          updateSeenedMessages();
        }
      }

      previousY = currentY;
      previousRatio = currentRatio;
    });
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersect, {
      threshold: thresholdArray(20),
    });

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  useEffect(() => {
    if (isSuccess && !canScrollDown) {
      setCanScrollDown(true);
    }
  }, [isFetching, isSuccess]);

  if (isChatRoomLoading) {
    return <MessageSkeletons />;
  }

  return (
    <div className="relative h-full w-full py-[50px]">
      <div
        style={{
          overflowY: "auto",
          maxHeight: "auto",
          scrollBehavior: "smooth",
          transition: "scroll-behavior 300ms ease-in-out",
        }}
        ref={messageRef}
        className="h-full flex flex-col justify-start relative w-full overflow-y-scroll overflow-x-hidden scrollbar-hide p-3 gap-3"
      >
        <div className={"text-center"}>
          {isFetching ? (
            <MessageSkeletons />
          ) : (
            messages?.length > 0 &&
            canFetch && (
              <IconButton
                aria-label={t("more.label")}
                size="large"
                title={t("more.label")}
                disabled={isFetching}
                disableFocusRipple={isFetching}
                disableRipple={isFetching}
                onClick={() => {
                  setCanScrollDown(false);
                  dataFetch();
                }}
              >
                {<Autorenew />}
              </IconButton>
            )
          )}
        </div>
        {messages?.toReversed()?.map((message, index) => (
          <Message key={index} message={message} />
        ))}

        <div ref={observerTarget}></div>
      </div>
    </div>
  );
}
