import React from "react";
import { Field, useField } from "formik";
import {
  FormControl,
  MenuItem,
  Select as SelectField,
  TextField,
} from "@mui/material";
import { InputLabel } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import { Typography } from "@mui/joy";

interface SelectPropsInterface {
  name: string;
  type?: string;
  id?: string;
  labelId?: string;
  readOnly?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  fullWidth?: boolean;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  isLoading?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
  children?: JSX.Element[];
}

export default function Select(props: SelectPropsInterface): JSX.Element {
  const [field, meta, helper] = useField(props);

  const handleChange = (e: any) => helper.setValue(e.target.value);
  const id =
    props?.id ?? "select-" + (Math.random() + 1).toString(36).substring(10);
  const labelId = `${id}-label`;

  const select = (
    <Field
      labelid={labelId}
      id={id}
      as={props?.multiple ? SelectField : TextField}
      select={true}
      {...field}
      {...props}
      selectedkeys={[field.value]}
      onChange={props?.onChange ? props.onChange : handleChange}
      errormessage={meta?.error}
      isInvalid={meta?.error && meta?.touched}
      disabled={props?.isLoading || props?.disabled}
      error={meta?.error}
      helperText={meta.error}
      startadornment={
        <InputAdornment position="start">
          {props?.isLoading ? (
            <CircularProgress
              color={meta?.error && meta?.touched ? "error" : "primary"}
              size={17}
              className={"mx-1"}
            />
          ) : null}
        </InputAdornment>
      }
    >
      {(props?.label || props?.placeholder) && (
        <MenuItem
          value="test"
          disabled={props?.multiple}
          style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
          selected={
            !field.value ||
            (Array.isArray(field.value) && field.value?.length === 0)
          }
        >
          <Typography className="first-letter:uppercase">
            {props?.placeholder ? props?.placeholder : props?.label}
          </Typography>
        </MenuItem>
      )}

      {props?.children}
    </Field>
  );

  return (
    <FormControl variant="standard" className={"w-full"}>
      {props?.multiple ? (
        <InputLabel id={labelId}>{props?.label}</InputLabel>
      ) : null}
      {select}
    </FormControl>
  );
}
