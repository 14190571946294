import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { RootState } from "./store";
import i18n from "i18next";
import { urlHelpers } from "../_helpers/urlHelpers";
import querystring from "query-string";
import { PaginationInterface } from "./features/jobs/jobsSlice";
import { setMercure } from "./auth/authSlice";
import {Capacitor} from "@capacitor/core";

export const globalFetchBaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  paramsSerializer: (params: Record<string, any>) =>
    querystring.stringify(params, { arrayFormat: "bracket" }),
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;

    if (token && !headers.has('authorization')) {
      headers.set("authorization", `Bearer ${token}`);
    }

    if (!headers.has('accept-Language')) {
      const lang = i18n.language ?? localStorage.getItem("i18nextLng") ?? "fr";
      headers.set("accept-Language", lang);
    }

    if (!headers.has('Accept')) {
      headers.set("Accept", "application/ld+json");
    }

    return headers;
  },
});

export function providesList<
  R extends { id: string | number }[],
  T extends string
>(resultsWithIds: R | undefined, tagType: T) {
  if (typeof resultsWithIds == "undefined") {
    return [];
  }

  if (Array?.isArray(resultsWithIds)) {
    return [
      { type: tagType, id: "LIST" },
      ...resultsWithIds?.map(({ id }) => ({ type: tagType, id })),
    ];
  }

  if ("list" in resultsWithIds) {
    return [
      { type: tagType, id: "LIST" },
      ...resultsWithIds?.list?.map(({ id }) => ({ type: tagType, id })),
    ];
  }

  return [{ type: tagType, id: "LIST" }];
}

export function invalidatesList(
  tagTypes: string[],
  id: number | string | undefined
) {
  return [...tagTypes?.map((tagType) => ({ type: tagType, id }))];
}

export const apiPlatformResponseTransform = (
  response: any,
  meta: any,
  arg: any
): {
  list: [object];
  pagination: PaginationInterface | undefined;
  totalItems: number | undefined;
  mercureHubUrl: string | undefined;
} => {
  const pagination = "hydra:view" in response ? response["hydra:view"] : {};
  const first = "hydra:first" in pagination ? pagination["hydra:first"] : "";
  const last = "hydra:last" in pagination ? pagination["hydra:last"] : "";
  const next = "hydra:next" in pagination ? pagination["hydra:next"] : "";
  const current = "@id" in pagination ? pagination["@id"] : "";

  // const mercureHubUrl = getMercureHubUrl(meta?.response?.headers);
  const mercureHubUrl = "";
  // if (mercureHubUrl) {
  //   store.dispatch(
  //     setMercure({
  //       hubUrl: mercureHubUrl,
  //     })
  //   );
  // }

  return {
    list: "hydra:member" in response ? response["hydra:member"] : [],
    pagination: {
      first: urlHelpers(first),
      last: urlHelpers(last),
      next: urlHelpers(next),
      current: urlHelpers(current),
    },
    totalItems:
      "hydra:totalItems" in response ? response["hydra:totalItems"] : 0,
    mercureHubUrl: mercureHubUrl,
  };
};

export function getMercureHubUrl(headers: Headers): string | undefined {
  const mercureHubTags = headers
    ?.get("Link")
    ?.match(/<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/);
  //const {response: metaResponse} = meta;
  let mercureHubUrl = undefined;

  if (Array.isArray(mercureHubTags) && mercureHubTags?.length > 0) {
    const mercureHubTag = mercureHubTags[0];
    const urlRegex = /(https?:\/\/[^> ;]*)/;
    const mercureHubUrls = mercureHubTag.match(urlRegex);

    if (Array.isArray(mercureHubUrls) && mercureHubUrls?.length > 0) {
      mercureHubUrl = mercureHubUrls[0];
    }
  }

  return mercureHubUrl;
}
