import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TitledContainer from "../../../components/containers/TitledContainer";
import { CloseSharp, ExpandLess } from "@mui/icons-material";
import VisitorsIllustration from "../../../components/illustration/VisitorsIllustration";
import { Divider } from "@mui/joy";
import { NavLink } from "react-router-dom";
import { Button } from "@nextui-org/react";
import { AppRoutes } from "../../../utils/appRoutes";
import Visitors from "./Visitors";
import Visits from "./Visits";
import Statistics from "./Statistics";
import { Container } from "@mui/material";

export default function ProfileVisitorsAndStatistics() {
  const { t, i18n } = useTranslation();
  const [lastDays, setLastDays] = useState(90);
  const [open, setIsOpen] = useState(false);

  const days = [7, 30, 90];

  return (
    <Container maxWidth="md">
      <TitledContainer
        title={t("profile.visitors.and.statistics.label")}
        buttons={[
          <div
            key={1}
            onClick={() => setIsOpen(!open)}
            className="cursor-pointer flex flex-row justify-between items-center px-1 bg-success text-white font-xing-bold"
          >
            <p className="text-xs">{t("profile.header.premium.label")}</p>
            <div
              className={`transition-all duration-100 ${
                open ? "" : "rotate-180"
              }`}
            >
              <ExpandLess height={18} width={18} />
            </div>
          </div>,
        ]}
        className="flex flex-col justify-start items-start gap-6"
      >
        <div className="flex flex-row justify-start items-center gap-3">
          {days.map((day) => (
            <div
              onClick={() => setLastDays(day)}
              key={day}
              className={`rounded-full px-3 py-2 font-xing-bold ${
                lastDays === day ? "bg-primary text-white" : "bg-gray-300"
              } hover:text-white cursor-pointer`}
            >
              {`${day} ${t("events.days.label", { count: day })}`}
            </div>
          ))}
        </div>
        <div
          className={`transition-all duration-150 ${
            open ? "opacity-0 h-0" : ""
          } display flex max-lg:flex-col-reverse flex-row justify-between max-lg:items-end items-start rounded-xl bg-gray-100 p-3`}
        >
          <div className="p-0" style={{ padding: 0 }}>
            <div className="font-xing-bold py-3">
              Des statistiques détaillées des visites sur votre profil
            </div>
            <div className="py-3">
              En tant que membre Premium, vous pouvez découvrir ici qui a
              consulté votre profil, ainsi que la date et la durée de la visite.
              La page détaillée vous propose des informations utiles sur
              l'origine des visiteurs et vos points communs avec ces derniers.
              Ces statistiques peuvent vous aider à déterminer si votre profil
              touche les bonnes personnes.
            </div>
          </div>
          <div className="max-lg:hidden">
            <VisitorsIllustration primary="#FFB836" secondary="#FFB836" />
          </div>
          <div>
            <CloseSharp onClick={() => setIsOpen(!open)} />
          </div>
        </div>

        <Visits lastDays={lastDays} />

        <Statistics lastDays={lastDays} />

        <Divider />

        <div className="w-full flex flex-row max-lg:flex-col justify-between gap-6 items-center p-6 rounded-2xl bg-gray-100">
          <div className="flex flex-col justify-center items-start gap-6 rounded-xl bg-gray-100">
            <h1 className="text-xl font-xing-bold">
              Plus de visites de recruteurs avec ProJobs !
            </h1>
            <div className="w-full flex flex-row max-lg:flex-col justify-between items-center gap-6">
              <div className="flex flex-row justify-start items-center gap-3">
                <p className="text-3xl font-xing-bold">2,5 fois </p>
                <p>plus de visites</p>
              </div>

              <div className="flex flex-row justify-start items-center gap-3">
                <p className="text-3xl font-xing-bold">2 fois </p>
                <p>plus de messages</p>
              </div>
            </div>
          </div>
          <div className="shrink-0">
            <NavLink
              className="font-xing-bold"
              to={AppRoutes.PRIVATE_OPT_FOR_PRO_JOBS.replace(
                ":locale",
                `${i18n.language}`
              )}
            >
              <Button
                className="bg-pink-900 text-white"
                variant="flat"
                radius="full"
              >
                {t("sidebar.opt.for.pro.jobs")}
              </Button>
            </NavLink>
          </div>
        </div>

        <Visitors />
      </TitledContainer>
    </Container>
  );
}
