import React, { createRef, FC, useEffect, useRef, useState } from "react";
import Loading from "../../../Loading";
import MyProfileInformation from "./ui/myProfile/MyProfileInformation";
import MySkills from "./ui/myProfile/MySkills";
import MyHobbies from "./ui/myProfile/MyHobbies";
import MyCertificates, {
  CertificateInterface,
} from "./ui/myProfile/MyCertificates";
import { ExperienceInterface } from "./ui/myProfile/Experience";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../_redux/auth/services";
import MySummary from "./ui/myProfile/MySummary";
import { CvXSkillInterface } from "../../../_redux/features/skills/cvXSkillInterface";
import { functionTypeInterface } from "../../../_redux/features/functionType/types";
import MyProfileHeader from "./ui/myProfile/MyProfileHeader";
import MyProfileMemberSince from "./ui/myProfile/MyProfileMemberSince";
import ResumePercentage from "./ui/myProfile/ResumePercentage";
import MyProfessionalExpectations from "./ui/myProfile/MyProfessionalExpectations";
import MyExperiences from "./ui/myProfile/MyExperiences";
import MyLanguages from "./ui/myProfile/MyLanguages";
// import VisitorsAndStatistics from "./ui/myProfile/VisitorsAndStatistics";
import CvExport from "./ui/myProfile/CvExport";
import { Container } from "@mui/material";
import ScrollDownButton from "../../../components/scrollDownButton/ScrollDownButton";
import ScrollTopButton from "../../../components/scrollTopButton";
import SEO from "../../../SEO";
import { profilerTitle } from "../../../utils/seoProps";

export interface ProfileInterface {
  "@id": string;
  id: number;
  fullname: string;
  firstName: string;
  lastName: string;
  phone: string;
  address: string;
  birthday: string;
  nbVisit: number;
  nbSearch: number;
  registerDate: string;
  email: string;
  imageUrl: string | null;
  civility: string;
  mobile: string;
  fax: string;
  zipcode: number;
  birthplace: string | null;
  martialStatus: boolean;
  subAnem: boolean;
  socialNetworks: [];
  visibility: "private" | "invisible" | "public" | undefined;
}

export interface CvInterface extends Object {
  "@id": string;
  id: number;
  title: string;
  fullName: string;
  careerObjective: string;
  phone: string;
  birthday: string;
  address: string;
  experiences: ExperienceInterface[];
  formations: CertificateInterface[];
  competences: CvXSkillInterface[];
  hobby: string;
  progression: number;
  updatedDate: string;
  mobility: string;
  website: string | null;
  vehicle: string | null;
  militaryService: boolean;
  handicap: boolean;
  handicapAdaptation: boolean;
  hasCar: boolean;
  handicapAdaptationDesc: string;
  disponibility: string;
  disponibilityDuration: number;
  targetJob: string;
  function: string | functionTypeInterface | undefined;
  careerLevel: string | functionTypeInterface | undefined;
  salary: string | functionTypeInterface | undefined;
  contractTypes: string | functionTypeInterface | undefined;
  country: string | null;
  region: string | null;
  city: string | null;
  attachementPath: string | null;
}

const MyProfile = () => {
  const { data: profile, isLoading } = useGetProfileQuery();
  const { isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });
  const [isScrollDownVisible, setIsScrollDownVisible] = useState(true);
  const [isScrollTopVisible, setIsScrollTopVisible] = useState(false);

  useEffect(() => {
    // Listen to the scroll event
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsScrollDownVisible(scrollTop === 0);
    setIsScrollTopVisible(scrollTop > 0);
  };

  if (isLoading || isCvLoading) {
    return <Loading fullscreen />;
  }

  return (
    <Container maxWidth="md">
      <SEO {...profilerTitle} />
      <div className="w-full flex flex-col justify-start gap-y-9">
        <ResumePercentage />

        <MyProfileHeader />

        <MySummary />

        <MyExperiences />

        <MyCertificates />

        <MySkills />

        <MyLanguages />

        <MyHobbies />

        <MyProfileInformation />

        <MyProfessionalExpectations />

        {/* <VisitorsAndStatistics /> */}

        <MyProfileMemberSince />

        <CvExport />

        <ScrollDownButton isVisible={isScrollDownVisible} />

        <ScrollTopButton isVisible={isScrollTopVisible} />
      </div>
    </Container>
  );
};

export default MyProfile;
