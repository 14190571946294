import React from "react";
import { SvgIllustrationInterface } from "./AttentesProIllustration";
export default function VisitesIllustration({
  primary,
  secondary,
}: SvgIllustrationInterface) {
  return (
    <svg
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="92px"
      height="92px"
      data-xds="IllustrationPremiumSpotGroup"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.713 27.804l.048-4.933-6.102-.06s-1.884-21.51 8.685-21.407c11.192.11 10.742 11.028 10.728 12.437l-.137 14.092M45.75 49.372v-4.628h-5.726s-1.964-20.163 7.953-20.163c10.5 0 10.178 10.247 10.178 11.57v13.221"
        fill="#FFFFFE"
      ></path>
      <path
        d="M40.951 5.443c3.021 5.633 5.304 15.324 1.91 17.817-.371.273-.86.515-1.426.719l-.04 3.958-1-.01.038-3.646c-2.952.74-7.058.58-8.261-1.181l.155-.528.158-.526c.928-3.053 1.938-5.84 1.938-5.84l1.115.04a1.991 1.991 0 10.145-3.98l-1.74-.064s-7.544-2.29-7.07-9.303c-1.179.798-2.13 2.064-2.872 3.74 1.402.126 2.31.965 2.319 2.61.007 1.294-.358 2.452-1.028 3.699l-.196.353-.381.652a.706.706 0 00.265.974l.09.042 1.435.552-.359.933-1.436-.552a1.706 1.706 0 01-.925-2.33l.089-.162.353-.604.184-.332c.598-1.112.915-2.118.909-3.22-.007-1.147-.55-1.597-1.591-1.63l-.123-.001c-.411 1.134-.743 2.421-1 3.85l-.12.712c-.46 2.932-.57 6.034-.463 8.88l.04.877.022.373 5.912.058.014-.073h2.207l-2.003 1.295-.041 4.213-1-.01.042-4.433-6.055-.058-.06-.727-.046-.76c-.156-3.095-.068-6.535.444-9.79l.123-.734c.23-1.283.522-2.466.878-3.539l-.112.026-.143.036-.238.068-.163.054-.347-.938.206-.069.282-.08a7.109 7.109 0 01.914-.183c.99-2.447 2.38-4.198 4.224-5.09C28.388.185 37.563-.874 40.951 5.442zm-12.074 11.56l.931.365c-.326.83-.87 1.407-1.563 1.75a3.548 3.548 0 01-1.817.359l-.093-.009.125-.992.037.003c.384.027.86-.036 1.303-.256.422-.21.76-.541.994-1.03l.083-.19zm-.777-6.527a.5.5 0 01.488.415l.007.09-.007.705a.5.5 0 01-.992.08l-.008-.09.008-.705a.5.5 0 01.504-.495zm-4.363-.043a.5.5 0 01.487.415l.008.09-.007.705a.5.5 0 01-.993.08l-.007-.09.007-.705a.5.5 0 01.505-.495zm-8.665 1.794l3.867 1.61-.385.923-3.867-1.61.385-.923zm3.607 3.639v1H14.49v-1h4.19z"
        fill="#1D2124"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.123 56.202v-4.907h6.072s2.083-21.383-8.433-21.383C9.627 29.913 9.968 40.78 9.968 42.182v14.02"
        fill="#FFFFFE"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.432 27.301c-1.829-.548-3.998-.922-6.553-.988-6.8-.18-11.345 1.474-14.297 3.32-.152.094 2.941.434 5.078 3.676 0 0 1.674 5.082 1.822 5.111 2.207.432 4.687.691 7.397.627a26.18 26.18 0 003.809-.369c.134-.023.398-.077.398-.077l.378-5.106s2.096-6.156 1.968-6.194z"
        fill={primary}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.938 24.081c5.124 0 8.243 2.325 9.73 6.174.43 1.114.698 2.295.837 3.512.078.686.107 1.243.112 1.866v13.74h-1l.002-5.528c-.604-.006-5.64-.207-5.775-5.267.201-.014 1.057 0 1.057 0a1.888 1.888 0 000-3.774h-1.65s-3.054-1.54-1.39-5.87c-1.873.31-4.663.577-6.364-1.726-.529.637-.987 1.413-1.38 2.316 1.307.12 2.16.91 2.184 2.454.02 1.225-.316 2.323-.94 3.508l-.179.328-.346.604a.631.631 0 00.24.867l.086.04 1.351.505-.35.937-1.35-.505a1.631 1.631 0 01-.846-2.34l.342-.595.167-.307c.553-1.05.842-1.994.825-3.027-.016-1.055-.511-1.46-1.467-1.486h-.101c-.43 1.227-.76 2.642-.995 4.229-.434 2.925-.493 6.04-.331 8.877l.026.427.014.204h5.764v.064h1.828l-1.828 1.206v3.858h-1v-4.128h-5.678l-.07-.75-.028-.387-.027-.438c-.165-2.898-.105-6.077.341-9.08l.112-.7c.2-1.17.455-2.25.768-3.234l-.04.01-.136.036-.226.066-.156.054-.355-.935.089-.032.236-.076.305-.083c.223-.056.448-.101.67-.134 1.373-3.48 3.618-5.48 6.922-5.48zm-1.226 15.136l.935.357c-.304.796-.821 1.35-1.483 1.681a3.36 3.36 0 01-1.709.348l-.078-.007.112-.993.028.002c.35.022.79-.038 1.2-.244a1.97 1.97 0 00.92-.965l.075-.179zm-.76-6.135a.5.5 0 01.493.41l.008.09v.661a.5.5 0 01-.992.09l-.008-.09v-.66a.5.5 0 01.5-.5zm-4.093 0a.5.5 0 01.492.41l.008.09v.661a.5.5 0 01-.992.09l-.008-.09v-.66a.5.5 0 01.5-.5z"
        fill="#1D2124"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.72 55.352s-3.396-7.18-15.494-7.499c-12.732-.335-17.563 5.756-18.82 7.5 0 0 7.343 5.502 18.82 5.235 9.127-.212 15.494-5.236 15.494-5.236z"
        fill={primary}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.22 21.629a5.896 5.896 0 015.48 8.078c.922-.195 1.93-.295 3.024-.295 3.493 0 5.867 2.12 7.319 5.81.24.033.482.082.724.142l.322.089.25.08.093.033-.355.935-.167-.057a6.546 6.546 0 00-.387-.11l-.092-.022c.404 1.26.718 2.672.947 4.218.473 3.182.536 6.552.362 9.624l-.044.675-.042.514-.044.452h-6.026v4.407h-1v-4.055l-2.047-1.352h8.159l.032-.465.013-.233c.171-3.01.11-6.316-.352-9.42-.251-1.693-.603-3.203-1.063-4.51a1.4 1.4 0 00-.13 0c-1.03.026-1.568.468-1.586 1.604-.017 1.1.29 2.106.878 3.224l.178.327.362.632c.499.87.14 1.965-.749 2.383l-.13.055-1.433.536-.35-.937 1.432-.536a.699.699 0 00.362-1.004l-.368-.643-.189-.348c-.659-1.252-1.012-2.41-.993-3.704.025-1.636.934-2.463 2.33-2.577-.5-1.162-1.104-2.127-1.815-2.879-.46 3.636-4.91 7.79-8.704 8.29l-.209.002H14.88l-.086-.001h-.122a2.002 2.002 0 000 4.002s.264-.004.54-.005h.342c.104 0 .19.002.24.005-.118 4.387-3.708 5.33-5.364 5.531v6.078h-1v-8.786a58.096 58.096 0 010-4.027v-1.35c-.004-.836.02-1.511.12-2.383.087-.758.22-1.502.408-2.227l.004-.015c.246-1.66.864-2.978 1.687-4.022a5.896 5.896 0 01.572-11.763zm9.775 23.814c.228.598.595.991 1.07 1.23.378.19.778.264 1.121.266l.197-.007.114.994-.082.007a3.533 3.533 0 01-1.798-.366 3.126 3.126 0 01-1.464-1.545l-.092-.222.934-.357zm.834-6.485a.5.5 0 01.492.41l.008.09v.7a.5.5 0 01-.992.09l-.008-.09v-.7a.5.5 0 01.5-.5zm4.341 0a.5.5 0 01.492.41l.008.09v.7a.5.5 0 01-.992.09l-.008-.09v-.7a.5.5 0 01.5-.5z"
        fill="#1D2124"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.28 62.392s3.294-6.969 15.035-7.278c12.354-.325 17.042 5.587 18.263 7.278 0 0-7.127 5.34-18.263 5.08C6.458 67.266.28 62.392.28 62.392z"
        fill={primary}
      ></path>
    </svg>
  );
}
