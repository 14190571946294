import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import {useTranslation} from "react-i18next";
import MenuNavLink from "../MenuNavLink";
import MultiDirectionIcon from "../../../../../components/multiDirectionsIcons/ArrowMultiDirectionIcon";
import CoordinatesModal from "../../../../../components/modals/settings/CoordinatesModal";

export default function Confidentiality() {

    const {t} = useTranslation()
    const navigate = useNavigate()
    const [openContactDetails, setOpenContactDetails] = useState(false);
    const handleOpenContactDetails = () => setOpenContactDetails(true);
    // const handleOpenActivities = () => setOpenActivities(true);
    const handleCloseContactDetails = () => setOpenContactDetails(false);

    const confidentiality = [
        {
            title: t("settings.confidentiality.contact.details.label"),
            subtitle: undefined,
            icon: undefined,
            onClick: handleOpenContactDetails
        },
        // {
        //     title: t("settings.confidentiality.activities.label"),
        //     subtitle: undefined,
        //     icon: undefined,
        //     onClick: handleOpenActivities
        // }
    ]

    return (
        <div className="w-full flex flex-col justify-start gap-6">
            <MultiDirectionIcon
                rtlIcon={
                    <ArrowRightIcon
                        onClick={() => { navigate(-1) }}
                        height={32}
                        width={32}
                        className="cursor-pointer"
                    />
                }
                ltrIcon={
                    <ArrowLeftIcon
                        onClick={() => { navigate(-1) }}
                        height={32}
                        width={32}
                        className="cursor-pointer"
                    />
                }
            />
            <div className="w-full grid grid-cols-3 max-md:grid-cols-1 justify-between gap-12 bg-white max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full">
                {
                    confidentiality?.map((item: any, index: number) => {
                        return ( <MenuNavLink key={index} item={item} /> )
                    })
                }
            </div>
            <CoordinatesModal onClose={handleCloseContactDetails} isOpen={openContactDetails} />
            {/*<ActivitiesModal onClose={handleCloseActivities} isOpen={openActivities} />*/}
        </div>
    )

}