import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

export default function BackButton({ path }: { path?: string }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handelClick = () => (path ? navigate(path) : navigate(-1));

  return (
    <div
      onClick={handelClick}
      className="font-xing-bold flex flex-row justify-center items-center gap-1 cursor-pointer"
    >
      <ArrowRightAltIcon
        className={`${i18n.dir() === "ltr" ? "scale-[-1]" : ""}`}
      />
      <p>{t("back.label")}</p>
    </div>
  );
}
