import React from "react";
import {useGetMyCvQuery, useGetProfileQuery} from "../../_redux/auth/services";
import ProfileAvatar from "./ProfileAvatar";
import {Image} from "@nextui-org/react";

export default function AnimatedProfileAvatar() {

    const {data: profile, isLoading: isProfileLoading} = useGetProfileQuery()
    const {data: cv, isLoading: isCvLoading} = useGetMyCvQuery(profile?.id, {skip: !profile})

    if (isCvLoading || isProfileLoading) {
        return <Image
            src="images/default-user.png"
            alt="default-user"
            height={150}
            width={150}
            className="overflow-hidden z-[-1]"
        />
    }

    const progression = 3000 - ((cv?.progression || 0) * 682 / 100)

    return (
        <div className={"flex justify-center items-center"}>
            <div className="relative z-1 min-w-[194px] min-h-[194px]">
                  <ProfileAvatar
                      strokeDashoffset={progression}
                  />
            </div>
        </div>
    )
}