import React, { useState } from "react";
import { Button } from "@nextui-org/react";
import { boolean, number, object, string } from "yup";
import { Formik } from "formik";
import Loading from "../../../Loading";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
  useUpdateCvMutation,
} from "../../../_redux/auth/services";
import ProfileCvTitleEditForm from "../../_form/Profile/ProfileCvTitleEditForm";
import { careerLevelInterface } from "../../../_redux/features/careerLevel/types";
import { AVAILABILITY_WITH_NOTICE } from "../../_form/_field/Specified/SelectAvailability";
import { Container } from "@mui/material";
import { Modal, ModalClose, ModalDialog, ModalOverflow } from "@mui/joy";
import { Edit } from "@mui/icons-material";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

export const cvTitleSchema = () =>
  object().shape({
    // title: string().required(i18n.t("validation.field.required.unspecific")),
    // mobility: string().required().oneOf(["1", "2", "3"]),
    website: string(),
    // targetJob: string(),
    militaryService: boolean(),
    handicap: boolean(),
    handicapAdaptation: boolean().when("handicap", {
      is: (handicap: boolean) => !handicap,
      then: () => boolean().isFalse(),
    }),
    handicapAdaptationDesc: string().when("handicapAdaptation", {
      is: (handicapAdaptation: boolean) => handicapAdaptation,
      then: () =>
        string().required(i18n.t("validation.field.required.unspecific")),
    }),
    disponibility: string().nullable(),
    disponibilityDuration: number()
      .nullable()
      .when("disponibility", {
        is: (availability: string) => availability === AVAILABILITY_WITH_NOTICE,
        then: () =>
          number()
            .positive()
            .required(i18n.t("validation.field.required.unspecific")),
      }),
    hasCar: boolean(),
    // careerLevel: string().nullable(),
    // salary: string().nullable(),
    // country: string().nullable(),
    // region: string().nullable(),
    // city: string().nullable(),
  });

interface Values {
  // title: string;
  // function: string;
  // mobility: string;
  website: string;
  militaryService: boolean;
  handicap: boolean;
  handicapAdaptation: boolean;
  handicapAdaptationDesc: string;
  hasCar: boolean;
  disponibility: boolean;
  disponibilityDuration: number;
  // targetJob: string;
  // country: string | null;
  // region: string | null;
  // city: string | null;
  // careerLevel: string | careerLevelInterface;
}

export default function ProfileCvTitleEditModal() {
  const { t } = useTranslation();
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const { data: cv, isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });
  const [updateCvAction, trigger] = useUpdateCvMutation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let initialValues = {
    // title: cv?.title || "",
    // function: cv?.function?.["@id"] || null,
    // mobility: cv?.mobility || "",
    website: cv?.website || "",
    // targetJob: cv?.targetJob || "",
    militaryService: cv?.militaryService || false,
    handicap: cv?.handicap || false,
    handicapAdaptation: cv?.handicapAdaptation || false,
    handicapAdaptationDesc: cv?.handicapAdaptationDesc || "",
    disponibility: cv?.disponibility,
    disponibilityDuration: cv?.disponibilityDuration || 0,
    hasCar: cv?.hasCar,
    // careerLevel: cv?.careerLevel?.["@id"] || null,
    // salary: cv?.salary?.["@id"] || null,
    // country: cv?.country?.["@id"] || null,
    // region: cv?.region?.["@id"] || null,
    // city: cv?.city?.["@id"] || null,
    // contractTypes: cv?.contractTypes || [],
  };

  const handelSubmit = async (values: Values) => {
    updateCvAction({ id: profile?.id, ...values })
      .unwrap()
      .then((response) => {
        toast.success(t("update.message.specific", { value: "cv" }), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  if (isCvLoading || isProfileLoading) {
    return <Loading />;
  }

  const shema = cvTitleSchema();

  return (
    <>
      <Button
        aria-label={t("update.label")}
        title={t("update.label")}
        onClick={handleOpen}
        isIconOnly={true}
        color="default"
        variant="flat"
        radius="full"
        size="sm"
        className="p-0"
      >
        <Edit height={18} width={18} />
      </Button>

      <Modal
        open={open}
        className="min-h-full"
        onClose={handleClose}
        title={t("add.specific", { value: t("professionalExperience.label") })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <div className="flex flex-col justify-between items-center gap-6 scroll-smooth">
                <h1 className="text-4xl font-xing-bold">
                  {t("personalInformation.suplementairs")}
                </h1>
                <Formik
                  initialValues={initialValues as never}
                  onSubmit={handelSubmit}
                  validationSchema={shema}
                >
                  <ProfileCvTitleEditForm
                    onCancel={handleClose}
                    trigger={trigger}
                  />
                </Formik>
              </div>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
