import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import React, {ChangeEvent} from "react";

export interface PaginatorInterface {
    count: number,
    onChange: (e: ChangeEvent<unknown>, page: number) => void,
    disable?: boolean,
    page: number | undefined,
    disabled?: boolean|undefined
}

export const Paginator = ({count, onChange = () => {}, disabled = false, page}: PaginatorInterface) => {

    if (count < 2) {
        return <></>;
    }

    return (
        <Stack spacing={2} marginY={5}>
            <Pagination
                className={"justify-center flex"}
                count={count ?? 1}
                disabled={disabled}
                onChange={onChange}
                page={page ?? 1}
                defaultPage={page ?? 1}
                defaultValue={page ?? 1}
            />
        </Stack>
    )
}