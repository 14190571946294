import {Button} from "@nextui-org/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useRefuseInvitationMutation} from "../../../_redux/features/network/services";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import {CandidateInterface} from "../../../_redux/features/events/interfaces";

export default function RefuseInvitationButton({user}: {user: CandidateInterface}) {

    const {t} = useTranslation()
    const [refuseAction, refuseTriggers] = useRefuseInvitationMutation()
    const {isLoading: isProfileLoading} = useGetProfileQuery()

    return (
        <Button
            radius="full"
            variant="bordered"
            color="danger"
            disabled={isProfileLoading || refuseTriggers?.isLoading || refuseTriggers?.isFetching}
            isLoading={refuseTriggers?.isLoading || refuseTriggers?.isFetching}
            className="font-xing-bold text-sm"
            onClick={() => refuseAction(user?.friendShip?.id)}
        >
            {t("refuse.label")}
        </Button>
    )
}