import React from "react";
import { Button } from "@nextui-org/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { IconButtonProps, styled } from "@mui/material";
import IconButton from "@mui/material/IconButton";

interface SeeMoreInterface {
  handler: any;
  expanded: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export default function SeeMore({ handler, expanded }: SeeMoreInterface) {
  const { t } = useTranslation();

  const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  return (
    <div className="w-full flex flex-row justify-center items-center">
      <div
        className="rounded-full bg-transparent flex flex-row justify-center items-center cursor-pointer"
        onClick={handler}
        aria-expanded={expanded}
        role="button"
        aria-controls="see-more-content"
      >
        <p className="normal-case font-xing-bold">
          {" "}
          {expanded
            ? t("find.jobs.see.less.label")
            : t("find.jobs.see.more.label")}
        </p>
        <ExpandMore
          expand={expanded}
          aria-label={
            expanded
              ? t("find.jobs.see.less.label")
              : t("find.jobs.see.more.label")
          }
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </div>
    </div>
  );
}
