import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppRoutes } from "./appRoutes";
import { selectToken } from "../_redux/auth/authSlice";
import { useTranslation } from "react-i18next";

interface PrivateRoutesProps {
  component: any;
}

export default function PrivateRoutes({ component }: PrivateRoutesProps) {
  const isAuth = useSelector(selectToken);
  const { i18n } = useTranslation();
  return isAuth
    ? component
    : typeof window !== "undefined" && (
        <Navigate to={AppRoutes.HOME.replace(":locale", `${i18n.language}`)} />
      );
}
