import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { companyTypeInterface } from "../../../../_redux/features/company/companyType/types";
import { MenuItem } from "@mui/material";
import { useGetJobAlertFrequenciesQuery } from "../../../../_redux/features/jobAlertFrequency/services";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  multiple?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectJobAlertFrequency(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const { data: alertsData, isLoading } = useGetJobAlertFrequenciesQuery({
    pagination: false,
  });
  const { list: alerts } = alertsData || {};

  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("frequency.label")}
      placeholder={t("frequency.label")}
      {...props}
    >
      {alerts
        ?.filter((alert: companyTypeInterface) =>
          alert?.name?.startsWith("paramsfrequencyalert.")
        )
        ?.map((alert: companyTypeInterface) => (
          <MenuItem key={alert["@id"]} value={alert["@id"]}>
            {t(`frequency.choices.${alert?.name}`)}
          </MenuItem>
        ))}
    </Select>
  );
}
