import axios from "axios";

export const LOGIN_URL = "/api/auth";
export const REGISTER_URL = "/api/candidates";

export async function login(email: string, password: string) {
    return axios.post(LOGIN_URL, {email, password}, {headers: {"Authorization": ""}});
}

interface Candidate {
    "civility": string;
    email: string;
    password: string;
    "firstName": string;
    "lastName": string;
    "birthday": string;
}

export async function register(candidate: Candidate) {
    return axios.post(REGISTER_URL, candidate, {headers: {"Authorization": ""}});
}

export async function googleAuth(token: string) {
    return axios.post("/api/auth/google",
        {token},
        {headers: {"Authorization": ""}},
    )
}

export async function facebookAuth(token: string) {
    return axios.post("/api/auth/facebook",
        {token},
        {headers: {"Authorization": ""}},
    )
}

export async function linkedinAuth(token: string) {
    return axios.post("/api/auth/linkedin",
        {token},
        {headers: {"Authorization": ""}},
    )
}

export async function getProfile() {
    return axios.get("/api/profile")
}

export async function getCv(id: number) {

    return axios.get(`/api/candidates/${id}/cv`)
}


export async function updateCv(id: number|undefined, values: object) {
    let url = `/api/candidates/${id}/cv`

    if (!id) {
        return Promise.resolve() ;
    }

    return axios.patch(url, values)
}

export async function updateProfile(id: number|undefined, values: object) {

    if (!id) {
        return Promise.resolve() ;
    }

    let url = `/api/candidates/${id}`

    return axios.patch(url, values)
}


export async function putExperience(uri: string , values: object) {

    return axios.patch(uri, values)
}

export async function update(uri: string , values: object) {

    return axios.patch(uri, values)
}

export async function addExperience(values: object) {
    let url = `/api/experiences`

    return axios.post(url, values)
}

export async function uploadCv(file: File, cvId?: number) {
    let url = `/api/cvs/${cvId}/attachment`

    let formData = new FormData();
    formData.append('attachment', file);

    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}
