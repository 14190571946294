import React from "react";
import RecentOffers from "./ui/RecentOffers";
import SmartOffers from "./ui/SmartOffers";
import FoundByRecruiters from "./ui/FoundByRecruiters";
import SuggestionOffers from "./ui/SuggestionOffers";
import SEO from "../../../SEO";
import { publicHome } from "../../../utils/seoProps";

export default function Jobs() {
  return (
    <div className="h-full w-full flex flex-col justify-start gap-4">
      <SEO {...publicHome} />

      <SmartOffers />

      {/*<TopRecruitersOffers />*/}

      <FoundByRecruiters />

      <RecentOffers />

      <SuggestionOffers />
    </div>
  );
}
