import React from "react";
import MyLanguageItem from "./MyLanguageItem";
import NoLanguagesFound from "../../../../../components/noResultFound/NoLanguagesFound";
import LanguageEditModal from "../../../../../components/modals/Profile/Langue/LanguageEditModal";
import {CvXLanguageInterface} from "../../../../../_redux/features/languages/cvXLanguageInterface";

export function MyLanguagesList({languages}: {languages: CvXLanguageInterface[]}) {

    return (
        <div className="flex flex-col justify-between gap-3">
            {
                languages?.length > 0
                    ?
                    languages.map((language : any) => <MyLanguageItem language={language} key={language["@id"]}/>).sort((a, b) => a["languageName"] - b["languageName"])
                    :
                    <NoLanguagesFound />
            }
            <LanguageEditModal />
        </div>
    )
}