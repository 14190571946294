import React from "react";
import AnimatedProfileAvatar from "../../../../components/animatedProfileAvatar/AnimatedProfileAvatar";
import ProfileCvTitleEditModal from "../../../../components/modals/Profile/ProfileCvTitleEditModal";
import {useGetMyCvQuery, useGetProfileQuery} from "../../../../_redux/auth/services";
import {useTranslation} from "react-i18next";
import moment from "moment";
import CustomAvatar from "../../../../components/customAvatar/CustomAvatar";

export default function SettingsHeader () {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const {data: cv, isLoading: isCvLoading} = useGetMyCvQuery(profile?.id, {skip: !profile})
    const currentExperience = cv?.experiences?.find((exp: any) => exp?.current)

    return (
        <>
            <div
                className="flex lg:justify-start md:justify-start max-lg:justify-center text-3xl font-semibold font-xing-bold text-gray-800">
                {t("greeting", {name: profile?.firstName})}
            </div>
            <div className="flex flex-row justify-start items-center">
                <CustomAvatar
                    className="h-[194px] w-[194px] max-lg:h-[100px] max-lg:w-[100px]"
                    src={profile?.imageUrl}
                    // stroke="#00d8a2"
                    strokeWidth={6}
                    strokeDashoffset={1760}
                    strokeDasharray={1000}
                />
            </div>


            <div className="flex flex-col md:flex-row justify-start gap-1">

                { isCvLoading ?
                    <div className="relative w-[368px] flex flex-col justify-between items-start gap-6 animate-pulse ">
                        <div className="w-2/3 flex flex-col justify-between items-start gap-3">
                            <div className="bg-gray-200 min-h-[15px] w-full" />
                            <div className="bg-gray-200 min-h-[15px] w-2/3" />
                        </div>
                        <div className="w-full flex flex-col justify-between items-start gap-3">
                            <div className="bg-gray-200 min-h-[15px] w-full" />
                            <div className="bg-gray-200 min-h-[15px] w-full" />
                        </div>
                    </div>
                    :
                    <div className="flex flex-col justify-between items-start">
                        <div className="md:text-xl text-black font-xing-bold">
                            {cv?.fullName}
                        </div>
                        <div
                            className="group md:text-lg flex justify-between content-center w-full font-medium text-gray-800">
                            <div>{cv?.title}</div>
                        </div>

                        { currentExperience &&
                            <div className="font-xing text-gray-400">
                                {t("profile.work.with.from", {
                                    companyName: currentExperience?.companyName,
                                    date: moment(currentExperience?.startDate).format("MMMM YYYY")
                                })}
                            </div>
                        }

                        <div className=" font-normal text-gray-400">
                            {t("last.update", {
                                date: cv?.updatedDate
                            })}
                        </div>
                    </div>
                }

            </div>
        </>
    )
}