import {EventListApiRequest} from "./api";
import {EventsState} from "./eventsSlice";
import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {EventSearchInterface} from "./interfaces";

export const fetchEvents = createAsyncThunk<
    EventsState,
    EventSearchInterface
>("events/fetchEvents", async (filters: EventSearchInterface = {_page: 1}) => await EventListApiRequest(filters))


export async function getEventItem(id: number) {
    return axios.get(`/api/events/${id}`)
}
export function getParamsFromUrl(uri: string) {
    const url = new URL(process.env.REACT_APP_API_BASE_URL + uri);
    const params = url.searchParams;
    const paramsObject = {};

    for (const [key, value] of params.entries()) {
        paramsObject[key] = value;
    }

    return paramsObject
}