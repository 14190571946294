import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../../../_redux/store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  selectJobsSearch,
  setSearch,
} from "../../../../_redux/features/jobs/jobsSlice";
import {
  useAddJobAlertMutation,
  useUpdateJobAlertMutation,
} from "../../../../_redux/features/jobs/services";
import { Button } from "@nextui-org/react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import EditNotificationsIcon from "@mui/icons-material/EditNotificationsOutlined";
import useCurrentDimension, {
  lg,
} from "../../../../_hooks/useCurrentDimension";
import { useGetProfileQuery } from "../../../../_redux/auth/services";
import { toast } from "react-toastify";
import { JobAlertInterface } from "../../applications/searchAlert/ui/JobAlertItem";
import { useGetJobAlertFrequenciesQuery } from "../../../../_redux/features/jobAlertFrequency/services";
import { JobAlertFrequencyInterface } from "../../../../_redux/features/jobAlertFrequency/types";
import CustomSpinner from "../../../../components/spinner/CustomSpinner";
import AddJobAlertButton from "../../applications/searchAlert/ui/AddAlertDialog";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

export default function AlertHandlerButton() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const search = useSelector(selectJobsSearch);
  const [screenSize] = useCurrentDimension();
  const [updateJobAlertAction, updateJobAlertTriggers] =
    useUpdateJobAlertMutation();
  const [addJobAlertAction, addJobAlertTriggers] = useAddJobAlertMutation();
  const { data: user } = useGetProfileQuery();
  const {
    data: jobAlertFrequenciesData,
    isLoading: jobAlertFrequenciesLoading,
  } = useGetJobAlertFrequenciesQuery({ pagination: false });
  const { list: jobAlertFrequencies } = jobAlertFrequenciesData || {};
  const removePageFilter = (obj: object) =>
    Object.keys(obj)?.filter((key) => key !== "_page");
  const [keys, setKeys] = useState(removePageFilter(search));
  const [attr, setAttr] = useState({
    label: "",
    onClick: () => {},
    isLoading: false,
    icon: <></>,
  });

  useEffect(() => {
    setKeys(removePageFilter(search));
  }, [search]);

  useEffect(() => {
    console.log(keys);
    if (keys?.length === 0) {
      return;
    }

    if (keys?.includes("alertId")) {
      setAttr({
        label: t("search.alert.update.label"),
        onClick: updateSearchAlert,
        icon: <EditNotificationsIcon />,
        isLoading:
          updateJobAlertTriggers?.isLoading ||
          updateJobAlertTriggers?.isFetching,
      });
    } else {
      setAttr({
        label: t("search.alert.create.label"),
        isLoading:
          addJobAlertTriggers?.isLoading || addJobAlertTriggers?.isFetching,
        onClick: addSearchAlert,
        icon: <NotificationsNoneOutlinedIcon />,
      });
    }
  }, [keys, jobAlertFrequencies, addJobAlertTriggers, updateJobAlertTriggers]);

  const updateSearchAlert = () => {
    console.log(search);
    updateJobAlertAction({ ...search, id: search?.alertId })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", { value: t("search.alert.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  const addSearchAlert = () => {
    const weeklyFrequency = jobAlertFrequencies?.find(
      (freq: JobAlertFrequencyInterface) =>
        freq?.name === "paramsfrequencyalert.label.weekly"
    )?.["@id"];

    addJobAlertAction({
      ...search,
      frequency: weeklyFrequency,
      candidate: `/api/candidates/${user?.id}`,
    })
      .unwrap()
      .then((response: { data: JobAlertInterface }) => {
        dispatch(
          setSearch({
            search: {
              ...search,
              alertId: response?.data?.id,
            },
          })
        );
        toast.success(
          t("add.message.specific", { value: t("search.alert.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  if (keys?.length === 0) {
    return <></>;
  }

  return keys?.includes("alertId") ? (
    <div className="w-full flex flex-row justify-between items-center">
      <Button
        aria-label={attr?.label}
        title={attr?.label}
        isIconOnly={screenSize.width < lg}
        radius="full"
        fullWidth={true}
        color="primary"
        isLoading={attr?.isLoading || jobAlertFrequenciesLoading}
        disabled={attr?.isLoading}
        onClick={attr?.onClick}
        variant="bordered"
        className="text-md text-black first-letter:capitalize font-xing-bold"
        startContent={
          attr?.isLoading || jobAlertFrequenciesLoading ? null : attr?.icon
        }
        spinner={<CustomSpinner />}
      >
        <p className="max-lg:hidden">{attr?.label}</p>
      </Button>
    </div>
  ) : (
    <AddJobAlertButton alert={alert} isIconOnly />
  );
}
