import React from "react";
import {useTranslation} from "react-i18next";
import TitledContainer from "../../../components/containers/TitledContainer";
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import NotificationItem from "./NotificationItem";
import {Container} from "@mui/material";

export default function PrivateNotifications() {

    const {t} = useTranslation()

    const notifications = [
        {
            id: 1,
            createdAt: new Date("Fri Mar 1 2024 10:28:49 GMT+0100 (heure normale d’Europe centrale)"),
            description: "Il y a 2 nouvelles offres d'emploi correspondant à votre profil et à vos attentes professionnelles.",
            type: "offer",
            label: "Voir les offres",
            icon: <WorkOutlineOutlinedIcon fontSize="small"/>,
            thumbnail: "images/prototype-image-dentia.png",
            color: "warning",
            redirect: "/jobs",
            viewed: false
        },
        {
            id: 2,
            createdAt: new Date("Fri Mar 8 2024 10:28:49 GMT+0100 (heure normale d’Europe centrale)"),
            description: "Il y a 3 nouvelles offres d'emploi correspondant à votre profil et à vos attentes professionnelles.",
            type: "offer",
            label: "Voir les offres",
            icon: <WorkOutlineOutlinedIcon fontSize="small"/>,
            thumbnail: "images/prototype-image-dentia.png",
            color: "secondary",
            redirect: "/jobs",
            viewed: true
        },
        {
            id: 3,
            createdAt: new Date(),
            description: "Katja Uhl de Lidl Vertriebs GmbH und Co. KG a accepté votre demande de contact. Envoyez-lui un message",
            type: "added",
            label: "Dire bonjour",
            icon: <ChatOutlinedIcon fontSize="small"/>,
            thumbnail: "images/user-1.jpg",
            color: "secondary",
            redirect: "/chat",
            viewed: false
        },
        {
            id: 4,
            createdAt: new Date("Fri Mar 1 2024 10:28:49 GMT+0100 (heure normale d’Europe centrale)"),
            description: "Il y a 2 nouvelles offres d'emploi correspondant à votre profil et à vos attentes professionnelles.",
            type: "offer",
            label: "Voir les offres",
            icon: <WorkOutlineOutlinedIcon fontSize="small"/>,
            thumbnail: "images/prototype-image-dentia.png",
            color: "warning",
            redirect: "/jobs",
            viewed: false
        },
        {
            id: 5,
            createdAt: new Date("Fri Mar 8 2024 10:28:49 GMT+0100 (heure normale d’Europe centrale)"),
            description: "Il y a 3 nouvelles offres d'emploi correspondant à votre profil et à vos attentes professionnelles.",
            type: "offer",
            label: "Voir les offres",
            icon: <WorkOutlineOutlinedIcon fontSize="small"/>,
            thumbnail: "images/prototype-image-dentia.png",
            color: "secondary",
            redirect: "/jobs",
            viewed: true
        },
        {
            id: 6,
            createdAt: new Date(),
            description: "Katja Uhl de Lidl Vertriebs GmbH und Co. KG a accepté votre demande de contact. Envoyez-lui un message",
            type: "added",
            label: "Dire bonjour",
            icon: <ChatOutlinedIcon fontSize="small"/>,
            thumbnail: "images/user-1.jpg",
            color: "secondary",
            redirect: "/chat",
            viewed: true
        }
    ]

    return (
        <Container maxWidth="md">
            <div className="w-full flex flex-col justify-start items-center gap-6">
                <TitledContainer
                    title={t("notifications.label")}
                    className="relative w-full flex flex-col justify-between gap-6"
                >
                    {
                        notifications?.length === 0 ?
                            <p>no notification</p>
                            :
                            notifications?.map((notification) => <NotificationItem key={notification.id}
                                                                                   notification={notification}/>)
                    }
                </TitledContainer>
            </div>
        </Container>
    )
}