import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import CustomCheckbox from "../../../../components/_form/_field/Checkbox";
import { useTranslation } from "react-i18next";
import DropDownFilter from "./DropDownFilter";
import { useGetWorkplacesQuery } from "../../../../_redux/features/workplace/services";

export default function WorkplaceFilter() {
  const { t } = useTranslation();
  const { values, handleChange } = useFormikContext();

  const { data, isLoading } = useGetWorkplacesQuery({ pagination: false });
  const { list: workplaces } = data || {};

  return (
    <DropDownFilter
      hasBadge={values?.workplace?.length}
      isLoading={isLoading}
      label={t("workplace.label")}
    >
      {workplaces && (
        <div className="flex flex-col justify-between px-3">
          {workplaces?.map((workplace: any, index: number) => (
            <CustomCheckbox
              key={index}
              disabled={isLoading}
              defaultChecked={values?.workplace?.find(
                (f: any) => f == workplace?.id
              )}
              value={workplace?.id}
              onChange={handleChange}
              label={workplace?.name}
              name="workplace"
            />
          ))}
        </div>
      )}
    </DropDownFilter>
  );
}
