import {Container} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {useGetMyCvQuery, useGetProfileQuery} from "../../../../_redux/auth/services";

export default function Performances() {

    const {t} = useTranslation()
    const {data: profile} = useGetProfileQuery()
    const {data: cv} = useGetMyCvQuery(profile?.id, {skip: !profile})

    return (
        <>
            <div className="text-xl first-letter:capitalize font-xing-bold">
                {t("profile.performance.performances.label")}
            </div>
            <Container maxWidth="xs" className="p-0" style={{padding: 0}}>
                <div className="text-md text-justify">
                    {t("profile.performance.discover.frequency.of.searches.label")}
                </div>
            </Container>
            <Container maxWidth="xs" className="p-0" style={{padding: 0}}>
                <div className="w-full flex flex-col gap-3">
                    {
                        cv?.competences?.length > 0 && cv?.competences.map(skill =>
                            <div key={skill["@id"]} className="flex flex-row justify-between items-center">
                                <div>
                                    <p className="first-letter:capitalize font-xing-bold rounded-full border-1 border-solid border-gray-300 px-3 py-1">
                                        {skill?.competence?.name}
                                    </p>
                                </div>
                                <div className="shrink-0">
                                    <p className="first-letter:capitalize font-xing-bold">
                                        47680
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </div>
            </Container>
        </>
    )
}