import React from "react";
import HobbiesEditModal from "../../../../../components/modals/Profile/HobbiesEditModal";
import {useTranslation} from "react-i18next";
import {useGetMyCvQuery, useGetProfileQuery} from "../../../../../_redux/auth/services";
import Loading from "../../../../../Loading";
import {Edit} from "@mui/icons-material";
import ColoredTitledContainer from "../../../../../components/containers/ColoredTitledContainer";
import NoHobbiesFound from "../../../../../components/noResultFound/NoHobbiesFound";

export default function MyHobbies() {

    const {data: profile} = useGetProfileQuery()
    const {data: cv, isLoading: isCvLoading, isFetching: isCvFetching} = useGetMyCvQuery(profile?.id, {skip: !profile})
    const {t} = useTranslation()

    if (isCvLoading) {
        return <Loading/>
    }
    return (
        <ColoredTitledContainer
            title={t("center.of.interest.label")}
            buttons={[
                <HobbiesEditModal
                    hobby={cv?.hobby || ""}
                    key={1}
                    isIconOnly={true}
                    color="default"
                    variant="flat"
                    radius="full"
                    size="sm"
                    className="p-0"
                    icon={<Edit height={10} width={10} />}
                />
            ]}
            isLoading={isCvLoading || isCvFetching}
        >
            { cv?.hobby ?
                <div className=" w-full flex flex-row justify-between items-center p-3">
                    <p className="text-justify text-md font-xing-bold">
                        {cv?.hobby}
                    </p>
                </div>
            :
                <NoHobbiesFound />
            }
        </ColoredTitledContainer>
    )
}
