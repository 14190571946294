import axios from "axios";
import {EventSearchInterface} from "./eventsSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

export async function EventListApiRequest(filters: EventSearchInterface = {"_page" : 1}) {

    const params = new URLSearchParams();
    const token = useSelector((state: RootState) => state.auth.token)

    Object.keys(filters).forEach(key => {
        if (filters[key]) {
            params.append(key, filters[key])
        }
    })

    try {
        const response = await axios.get(`/api/events?${params.toString()}`, {
            headers: {
                Authorization: 'Bearer ' + token //the token is a variable which holds the token
            }
        })
        return response?.data
    } catch (error: any) {
        console.error(error)
        return error?.message || "error unknown"
    }
}

export async function GetEventItem(id: number) {

    const token = useSelector((state: RootState) => state.auth.token)

    return axios.get(`/api/events/${id}`, {
        headers: {
            Authorization: 'Bearer ' + token //the token is a variable which holds the token
        }
    })
}