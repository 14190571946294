import React from "react";
import CustomUserAvatar from "../../customAvatar/CustomUserAvatar";
import {Skeleton} from "@mui/material";
import FriendShipButton from "../Buttons/FriendShipButton";
import {useNavigate} from "react-router-dom";

export default function NetworkBorderlessUserItem({user, isLoading = false}: { user: any, isLoading?: boolean }) {

    const navigate = useNavigate()
    const viewProfile = () => navigate(`/profile/${user?.id}`)

    return (
        <div
            className="h-[340px] min-h-[340px] max-h-[340px] bg-white flex flex-col justify-between items-center gap-3"
        >
            <CustomUserAvatar
                onClick={viewProfile}
                className={`h-[180px] w-[180px] ${(isLoading) ? "animate-pulse" : ""} cursor-pointer bg-["#f4f4f5"]`}
                src={user?.imageUrl}
                objectfit="cover"
            />
            {
                isLoading
                    ?
                    <Skeleton animation="wave" width={"70%"}/>
                    :
                    <div className="w-full text-center">
                        <p className="text-md font-xing-bold cursor-pointer"
                           onClick={viewProfile}
                        >
                            {user?.fullname}
                        </p>
                    </div>

            }
            {
                isLoading
                    ?
                    <Skeleton animation="wave" width={"85%"}/>
                    :
                    <div className="w-full text-center">
                        <p className="text-sm font-xing">
                            {user?.company?.job}
                        </p>
                    </div>
            }
            {
                isLoading
                    ?
                    <Skeleton animation="wave" width={"70%"}/>
                    :
                    <div className="w-full text-center">
                        <p className="font-xing text-sm text-gray-700">
                            {user?.description}
                        </p>
                    </div>
            }
            {
                isLoading ?
                    <Skeleton animation="wave" variant="rounded" width={80} height={30}/>
                    :
                    <div className="w-full flex flex-row justify-center items-center">
                        <FriendShipButton user={user} showViewProfile/>
                    </div>
            }
        </div>
    )
}
