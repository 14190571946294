import React from "react";
import {Button} from "@nextui-org/react";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useTranslation} from "react-i18next";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import MyContacts from "../network/MyContacts/MyContacts";

export default function NewMessageModal() {

    const {t} = useTranslation()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () =>  {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);


    return (
        <>
            <Button variant="flat" radius="full" color="default"
                    className="bg-[#FFB836] font-xing-bold text-black"
                    onClick={handleClickOpen}
            >
                <DriveFileRenameOutlineIcon height={18} width={18}/>
                <p>
                    {t("messages.new.message.label")}
                </p>
            </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            <MyContacts/>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

        </>
    )
}