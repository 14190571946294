import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectMartialStatus(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const list = [
    { label: "martial.status.single.label", value: "1" },
    { label: "martial.status.married.label", value: "2" },
    { label: "martial.status.divorced.label", value: "3" },
    { label: "martial.status.widow.label", value: "4" },
  ];
  return (
    <Select
      variant="standard"
      fullWidth
      label={t("martial.status.label")}
      placeholder={t("martial.status.label")}
      className="flex-col"
      {...props}
    >
      {list.map((e) => (
        <MenuItem
          value={e.value}
          key={e.value}
          style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
          className="w-full"
        >
          {t(e.label)}
        </MenuItem>
      ))}
    </Select>
  );
}
