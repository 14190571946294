import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import TitledContainer from "../../../components/containers/TitledContainer";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import moment from "moment/moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGetProfileViewersQuery } from "../../../_redux/features/network/services";
import { ProfileInterface } from "../profile/MyProfile";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { Paginator } from "../../../components/paginator/Paginator";
import Visitor from "./Visitor";
import VisitorDetails from "./VisitorDetails";

export default function Visitors() {
  const { t, i18n } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const [page, setPage] = useState<number>(1);
  const { data, isLoading, isFetching } = useGetProfileViewersQuery(
    {
      id: profile?.id,
      filters: { _page: page },
    },
    { skip: !profile }
  );
  const { list: views, pagination, totalItems } = data || {};

  function scrollHandler(e: ChangeEvent<unknown>, _page: number) {
    setPage(_page);
  }

  return (
    <TitledContainer title={t("visits.profil.label")}>
      <div className="w-full flex flex-col justify-start items-start gap-3">
        <div className="text-xl font-xing-bold">
          Nouvelles visites sur le profil ({totalItems})
        </div>
      </div>
      {isFetching || isLoading ? (
        <div className="w-full flex flex-col justify-evenly items-start">
          <Visitor isLoading />
        </div>
      ) : (
        <>
          <div className="w-full flex flex-col justify-evenly items-start">
            {views?.map((view: { user: ProfileInterface }) => (
              <Accordion className={"w-full"} key={view["@id"]} elevation={0}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ border: "none" }}
                >
                  <Visitor
                    viewedDate={moment()
                      .locale(i18n.language)
                      .to(view?.viewedDate)}
                    job={view?.user?.job}
                    company={view?.user?.company}
                    fullName={view?.user?.fullname}
                    src={view?.user.imageUrl}
                    userId={view?.user?.id}
                    isFriend={view?.user?.alreadyFriend}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <VisitorDetails view={view} />
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <Paginator
            count={pagination?.last?._page ?? 1}
            disabled={isFetching || isLoading}
            onChange={scrollHandler}
            page={page ?? 1}
          />
        </>
      )}
    </TitledContainer>
  );
}
