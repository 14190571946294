import React from "react";
import ColoredContainer from "../containers/ColoredContainer";
import { Add } from "@mui/icons-material";
import ExperienceIllustration from "../illustration/ExperienceIllustration";
import ExperienceAddModal from "../modals/Profile/Experience/ExperienceAddModal";
import { useTranslation } from "react-i18next";

export default function NoExperienceFound() {
  const { t } = useTranslation();

  return (
    <ColoredContainer className="w-full flex flex-row justify-start items-center gap-6 p-3">
      <ExperienceIllustration primary="#ffb836" secondary="#d9d9d9" />
      <div className="flex flex-col justify-between items-start gap-3">
        <h1 className="font-xing-bold">
          {t("profile.experiences.what.recruiters.want.to.know.label")}
        </h1>

        <ExperienceAddModal
          isIconOnly={false}
          variant="bordered"
          color="primary"
          radius="full"
          className="font-xing-bold"
          text={t("profile.experiences.add.label")}
          icon={<Add height={10} width={10} />}
        />
      </div>
    </ColoredContainer>
  );
}
