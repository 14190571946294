import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { DateTime } from "luxon";
import moment from "moment";

let currentLocale = "fr";
if (typeof window !== "undefined") {
  const browserLang = navigator.language;
  const storedLang = localStorage.getItem("i18nextLng");
  currentLocale = storedLang || browserLang || "fr";
  currentLocale = currentLocale.split("-")[0];
}
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: currentLocale,
    // fallbackLng: currentLocale,
    debug: false,
    react: {
      useSuspense: false,
    },
  });

i18n.services.formatter?.add("DATE_MED", (value, lng) => {
  return DateTime.fromJSDate(value)
    .setLocale(lng as string)
    .toLocaleString(DateTime.DATE_MED);
});

i18n.services.formatter?.add("MOMENT", (value, lng) => {
  moment.locale(lng);
  return moment(value).format("ll");
});

i18n.services.formatter?.add("FROM_NOW", (value, lng) => {
  moment.locale(lng);
  return moment(value).fromNow();
});

export default i18n;
