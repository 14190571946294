import React from "react";
import { useGetCareerLevelsQuery } from "../../../../_redux/features/careerLevel/services";
import { useFormikContext } from "formik";
import CustomCheckbox from "../../../../components/_form/_field/Checkbox";
import { useTranslation } from "react-i18next";
import DropDownFilter from "./DropDownFilter";

export default function StudyLevelFilter() {
  const { t } = useTranslation();
  const { values, handleChange } = useFormikContext();
  const { data, isLoading } = useGetCareerLevelsQuery({ pagination: false });
  const { list: careerLevels } = data || {};

  return (
    <DropDownFilter
      isLoading={isLoading}
      label={t("profile.careerLevel.label")}
      hasBadge={values?.careerLevel?.length}
    >
      {careerLevels && (
        <div className="flex flex-col justify-between px-3">
          {careerLevels?.map((careerLevel: any, index: number) => (
            <CustomCheckbox
              disabled={isLoading}
              key={index}
              defaultChecked={values?.careerLevel?.find(
                (c: any) => parseInt(c) === parseInt(careerLevel?.id)
              )}
              value={careerLevel?.id}
              onChange={handleChange}
              label={careerLevel?.name}
              name="careerLevel"
            />
          ))}
        </div>
      )}
    </DropDownFilter>
  );
}
