import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../utils/appRoutes";

const RedirectBlog = () => {
  const { locale, categoryParams, articelParams } = useParams();
  if (!locale || !categoryParams || !articelParams)
    return <Navigate to="/" replace />;

  const articelId = articelParams.split("-")[0];

  return (
    <Navigate
      to={AppRoutes.PRIVATE_BLOG_ARTICLE_DETAILS.replace(
        ":id",
        articelId
      ).replace(":locale", locale)}
      replace
    />
  );
};

export default RedirectBlog;
