export class AppRoutes {
  public static OFFLINE = "/:locale/offline";
  public static HOME = "/:locale";
  public static LINKEDIN_CALLBACK = "/:locale/linkedin";

  public static JOBS = [
    "/:locale/jobs/search",
    "/:locale/offre-emploi/par-region/:regionFilterParams",
    "/:locale/offre-emploi/par-fonction/:functionFilterParams",
    "/:locale/offre-emploi/par-secteur-activite/:sectorFilterParams",
    "/:locale/offre-emploi/keyword/:keyword",
    "/:locale/offre-emploi/:sectorGroupId/:companyName/:companyId",
    "/:locale/offre-emploi",
  ];

  public static PUBLIC_JOB_DETAILS = "/:locale/jobs/:id";

  public static BLOG = "/:locale/blog";
  public static FAQ = "/:locale/faq";
  public static LOGIN = "/:locale/login";
  public static REGISTER = "/:locale/register";
  public static SEND_EMAIL = "/:locale/password/send-email";
  public static RESET_PASSWORD = "/:locale/password/reset/:token";
  public static LANGUAGE = "/:locale/language";
  public static CONTACT_AND_HELP = "/:locale/contact";
  public static POLICY = [
    "/:locale/policy",
    "/:locale/conditions-d-utilisation",
  ];
  public static PRIVACY_POLICY = "/:locale/privacy-policy";

  public static PRIVATE_HOME = "/:locale";

  public static PRIVATE_JOBS = ["/:locale/", "/:locale/jobs"];
  public static PRIVATE_JOBS_SEARCH = [
    "/:locale/jobs/search",
    "/:locale/offre-emploi/par-region/:regionFilterParams",
    "/:locale/offre-emploi/par-fonction/:functionFilterParams",
    "/:locale/offre-emploi/par-secteur-activite/:sectorFilterParams",
    "/:locale/offre-emploi/keyword/:keyword",
    "/:locale/offre-emploi/:sectorGroupId/:companyName/:companyId",
    "/:locale/offre-emploi",
  ];
  public static PRIVATE_JOB_DETAILS = "/:locale/jobs/:id";
  public static PRIVATE_APPLICATIONS = "/:locale/applications";
  public static PRIVATE_RECENTLY_VIEWED_OFFERS =
    "/:locale/applications/recently_viewed";
  public static PRIVATE_APPLICATIONS_SELECTED_OFFERS = "/:locale/applications";
  public static PRIVATE_APPLICATIONS_SEARCH_ALERT =
    "/:locale/applications/search-alert";

  public static PRIVATE_APPLICATIONS_MY_APPLICATIONS =
    "/:locale/applications/yours";
  public static PRIVATE_NETWORK = "/:locale/network";
  public static PRIVATE_NETWORK_REQUESTS = "/:locale/network/requests";
  public static PRIVATE_NETWORK_REQUESTS_SENT =
    "/:locale/network/requests/send";
  public static PRIVATE_NETWORK_REQUESTS_RECEIVED =
    "/:locale/network/requests/received";
  public static PRIVATE_YOUR_NETWORK = "/:locale/network/yours";
  public static PRIVATE_CONTACT_AND_HELP = "/:locale/contact";
  public static PRIVATE_CHAT = "/:locale/chat";
  public static PRIVATE_CONVERSATION = "/:locale/chat/:id";
  public static PRIVATE_PROFILE = [
    "/:locale/profile",
    "/:locale/candidate/profile/show",
  ];
  public static PRIVATE_VIEW_CANDIDATE = "/:locale/profile/:id";
  public static PRIVATE_PROFESSIONAL_EXPECTATIONS = "/:locale/expectations";
  public static PRIVATE_SUBSCRIPTIONS = "/:locale/subscriptions";
  public static PRIVATE_MY_POSTS = "/:locale/my-posts";
  public static PRIVATE_EVENTS = "/:locale/events";
  public static PRIVATE_EVENT_DETAILS = "/:locale/events/:id";
  public static PRIVATE_BECOME_PREMIUM = "/:locale/become-premium";
  public static PRIVATE_OPT_FOR_PRO_JOBS = "/:locale/opt-for-pro-jobs";
  public static PRIVATE_BLOG = "/:locale/blog";
  public static PRIVATE_BLOG_ARTICLE_DETAILS = "/:locale/blog/:id";
  public static PRIVATE_NOTIFICATIONS = "/:locale/notifications";
  public static PRIVATE_DASHBOARD = "/:locale/dashboard";

  public static PRIVATE_SKILLS_PERFORMANCE = "/:locale/skills/performance";
  public static PRIVATE_PROFILE_VISITORS_AND_STATISTICS =
    "/:locale/visits-statistics";

  public static PRIVATE_SETTINGS = "/:locale/settings";
  public static PRIVATE_SETTINGS_ACCOUNT = "/:locale/settings/account";
  public static PRIVATE_SETTINGS_PASSWORD = "/:locale/settings/reset";
  public static PRIVATE_SETTINGS_CONFIDENTIALITY =
    "/:locale/settings/confidentiality";
  public static PRIVATE_LANGUAGE = "/:locale/language";

  // EP_V3_ROUTES

  public static PRIVATE_APPLICATIONS_SEARCH_ALERT_CANDIDATE =
    "/:locale/candidate/candidate-alert";
  public static PRIVATE_APPLICATIONS_SELECTED_OFFERS_CANDIDATE =
    "/:locale/candidate/selected-job";
  public static PRIVATE_APPLICATIONS_MY_APPLICATIONS_CANDIDATE =
    "/:locale/candidate/application";
  public static PRIVATE_EVENTS_CANDIDATE = "/:locale/candidate/event";

  public static CONTACT_AND_HELP_FROM_v3 = "/:locale/contactez-nous";
  public static REGISTER_FROM_v3 = "/:locale/register/candidate";

  public static SEND_EMAIL_FROM_v3 = "/:locale/resetting/request";
  public static RESET_PASSWORD_FROM_v3 = "/:locale/resetting/reset/:token";
  public static PRIVATE_PROFILE_FROM_V3 = "/:locale/candidate/cv/:cvID/show";

  public static PRIVATE_BLOG_FROM_v3 = "/:locale/carriere";

  public static PRIVATE_BLOG_ARTICLE_DETAILS_FROM_v3 =
    "/:locale/carriere/:categoryParams/:articelParams";

  public static PRIVATE_JOB_DETAILS_FROM_v3 = [
    "/:locale/candidate/application/:jobId/apply",
    "/:locale/offre-emploi/:companyName/:regionName/:jobId/:functionName/:jobSlug",
  ];

  public static PRIVATE_BLOG_FILTER_FROM_v3 =
    "/:locale/carriere/categorie/:categoryParams";

  // REDIRECT TO V3

  public static REGISTER_FROM_v4 = "/:locale/register/recruiter";
  public static RECRUITER_FROM_v4 = "/:locale/recruiter/:slug";
  public static ADAMANTIUMWEBADMIN_FROM_v4 =
    "/:locale/adamantiumwebadmin/:slug";
}
