import React, {useEffect} from "react";
import SideChat from "../../../components/sidechat/sideChat";
import {Outlet, useParams} from "react-router-dom";
import {Container} from "@mui/material";
import ConversationIllustration from "../../../components/illustration/ConversationIllustration";
import {useTranslation} from "react-i18next";

import useCurrentDimension, {getCurrentDimension} from "../../../_hooks/useCurrentDimension";
import NewMessageModal from "./NewMessageModal";

export default function Chat() {

    const {t} = useTranslation()
    const {id} = useParams()
    const md = 768
    const width = window.innerWidth
    const height = window.innerHeight - 100
    const [screenSize, setScreenSize] = useCurrentDimension();

    useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension())
        }
        window.addEventListener('resize', updateDimension);
        return (() => {
            window.removeEventListener('resize', updateDimension);
        })
    }, [screenSize])

    return (
        <Container maxWidth="md">
            <div style={{height: height}} className="relative w-full bg-white">
                <div className="relative h-full w-full flex flex-row justify-between gap-6">
                    <SideChat/>
                    {(id) ?
                        <Outlet/>
                        :
                        (width > md) &&
                        <div
                            className="relative h-full max-md:w-full w-3/5 bg-white flex flex-col justify-center gap-6 items-center"
                        >
                            <div className="text-3xl font-xing-bold">
                                <p>
                                    {t("messages.say.hello.label")}
                                </p>
                            </div>
                            <Container maxWidth="xs">
                                <p className="text-center">
                                    {t("messages.send.messages.to.your.friends.label")}
                                </p>
                            </Container>
                            <NewMessageModal/>
                            <ConversationIllustration primary="#ffb836" secondary="#2f2e41"/>
                        </div>
                    }
                </div>
            </div>
        </Container>
    )
}