import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import {
  useGetProfileQuery,
  useUpdateCvLanguageMutation,
} from "../../../../_redux/auth/services";
import { languageSchema } from "../../../_form/_schema/languageSchema";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSingleLanguage,
  setSingleLanguage,
} from "../../../../_redux/auth/authSlice";
import { CvXLanguageInterface } from "../../../../_redux/features/languages/cvXLanguageInterface";
import LanguageEditForm from "../../../_form/Langue/LanguageEditForm";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

export default function LanguageEditModal() {
  const dispatch = useDispatch();
  const { data: profile } = useGetProfileQuery();
  const language = useSelector(selectSingleLanguage);

  const [updateLanguageAction, updateLanguageTrigger] =
    useUpdateCvLanguageMutation();

  const { t } = useTranslation();

  function onClose() {
    dispatch(setSingleLanguage({ language: undefined }));
  }

  const isOpen = !!language;

  const initialValues = {
    id: language?.id,
    cv: `/api/candidates/${profile?.id}/cv`,
    level: language?.level ?? "",
    language: language?.language ?? "",
  };

  const handelSubmit = async (values: CvXLanguageInterface) => {
    updateLanguageAction(values)
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", {
            value: t("profile.languages.label", { count: 1 }),
          }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  return (
    <Modal
      className="min-h-full"
      open={isOpen}
      onClose={onClose}
      title={t("add.specific", {
        value: t("profile.languages.label", { count: 1 }),
      })}
    >
      <ModalOverflow>
        <ModalDialog variant="plain" layout="fullscreen">
          <ModalClose onClick={onClose} />
          <Container maxWidth="md">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              flex={3}
              maxWidth="md"
            >
              <h1>
                {t("update.specific", {
                  value: t("languages.label", { count: 1 }),
                })}
              </h1>
              <Formik
                initialValues={initialValues as never}
                onSubmit={handelSubmit}
                validationSchema={languageSchema}
              >
                {() => (
                  <LanguageEditForm
                    onCancel={onClose}
                    triggers={updateLanguageTrigger}
                  />
                )}
              </Formik>
            </Stack>
          </Container>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
