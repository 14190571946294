import {createSlice} from '@reduxjs/toolkit'
import {RootState} from "../store";

export interface SimilarOffersState {
    applyToSimilar: [] | undefined,
}

const initialState: SimilarOffersState = {
    applyToSimilar: undefined,
}

export const similarOffersApplySlice = createSlice({
    name: 'similarOffersApply',
    initialState,
    reducers: {
        setSimilarOffers: (state, action) => {

            const { applyToSimilar } = action.payload;

            return { ...state, applyToSimilar };

        }
    },
})

export const selectSimilarOffersApply = (state: RootState) => state.similarOffersApply.applyToSimilar;

export const { setSimilarOffers} = similarOffersApplySlice.actions

export default similarOffersApplySlice.reducer