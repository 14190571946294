import React, {useState} from "react";
import {EyeIcon, EyeSlashIcon} from "@heroicons/react/24/solid";
import Input, {InputPropsInterface} from "../_form/_field/Input";
import InputAdornment from "@mui/material/InputAdornment";


export default function PasswordInput(props: InputPropsInterface): JSX.Element {

    const [isPasswordVisible, setIsPasswordVisible] = useState(false)

    return (
        <Input
            {...props}
            type={isPasswordVisible ? "text" : "password"}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    <button
                        className="focus:outline-none"
                        type="button"
                        onClick={() => setIsPasswordVisible(prevState => !prevState)}
                    >
                        {isPasswordVisible ? (
                            <EyeSlashIcon height={18} width={18} className="cursor-pointer"/>
                        ) : (
                            <EyeIcon height={18} width={18} className="cursor-pointer"/>
                        )}
                    </button>
                </InputAdornment>
            }}
        />
    )
}