import React from "react";
import {Link} from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';

interface SubscriptionOfferItemProps {
    title: string,
    icon: string,
    color: string,
    background: string,
    price: number,
    description: Array<any>
}

export default function SubscriptionOfferItem ({ title, icon, color, background, price, description } : SubscriptionOfferItemProps) {
    return (
        <div className="flex flex-col justify-evenly items-center gap-12">
            <div className="flex flex-col justify-evenly items-center gap-3">
                <img
                    src={icon}
                    height={80} width={80}
                    alt={`Subscription ${title}`}
                />
                <div style={{ color: color }} className="text-4xl font-xing-bold first-letter:capitalize">
                    { title }
                </div>
                <div className="text-4xl font-xing-bold first-letter:capitalize">
                    { price + " DA" }
                </div>
            </div>
            <div className="mt-6">
                <ul className="flex flex-col gap-3 text-xl">
                    {description.map((line, key) =>
                        <li key={key} className="flex flex-row justify-start items-center gap-3">
                            <CheckIcon style={{ fill: background }} height={32} width={32} />
                            { line }
                        </li>
                    )}
                </ul>
            </div>

            <Link style={ { background: background } } to="/" className="w-full flex flex-row justify-center items-center rounded-full py-4 text-lg font-xing-bold">
                En savoir plus
            </Link>

        </div>
    )
}