import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Tooltip,
} from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";
import { logOut, selectToken } from "../../../_redux/auth/authSlice";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

const SettingsIconButton = () => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector(selectToken);
  const [isAuth] = useState(!!token);
  function signOut() {
    dispatch(logOut());
    navigate(AppRoutes["HOME"].replace(":locale", `${i18n.language}`));
  }

  return (
    <Dropdown
      id="settings-dropdown"
      closeOnSelect={true}
      className="bg-white mx-3 shadow-2xl rounded-md"
    >
      <DropdownTrigger>
        <div className="flex flex-col items-center justify-center cursor-pointer">
          <SettingsOutlinedIcon width={32} height={32} />
          <p className="text-sm max-md:hidden">{t("settings.label")}</p>
        </div>
      </DropdownTrigger>
      <DropdownMenu variant="flat" color="warning">
        {pathname !==
          AppRoutes.PRIVATE_SETTINGS.replace(":locale", `${i18n.language}`) && (
          <DropdownItem
            key="settings"
            onClick={() => {
              if (
                pathname !==
                AppRoutes.PRIVATE_SETTINGS.replace(
                  ":locale",
                  `${i18n.language}`
                )
              )
                navigate(
                  AppRoutes.PRIVATE_SETTINGS.replace(
                    ":locale",
                    `${i18n.language}`
                  )
                );
            }}
          >
            <div className="flex flex-row items-center justify-start gap-3">
              <SettingsOutlinedIcon width={32} height={32} />
              <p className="text-sm">{t("settings.label")}</p>
            </div>
          </DropdownItem>
        )}
        <DropdownItem key="signOut" onClick={signOut}>
          <div className="flex flex-row items-center justify-start gap-3">
            <LogoutOutlinedIcon width={32} height={32} />
            <p className="text-sm">{t("actions.close.session.label")}</p>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default SettingsIconButton;
