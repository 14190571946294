import React, { useCallback, useEffect } from "react";
import NavBar from "../../components/navbar/navbar";
import SideBar from "../../components/sidebar/sidebar";
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Container, Link } from "@mui/material";
import Footer from "../../components/footer/Footer";
import { JobSearchPublicForm } from "../../components/forms/job/JobSearchPublicForm";
import AcceptPolicyModal from "../../components/modals/acceptPolicyModal/AcceptPolicyModal";
import { AppRoutes } from "../../utils/appRoutes";
import BottomNavBar from "../../components/bottomNavbar/BottomNavbar";
import { deleteLocalFromPath, localLanguages } from "../../_helpers/locals";

export default function Private() {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { locale } = useParams();
  const navigate = useNavigate();
  moment().locale(i18n.language);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  const updateUrlWithLanguage = useCallback(
    (lang: string) => {
      const pathWithoutLocale = deleteLocalFromPath({
        pathname,
        locale: locale || "",
      });

      navigate(`/${lang}/${pathWithoutLocale}`, { replace: true });
    },
    [locale, navigate, pathname]
  );

  useEffect(() => {
    if (!locale || locale !== i18n.language) {
      updateUrlWithLanguage(i18n.language);
    }
  }, [i18n.language, pathname, locale, updateUrlWithLanguage]);
  return (
    <div dir={i18n.dir()}>
      <NavBar />
      {AppRoutes.PRIVATE_JOBS.map((route) =>
        route.replace("/:locale/", "")
      ).includes(
        deleteLocalFromPath({
          pathname,
          locale: locale || "",
        })
      ) ? (
        <>
          <section
            className="w-full pb-20 pt-10"
            style={{
              backgroundImage:
                "url('/svg/prototype-illustration-home-background.svg')",
              backgroundSize: "100%",
              backgroundPosition: "center center",
              backgroundRepeat: "none",
              backgroundColor: "#FFC761",
            }}
          >
            <Container maxWidth="lg">
              <div className="flex flex-col justify-between items-center gap-3 px-9">
                <h1 className="font-xing">
                  {t("find.jobs.you.want.to.publish.job.offers.label")}{" "}
                  <a
                    className="font-xing-bold cursor-pointer hover:underline text-blue-600"
                    href={"https://corporate.emploipartner.com/publier-une-annonce/"}
                    target="_blank"
                  >
                    {t("find.jobs.clic.here.label")}{" "}
                  </a>
                </h1>
                <h1 className="font-xing-bold text-[42px] text-center">
                  {t("find.jobs.find.the.job.that.really.suits.you.label")}
                </h1>
                <JobSearchPublicForm />
              </div>
            </Container>
          </section>
          <Container
            style={{
              position: "relative",
              top: "-70px",
              borderRadius: "15px",
              backgroundColor: "white",
              marginTop: "20px",
            }}
            maxWidth="lg"
          >
            <section className="relative w-full min-w-full flex flex-row items-start gap-6 max-md:px-[0px] max-lg:px-[10px] py-[23px]">
              <SideBar />
              <main className="relative flex flex-col justify-start items-center">
                <Outlet />
              </main>
            </section>
          </Container>
        </>
      ) : (
        <section className="relative w-full min-w-full flex flex-row items-start max-md:px-[0px] max-lg:px-[10px] px-[72px] py-[23px]">
          <SideBar />
          <main className="w-full min-h-screen relative m-auto flex flex-col justify-start items-center">
            <Outlet />
          </main>
        </section>
      )}
      <Footer />
      <BottomNavBar />
      <AcceptPolicyModal />
    </div>
  );
}
