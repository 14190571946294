import React from "react";
import { useFormikContext } from "formik";
import CustomCheckbox from "../../../../components/_form/_field/Checkbox";
import { useTranslation } from "react-i18next";
import DropDownFilter from "./DropDownFilter";
import { useGetParamsExperiencesQuery } from "../../../../_redux/features/experiences/services";

export default function ExperienceFilter() {
  const { t } = useTranslation();
  const { values, handleChange } = useFormikContext();

  const { data, isLoading } = useGetParamsExperiencesQuery({
    pagination: false,
  });
  const { list: experiences } = data || {};

  return (
    <DropDownFilter
      hasBadge={values?.nbMonthExperience?.length}
      isLoading={isLoading}
      label={t("experience.title.label")}
    >
      {experiences && (
        <div className="flex flex-col justify-between px-3">
          {experiences?.map((experience: any, index: number) => (
            <CustomCheckbox
              key={index}
              disabled={isLoading}
              defaultChecked={values?.nbMonthExperience?.find(
                (f: any) => f == experience?.id
              )}
              value={experience?.id}
              onChange={handleChange}
              label={experience?.label}
              name="nbMonthExperience"
            />
          ))}
        </div>
      )}
    </DropDownFilter>
  );
}
