import React from "react";
import ProfileInformationEditModal from "../../../../../components/modals/Profile/ProfileInformationEditModal";
import Loading from "../../../../../Loading";
import { useTranslation } from "react-i18next";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../../_redux/auth/services";
import moment from "moment";
import TitledContainer from "../../../../../components/containers/TitledContainer";
import { Edit } from "@mui/icons-material";
import ResumeIllustration from "../../../../../components/illustration/ResumeIllustration";
import PersonalInformationIllustration from "../../../../../components/illustration/PersonalInformationIllustration";
import ProfileCvTitleEditModal from "../../../../../components/modals/Profile/ProfileCvTitleEditModal";
export default function MyProfileInformation() {
  const { t } = useTranslation();
  const { data: profile, isLoading } = useGetProfileQuery();
  const { isLoading: isCvLoading, isFetching: isCvFetching } = useGetMyCvQuery(
    profile?.id,
    { skip: !profile }
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <TitledContainer
      title={t("personalInformation.suplementairs")}
      className="p-3 flex flex-col justify-between gap-1"
      buttons={[<ProfileCvTitleEditModal />]}
      isLoading={isCvLoading || isCvFetching}
    >
      {!profile ? (
        <div className="flex flex-row max-lg:flex-col-reverse justify-between items-center">
          <div className="flex flex-col justify-start items-start gap-6">
            <div className="flex flex-col justify-start gap-3">
              <p className="first-letter:capitalize text-md">
                {t("fullName.label")} :
              </p>
              <p className="first-letter:capitalize text-[1.375rem]">
                {" "}
                {profile?.fullname}
              </p>
            </div>
            <div className="flex flex-col justify-start gap-1">
              <p className="first-letter:capitalize text-md">
                {t("phone.label")} :
              </p>
              <p className="first-letter:capitalize text-[1.375rem]">
                {profile?.phone}
              </p>
            </div>
            <div className="flex flex-col justify-start gap-1">
              <p className="first-letter:capitalize text-md">
                {t("birthday.label")} :
              </p>
              <p className="first-letter:capitalize text-[1.375rem]">
                {moment(profile?.birthday).format("ll")}
              </p>
            </div>
          </div>
          <div>
            <PersonalInformationIllustration
              primary="#ffb836"
              secondary="#d9d9d9"
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex flex-row max-lg:flex-col justify-evenly items-center gap-3">
          <ResumeIllustration primary="#ffb836" secondary="#d9d9d9" />
          <div className="flex flex-col justify-between items-start gap-3">
            <ProfileInformationEditModal
              isIconOnly={false}
              variant="bordered"
              color="primary"
              radius="full"
              className="font-xing-bold"
              text={t("profile.informations.add.brief.presentation.label")}
              icon={<Edit height={10} width={10} />}
            />
          </div>
        </div>
      )}
    </TitledContainer>
  );
}
