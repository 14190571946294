import React, {useState} from "react";
import JobComponentItem, {JobInterface} from "../../../../components/jobComponent/jobComponentItem/jobComponentItem";
import SeeMore from "../../../../components/seeMore/SeeMore";
import TitledContainer from "../../../../components/containers/TitledContainer";
import {useTranslation} from "react-i18next";
import {useGetRecentJobsQuery} from "../../../../_redux/features/jobs/services";
import JobsLoading from "../loading";

export default function RecentOffers() {

    const {t} = useTranslation()
    const {data, isLoading} = useGetRecentJobsQuery({limit: 9})
    const {list: recentJobs} = data || {}

    const [expandedSimilarOffers, setExpandedSimilarOffers] = useState(false);
    const handleExpandSimilarOffersClick = () => {
        setExpandedSimilarOffers(!expandedSimilarOffers);
    }

    return (
        <TitledContainer
            title={t("find.jobs.recent.offers.label")}
            subtitle={t("find.jobs.recently.viewed.offers.label")}
        >
                {
                    isLoading ?
                        <JobsLoading />
                    :
                    recentJobs?.length <= 0 ?
                        <div>
                            {t("job.label", {count: recentJobs?.length})}
                        </div>
                    :
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center justify-center">
                            {recentJobs?.slice(0, (expandedSimilarOffers) ? 9 : 3).map((job: JobInterface) =>
                                <JobComponentItem
                                    key={job["@id"]}
                                    job={job}
                                />
                            )}
                        </div>
                }

            { recentJobs && <SeeMore handler={handleExpandSimilarOffersClick} expanded={expandedSimilarOffers}/> }

        </TitledContainer>
    )
}