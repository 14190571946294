import CustomUserAvatar from "../../../../components/customAvatar/CustomUserAvatar";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Close, Message } from "@mui/icons-material";
import { AppRoutes } from "../../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { CandidateInterface } from "../../../../_redux/features/events/interfaces";
import { Skeleton, Button, Container, IconButton } from "@mui/material";
import { useCancelInvitationMutation } from "../../../../_redux/features/network/services";
import CircularProgress from "@mui/material/CircularProgress";

export interface InvitationInterface {
  "@id": number | string;
  "@type": number | string;
  id: number | string;
  invitedDate: Date;
  updatedDate: Date;
  invitedBy: CandidateInterface;
  invited: CandidateInterface;
  status: "PENDING" | "ACCEPT" | "REFUSED" | "CANCELED";
}

interface InvitationPropsInterface {
  invitation: InvitationInterface;
  isLoading?: boolean;
}

export default function FriendRequestItem({
  invitation,
  isLoading = false,
}: InvitationPropsInterface) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [cancelAction, cancelTriggers] = useCancelInvitationMutation();
  const viewProfile = () => navigate(`/profile/${invitation?.invitedBy?.id}`);
  const goToSendMessage = () =>
    navigate(
      AppRoutes.PRIVATE_CONVERSATION.replace(
        ":locale",
        `${i18n.language}`
      ).replace(":id", invitation?.invitedBy?.id)
    );

  return (
    <Container maxWidth="lg">
      <div className="w-full flex flex-row justify-start items-start gap-3">
        <div className="flex flex-col justify-start items-center">
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={48}
              height={48}
            />
          ) : (
            <CustomUserAvatar
              src={invitation?.invited?.imageUrl}
              className="h-[48px] w-[48px]"
            />
          )}
        </div>

        {isLoading ? (
          <div className="w-full flex flex-col justify-between items-stretch gap-2">
            <Skeleton
              className={"pb-1"}
              animation="wave"
              variant="rounded"
              width={"60%"}
              height={10}
            />
            <Skeleton
              className={"pb-1"}
              animation="wave"
              variant="rounded"
              width={"80%"}
              height={10}
            />
            <Skeleton
              className={"pb-1"}
              animation="wave"
              variant="rounded"
              width={"90%"}
              height={10}
            />
          </div>
        ) : (
          <div className="flex flex-row justify-between items-center gap-2 w-full">
            <div className={"cursor-pointer"} onClick={viewProfile}>
              <p className="text-black first-letter:capitalize">
                {invitation?.invited?.fullname}
              </p>
              <p className="text-sm text-[#5e737d] first-letter:capitalize">
                {moment()
                  .locale(`${i18n.language}-DZ`)
                  .to(invitation?.invitedDate)}
              </p>
            </div>
            <div className="flex flex-row justify-between items-start gap-2">
              <Button
                size="small"
                color={"primary"}
                variant="outlined"
                onClick={goToSendMessage}
                className="first-letter:capitalize flex flex-row justify-between items-center gap-3 font-xing-bold p-0"
                title={t("messages.new.message.label")}
              >
                <Message fontSize="small" />
                {t("messages.new.message.label")}
              </Button>
              {invitation?.status === "PENDING" && (
                <IconButton
                  color="error"
                  size="small"
                  disabled={
                    cancelTriggers.isLoading || cancelTriggers.isFetching
                  }
                  className="first-letter:capitalize font-xing-bold p-0 rounded-full"
                  title={t("refuse.label")}
                  aria-label="cancelTriggers"
                >
                  {cancelAction.isLoading || cancelAction.isFetching ? (
                    <CircularProgress color={"inherit"} size={17} />
                  ) : (
                    <Close />
                  )}
                </IconButton>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}
