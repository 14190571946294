import React, {useState} from "react";
import EventItem from "../../../components/eventsComponent/eventsItem/EventItem";
import {useSelector} from "react-redux";
import {selectEventsSearch} from "../../../_redux/features/events/eventsSlice";
import {useGetEventsQuery} from "../../../_redux/features/events/services";
import EventsLoading, {EventsFetching} from "./loading";
import {Paginator} from "../../../components/paginator/Paginator";

export default function EventList() {

    const eventSearch = useSelector(selectEventsSearch)
    const [page, setPage] = useState<number>(1);
    const {data, isLoading, isFetching} = useGetEventsQuery({...eventSearch, _page: page})
    const {list: events, pagination} = data || {}

    return (isLoading ?
            <EventsLoading/>
            :
            <>
                <div
                    className="grid max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-2 max-xl:grid-cols-2 max-2xl:grid-cols-3 2xl:grid-cols-4 gap-6"
                >
                    {isLoading ?
                        <EventsFetching/>
                        : events?.map((event: any, key: number) => <EventItem key={key} event={event}/>)
                    }
                </div>
                <Paginator
                    count={pagination?.last?._page ?? 1}
                    disabled={isFetching || isLoading}
                    onChange={(e, page) => setPage(page)}
                    page={page ?? 1}
                />
            </>
    )
}