import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectCivility(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const items = [
    { label: "civility.mr.label", value: "mr" },
    { label: "civility.mrs.label", value: "mrs" },
    { label: "civility.ms.label", value: "ms" },
  ];
  return (
    <Select
      variant="standard"
      fullWidth
      label={t("civility.label")}
      placeholder={t("civility.placeholder")}
      {...props}
    >
      {items.map((e, i) => (
        <MenuItem
          style={{ padding: "0.35rem 0.8rem", justifyContent: "start" }}
          className="w-full"
          value={e.value}
          key={e.value}
        >
          {t(e.label)}
        </MenuItem>
      ))}
    </Select>
  );
}
