import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { Button } from "@nextui-org/react";
import { SearchIcon } from "@nextui-org/shared-icons";
import { Input as NextInput } from "@nextui-org/react";
import { Accordion, AccordionItem } from "@nextui-org/react";
import SEO from "../../../SEO";
import { faqTitle } from "../../../utils/seoProps";

interface InitialValues {
  keyword: string;
}

interface FaqItemType {
  id: string;
  keywords: string;
  question: string;
  answer: string;
}

interface CategoryType {
  id: string;
  name: string;
  faqs: FaqItemType[];
}

const FaqPage: React.FC = () => {
  const { t } = useTranslation();
  const items: CategoryType[] = [
    {
      id: "1",
      name: t("faq.category1"),
      faqs: [
        {
          id: "1",
          keywords: "J'ai oublié mon mot de passe. Que faire ?",
          question: t("faq.question1"),
          answer: t("faq.answer1"),
        },
        {
          id: "2",
          keywords: "Je n'arrive pas à me connecter. Que faire ?",
          question: t("faq.question2"),
          answer: t("faq.answer2"),
        },
        {
          id: "3",
          keywords:
            "Pourquoi me demande-t-on de confirmer mon adresse e-mail ?",
          question: t("faq.question3"),
          answer: t("faq.answer3"),
        },
      ],
    },
    {
      id: "2",
      name: t("faq.category2"),
      faqs: [
        {
          id: "1",
          keywords: "Comment créer un compte sur Emploipartner ?",
          question: t("faq.question4"),
          answer: t("faq.answer4"),
        },
        {
          id: "2",
          keywords: "Comment supprimer mon compte ?",
          question: t("faq.question5"),
          answer: t("faq.answer5"),
        },
        {
          id: "3",
          keywords:
            "Puis-je modifier l'adresse e-mail associée à mon compte après l'avoir créé ?",
          question: t("faq.question6"),
          answer: t("faq.answer6"),
        },
        {
          id: "4",
          keywords: "Comment nettoyer mon cache et mes cookies ?",
          question: t("faq.question7"),
          answer: t("faq.answer7"),
        },
        {
          id: "5",
          keywords: "Comment utiliser l'application Emploipartner ?",
          question: t("faq.question8"),
          answer: t("faq.answer8"),
        },
        {
          id: "6",
          keywords: "Comment changer la langue sur le site ?",
          question: t("faq.question9"),
          answer: t("faq.answer9"),
        },
      ],
    },
    {
      // 2 questions
      id: "3",
      name: t("faq.category3"),
      faqs: [
        {
          id: "1",
          keywords: "Qu’est-ce qu’un profil Emploipartner ?",
          question: t("faq.question10"),
          answer: t("faq.answer10"),
        },
        {
          id: "2",
          keywords:
            "Comment configurer la visibilité de mon profil pour le rendre public, confidentiel ou privé ?",
          question: t("faq.question11"),
          answer: t("faq.answer11"),
        },
      ],
    },
    {
      // 2 questions
      id: "4",
      name: t("faq.category4"),
      faqs: [
        {
          id: "1",
          keywords:
            "Comment rechercher des offres d'emploi sur Emploipartner ?",
          question: t("faq.question12"),
          answer: t("faq.answer12"),
        },
        {
          id: "2",
          keywords: "Comment postuler ?",
          question: t("faq.question13"),
          answer: t("faq.answer13"),
        },
      ],
    },
    {
      // 2 questions
      id: "5",
      name: t("faq.category5"),
      faqs: [
        {
          id: "1",
          keywords:
            "A quels types d'e-mails puis-je m'abonner sur Emploipartner ?",
          question: t("faq.question14"),
          answer: t("faq.answer14"),
        },
        {
          id: "2",
          keywords: "Que sont les alertes d’emploi Emploipartner ?",
          question: t("faq.question15"),
          answer: t("faq.answer15"),
        },
      ],
    },
    {
      // 2 questions
      id: "6",
      name: t("faq.category6"),
      faqs: [
        {
          id: "1",
          keywords:
            "Où puis-je trouver des informations sur la politique de confidentialité de Emploipartner ?",
          question: t("faq.question16"),
          answer: t("faq.answer16"),
        },
        {
          id: "2",
          keywords: "Comment fonctionnent les cookies sur Emploipartner ?",
          question: t("faq.question17"),
          answer: t("faq.answer17"),
        },
      ],
    },
  ];
  const [searchResults, setSearchResults] = useState<CategoryType[]>([
    ...items,
  ]);

  const initialValues = {
    keyword: "",
  };
  const handelSubmit = async (values: InitialValues) => {
    const { keyword } = values;
    if (keyword) {
      // filter questions by keyword list in each question
      // if a category has at least one question that contains the keyword, then show the category
      const results = items.filter((category) => {
        return category.faqs.some((item) => {
          return item.keywords.includes(keyword);
        });
      });
      setSearchResults(results);
    } else {
      setSearchResults([...items]);
    }
  };
  return (
    <section className="w-full h-full flex flex-col">
      <SEO {...faqTitle} />

      <header className="lg:w-8/12 md:w-10/12 mx-4 sm:mx-auto my-10 flex flex-col gap-4 justify-stretch items-stretch">
        <h1 className="text-4xl md:text-6xl font-xing-bold">
          {t("faq.title")}
        </h1>
        <Formik initialValues={initialValues} onSubmit={handelSubmit}>
          {() => (
            <Form className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 max-md:grid-cols-2 items-center justify-center">
              <div className="col-span-1 sm:col-span-2 md:col-span-3">
                <Field
                  radius="full"
                  startContent={<SearchIcon height={20} width={20} />}
                  classNames={{
                    input: ["text-lg text-black h-12 font-xing-bold text-lg"],
                    innerWrapper: "bg-white h-10 font-xing-bold text-lg",
                    inputWrapper: [
                      "h-12 font-xing-bold text-lg w-full",
                      "bg-white",
                      "cursor-text",
                    ],
                  }}
                  variant="bordered"
                  fullWidth={true}
                  as={NextInput}
                  type="search"
                  name="keyword"
                  placeholder={t("faq.keyword", { count: 1 })}
                />
              </div>
              <Button
                type="submit"
                fullWidth={true}
                className="h-12 font-xing-bold text-lg col-span-1"
                radius="full"
                variant="solid"
                color="primary"
              >
                {t("faq.search")}
              </Button>
            </Form>
          )}
        </Formik>
      </header>
      <div className="w-10/12 lg:w-8/12 mx-4 sm:mx-auto my-10 flex flex-col gap-4 justify-stretch items-stretch">
        {searchResults.map((category) => (
          <FaqPageCategory key={category.id} category={category} />
        ))}
      </div>
    </section>
  );
};

export default FaqPage;

const FaqPageCategory: React.FC<{ category: CategoryType }> = ({
  category,
}) => {
  return (
    <div className="w-full mx-auto flex gap-6 flex-wrap lg:flex-nowrap">
      <h2 className="text-2xl font-xing-bold lg:w-1/4 pt-4">{category.name}</h2>
      <Accordion selectionMode="multiple" className="w-full lg:w-3/4">
        {category.faqs.map((item) => (
          <AccordionItem key={item.id} title={item.question}>
            <p>{item.answer}</p>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};
