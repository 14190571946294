import React, {useState} from "react";
import {useGetProfileQuery} from "../../../../_redux/auth/services";
import {useGetMyInvitationsQuery} from "../../../../_redux/features/network/services";
import InvitationItem, {InvitationInterface} from "./InvitationItem";
import {Paginator} from "../../../../components/paginator/Paginator";
import NoInvitations from "./NoInvitations";

export default function ReceivedInvitations() {

    const [page, setPage] = useState<number>(1);
    const {data: profile} = useGetProfileQuery()
    const {data, isFetching, isLoading} = useGetMyInvitationsQuery({id: profile?.id}, {skip: !profile})
    const {list: invitations, pagination} = data || {}

    return (
        <>
            {
                isFetching ?
                    <InvitationItem isLoading invitation={null}/>
                    :
                    <>
                        {
                            invitations?.length
                                ?
                                invitations?.map((invitation: InvitationInterface) => <InvitationItem
                                    key={invitation.id} invitation={invitation}/>)
                                :
                                <NoInvitations/>

                        }
                        <Paginator
                            count={pagination?.last?._page ?? 1}
                            disabled={isFetching || isLoading}
                            onChange={(e, page) => setPage(page)}
                            page={page ?? 1}
                        />
                    </>
            }
        </>
    )
}