import React from "react";
import { useTranslation } from "react-i18next";
import TitledContainer from "../../../../../components/containers/TitledContainer";
import LanguageAddModal from "../../../../../components/modals/Profile/Langue/LanguageAddModal";
import { Add } from "@mui/icons-material";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
} from "../../../../../_redux/auth/services";
import Loading from "../../../../../Loading";
import { MyLanguagesList } from "./MyLanguagesList";

export default function MyLanguages() {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const { data: cv, isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });

  if (isCvLoading) {
    return <Loading />;
  }

  return (
    <TitledContainer
      className="p-3 flex flex-col justify-between gap-1"
      title={t("profile.languages.label")}
      buttons={[
        <LanguageAddModal
          key={1}
          isIconOnly={true}
          color="default"
          variant="flat"
          radius="full"
          size="sm"
          className="p-0"
          icon={<Add height={10} width={10} />}
        />,
      ]}
    >
      <MyLanguagesList languages={cv?.languages} />
    </TitledContainer>
  );
}
