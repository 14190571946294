import {KeyboardBackspace} from "@mui/icons-material";
import {NavLink} from "react-router-dom";
import React from "react";
import {useTranslation} from "react-i18next";

export default function Previous () {

    const {t} = useTranslation()

    return (
        <NavLink to="/profile" className="flex flex-row justify-start items-center gap-3">
            <KeyboardBackspace height={18} width={18}/>
            <p className="first-letter:capitalize font-xing-bold">
                {t("actions.previous.label")}
            </p>
        </NavLink>
    )
}