import React, { useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import { useGetCategoriesQuery } from "../../../../_redux/features/blog/services";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { removeInvalidKeys } from "../../../../_helpers/urlHelpers";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import { CloseOutlined, ExpandLess } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { AppRoutes } from "../../../../utils/appRoutes";

export default function CategoriesFilter() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const { data, isLoading } = useGetCategoriesQuery({ _page: 1 });
  const { list: categories } = data || {};
  let [searchParams, setSearchParams] = useSearchParams();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => setIsOpen(!isOpen);
  const { categoryParams } = useParams();
  useEffect(() => {
    if (categoryParams) {
      setFieldValue("type", categoryParams.split("-")[0]);
    }
  }, [categoryParams, setFieldValue, i18n.language]);

  useEffect(() => {
    if (values?.type) {
      setSearchParams(removeInvalidKeys(values));
      categoryParams &&
        navigate(
          AppRoutes.PRIVATE_BLOG.replace(":locale", `${i18n.language}`) +
            `?type=${categoryParams ? categoryParams.split("-")[0] : ""}`
        );
    }
  }, [values?.type]);

  return (
    <div className="w-full flex flex-col justify-between items-start gap-3 border-gray-200 rounded-2xl border-2 border-solid font-xing select-none cursor-pointer transition-all duration-150">
      <div className="w-full p-4 flex flex-row justify-between items-start gap-3">
        <div className="flex-none w-10 flex flex-row justify-end items-center">
          <WorkspacesIcon className="rotate-12" />
        </div>
        <div className="grow">
          <div onClick={handleOpen} className="w-full flex flex-col gap-1">
            <div className="w-full flex flex-row justify-between items-center gap-1">
              <h6 className="font-xing-bold text-xl first-letter:uppercase">
                {t("categories.label")}
                {searchParams.get("type") &&
                  searchParams.get("type") != null && (
                    <CloseOutlined
                      onClick={(e) => {
                        e.stopPropagation();
                        setFieldValue("type", null);
                        setSearchParams(removeInvalidKeys(values));
                      }}
                    />
                  )}
              </h6>
              <div
                className={`transition-all duration-100 ${
                  isOpen ? "" : "rotate-180"
                }`}
              >
                <ExpandLess height={18} width={18} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full p-4 flex flex-row justify-center items-center gap-3 ${
          isOpen ? "" : "hidden h-0"
        }`}
      >
        <div className="flex-none w-1" />
        <div className="grow">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={searchParams.get("type")}
              name="radio-buttons-group"
              className="flex flex-col justify-between items-stretch gap-2"
            >
              {isLoading ? (
                <>
                  <div className="animate-pulse bg-gray-100 min-h-[20px] w-2/5 p-1" />
                  <div className="animate-pulse bg-gray-100 min-h-[20px] w-3/5 p-1" />
                  <div className="animate-pulse bg-gray-100 min-h-[20px] w-4/5 p-1" />
                  <div className="animate-pulse bg-gray-100 min-h-[20px] w-2/5 p-1" />
                  <div className="animate-pulse bg-gray-100 min-h-[20px] w-3/5 p-1" />
                  <div className="animate-pulse bg-gray-100 min-h-[20px] w-4/5 p-1" />
                  <div className="animate-pulse bg-gray-100 min-h-[20px] w-3/5 p-1" />
                </>
              ) : (
                <>
                  {categories?.map((category) => (
                    <Field
                      checked={category.id == searchParams.get("type")}
                      key={category?.id}
                      label={category?.name}
                      as={FormControlLabel}
                      control={<Radio color="warning" />}
                      value={category?.id}
                      name="type"
                      className="hover:underline hover:font-xing-bold cursor-pointer"
                    />
                  ))}
                </>
              )}
            </RadioGroup>
          </FormControl>
        </div>
      </div>
    </div>
  );
}
