import React from "react";
import Select from "../Select";
import {useTranslation} from "react-i18next";
import {careerLevelInterface} from "../../../../_redux/features/careerLevel/types";
import {useGetSalariesQuery} from "../../../../_redux/features/salary/services";
import {MenuItem} from "@mui/material";

interface SelectPropsInterface {
    name: string;
    type?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    label?: string;
    placeholder?: string;
    isRequired?: boolean;
    ch?: boolean;
    onChange?: (e: any) => void;
}

export default function SelectSalary(props: SelectPropsInterface): JSX.Element {

    const {t} = useTranslation()
    const {data, isLoading} = useGetSalariesQuery({pagination: false});
    const {list: salaries} = data || {}

    return (
        <Select
            variant="standard"
            fullWidth
            isLoading={isLoading}
            label={t("salary.label")}
            placeholder={t("salary.placeholder")}
            {...props}
        >
            {
                salaries?.map((salary: careerLevelInterface) =>
                    <MenuItem key={salary["@id"]} value={salary["@id"]}>
                        {t("salary.range", {min: salary?.min, max: salary?.max})}
                    </MenuItem>)
            }
        </Select>
    )
}