import React from "react";
import SkillDeleteModal from "../../../../../components/modals/Profile/Skill/SkillDeleteModal";
import {CvXSkillInterface} from "../../../../../_redux/features/skills/cvXSkillInterface";
import {useDispatch} from "react-redux";
import {setSingleSkill} from "../../../../../_redux/auth/authSlice";

export default function MySkillItem({skill}: { skill: CvXSkillInterface }) {

    const dispatch = useDispatch()

    return (
        <div className="max-h-[44px] group flex p-2 items-center rounded-full bg-white border-solid border-2 border-[#bcc9d1] text-black font-medium">
            <div className="cursor-pointer text-[#1d2124]" onClick={() => dispatch(setSingleSkill({skill}))}>
                {skill?.competence?.name} ({skill?.level})
            </div>
            <div className="hidden group-hover:block">
                <SkillDeleteModal skill={skill}/>
            </div>
        </div>
    )
}