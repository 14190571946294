import React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MenuNavLink from "../MenuNavLink";
import MultiDirectionIcon from "../../../../../components/multiDirectionsIcons/ArrowMultiDirectionIcon";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/24/outline";
import {useGetProfileQuery} from "../../../../../_redux/auth/services";
import {AppRoutes} from "../../../../../utils/appRoutes";

export default function Account() {

    const {data: profile} = useGetProfileQuery()
    const navigate = useNavigate()
    const {t} = useTranslation()

    const account = [
        {
            title: t("settings.account.fullname.label"),
            subtitle: profile?.fullname,
            icon: undefined,
            onClick: undefined
        },
        {
            title: t("settings.account.email.label"),
            subtitle: profile?.email,
            icon: undefined,
            onClick: undefined
        },
        {
            title: t("settings.account.phone.label"),
            subtitle: profile?.phone,
            icon: undefined,
            onClick: undefined
        },
        {
            title: t("settings.password.label"),
            subtitle: "********",
            icon: undefined,
            path: AppRoutes.PRIVATE_SETTINGS_PASSWORD,
            onClick: undefined
        },
    ]

    return (
        <>
            <div className="w-full flex flex-col justify-start gap-6">
                <MultiDirectionIcon
                    rtlIcon={
                        <ArrowRightIcon
                            onClick={() => { navigate(-1) }}
                            height={32}
                            width={32}
                            className="cursor-pointer"
                        />
                    }
                    ltrIcon={
                        <ArrowLeftIcon
                            onClick={() => { navigate(-1) }}
                            height={32}
                            width={32}
                            className="cursor-pointer"
                        />
                    }
                />
                <div className="w-full grid grid-cols-3 max-md:grid-cols-1 justify-between gap-12 bg-white max-sm:w-full max-md:w-full max-lg:w-full max-xl:w-full">
                    {
                        account?.map((item: any) => {
                            return (<MenuNavLink item={item} /> )
                        })
                    }
                </div>
            </div>
        </>
    )

}
