import {Button} from "@nextui-org/react";
import React from "react";
import {useTranslation} from "react-i18next";
import {useCancelInvitationMutation} from "../../../_redux/features/network/services";
import {CandidateInterface} from "../../../_redux/features/events/interfaces";

export default function CancelInvitationButton({user}: {user: CandidateInterface}) {

    const {t} = useTranslation()
    const [cancelAction, cancelTriggers] = useCancelInvitationMutation()

    return (
        <Button
            radius="full"
            variant="bordered"
            color="danger"
            disabled={cancelTriggers?.isLoading || cancelTriggers?.isFetching}
            isLoading={cancelTriggers?.isLoading || cancelTriggers?.isFetching}
            className="font-xing-bold text-sm"
            onClick={() => cancelAction(user?.friendShip?.id)}
        >
            {t("cancel.label")}
        </Button>
    )
}