import { Form, useFormikContext } from "formik";
import { Button } from "@nextui-org/react";
import { PlusCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import SelectLanguagesLevel from "../_field/Specified/SelectLanguagesLevel";
import SelectLanguages from "../_field/Specified/SelectLanguages";

interface LanguageAddFormPropsInterface {
  triggers: MutationResultSelectorResult<any>;
  onCancel?: () => void | undefined;
}

export default function LanguageAddForm({
  triggers,
  onCancel = undefined,
}: LanguageAddFormPropsInterface) {
  const { t } = useTranslation();
  const { isSubmitting } = useFormikContext();

  return (
    <Form className="w-full flex flex-col gap-4">
      <SelectLanguages fullWidth isRequired name="language" />
      <SelectLanguagesLevel name="level" isRequired />
      <div className="flex flex-row justify-center items-center gap-6">
        {typeof onCancel === "function" && (
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
            startContent={<XMarkIcon height={24} width={24} />}
          >
            {t("close.label")}
          </Button>
        )}
        <Button
          type="submit"
          disabled={isSubmitting || triggers.isLoading}
          isLoading={isSubmitting || triggers.isLoading}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          color="warning"
          startContent={
            isSubmitting || triggers.isLoading ? null : (
              <PlusCircleIcon height={24} width={24} />
            )
          }
        >
          {t("add.label")}
        </Button>
      </div>
    </Form>
  );
}
