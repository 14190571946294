import { Form, useFormikContext } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import SelectFunctionType from "../_field/Specified/SelectFunctionType";
import Input from "../_field/Input";
import SelectCareerLevel from "../_field/Specified/SelectCareerLevels";
import SelectMobility from "../_field/Specified/SelectMobility";
import SelectAvailability, {
  AVAILABILITY_WITH_NOTICE,
} from "../_field/Specified/SelectAvailability";
import SelectSalary from "../_field/Specified/SelectSalary";
import SelectCountry from "../_field/Specified/SelectCountry";
import SelectCity from "../_field/Specified/SelectCity";
import ExpectationAccordion from "../../../pages/private/expectations/ExpectationAccordion";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import DomainOutlinedIcon from "@mui/icons-material/DomainOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import SelectRegion from "../_field/Specified/SelectRegion";

interface SelectionCriteriaEditFormInterface {
  trigger: MutationResultSelectorResult<any>;
}

export default function SelectionCriteriaEditForm({
  trigger,
}: SelectionCriteriaEditFormInterface) {
  const { t } = useTranslation();
  const {
    handleChange,
    submitForm,
    setFieldValue,
    isSubmitting,
    values,
    initialValues,
    errors,
  } = useFormikContext();
  const [onFocus, setOnFocus] = useState(false);

  const onChangeHandler = (e: any) => {
    handleChange(e);
    submitForm();
  };

  const onChangeAvailibilityHandler = (e: any) => {
    handleChange(e);
    if (values?.disponibility === AVAILABILITY_WITH_NOTICE) {
      submitForm();
    }
  };
  return (
    <Form className="w-full flex flex-col gap-4">
      <ExpectationAccordion
        isOpenAccordion={errors.targetJob}
        icon={<WorkOutlineOutlinedIcon />}
        title={t("find.jobs.expectations.target.title")}
        subtitle={t("find.jobs.expectations.target.description")}
        input={
          <div className="flex flex-row justify-between items-center gap-1">
            <Input
              fullWidth
              variant="standard"
              label={t("job.target.label")}
              placeholder={t("job.target.placeholder")}
              name="targetJob"
              disabled={isSubmitting}
              onFocus={() => setOnFocus(true)}
              onBlur={(e) => {
                if (values?.targetJob !== initialValues?.targetJob) {
                  onChangeHandler(e);
                }
                setOnFocus(false);
              }}
            />
            {onFocus && <CheckOutlinedIcon />}
          </div>
        }
      />
      <ExpectationAccordion
        icon={<FlagOutlinedIcon />}
        isOpenAccordion={errors.country}
        title={t("countries.label", { count: 1 })}
        subtitle={t("find.jobs.expectations.country.description")}
        input={
          <SelectCountry
            variant="standard"
            name="country"
            disabled={isSubmitting}
            onBlur={(e) => {
              setFieldValue("country", e.target.value);
              setFieldValue("region", "");
              setFieldValue("city", "");
              handleChange(e);
              submitForm();
            }}
          />
        }
      />

      <ExpectationAccordion
        icon={<DomainOutlinedIcon />}
        title={t("regions.label", { count: 1 })}
        subtitle={t("find.jobs.expectations.region.description")}
        isOpenAccordion={errors.region}
        input={
          <SelectRegion
            variant="standard"
            name="region"
            disabled={isSubmitting}
            countryId={values?.country?.split("/")[3]}
            onBlur={(e) => {
              setFieldValue("region", e.target.value);
              setFieldValue("city", "");
              handleChange(e);
              submitForm();
            }}
          />
        }
      />

      <ExpectationAccordion
        icon={<WorkOutlineOutlinedIcon />}
        title={t("cities.label", { count: 1 })}
        subtitle={t("find.jobs.expectations.city.description")}
        isOpenAccordion={errors.city}
        input={
          <SelectCity
            variant="standard"
            name="city"
            disabled={isSubmitting}
            regionId={values?.region?.split("/")[3]}
            onBlur={(e) => {
              handleChange(e);
              submitForm();
            }}
          />
        }
      />

      <ExpectationAccordion
        icon={<WorkOutlineOutlinedIcon />}
        isOpenAccordion={errors.mobility}
        title={t("find.jobs.expectations.mobility.title")}
        subtitle={t("find.jobs.expectations.mobility.description")}
        input={
          <SelectMobility
            variant="standard"
            name="mobility"
            onChange={(e) => {
              handleChange(e);
              submitForm();
            }}
          />
        }
      />

      <ExpectationAccordion
        icon={<WorkOutlineOutlinedIcon />}
        title={t("find.jobs.expectations.salary.title")}
        isOpenAccordion={errors.salary}
        subtitle={t("find.jobs.expectations.salary.description")}
        input={
          <SelectSalary
            variant="standard"
            name="salary"
            onChange={(e) => {
              handleChange(e);
              submitForm();
            }}
          />
        }
      />

      <ExpectationAccordion
        icon={<WorkOutlineOutlinedIcon />}
        title={t("find.jobs.expectations.availability.title")}
        subtitle={t("find.jobs.expectations.availability.description")}
        isOpenAccordion={errors.disponibility}
        input={
          <>
            <SelectAvailability
              variant="standard"
              name="disponibility"
              onChange={(e) => {
                setFieldValue("disponibility", e.target.value);
                if (e.target.value !== AVAILABILITY_WITH_NOTICE) {
                  // setFieldValue("disponibilityDuration", 0)
                  handleChange(e);
                  submitForm();
                }
              }}
            />
            {values?.disponibility === AVAILABILITY_WITH_NOTICE && (
              <Input
                variant="standard"
                fullWidth
                label={t("availability.duration.label")}
                placeholder={t("availability.duration.placeholder")}
                name="disponibilityDuration"
                type={"number"}
                endContent={t("days.label", {
                  count: values?.disponibilityDuration,
                })}
                disabled={
                  isSubmitting ||
                  values?.disponibility !== AVAILABILITY_WITH_NOTICE
                }
                onBlur={onChangeAvailibilityHandler}
              />
            )}
          </>
        }
      />

      <ExpectationAccordion
        icon={<WorkOutlineOutlinedIcon />}
        isOpenAccordion={errors.function}
        title={t("find.jobs.expectations.function.title")}
        subtitle={t("find.jobs.expectations.function.description")}
        input={
          <SelectFunctionType
            id="@id"
            variant="standard"
            name="function"
            onChange={(e) => {
              handleChange(e);
              submitForm();
            }}
          />
        }
      />

      <ExpectationAccordion
        icon={<WorkOutlineOutlinedIcon />}
        title={t("find.jobs.expectations.careerLevel.title")}
        subtitle={t("find.jobs.expectations.careerLevel.description")}
        isOpenAccordion={errors.careerLevel}
        input={
          <SelectCareerLevel
            variant="standard"
            name="careerLevel"
            onChange={(e) => {
              handleChange(e);
              submitForm();
            }}
          />
        }
      />
    </Form>
  );
}
