import React from "react";
import { useTranslation } from "react-i18next";
import { useGetCvQuery } from "../../../../_redux/auth/services";
import TitledContainer from "../../../../components/containers/TitledContainer";
import NoExperienceFound from "../../../../components/noResultFound/NoExperienceFound";
import Experience from "./myProfile/Experience";
import { useParams } from "react-router-dom";
import { useGetSingleCandidateQuery } from "../../../../_redux/features/network/services";
import { Skeleton } from "@mui/material";

export default function Experiences() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: profile } = useGetSingleCandidateQuery(parseInt(id), {
    skip: !id,
  });
  const { data: cv, isLoading: isCvLoading } = useGetCvQuery(profile?.id, {
    skip: !profile?.id,
  });

  if (isCvLoading) {
    return (
      <div className="flex flex-col justify-between gap-3">
        <Skeleton animation="wave" variant="rounded" width="70%" height={30} />
        <Skeleton animation="wave" variant="rounded" width="80%" height={20} />
        <Skeleton animation="wave" variant="rounded" width="60%" height={20} />
      </div>
    );
  }

  if (!cv?.experiences?.length) {
    return null;
  }

  return (
    <>
      <TitledContainer title={t("professionalExperience.label")}>
        <div className="flex flex-col justify-between items-center gap-3">
          {
            // @ts-ignore
            cv?.experiences?.length > 0 ? (
              cv?.experiences?.map((experience) => (
                <Experience key={experience["@id"]} experience={experience} />
              ))
            ) : (
              <NoExperienceFound />
            )
          }
        </div>
      </TitledContainer>
    </>
  );
}
