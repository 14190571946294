import { Form, useFormikContext } from "formik";
import { Button } from "@nextui-org/react";
import Input from "../_field/Input";
import SelectStudyLevel from "../_field/Specified/SelectStudyLevel";
import SelectCertificateDomain from "../_field/Specified/SelectCertificateDomain";
import SelectCertificateType from "../_field/Specified/SelectCertificateType";
import Textarea from "../_field/Textarea";
import {
  PencilIcon,
  PlusCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MutationResultSelectorResult } from "@reduxjs/toolkit/query";
import CustomSpinner from "../../spinner/CustomSpinner";

interface CertificateEditFormInterface {
  triggers: MutationResultSelectorResult<any>;
  onCancel?: () => void | undefined;
}

export default function CertificateEditForm({
  triggers,
  onCancel = () => undefined,
}: CertificateEditFormInterface) {
  const { t } = useTranslation();
  const {
    setErrors: setErrorsHook,
    setSubmitting,
    isSubmitting,
    initialValues,
  } = useFormikContext();
  const { isSuccess, isLoading, isError, error, reset } = triggers;

  return (
    <Form className="w-full flex flex-col justify-center items-center gap-4">
      <Input
        fullWidth
        label={t("certificate.title.label")}
        placeholder={t("certificate.title.placeholder")}
        name="title"
        disabled={isSubmitting}
      />
      <Input
        fullWidth
        name="school"
        label={t("certificate.school.label")}
        placeholder={t("certificate.school.placeholder")}
        required
        disabled={isSubmitting}
      />
      <SelectStudyLevel name="studyLevel" isRequired disabled={isSubmitting} />
      <SelectCertificateDomain
        name="domain"
        isRequired
        disabled={isSubmitting}
      />
      <SelectCertificateType name="type" isRequired disabled={isSubmitting} />
      <Input
        type="month"
        fullWidth
        name="startYear"
        label={t("certificate.startYear.label")}
        placeholder={t("certificate.startYear.placeholder")}
        required
        disabled={isSubmitting}
      />
      <Input
        fullWidth
        label={t("certificate.endDate.label")}
        placeholder={t("certificate.endDate.placeholder")}
        name="endDate"
        type="month"
        required
        disabled={isSubmitting}
      />
      <Textarea
        fullWidth
        label={t("certificate.description.label")}
        placeholder={t("certificate.description.placeholder")}
        name="description"
        isRequired
        disabled={isSubmitting}
      />
      <div className="flex flex-row justify-center items-center gap-6">
        {typeof onCancel === "function" && (
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
            startContent={<XMarkIcon height={24} width={24} />}
          >
            {t("close.label")}
          </Button>
        )}
        <Button
          type="submit"
          spinner={<CustomSpinner />}
          isLoading={isLoading || isSubmitting}
          disabled={isSubmitting}
          className="text-white py-8 px-6 text-xl font-normal flex flex-row justify-around"
          color="warning"
          startContent={
            isLoading || isSubmitting ? null : (
              <PencilIcon height={24} width={24} />
            )
          }
        >
          {t("update.label")}
        </Button>
      </div>
    </Form>
  );
}
