import React from "react";
import Select from "../Select";
import { useTranslation } from "react-i18next";
import { useGetCareerLevelsQuery } from "../../../../_redux/features/careerLevel/services";
import { careerLevelInterface } from "../../../../_redux/features/careerLevel/types";
import { MenuItem } from "@mui/material";

interface SelectPropsInterface {
  name: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  ch?: boolean;
  onChange?: (e: any) => void;
}

export default function SelectCareerLevels(
  props: SelectPropsInterface
): JSX.Element {
  const { t } = useTranslation();
  const { data, isLoading } = useGetCareerLevelsQuery({ pagination: false });
  const { list: careerLevels } = data || {};

  return (
    <Select
      variant="standard"
      fullWidth
      isLoading={isLoading}
      label={t("career.level.label")}
      placeholder={t("career.level.placeholder")}
      {...props}
    >
      {careerLevels?.map((careerLevel: careerLevelInterface) => (
        <MenuItem key={careerLevel["@id"]} value={careerLevel["@id"]}>
          {careerLevel?.name}
        </MenuItem>
      ))}
    </Select>
  );
}
