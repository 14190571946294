import { json, LoaderFunctionArgs } from "react-router-dom";
import { jobApi, removeEmptyProperty } from "./features/jobs/services";
import { careerLevelApi } from "./features/careerLevel/services";
import { functionTypeApi } from "./features/functionType/services";
import { activitySectorGroupApi } from "./features/activitySectorGroup/services";
import { regionApi } from "./features/region/services";
import { paramsExperiencesApi } from "./features/experiences/services";
import { contractTypeApi } from "./features/contractType/services";
import { workplaceApi } from "./features/workplace/services";
import { profileApi } from "./auth/services";
import { selectJobsSearch, setSearch } from "./features/jobs/jobsSlice";
import { useSelector } from "react-redux";
import { store } from "./store";
export const loadJobs = async ({ context }: LoaderFunctionArgs) => {
  const { store } = context;

  const jobsResult = await store.dispatch(jobApi.endpoints.getJobs.initiate());

  if (jobsResult.error) {
    throw new Error(jobsResult.error?.message);
  }

  await Promise.all(store.dispatch(jobApi.util.getRunningQueriesThunk()));
  return json({ jobsResult });
};

export const loadTopCompanies = async ({ context }: LoaderFunctionArgs) => {
  const { store } = context;

  const topCompanies = await store.dispatch(
    jobApi.endpoints.getTopRecruiters.initiate()
  );

  if (topCompanies.error) {
    throw new Error(topCompanies.error?.message);
  }

  return json({ topCompanies });
};

export const loadFunctions = async ({ context }: LoaderFunctionArgs) => {
  const { store } = context;

  const functions = await store.dispatch(
    jobApi.endpoints.getFunctions.initiate()
  );

  if (functions.error) {
    throw new Error(functions.error?.message);
  }

  return json({ functions });
};

export const loadRegions = async ({ context }: LoaderFunctionArgs) => {
  const { store } = context;

  const regions = await store.dispatch(jobApi.endpoints.getRegions.initiate());

  if (regions.error) {
    throw new Error(regions.error?.message);
  }

  return json({ regions });
};

export const loadPublicHomeData = async ({ context }: LoaderFunctionArgs) => {
  const { store } = context || {};
  if (!store) {
    return json({});
  }

  const [recentJobs, topCompanies, regions, functions] = await Promise.all([
    store.dispatch(jobApi.endpoints.getRecentJobs.initiate({ limit: 9 })),
    store.dispatch(jobApi.endpoints.getTopRecruiters.initiate({ _page: 1 })),
    store.dispatch(jobApi.endpoints.getRegions.initiate({ limit: 9 })),
    store.dispatch(jobApi.endpoints.getFunctions.initiate({ limit: 9 })),
  ]);

  if (
    recentJobs.error ||
    topCompanies.error ||
    functions.error ||
    regions.error
  ) {
    throw new Error(
      `${recentJobs.error?.message}\n${topCompanies.error?.message}\n${functions.error?.message}\n${regions.error?.message}\n`
    );
  }
  return json(store.getState());
};

export const loadPublicJobsData = async ({
  params,
  context,
}: LoaderFunctionArgs) => {
  const search = store.getState().jobs.search;
  const {
    companyId,
    regionFilterParams,
    sectorFilterParams,
    functionFilterParams,
    keyword,
  } = params;
  if (
    companyId ||
    regionFilterParams ||
    sectorFilterParams ||
    functionFilterParams ||
    keyword
  ) {
    // 0 for the id and 1 for the name

    const params = removeEmptyProperty({
      ...search,
      keyword: keyword ? keyword : "",
      location: "",
      careerLevel: [],
      nbMonthExperience: [],
      contractTypes: [],
      workplace: [],
      company: companyId ? companyId : "",
      ...(regionFilterParams
        ? {
            cardinals: [regionFilterParams.split("-")[1]],
          }
        : { cardinals: [] }),
      ...(sectorFilterParams
        ? {
            sectorGroup: [sectorFilterParams.split("-")[0]],
          }
        : { sectorGroup: [] }),
      ...(functionFilterParams
        ? {
            function: [functionFilterParams.split("-")[0]],
          }
        : { function: [] }),

      _page: 1,
    });

    store.dispatch(setSearch({ search: params }));
  }

  return json(store.getState());
};

export const loadJobData = async ({ context, params }: LoaderFunctionArgs) => {
  const { store } = context || {};
  const { id } = params || {};

  if (!store || !id) {
    return json({});
  }
  const [Job] = await Promise.all([
    store.dispatch(jobApi.endpoints.getSingleJob.initiate(id)),
  ]);
  if (Job.error) {
    throw new Error(`${Job.error?.message}\n`);
  }
  return json(store.getState());
};

export const loadPolicy = async ({ context }: LoaderFunctionArgs) => {
  const { store } = context || {};

  if (!store) {
    return json({});
  }
  const policy = await store.dispatch(
    profileApi.endpoints.getPolicy.initiate({ locale: "fr" })
  );
  if (policy.error) {
    throw new Error(policy.error?.message);
  }

  return json({ policy });
};
