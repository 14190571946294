import React, {useEffect} from "react";
import {Form, useFormikContext} from "formik";
import {Button} from "@nextui-org/react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {MutationResultSelectorResult} from "@reduxjs/toolkit/query";
import Input from "../_field/Input";
import CustomSpinner from "../../spinner/CustomSpinner";

interface SendResetPasswordEmailFormInterface {
    triggers: MutationResultSelectorResult<any>;
}
export default function SendResetPasswordEmailForm({triggers}: SendResetPasswordEmailFormInterface) {

    const {t} = useTranslation()
    const {setErrors: setErrorsHook, setSubmitting, initialValues, isSubmitting} = useFormikContext();
    const {isSuccess, isLoading, isError, error, reset, data} = triggers

    useEffect(() => {
        return () => reset()
    }, [triggers]);

    useEffect(() => {
        if (isSuccess) {
            toast.success(data["hydra:description"] , {
                position: toast.POSITION.BOTTOM_LEFT
            });

            if (typeof data === "string") {
                toast.success(data , {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }

            setErrorsHook({})
            reset();
        }
    }, [isSuccess]);

    useEffect(() => {
        setSubmitting(isLoading)
    }, [isLoading]);

    useEffect(() => {
        if (isError) {
            let errors = {}
            // @ts-ignore
            error?.data?.violations?.forEach((violation: { propertyPath: any; message: any; }) => {
                // @ts-ignore
                if (violation?.propertyPath in initialValues) {
                    errors[violation?.propertyPath] = violation?.message;
                } else {
                    toast.error(`${violation?.propertyPath}: ${violation?.message}`, {
                        position: toast.POSITION.BOTTOM_LEFT
                    });
                }
            });

            if ( triggers?.error?.data &&"hydra:description" in triggers?.error?.data && typeof triggers?.error?.data["hydra:description"] === "string") {
                toast.error(triggers?.error?.data["hydra:description"], {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }

            if (errors) {
                setErrorsHook(errors)
            }
        }
    }, [isError, error]);

    return (
        <Form className="w-full flex flex-col justify-center gap-3">
            <Input
                label={t("email.label")}
                type="email"
                name="email"
                placeholder={t("email.placeholder")}
                required
            />
            <Button
                type="submit"
                variant="solid"
                color="warning"
                radius="full"
                className="w-full font-xing-bold first-letter:capitalize text-[16px]"
                disabled={isSubmitting || isLoading}
                isLoading={isSubmitting || isLoading}
                spinner={ <CustomSpinner /> }
            >
                {t("actions.next.label")}
            </Button>
        </Form>
    )
}