import {Field, useField} from "formik";
import React from "react";
import {Autocomplete as NextAutocomplete} from "@nextui-org/react";

interface AutoCompletePropsInterface {
    name: string;
    type?: string;
    disabled?: boolean;
    isLoading?: boolean;
    fullWidth?: boolean;
    label: string;
    placeholder?: string;
    isRequired?: boolean;
    children: any;
    onInputChange?: (value: string) => void;
    onSelectionChange?: (key: string) => void;
    onBlur?: (e: any) => void;
}

export default function AutoComplete(props: AutoCompletePropsInterface): JSX.Element {

    const [field, meta, helpers] = useField(props);

    return (
        <Field onInputChange={(e: string) => helpers.setValue(e)}
               selectedKeys={[field.value]}
               {...field} {...props}
               allowsCustomValue
               fullWidth
               as={NextAutocomplete}
               errorMessage={meta.error}
               isInvalid={meta.error && meta.touched}
        />
    )
}