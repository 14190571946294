import React from "react";
import {useFormikContext} from "formik";
import CustomCheckbox from "../../../../components/_form/_field/Checkbox";
import {useTranslation} from "react-i18next";
import DropDownFilter from "./DropDownFilter";
import {useGetContractTypesQuery} from "../../../../_redux/features/contractType/services";

export default function ContratTypeFilter() {

    const {t} = useTranslation()
    const {values, handleChange} = useFormikContext();

    const {data, isLoading} = useGetContractTypesQuery({pagination: false});
    const {list: contracts} = data || {}

    return (
            <DropDownFilter
                hasBadge={values?.contractTypes?.length}
                isLoading={isLoading}
                label={t("contract.type.label")}
            >
                { contracts &&
                    <div className="flex flex-col justify-between px-3">
                        { contracts?.map((contract: any, index: number) =>
                            <CustomCheckbox
                                key={index}
                                disabled={isLoading}
                                defaultChecked={values?.contractTypes?.find((f: any) => f == contract?.id)}
                                value={contract?.id}
                                onChange={handleChange}
                                label={contract?.name}
                                name="contractTypes"
                            />
                        ) }
                    </div>
                }
            </DropDownFilter>
    )
}