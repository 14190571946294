import React from "react";
import LanguageItem from "./myProfile/LanguageItem";
import {CvXLanguageInterface} from "../../../../_redux/features/languages/cvXLanguageInterface";

export function LanguagesList({languages}: {languages: CvXLanguageInterface[]}) {

    if (languages?.length === 0) {
        return null;
    }

    return (
        <div className="flex flex-col justify-between gap-3">
            {
                languages?.map((language : any) => <LanguageItem language={language} key={language["@id"]}/>).sort((a, b) => a["languageName"] - b["languageName"])
            }
        </div>
    )
}