import React from "react";
import { Button } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { PencilIcon } from "@heroicons/react/24/outline";
import { CertificateInterface } from "../../../../pages/private/profile/ui/myProfile/MyCertificates";
import CertificateEditForm from "../../../_form/Certificate/CertificateEditForm";
import { certificateSchema } from "../../../_form/_schema/certificateSchema";
import { Formik } from "formik";
import moment from "moment/moment";
import { useUpdateCvCertificateMutation } from "../../../../_redux/auth/services";

import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../../_helpers/toastErrorMessage";

type CertificateEditModalProps = {
  certificate: CertificateInterface;
};

export default function CertificateEditModal({
  certificate,
}: CertificateEditModalProps) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [updateCertificateAction, triggers] = useUpdateCvCertificateMutation(
    certificate?.id
  );

  let initialValues = {
    title: certificate.title,
    school: certificate.school,
    startYear: moment(certificate.startYear, "yyyy").format("yyyy-MM"),
    endDate: moment(certificate.endDate, "yyyy").format("yyyy-MM"),
    domain: certificate?.domain?.["@id"],
    type: certificate?.type?.["@id"],
    studyLevel: certificate?.studyLevel?.["@id"],
    description: certificate?.description,
  };

  const handelSubmit = async (values: CertificateInterface) => {
    const endDate = parseInt(moment(values.endDate, "yyyy-MM").format("yyyy"));
    const startYear = parseInt(
      moment(values.startYear, "yyyy-MM").format("yyyy")
    );
    updateCertificateAction({
      id: certificate?.id,
      ...values,
      startYear,
      endDate,
    })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", { value: t("certificate.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
        handleClose();
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };
  const shema = certificateSchema();

  return (
    <>
      <Button
        isIconOnly
        aria-label={t("update.label")}
        title={t("update.specific", { value: t("certificate.label") })}
        className="p-0"
        size="sm"
        onClick={handleOpen}
        variant="light"
      >
        <PencilIcon height={18} width={18} />
      </Button>
      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("update.specific", {
          value: t("professionalExperience.label"),
        })}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("update.specific", { value: t("certificate.label") })}
                </h1>

                <Formik
                  initialValues={initialValues as CertificateInterface}
                  onSubmit={handelSubmit}
                  validationSchema={shema}
                >
                  <CertificateEditForm
                    triggers={triggers}
                    onCancel={handleClose}
                  />
                </Formik>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
