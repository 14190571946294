import React from 'react';
import CustomLoader from "../customLoader/CustomLoader";

interface TitledContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    title: string,
    subtitle?: string,
    isLoading?: boolean,
    isFullWidth?: boolean,
    buttons?: JSX.Element[]
}

const TitledContainer: React.FC<TitledContainerProps> = ({ title, subtitle, className, buttons, isLoading = false, isFullWidth = false,  ...props }) => {

    const customClassName = "w-full rounded-2xl p-1 gap-3";
    const combinedClassName = `${customClassName} ${className || ''}`;

    return (
        <div id={props?.id} className="w-full relative flex flex-col justify-start items-center gap-6 mb-6">
            <div className="w-full flex flex-row justify-start max-md:justify-between items-center gap-3 mt-3 mb-6">
                <div className={`${(isFullWidth ? "w-full" : "")} flex flex-col justify-between items-start`}>
                    <div className="text-3xl font-xing-bold first-letter:uppercase">
                        {title}
                    </div>
                    <div>
                        <p className="font-xing text-medium text-[#5e737d]">
                            {subtitle}
                        </p>
                    </div>
                </div>
                <div className="grow flex flex-row gap-3">
                    { isLoading ? <CustomLoader /> : buttons && buttons.map((button) => button) }
                </div>
            </div>
            <div className={combinedClassName} {...props}>
                { props.children }
            </div>
        </div>
    );
}

export default TitledContainer;