import CustomUserAvatar from "../../../components/customAvatar/CustomUserAvatar";
import moment from "moment/moment";
import { useGetProfileQuery } from "../../../_redux/auth/services";
import { AppRoutes } from "../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { Visibility } from "@mui/icons-material";
import { MessageInterface } from "./Conversation";
import { useTranslation } from "react-i18next";

export default function Message({
  message,
  hideDate,
}: {
  message: MessageInterface;
  hideDate: boolean;
}) {
  const { i18n } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const navigate = useNavigate();
  const seeProfile = () =>
    navigate(
      AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
        ":locale",
        `${i18n.language}`
      ).replace(":id", message.sender?.id)
    );

  return (
    <div
      key={message?.id}
      id={`message-${message?.id}`}
      className={`flex flex-col justify-between ${
        message?.sender?.id === profile?.id ? "items-start" : "items-end"
      } gap-2`}
    >
      {!hideDate && (
        <div className="italic text-xs text-gray-400 w-full text-center">
          {moment().to(message?.sendDate)}
        </div>
      )}

      <div
        className={`flex ${
          message?.sender?.id === profile?.id ? "flex-row" : "flex-row-reverse"
        } justify-start items-center gap-3`}
      >
        <div className="shrink-0 h-12 w-12">
          <CustomUserAvatar
            title={message?.sender?.id}
            onClick={seeProfile}
            src={message?.sender?.imageUrl}
            className="object-cover cursor-pointer overflow-hidden h-12 w-12"
          />
        </div>
        <div className="text-sm font-semibold text-black">
          {message?.sender?.fullname}
        </div>
      </div>
      <div
        className={`flex flex-col flex-end ${
          message?.sender?.id === profile?.id ? "items-start" : "items-end"
        }`}
      >
        <div
          key={message?.id}
          title={moment(message?.sendDate).format("llll")}
          className={`break-all p-3 rounded-t-xl ${
            message?.sender?.id === profile?.id
              ? ` ${
                  message?.seenDate ? "cursor-pointer" : ""
                } bg-white border-solid border-2 border-gray-300 rounded-br-xl `
              : " rounded-bl-xl bg-gray-100"
          }`}
        >
          {message?.content}
        </div>

        {message?.seenDate && message?.sender?.id === profile?.id && (
          <div className="italic text-[0.6rem] text-gray-400 ease-in-out duration-300 transition-all px-0.5">
            <Visibility fontSize={"0.6rem"} /> {moment().to(message?.seenDate)}
          </div>
        )}
      </div>
    </div>
  );
}
