import React from "react";
import LanguagesIllustration from "../illustration/LanguagesIllustration";
import ColoredContainer from "../containers/ColoredContainer";
import LanguageAddModal from "../modals/Profile/Langue/LanguageAddModal";
import {Add} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

export default function NoLanguagesFound() {

    const {t} = useTranslation()

    return (
        <ColoredContainer>
            <div className="w-full flex flex-row max-lg:flex-col justify-evenly items-center gap-6 p-3">
                <div>
                    <LanguagesIllustration primary="#d9d9d9" secondary="#ffb836" />
                </div>
                <div className="flex flex-col justify-between items-start gap-3">
                    <h1 className="font-xing-bold">
                        {t("profile.languages.do.you.speak.spanish.label")}
                    </h1>
                    <p>
                        {t("profile.languages.what.are.your.passions.outside.of.your.professional.life.label")}
                    </p>
                    <LanguageAddModal
                        variant="bordered"
                        color="primary"
                        radius="full"
                        className="font-xing-bold"
                        icon={<Add height={10} width={10} />}
                        text="Ajouter des langues"
                    />
                </div>
            </div>
        </ColoredContainer>
    )
}