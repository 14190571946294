import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { Formik } from "formik";
import { object, string } from "yup";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
} from "../../../_redux/auth/services";
import { ProfileInterface } from "../../../pages/private/profile/MyProfile";
import CoordinatesForm from "../../_form/confidentiality/CoordinatesForm";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

interface UseDisclosureProps {
  isOpen: boolean;
  onClose?(): void;
}

export default function CoordinatesModal({
  isOpen,
  onClose,
}: UseDisclosureProps) {
  const { t } = useTranslation();
  const { data: profile } = useGetProfileQuery();
  const [updateProfileAction, triggers] = useUpdateProfileMutation();

  const contactDetailsSchema = object().shape({
    visibility: string().required(),
  });

  const initialValues = {
    visibility: profile?.visibility,
  };

  const handleSubmit = async (values: ProfileInterface) => {
    updateProfileAction({ id: profile?.id, ...values })
      .unwrap()
      .then((response) => {
        toast.success(
          t("update.message.specific", { value: t("profile.label") }),
          {
            position: toast.POSITION.BOTTOM_LEFT,
          }
        );
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  return (
    <Modal
      className="min-h-full"
      open={isOpen}
      onClose={onClose}
      title={t("update.specific", {
        value: t("settings.confidentiality.contact.details.label"),
      })}
    >
      <ModalOverflow>
        <ModalDialog variant="plain" layout="fullscreen">
          <ModalClose onClick={onClose} />

          <Container maxWidth="md">
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              spacing={3}
              flex={3}
              maxWidth="md"
            >
              <h1 className="text-4xl font-xing-bold">
                {t("settings.confidentiality.contact.details.label")}
              </h1>

              <Formik
                initialValues={initialValues as never}
                validationSchema={contactDetailsSchema}
                onSubmit={handleSubmit}
              >
                <CoordinatesForm onCancel={onClose} triggers={triggers} />
              </Formik>
            </Stack>
          </Container>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
  );
}
