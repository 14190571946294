import React, { ReactNode, useEffect } from "react";
import { Button } from "@nextui-org/react";
import { number, object, string } from "yup";
import { Formik } from "formik";
import Loading from "../../../Loading";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import {
  useGetMyCvQuery,
  useGetProfileQuery,
  useUpdateCvMutation,
} from "../../../_redux/auth/services";
import { careerLevelInterface } from "../../../_redux/features/careerLevel/types";
import { AVAILABILITY_WITH_NOTICE } from "../../_form/_field/Specified/SelectAvailability";
import SelectionCriteriaEditForm from "../../_form/Profile/SelectionCriteriaEditForm";
import { Container, Modal } from "@mui/material";
import { ModalClose, ModalDialog, ModalOverflow, Stack } from "@mui/joy";
import { AppRoutes } from "../../../utils/appRoutes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastMessageError } from "../../../_helpers/toastErrorMessage";

export const cvTitleSchema = () =>
  object().shape({
    title: string().required(i18n.t("validation.field.required.unspecific")),
    mobility: string().required().oneOf(["1", "2", "3", ""]).nullable(),
    targetJob: string(),
    disponibility: string().nullable(),
    disponibilityDuration: number()
      .nullable()
      .when("disponibility", {
        is: (availability: string) => availability === AVAILABILITY_WITH_NOTICE,
        then: () =>
          number()
            .positive()
            .required(i18n.t("validation.field.required.unspecific")),
      }),
    careerLevel: string().nullable(),
    salary: string().nullable(),
    country: string().nullable(),
    region: string().nullable(),
    city: string().nullable(),
  });

interface Values {
  title: string;
  mobility: string;
  targetJob: string;
  disponibility: boolean;
  disponibilityDuration: number;
  careerLevel: string | careerLevelInterface;
  country: string | null;
  region: string | null;
  city: string | null;
}

interface SelectionCriteriaEditModalInterface {
  text?: string;
  variant?: string;
  icon?: ReactNode;
}

export default function SelectionCriteriaEditModal({
  text,
  icon,
  ...props
}: SelectionCriteriaEditModalInterface) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: profile, isLoading: isProfileLoading } = useGetProfileQuery();
  const { data: cv, isLoading: isCvLoading } = useGetMyCvQuery(profile?.id, {
    skip: !profile,
  });
  const [updateCvAction, trigger] = useUpdateCvMutation();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let initialValues = {
    title: cv?.title || "",
    mobility: cv?.mobility || "",
    targetJob: cv?.targetJob || "",
    disponibility: cv?.disponibility,
    disponibilityDuration: cv?.disponibilityDuration || 0,
    careerLevel: cv?.careerLevel?.["@id"] || "",
    salary: cv?.salary?.["@id"] || "",
    country: cv?.country?.["@id"] || "",
    region: cv?.region?.["@id"] || "",
    city: cv?.city?.["@id"] || "",
    function: cv?.function?.["@id"] || "",
  };
  const handelSubmit = async (values: Values) => {
    updateCvAction({ id: profile?.id, ...values })
      .unwrap()
      .then((response) => {
        toast.success(t("update.message.specific", { value: "cv" }), {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      })
      .catch((error) => {
        toastMessageError({ error });
      });
  };

  if (isCvLoading || isProfileLoading) {
    return <Loading />;
  }
  const shema = cvTitleSchema();
  return (
    <>
      <Button
        {...props}
        aria-label={t("update.label")}
        onClick={() => {
          navigate(
            AppRoutes.PRIVATE_PROFESSIONAL_EXPECTATIONS.replace(
              ":locale",
              `${i18n.language}`
            )
          );
        }}
        // onClick={handleOpen}
        title={t("update.label")}
      >
        {icon && icon}
        {text && <p className="font-xing-bold">{text}</p>}
      </Button>

      <Modal
        className="min-h-full"
        open={open}
        onClose={handleClose}
        title={t("position.current.label")}
      >
        <ModalOverflow>
          <ModalDialog variant="plain" layout="fullscreen">
            <ModalClose onClick={handleClose} />
            <Container maxWidth="md">
              <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                flex={3}
                maxWidth="md"
              >
                <h1 className="text-4xl font-xing-bold">
                  {t("position.current.label")}
                </h1>
                <Formik
                  initialValues={initialValues as never}
                  onSubmit={handelSubmit}
                  validationSchema={shema}
                >
                  <SelectionCriteriaEditForm
                    onCancel={handleClose}
                    trigger={trigger}
                  />
                </Formik>
              </Stack>
            </Container>
          </ModalDialog>
        </ModalOverflow>
      </Modal>
    </>
  );
}
