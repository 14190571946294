import { Button } from "@nextui-org/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { CandidateInterface } from "../../../_redux/features/events/interfaces";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../utils/appRoutes";

export default function ViewProfileButton({
  user,
}: {
  user: CandidateInterface;
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const viewProfile = () =>
    navigate(
      AppRoutes.PRIVATE_VIEW_CANDIDATE.replace(
        ":locale",
        `${i18n.language}`
      ).replace(":id", user?.id)
    );

  return (
    <Button
      onClick={viewProfile}
      radius="full"
      variant="bordered"
      color="primary"
      className="font-xing-bold text-sm"
    >
      {t("profile.view.label")}
    </Button>
  );
}
