import React, {useState} from "react";
import {Container} from "@mui/material";
import TitledContainer from "../../../components/containers/TitledContainer";
import {useGetUserNetworkQuery} from "../../../_redux/features/network/services";
import NetworkUserItem from "../../../components/networkUserComponent/networkUserItem/NetworkUserItem";
import {Paginator} from "../../../components/paginator/Paginator";
import {useGetProfileQuery} from "../../../_redux/auth/services";
import EmptyNetwork from "./EmptyNetwork";
import {useTranslation} from "react-i18next";

export default function YourNetwork() {

    const {t} = useTranslation();
    const [page, setPage] = useState<number>(1);
    const {data: profile} = useGetProfileQuery()
    const {data, isLoading, isFetching} = useGetUserNetworkQuery(
        {id: profile?.id, filters: {_page: page}}, {skip: !profile}
    )
    const {list: users, pagination} = data || {}

    return (
        <Container maxWidth="md">
            <div className="w-full flex flex-col justify-start items-center gap-6">

                <TitledContainer
                    title={t("network.yours.label")}
                    subtitle=""
                    className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4"
                > {
                    (isLoading)
                        ?
                        <>
                            <NetworkUserItem user={null} isLoading/>
                            <NetworkUserItem user={null} isLoading/>
                            <NetworkUserItem user={null} isLoading/>
                        </>
                        :
                        <>
                            {
                                users?.length
                                    ?
                                    users?.map((user: any, i: number) => <NetworkUserItem user={user} key={i}/>)
                                    :
                                    <EmptyNetwork placeholder={t("network.yours.label", {count: users?.length})}/>
                            }
                        </>
                }
                </TitledContainer>

                {
                    <Paginator
                        count={pagination?.last?._page ?? 1}
                        disabled={isFetching || isLoading}
                        onChange={(e, page) => setPage(page)}
                        page={page ?? 1}
                    />
                }
            </div>
        </Container>
    )
}